import {Box, Flex, Icon, Text} from 'exsportia-components'
import {Formik} from 'formik'
import {FC, useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {object} from 'yup'

import {Button} from '../../../ui/components/button/button'
import {Input} from '../../../ui/components/input/input'
import {useLocale} from '../../../utils/i18n'
import {EMAIL_VALIDATION} from '../../../utils/validation'
import {AuthService} from '..'

export type RequestStatusType = 'done' | 'unset' | 'wrongEmail'

export type ForgotPasswordFormType = {
  email: string
}

const initialValues: ForgotPasswordFormType = {
  email: '',
}

const validationSchema = object().shape({
  email: EMAIL_VALIDATION,
})

export const ForgotPasswordForm: FC = () => {
  const dispatch = useDispatch()
  const {l} = useLocale()
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('unset')
  const contentFromStatus = useMemo(
    () =>
      ({
        done: {
          text: l('recovery_link_is_sent_to_your_email'),
          icon: 'doneIllustration',
          color: '#0DC268',
        },
        unset: {
          text: l('enter_your_email_to_recover'),
        },
        wrongEmail: {
          text: l('thisEmailUnknown'),
          icon: 'blocked',
          color: '#ED0A34',
        },
      })[requestStatus],
    [requestStatus, l],
  )
  const onSubmit = useCallback(({email}: ForgotPasswordFormType) => {
    dispatch(
      AuthService.actions.recoverPasswordRequestAction({
        email,
        callback: (status: RequestStatusType) => setRequestStatus(status),
      }),
    )
  }, [])
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({handleSubmit}) => (
        <Box pb='24px'>
          <>
            <Text settings={{color: contentFromStatus.color || 'unset'}} text={contentFromStatus.text} />
            <Flex justifyContent='center'>
              {contentFromStatus.icon && (
                <Icon
                  settings={{
                    color: contentFromStatus.color,
                    width: '120px',
                    height: '120px',
                    iconHeight: '120px',
                    iconWidth: '120px',
                  }}
                  icon={contentFromStatus.icon}
                />
              )}
            </Flex>
          </>
          <Box py='16px'>
            <Input
              id={'email'}
              disabled={requestStatus === 'done'}
              label={l('whats_your_email', {caseAction: 'capitalize'})}
            />
          </Box>
          <Button
            disabled={requestStatus === 'done'}
            onClick={() => handleSubmit()}
          >
            {l('email_me_recovery_link')}
          </Button>
        </Box>
      )}
    </Formik>
  )
}
