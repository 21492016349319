import {A, Flex, Text} from 'exsportia-components' // Replace 'your-ui-library' with the actual UI library you are using
import React from 'react'

export const ExsportiaCompanyDetails: React.FC = () => {
  return (
    <Flex maxWidth='480px'>
      <Text settings={{overflow: 'visible', croppedLines: 'unset'}}>
        <div>
          Exsprotia EOOD is registered company with its address at Sofia 1504, 17A Tzar Osvoboditel., floor 3, apt. 6,
          Bulgaria registration number 206770878
        </div>
        <div>
          Telephone:{' '}
          <A color={'link'} href='tel:4402032901106'>
            +44 (0) 203 290 1106
          </A>
        </div>
        <div>
          Email:{' '}
          <A color={'link'} href='mailto:accounts@exsportia.com'>
            accounts@exsportia.com
          </A>
          ,{' '}
          <A color={'link'} href='www.exsportia.com'>
            www.exsportia.com
          </A>
        </div>
      </Text>
    </Flex>
  )
}
