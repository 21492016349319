import {API} from '../../networking/http'
import {SessionsList} from '../class-session/types'
import {QueryMethod} from '../helpers/types'

export type GetVenueClassSessionsByDateParams = undefined
export type GetVenueClassSessionsByDateQueryParams = {
  venueId: string
  classId: string
  date: number | string
  memberId?: string
}

export type GetVenueClassSessionsByDateReturnType = SessionsList & {closestSessionTimeStamp?: number}
export type GetVenueClassSessionsByDateQuery = QueryMethod<
  GetVenueClassSessionsByDateParams,
  GetVenueClassSessionsByDateQueryParams,
  GetVenueClassSessionsByDateReturnType
>

export const makeGetClassSessionsByDateEndpoint = () => 'classesSlots/sessionsByDateList'

export const getVenueClassSessionsByDateQuery: GetVenueClassSessionsByDateQuery = ({query}) => ({
  queryKey: ['getVenueInstructorsQuery', query],
  cacheTime: 1000 * 300,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<GetVenueClassSessionsByDateQueryParams, GetVenueClassSessionsByDateReturnType>(
      makeGetClassSessionsByDateEndpoint(),
      {params: config},
    ).then((res) => res.data),
})
