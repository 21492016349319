import {call, put, takeLeading} from 'redux-saga/effects'

import {SignUpMutationParams} from '../../api/auth/sign-up.mutation'
import {APIDomain} from '../../networking/constants'
import {sendRequest} from '../../utils/axios'
import {auth} from '../../utils/firebase'
import {queryClient} from '../../utils/react-query'
import {
  addLeadRequestAction,
  basicActions,
  recoverPasswordRequestAction,
  removeAccountPayload,
  removeAccountRequestAction,
  resetPasswordConfirmRequestAction,
  signInRequestAction,
  signOutRequestAction,
  signUpRequestAction,
} from './actions'
import {AuthService} from './index'

export function* signInRequestSaga({
  payload: {email, venueId, password, callback, addLeadData},
}: ReturnType<typeof signInRequestAction>) {
  yield put(basicActions.isLoading(true))
  const queryRequest = () => queryClient.fetchQuery(AuthService.queries.UserSignInCheckQuery({query: {email, venueId}}))
  try {
    const res: Awaited<ReturnType<typeof queryRequest>> = yield call<any>(queryRequest)
    if (res.status) {
      yield call([auth, auth.signInWithEmailAndPassword], email, password)
      if (addLeadData?.venueId) {
        yield put(addLeadRequestAction({...addLeadData.body, venueId: addLeadData.venueId, userId: auth.currentUser?.uid}))
      }
      callback?.()
    }
  } catch (e: any) {
    console.log(e.message)
  } finally {
    yield put(basicActions.isLoading(false))
  }
}

export function* signOutRequestSaga() {
  yield put(basicActions.isLoading(true))
  try {
    yield call([auth, auth.signOut])
  } catch (e: any) {
    // catch
  } finally {
    yield put(basicActions.isLoading(false))
  }
}

export type removeAccountRes = {
  status: number
  data: string
}

export function* removeAccountRequestSaga(payload: removeAccountPayload) {
  yield put(basicActions.isLoading(true))
  try {
    const endpoint = `${APIDomain}user`
    const res: removeAccountRes = yield call<any>(sendRequest, 'delete', endpoint)
    if (res.status === 200) {
      yield call([auth, auth.signOut])
      if (payload.callback) {
        payload.callback()
      }
    }
  } catch (e: any) {
    // catch
  } finally {
    yield put(basicActions.isLoading(false))
  }
}

export function* signUpRequestSaga({
  payload: {
    dob,
    email,
    phone,
    avatar,
    venueId,
    password,
    callback,
    lastName,
    firstName,
  },
}: ReturnType<typeof signUpRequestAction>) {
  yield put(basicActions.isLoading(true))
  let body: SignUpMutationParams = {
    dob,
    email,
    phone,
    password,
    lastName,
    firstName,
    dobStatus: 'awaiting',
    genderStatus: 'awaiting',
    lastNameStatus: 'awaiting',
    firstNameStatus: 'awaiting',
    name: `${firstName} ${lastName}`,
    role: ['USER'],
    withPassword: true,
  }
  if (avatar) {
    body = {
      ...body,
      avatar,
    }
  }
  const signUp = AuthService.queries.signUpMutation({}).mutationFn
  try {
    const res: Awaited<ReturnType<typeof signUp>> = yield call(signUp, body)
    yield put(
      signInRequestAction({
        email,
        password,
        callback,
        addLeadData: {
          venueId,
          body: {
            ...body,
            avatar: res.avatar,
          },
        },
      }),
      )
  } catch (e: any) {
    // catch
  } finally {
    yield put(basicActions.isLoading(false))
  }
}

export function* recoverPasswordRequestSaga({
  payload: {email, callback},
}: ReturnType<typeof recoverPasswordRequestAction>) {
  yield put(basicActions.isLoading(true))
  try {
    yield call<any>([auth, auth.sendPasswordResetEmail], email)
    callback('done')
  } catch (e: unknown) {
    callback('wrongEmail')
  } finally {
    yield put(basicActions.isLoading(false))
  }
}

export function* resetPasswordConfirmRequestSaga({
  payload: {oobCode, callback, password, onError},
}: ReturnType<typeof resetPasswordConfirmRequestAction>) {
  yield put(basicActions.isLoading(true))
  try {
    yield call({context: auth, fn: auth.confirmPasswordReset}, String(oobCode), password)
    callback()
  } catch (e: unknown) {
    onError()
  } finally {
    yield put(basicActions.isLoading(false))
  }
}

export function* addLeadRequestSaga({
  payload: {email, dob, avatar, phone, lastName, firstName, venueId, userId},
}: ReturnType<typeof addLeadRequestAction>) {
  yield put(basicActions.isLoading(true))
  try {
    const endpoint = `${APIDomain}members/${venueId}/addOneLead`
    const options = {
      leadData: {
        dob,
        email,
        userId,
        avatar,
        lastName,
        firstName,
        phone: phone || '',
        dobStatus: 'awaiting',
        genderStatus: 'awaiting',
        lastNameStatus: 'awaiting',
        firstNameStatus: 'awaiting',
        name: `${firstName} ${lastName}`,
        isMember: true
      },
    }
    yield call<any>(sendRequest, 'post', endpoint, options)
  } catch (e: unknown) {
    console.log(e)
  }
}

export function* authWatcherSaga() {
  yield takeLeading(signInRequestAction, signInRequestSaga)
  yield takeLeading(signOutRequestAction, signOutRequestSaga)
  yield takeLeading(removeAccountRequestAction, function* (action) {
    yield removeAccountRequestSaga(action.payload)
  })
  yield takeLeading(signUpRequestAction, signUpRequestSaga)
  yield takeLeading(addLeadRequestAction, addLeadRequestSaga)
  yield takeLeading(recoverPasswordRequestAction, recoverPasswordRequestSaga)
  yield takeLeading(resetPasswordConfirmRequestAction, resetPasswordConfirmRequestSaga)
}
