import {API} from '../../networking/http'
import {IntegratedSignUpFormType} from '../../services/auth/forms/integrated-sign-up-form'
import {MutationMethod} from '../helpers/types'
import {User} from '../user/types'

export type IntegratedSignUpParams = undefined
export type IntegratedSignUpMutationParams = IntegratedSignUpFormType
export type IntegratedSignUpMutationReturnType = User & {password: string}
export type IntegratedSignUpMutation = MutationMethod<
  IntegratedSignUpParams,
  IntegratedSignUpMutationParams,
  IntegratedSignUpMutationReturnType
>

export const makeIntegratedSignUpEndpoint = () => 'user/send-credentials'

export const integratedSignUpMutation: IntegratedSignUpMutation = () => ({
  mutationFn: (user) =>
    API.post<IntegratedSignUpMutationParams, IntegratedSignUpMutationReturnType>(
      makeIntegratedSignUpEndpoint(),
      {
        ...user,
        email: user.email.toLowerCase(),
      },
    ).then((res) => res.data),
})
