import {Formik, FormikConfig} from 'formik'
import {FC, useCallback, useMemo, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'

import {AreaSelectArea} from '../../../api/area/types'
import {BookingQueries} from '../../../api/booking'
import {commonServiceBookingDataAdapter, commonServiceBookingPayByTokenAdapter, PayByTokenServiceBookingDraft} from '../../../api/booking/helpers'
import {Instructor, ServiceItem} from '../../../api/booking/types'
import {getEntityDataByIdFromAppearanceSettings} from '../../../api/helpers/data'
import {auth} from '../../../utils/firebase'
import {useLuxon} from '../../../utils/i18n'
import {useModalActions} from '../../../utils/modals'
import {defaultUserValues, IntegratedSignUpFormType} from '../../auth/forms/integrated-sign-up-form'
import {useHandleDraftEntityStatusChange} from '../../payments/hooks/handle-draft-entity-status-change.hook'
import {usePaymentProvider} from '../../payments/hooks/payment-provider-form.provider'
import {useBookingLoader} from '../hooks'
import {BookingService} from '../index'
import {RedirectionComponent} from './components/redirection-component'
import {useOpenBookingValidationModal} from './hooks/open-booking-validation-modal.hook'
import {BookingStateParticipant} from './types'

export type CreateServiceBookingFormType = IntegratedSignUpFormType &
  {
    date: string
    venueId?: string
    specialRequest: string
    bookingType: 'service' | 'space' | 'teacher'
    maxParticipants: number
    minParticipants: number
    isJustTheSpace: boolean
    serviceId: string | null
    coverEquipment?: boolean
    teacherId?: string | null
    teacher: Instructor | null
    duration?: number | string
    hasSpecialRequests: boolean
    service: ServiceItem | null
    area: AreaSelectArea | null
    useForParticipants?: boolean
    entity: 'service' | 'teacher'
    paymentMethodId: string | null
    userId: string | null | undefined
    participants: BookingStateParticipant[]
    time: null[] | [number | null, number | null]
    paymentType?: 'oneTimePayment' | 'payNow' | 'payOnLocation'
    flowStartsFromService?: boolean
  }

type Params = {
  venueId: string
  serviceId: string
}

export const CreateServiceBookingForm: FC = () => {
  const luxon = useLuxon()
  const [entityId, setEntityId] = useState<string | null>(null)
  const {isLoading, setIsLoading} = useBookingLoader()
  const {venueId, serviceId} = useParams<Params>()
  const {closeAllModals} = useModalActions()
  // const initialUserId = useMemo(() => auth.currentUser?.uid, [])
  const navigate = useNavigate()
  const {data: priceDataObjects} = useQuery(BookingQueries.calculateBookingPriceQuery({enabled: false, refetchOnWindowFocus: false}))
  const {openPaymentForm} = usePaymentProvider()
  const {state}: {state?: {service?: ServiceItem}} = useLocation()

  if (!venueId || !serviceId) {
    return null
  }

  const serviceData = getEntityDataByIdFromAppearanceSettings(venueId, serviceId) as ServiceItem
  const {mutate: createServiceBooking} = useMutation(BookingService.mutations.createServiceBookingMutation({}))
  const {mutate: createDraftBooking} = useMutation(BookingService.mutations.createServiceBookingDraftMutation({}))
  const {mutate: createServiceBookingPayByToken} = useMutation(
    BookingService.mutations.createServiceBookingPayByTokenMutation({}),
  )
  const {setStartRefetch} = useHandleDraftEntityStatusChange({
    entity: 'booking',
    entityId,
    isLoading,
    setIsLoading,
  })
  const {openBookingValidationModal} = useOpenBookingValidationModal()

  const initialValues = useMemo<CreateServiceBookingFormType>(
    () => ({
      venueId,
      area: null,
      teacher: null,
      participants: [],
      entity: 'service',
      minParticipants: 1,
      specialRequest: '',
      time: [null, null],
      service: serviceData,
      isJustTheSpace: false,
      serviceId: serviceId!,
      paymentMethodId: null,
      bookingType: 'service',
      hasSpecialRequests: false,
      maxParticipants: Infinity,
      useForParticipants: false,
      date: luxon.now().toFormat('dd/MM/yyyy'),
      ...defaultUserValues,
    }),
    [serviceData, venueId, serviceId],
  ) //eslint-disable-line

  if (state?.service) {
    initialValues.service = state.service
    initialValues.serviceId = state.service.uid
    initialValues.isJustTheSpace = state.service.areaSpace || false
  }

  const handleSubmit = useCallback<FormikConfig<CreateServiceBookingFormType>['onSubmit']>(
    (values) => {
      if (!priceDataObjects) {
        return
      }
      // if (initialUserId !== auth.currentUser?.uid && serviceData.accessForAnyone !== false) {
      //   return openBookingValidationModal({type: 'service'})
      // }
      const isPayNow = values.paymentType !== 'payOnLocation'
      const createBookingFunction = isPayNow ? createServiceBookingPayByToken : createServiceBooking
      const dataForAdapter = {
        bookingState: {
          ...values,
          userId: auth.currentUser?.uid,
        },
        priceDataObjects,
      }
      let adaptedData = null
      let dataForDraft: PayByTokenServiceBookingDraft | null = null
      if (values.paymentType !== 'payOnLocation') {
        adaptedData = commonServiceBookingPayByTokenAdapter(dataForAdapter)
        dataForDraft = adaptedData
      } else {
        adaptedData = commonServiceBookingDataAdapter(dataForAdapter)
      }
      if (values.paymentType === 'oneTimePayment') {
        if (dataForDraft === null) {
          return
        }
        createDraftBooking(
          adaptedData,
          {
            onSuccess: () => {
              setStartRefetch(true)
              setEntityId(dataForDraft?.bookingId || null)
              openPaymentForm && openPaymentForm({
                entity: 'booking',
                bookingId: dataForDraft?.bookingId,
                totalPrice: dataForDraft?.data.totalPrice,
                startProcessCallback: () => setIsLoading(true),
              })
            }
          }
        )
      } else {
        setEntityId(null)
        setIsLoading(true)
        createBookingFunction(
          adaptedData,
          {
            onSuccess: (booking) => {
              closeAllModals()
              setIsLoading(false)
              navigate(`/${venueId}/bookings/${booking.uid}`)
            },
            onError: (error) => {
              console.log(error)
              setIsLoading(false)
              if (error.response?.status === 409) {
                return openBookingValidationModal({type: 'service'})
              }
            },
          },
        )
      }
    },
    [
      venueId,
      navigate,
      setIsLoading,
      closeAllModals,
      openPaymentForm,
      priceDataObjects,
      createDraftBooking,
      createServiceBooking,
      auth.currentUser?.uid,
      openBookingValidationModal,
      createServiceBookingPayByToken,
    ],
  )

  return (
    <Formik
      onSubmit={handleSubmit}
      validateOnChange={false}
      initialValues={initialValues}
    >
      <RedirectionComponent>
        <Outlet />
      </RedirectionComponent>
    </Formik>
  )
}
