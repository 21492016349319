import {Box, Flex, Span} from 'exsportia-components'
import React, {ComponentProps, FC, Fragment, memo, PropsWithChildren, useCallback, useMemo} from 'react'

import {Participant, TimeslotStatesEnum} from '../../../api/booking/types'
import {l, useLocale, useLuxon} from '../../../utils/i18n'
import {useModalActions} from '../../../utils/modals'
import {NO_USER_PHOTO} from '../../../utils/remote-content'
import {ImagesStack} from '../../images-stack/images-stack'
import {getTimeSlotStickyStylesMap} from '../helpers'
import {StickyStatesEnum} from '../types'
import {ParticipantsList} from './participants-list'

type TimeslotStateStyle = {
  box: {
    cursor: 'default' | 'pointer'
    bg?: string
  }
  text: {
    color: string
    opacity?: number
  }
}

type TimeslotStateStylesMap = {
  [key in TimeslotStatesEnum]: TimeslotStateStyle
}

export const timeslotStateStylesMap: TimeslotStateStylesMap = {
  [TimeslotStatesEnum.PAST]: {box: {cursor: 'default'}, text: {color: 'text-secondary', opacity: 0.5}},
  [TimeslotStatesEnum.SELECTED]: {
    box: {cursor: 'pointer', bg: 'primary-secondary'},
    text: {color: 'text-default'},
  },
  [TimeslotStatesEnum.BLOCKED]: {box: {cursor: 'default'}, text: {color: 'text-secondary', opacity: 0.5}},
  [TimeslotStatesEnum.DEFAULT]: {box: {cursor: 'pointer', bg: 'bg'}, text: {color: 'text-default'}},
  [TimeslotStatesEnum.NOT_SELECTABLE]: {box: {cursor: 'pointer', bg: 'bg'}, text: {color: 'text-default'}},
}

const viewStylesMap: {
  [key: number]: {
    box: ComponentProps<typeof Box>
    content: ComponentProps<typeof Box>
    thumb: ComponentProps<typeof Box>
    time: ComponentProps<typeof Span>
  }
} = {
  1: {
    box: {
      px: '16px'
    },
    content: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      px: '16px'
    },
    thumb: {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
      border: 'default-border'
    },
    time: {
      fontWeight: 'bold'
    }
  },
  4: {
    box: {},
    content: {
      flexDirection: 'column',
      justifyContent: 'center'
    },
    thumb: {
      borderColor: 'white'
    },
    time: {}
  }
}

type TimeSlotItemProps = {
  state: TimeslotStatesEnum
  spaces: number
  onClick: (_: number, __?: number) => void
  stickyState: StickyStatesEnum
  isSelectEnd: boolean
  isSelectStart: boolean
  slotTimestamp: number
  fakeValue?: number
  timeSlotsPerRow: number
  participants?: Participant[]
  setFirstPoint: (_: number | null) => void
  setSecondPoint: (_: number | null) => void
  onPressReversed: (_: number) => void
  setSelectedPoint: (_: number | null) => void
  showParticipants?: boolean
  showParticipantsWhenFull?: boolean
}

export const TimeSlotItem: FC<TimeSlotItemProps> = memo(
  ({
    state,
    spaces,
    onClick,
    fakeValue,
    stickyState,
    isSelectEnd,
    participants,
    slotTimestamp,
    isSelectStart,
    setFirstPoint,
    setSecondPoint,
    timeSlotsPerRow,
    setSelectedPoint,
    showParticipants,
    showParticipantsWhenFull
  }) => {
    const luxon = useLuxon()
    const {language} = useLocale()
    const {openModal} = useModalActions()
    const timeSlotStickyStylesMap = useMemo(() => getTimeSlotStickyStylesMap(language), [language])
    const isParticipantsShowed = useMemo(
      () => (showParticipantsWhenFull && spaces === 0) || (showParticipants && spaces !== 0),
      [showParticipantsWhenFull, showParticipants, spaces],
    )
    const openParticipantsModalHandler = useCallback(
      (slotParticipants: Participant[]) => {
        openModal({
          title: l('participants'),
          footerConfig: {
            disabled: true,
          },
          component: <ParticipantsList participants={slotParticipants} />,
        })
      },
      [l, openModal],
    )
    const Wrapper: FC<PropsWithChildren> =
      isSelectStart || isSelectEnd
        ? ({children}) => (
            <Box
              height='100%'
              onClick={() => {
                setSelectedPoint(null)
              }}
            >
              {children}
            </Box>
          )
        : Fragment

    const onTimeslotClick = useCallback(
      () => {
        if (isSelectEnd || isSelectStart) {
          if (isSelectEnd) {
            setSecondPoint(null)
          }
          if (isSelectStart) {
            setFirstPoint(null)
          }
          return
        }
        if (state !== TimeslotStatesEnum.BLOCKED && state !== TimeslotStatesEnum.PAST) {
          onClick(slotTimestamp, fakeValue)
        }
      },
       [isSelectEnd, isSelectStart, setFirstPoint, setSecondPoint, onClick, state]
    )

    const participantsSortedByAvatar = useMemo(
      () => (
        [...(participants || [])].sort((a) => a.avatar === NO_USER_PHOTO ? 1 : -1)
      ),
      [participants]
    )

    const onParticipantsClick = useCallback(
      () => openParticipantsModalHandler(participantsSortedByAvatar),
      [participantsSortedByAvatar]
    )

    const participantsAvatars = useMemo(
      () => participantsSortedByAvatar.map(participant => participant.avatar || NO_USER_PHOTO),
      [participantsSortedByAvatar]
    )

    return (
      <Box
        py={['unset', '4px']}
        my={['4px', 'unset']}
        onClick={onTimeslotClick}
        style={{aspectRatio: 8 / timeSlotsPerRow + 0.5}}
        width={`${100 / timeSlotsPerRow}%`}
        {...timeSlotStickyStylesMap[stickyState].container}
        {...viewStylesMap[timeSlotsPerRow].box}
      >
        <Box
          height='100%'
          position='relative'
          {...timeslotStateStylesMap[state].box}
          {...timeSlotStickyStylesMap[stickyState].thumb}
          overflow={'hidden'}
          {...viewStylesMap[timeSlotsPerRow].thumb}
          borderColor={state !== TimeslotStatesEnum.SELECTED ? viewStylesMap[timeSlotsPerRow].box.borderColor : 'bg'}
        >
          <Wrapper>
            <Flex
              height='100%'
              alignItems='center'
              justifyContent='center'
              borderRadius={'12px'}
              bg={isSelectStart || isSelectEnd ? 'primary' : 'transparent'}
              {...viewStylesMap[timeSlotsPerRow].content}
            >
              <Span
                {...timeslotStateStylesMap[state].text}
                color={isSelectStart || isSelectEnd ? 'text-contrast' : timeslotStateStylesMap[state].text.color}
                style={viewStylesMap[timeSlotsPerRow].time}
              >
                {luxon.fromMillis(slotTimestamp).toUTC().toFormat('HH:mm')}
              </Span>
              {isParticipantsShowed && (
                <ImagesStack
                  maxImagesToStack={3}
                  imageSize={32}
                  onStackClick={onParticipantsClick}
                  images={participantsAvatars}
                />
              )}
            </Flex>
          </Wrapper>
        </Box>
      </Box>
    )
  },
)
