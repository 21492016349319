import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {TimeSlotGrid} from './types'

export type GetServiceTimeslotGridParams = undefined
export type GetServiceTimeslotGridQueryParams = {
  date: string
  areaId?: string | null
  sportId: string
  teacherId?: string | null
  participantsQty: number
}
export type GetServiceTimeslotGridQueryReturnType = TimeSlotGrid
export type GetServiceTimeslotGridQuery = QueryMethod<
  GetServiceTimeslotGridParams,
  GetServiceTimeslotGridQueryParams,
  GetServiceTimeslotGridQueryReturnType
>

export const makeGetServiceTimeslotGridEndpoint = () => 'bookings/grid'

export const getServiceTimeslotGridQuery: GetServiceTimeslotGridQuery = ({query}) => ({
  queryKey: ['getServiceTimeslotGridQuery', query],
  cacheTime: 0,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<GetServiceTimeslotGridQueryParams, GetServiceTimeslotGridQueryReturnType>(
      makeGetServiceTimeslotGridEndpoint(),
      {params: config},
    ).then((res) => res.data),
})
