import {useDispatch, useSelector} from 'react-redux'

import {basicSelectors as authSelectos} from '../auth/selectors'
import {basicSelectors as bookingSelectors} from '../booking/selectors'
import {basicActions} from './actions'
import {basicSelectors as globalSelectors} from './selectors'

export const useAppLoading = () => {
  const isAuthLoading = useSelector(authSelectos.isLoading())
  const isGlobalLoading = useSelector(globalSelectors.isLoading())
  const isBookingLoading = useSelector(bookingSelectors.isLoading())
  return {
    isAuthLoading,
    isGlobalLoading,
    isBookingLoading,
    isLoading: isGlobalLoading || isAuthLoading || isBookingLoading,
  }
}

export const useSrollMainFrame = () => {
  const dispatch = useDispatch()
  const setMainFrameIsScrollable = (arg: boolean) => {
    dispatch(basicActions.isMainFrameScrollable(arg))
  }
  const isMainFrameScrollable = useSelector(globalSelectors.isMainFrameScrollable())
  return {
    isMainFrameScrollable,
    setMainFrameIsScrollable,
  }
}
