import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {Booking} from './types'

export type GetUserBookingsParams = {
  userId?: string
}
export type GetUserBookingsQueryParams = {
  venueId?: string
  bookingType?: 'past' | 'upcoming' | 'waiting'
}
export type GetUserBookingsQueryReturnType = {
  bookings: Booking[] | null
  hasMore: boolean
}
export type GetUserBookingsQuery = QueryMethod<
  GetUserBookingsParams,
  GetUserBookingsQueryParams,
  GetUserBookingsQueryReturnType
>

export const makeGetUserBookingsEndpoint = ({userId}: GetUserBookingsParams) =>
  `bookings/client/bookings/${userId}/native`

export const getUserBookingsQuery: GetUserBookingsQuery = ({query, params}) => ({
  queryKey: ['getUserBookingsQuery', query],
  cacheTime: 1000 * 60,
  queryFn: ({queryKey: [_, config]}) => {
    if (params?.userId && query?.venueId && query.bookingType) {
      return API.post<GetUserBookingsQueryParams, GetUserBookingsQueryReturnType>(makeGetUserBookingsEndpoint(params), {
        params: config,
        page: 0,
        elementsPerPage: 1000,
        venueId: query.venueId,
        bookingType: query.bookingType,
      }).then((res) => res.data)
    }
    return new Promise((res) => res({bookings: null, hasMore: false}))
  },
})
