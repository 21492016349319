import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {Venue} from './types'

export type GetVenueParams = {
  id?: string
}
export type GetVenueQueryParams = {
  id?: string
} | null
export type GetVenueQueryReturnType = Venue | null
export type GetVenueQuery = QueryMethod<GetVenueParams, GetVenueQueryParams, GetVenueQueryReturnType>

export const makeGetVenueEndpoint = ({id}: GetVenueParams) => `venues/simple/${id}/native`

export const getVenueQuery: GetVenueQuery = ({query, params, ...rest}) => ({
  queryKey: ['getVenueQuery', query],
  cacheTime: 1000 * 60,
  refetchOnWindowFocus: false,
  ...rest,
  queryFn: ({queryKey: [_, config]}) => {
    if (params?.id) {
      return API.get<GetVenueQueryParams, GetVenueQueryReturnType>(makeGetVenueEndpoint(params), {
        params: config,
      }).then((res) => res.data)
    }
    return new Promise((res) => res(null))
  },
})
