import {A, Box, Flex, Text} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {FC} from 'react'

import {useAppLoading} from '../../../services/global/hooks'
import {Button} from '../../../ui/components/button/button'
import {CheckBox} from '../../../ui/components/input/checkbox'
import {Input} from '../../../ui/components/input/input'
import {useLocale} from '../../../utils/i18n'
import {PRIVACY_POLICY, TERMS_AND_CONDITIONS} from '../../../utils/remote-content'

type PasswordSignUpStepProps = {
  backAction?: (e: Event) => void
}

export const PasswordSignUpStep: FC<PasswordSignUpStepProps> = ({backAction}) => {
  const {l} = useLocale()
  const {isLoading} = useAppLoading()
  const {handleSubmit} = useFormikContext()

  return (
    <Box pb={'24px'}>
      <Flex mb='16px'>
        <Text text={l('createPassword', {caseAction: 'capitalize'})} />
      </Flex>
      <Input
        label={l('newPassword')}
        id={'password'}
        type='password'
      />
      <Input
        label={l('newPasswordVerify')}
        id={'passwordCheck'}
        type='password'
      />
      <Flex
        mt='24px'
        mb='16px'
      >
        <CheckBox id='termsAndConditionsConfirm'>
          <Flex ml='16px'>
            <Text>
              {l('i_accept_to_be_contacted_at_this_email', {caseAction: 'capitalize'})}{'. '}
              {l('more_about', {caseAction: 'capitalize'})}
              {' '}
              <A
                href={TERMS_AND_CONDITIONS}
                target='_blank'
              >
                {l('termsConditions')}
              </A>
              {l('and')}
              <A
                href={PRIVACY_POLICY}
                target='_blank'
              >
                {l('privacyPolicy')}
              </A>
            </Text>
          </Flex>
        </CheckBox>
      </Flex>
      <Flex
        gap='16px'
        mt={'16px'}
      >
        {backAction && (
          <Button
            type='secondary'
            disabled={isLoading}
            onClick={backAction}
          >
            {l('back')}
          </Button>
        )}
        <Button
          isLoading={isLoading}
          // @ts-ignore
          onClick={handleSubmit}
        >
          {l('sign_up_free', {caseAction: 'capitalize'})}
        </Button>
      </Flex>
    </Box>
  )
}
