import {StyleSizeSpaceType} from 'exsportia-components/dist/types'

import {theme} from '../../theme/theme'

export type QRCodeSettingsProps = {
  p?: StyleSizeSpaceType
  size?: StyleSizeSpaceType
  borderRadius?: StyleSizeSpaceType
}

export const defaultSettings: QRCodeSettingsProps = {
  size: 102,
  p: `${theme.space.multiplier1_5x}`,
  borderRadius: 'multiplier2x',
}
