import {Box, Flex, Icon, IconWithText, PositionedBox, Text, Theme} from 'exsportia-components'
import {createContext, FC, PropsWithChildren, useContext, useState} from 'react'

import {Button} from '../../../ui/components/button/button'
import {useLuxon} from '../../../utils/i18n'
import {useBookingLoader} from '../../booking/hooks'
import {openPaymentFormType, useTriggerProviderPayment} from './trigger-provider-payment.hook'

const iframeWhapSettings = {
  top: 0,
  left: 0,
  zIndex: 10,
  width: '100vw',
  height: '100vh',
  overflow: 'auto',
  position: 'fixed',
  bg: Theme?.colors?.bg,
}

const iframeSettings = {
  width: '100%',
  border: 'none',
  height: '100%',
}

type setIframeSrcType = (url: string | null) => void
type PaymentProviderContextType = {
  clearUrls?: () => void,
  imageSrc: string | null
  iframeSrc: string | null;
  setIframeSrc: setIframeSrcType | null,
  openPaymentForm: openPaymentFormType | null
}

const PaymentProviderContext = createContext<PaymentProviderContextType>({
  imageSrc: null,
  iframeSrc: null,
  setIframeSrc: null,
  openPaymentForm: null
})

export const usePaymentProvider = () => useContext(PaymentProviderContext)

export type handleLinkCallbackArgsType = {
  url?: string | null,
  type?: string | null
  expires_at?: number | null
  downloadUrl?: string | null
}

// const extractFileName = (url: string) => {
//   const parts = url.split('/')
//   return parts[parts.length - 3]
// }

export const PaymentProviderProvider: FC<PropsWithChildren> = ({children}) => {
  const luxon = useLuxon()
  // const {l} = useLocale()
  const [iframeSrc, setIframeSrc] = useState<string | null>(null)
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [expiresAt, setExpiresAt] = useState<number | null>(null)
  // const [downloadSrc, setDownloadSrc] = useState<string | null>(null)
  const {setIsLoading} = useBookingLoader()

  const handleLinkCallback = ({url = null, type = null, expires_at = null}: handleLinkCallbackArgsType) => {
    if (expires_at) {
      setExpiresAt(expires_at)
    }
    if (!url) {
      setImageSrc(null)
      return setIframeSrc(null)
    }
    if (type === 'image') {
      setImageSrc(url)
      // return setDownloadSrc(downloadUrl)
    }
    window.open(url, '_self')
  }
  const {openPaymentForm} = useTriggerProviderPayment({handleLinkCallback})

  // const handleDownload = () => {
  //   if (imageSrc) {
  //     const a = document.createElement('a')
  //     a.href = imageSrc
  //     a.download = extractFileName(imageSrc)
  //     document.body.appendChild(a)
  //     a.click()
  //     document.body.removeChild(a)
  //   }
  // }
  const clearUrls = () => {
    setIsLoading(false)
    setIframeSrc(null)
    setImageSrc(null)
    // setDownloadSrc(null)
    setExpiresAt(null)
  }

  return (
    <PaymentProviderContext.Provider value={{
      iframeSrc,
      imageSrc,
      clearUrls,
      setIframeSrc,
      openPaymentForm,
    }}>
      <div>
        {children}
        {(iframeSrc || imageSrc) && (
          <Box {...iframeWhapSettings}>
            {iframeSrc && <iframe src={iframeSrc} style={iframeSettings} title='Payment Authorization' />}
            {imageSrc && (
              <Flex width='100%' justifyContent='center' alignItems='flex-start'>
                <Flex gap='8px' alignItems='center' flexDirection='column' maxWidth='95vw' width='500px'>
                  <img src={imageSrc} alt='Downloaded' style={{maxWidth: '100%'}} />
                  <Text settings={{textAlign: 'center'}}>
                    Mobile users - Please take a screenshot of the QR, make the payment and then return to this screen.
                  </Text>
                  <Text settings={{textAlign: 'center'}}>
                  PC, Laptop - Please use your mobile device to scan the QR code
                  </Text>
                  {expiresAt && <IconWithText settings={{justifyContent: 'center'}} text={`Expire at ${luxon.fromMillis(expiresAt).toFormat('HH:mm')}`} icon='clock' />}
                  {/* <Button icon='download' type='secondary' onClick={handleDownload}>{l('download')}</Button> */}
                </Flex>
              </Flex>
            )}
            <PositionedBox top='8px' left='8px' position='absolute'>
              <Button type='flat_short' onClick={clearUrls}>
                <Icon icon='arrowLeft' />
              </Button>
            </PositionedBox>
          </Box>
        )}
      </div>
    </PaymentProviderContext.Provider>
  )
}
