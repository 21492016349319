import {showMessage} from 'exsportia-components'
import {call, takeLeading} from 'redux-saga/effects'

import {APIDomain} from '../../networking/constants'
import {sendRequest} from '../../utils/axios'
import {createOpmTokenRequest, createStripeCheckoutRequest} from './actions'
import {StripeCheckoutRequestDataType} from './types'

export type StripeCheckoutResType = {
  status: number
  data: string
}

export type OpnCreateTokenResType = {
  status: number
  message: string
  data: {
    authorize_uri: string
  }
}

export function* handleCreateStripeCheckoutRequest(payload: StripeCheckoutRequestDataType) {
  try {
    // yield put(loadingStart());
    // const options = {
    //   data: payload,
    // }
    const endpoint = `${APIDomain}payments/stripe/checkout`
    const res: StripeCheckoutResType = yield call<any>(sendRequest, 'post', endpoint, payload)
    if (res.status === 200) {
      const windowReference = window.open(res.data, '_blank')
      if (!windowReference) {
        const message = 'Pop-up Blocked. Please enable pop-ups for this website and try again!'
        showMessage(message, 'error')
      }
    }
    // yield put(loadingEnd());
  } catch (error) {
    // yield put(loadingEnd());
    // showMessage(
    //   `Error: ${R.pathOr('Something went wrong', ['response', 'data', 'message'], error)}`,
    //   'error'
    // );
  }
}
export function* handleCreateOpnTokenRequest(payload: StripeCheckoutRequestDataType) {
  try {
    const endpoint = `${APIDomain}payments/opn/token`
    const res: OpnCreateTokenResType = yield call<any>(sendRequest, 'post', endpoint, payload)
    if (res.status === 200) {
      const payment = res.data
      if (payment.authorize_uri) {
        const windowReference = window.open(payment.authorize_uri, '_blank')
        if (!windowReference) {
          const message = 'Pop-up Blocked. Please enable pop-ups for this website and try again!'
          showMessage(message, 'error')
        }
      }
    } else {
      showMessage(`Error: ${res.message}`, 'error')
    }
  } catch (error) {
    // showMessage(error);
  }
}

export function* paymentsWatcherSaga() {
  yield takeLeading(createStripeCheckoutRequest, function* (action) {
    yield handleCreateStripeCheckoutRequest(action.payload)
  })
  yield takeLeading(createOpmTokenRequest, function* (action) {
    yield handleCreateOpnTokenRequest(action.payload)
  })
}
