import {API} from '../../networking/http'
import {Instructor, InstructorPickValues} from '../booking/types'
import {QueryMethod} from '../helpers/types'

export type GetInstructorParams = {
  id: string
}
export type GetInstructorQueryParams = {
  pickValues?: InstructorPickValues[]
}
export type GetInstructorQueryReturnType = Instructor<InstructorPickValues>
export type GetInstructorQuery = QueryMethod<
  GetInstructorParams,
  GetInstructorQueryParams,
  GetInstructorQueryReturnType
>

export const makeGetInstructorEndpoint = (id: string) => `teachers/partial/${id}`

export const getInstructorQuery: GetInstructorQuery = ({query, params}) => ({
  queryKey: ['getInstructorQuery', query],
  cacheTime: 1000 * 60,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<GetInstructorQueryParams, GetInstructorQueryReturnType>(makeGetInstructorEndpoint(params?.id || ''), {
        params: config,
        query
    }).then((res) => res.data),
})
