import {Flex, Label, Text} from 'exsportia-components'
import {FC} from 'react'

import {useLocale, useLuxon} from '../../utils/i18n'

type SelectedTimeRowType = {
  time: (number | null)[]
}

export const SelectedTimeRow: FC<SelectedTimeRowType> = ({time}) => {
  const {l} = useLocale()
  const luxon = useLuxon()
  if (!time[0] && !time[1]) {
    return null
  }
  const from = time[0] ? luxon.fromMillis(time[0]).toUTC().toFormat('HH:mm') : ''
  const to = time[1] ? luxon.fromMillis(time[1]).toUTC().toFormat('HH:mm') : ''
  return (
    <Flex
      width='100%'
      alignItems='center'
    >
      <Text
        settings={{fontSize: '18px', fontWeight: 'bold', m: '8px'}}
        text={`${from} - ${to}`}
      />
      <Label>{l('selected', {caseAction: 'lowerCase'})}</Label>
    </Flex>
  )
}
