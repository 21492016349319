import {Formik, FormikConfig} from 'formik'
import {FC, PropsWithChildren, useCallback, useMemo} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {object} from 'yup'

import {SignUpMutationParams} from '../../../api/auth/sign-up.mutation'
import {User} from '../../../api/user/types'
import {auth} from '../../../utils/firebase'
import {useLocale} from '../../../utils/i18n'
import {CONFIRMATION_VALIDATION, EMAIL_VALIDATION, PHONE_VALIDATION, USER_NAME_VALIDATION} from '../../../utils/validation'
import {UserService} from '../../user'
import {useAuthState} from '../hooks'
import {AuthService} from '../index'

export type IntegratedSignUpFormType = {
  name: string
  phone: string
  email: string
  userId: string | null | undefined
  termsAndConditionsConfirm: boolean
  isRequestedToCreateAccount?: boolean
}

export const defaultUserValues: IntegratedSignUpFormType = {
  phone: '',
  email: '',
  name: '',
  userId: null,
  isRequestedToCreateAccount: true,
  termsAndConditionsConfirm: false
}

type IntegratedSignUpFormProps = {
  callback: (values: IntegratedSignUpFormType) => void // function that navigates to next step
}

const formValuesWithData = (data: User): IntegratedSignUpFormType => ({
  phone: data.phone ?? '',
  email: data.email,
  name: data.name,
  userId: null,
  isRequestedToCreateAccount: true,
  termsAndConditionsConfirm: false
})

const getLeadData = (values: IntegratedSignUpFormType) => {
  const names = values.name.split(' ')
  const lastName = names.slice(-1).toString()
  const firstName = names.slice(0, -1).toString()
  return {
    role: ['USER'],
    name: values.name,
    phone: values.phone,
    email: values.email,
    lastName,
    password: 'awaiting',
    firstName,
    dobStatus: 'awaiting',
    genderStatus: 'awaiting',
    withPassword: false,
    lastNameStatus: 'awaiting',
    firstNameStatus: 'awaiting',
  }
}

export const IntegratedSignUpForm: FC<PropsWithChildren<IntegratedSignUpFormProps>> = ({children, callback}) => {
  const {isLoggedIn, signIn} = useAuthState()
  const {l} = useLocale()
  const {venueId} = useParams()
  const dispatch = useDispatch()
  const {data} = useQuery(UserService.queries.GetUserQuery({cacheTime: 0, params: {id: auth.currentUser?.uid}}))
  const {mutate: createAccount} = useMutation(AuthService.mutations.integratedSignUpMutation({}))
  const initialValues = useMemo<IntegratedSignUpFormType>(() => {
    let values = defaultUserValues
    if (isLoggedIn && data) {
      values = formValuesWithData(data)
    }
    return values
  }, [data, isLoggedIn])
  const handleSubmit = useCallback<FormikConfig<IntegratedSignUpFormType>['onSubmit']>(
    (valuesDirty) => {
      const values = {
        ...valuesDirty,
        email: valuesDirty.email.toLowerCase(),
      }
      // check if account exist for this phone | email
      if (!values.isRequestedToCreateAccount || isLoggedIn) {
        return callback(values)
      }
      createAccount(values, {
        onSuccess: ({password}) => {
          if (password) {
            dispatch(
              AuthService.actions.signInRequestAction({
                email: values.email,
                password,
                callback: () => {
                  callback(values)
                },
                addLeadData: {
                  body: getLeadData(values) as SignUpMutationParams,
                  venueId,
                },
              }),
            )
          }
        },
        onError: (error) => {
          const isAccountAlreadyExist = error.response?.status === 302
          if (isAccountAlreadyExist) {
            signIn(values.email)
          }
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      // return callback(values)
    },
    [venueId, signIn, callback],
  )
  const validationSchema = useMemo(
    () =>
      object().shape({
        phone: PHONE_VALIDATION,
        email: EMAIL_VALIDATION,
        name: USER_NAME_VALIDATION,
        termsAndConditionsConfirm: CONFIRMATION_VALIDATION
      }),
    [l],
  )
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}
