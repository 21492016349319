import {Box, Flex, Span} from 'exsportia-components'
import {DateTime} from 'luxon'
import React, {FC, useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'

import {GlobalService} from '../../../services/global'
import {langToValidLuxonLangMap} from '../../../utils/i18n'
import {DayStatesEnum} from '../types'

type Props = {
  day: DateTime
  status: DayStatesEnum
  onClick?: (date: string) => void
}

const dayStateMap = {
  [DayStatesEnum.DEFAULT]: {
    dayTextWrapper: {bg: 'transparent'},
    dayText: {color: 'textDefault'},
    dayDot: {bg: 'green'},
    weekDayText: {color: 'text-secondary'},
  },
  [DayStatesEnum.DISABLED]: {
    dayTextWrapper: {bg: 'transparent'},
    dayText: {color: 'text-secondary', opacity: 0.5},
    dayDot: {bg: 'transparent'},
    weekDayText: {color: 'text-secondary', opacity: 0.5},
  },
  [DayStatesEnum.SELECTED]: {
    dayTextWrapper: {bg: 'primary'},
    dayText: {color: 'text-contrast'},
    dayDot: {bg: 'bgMain'},
    weekDayText: {color: 'text-secondary'},
  },
}
export const DayItem: FC<Props> = ({day, status, onClick}) => {
  const language = useSelector(GlobalService.selectors.language())
  const dayWithLocale = useMemo(() => day.setLocale(langToValidLuxonLangMap[language]), [day, language])
  const weekDayStyle = useMemo(() => ({fontSize: '14px', ...dayStateMap[status].weekDayText}), [status])
  const dayStyle = useMemo(() => ({fontSize: ['14px', '16px'], ...dayStateMap[status].dayText}), [status])
  const handleClick = useCallback(() => onClick?.(day.toFormat('dd/MM/yyyy')), [day, onClick])
  const isActive = status !== DayStatesEnum.DISABLED
  return (
    <Box
      cursor={isActive ? 'pointer' : 'default'}
      onClick={handleClick}
    >
      <Flex
        justifyContent={'center'}
        alignitems={'center'}
        mb={'8px'}
      >
        <Span {...weekDayStyle}>{dayWithLocale.toFormat('ccccc')}</Span>
      </Flex>
      <Flex
        width={['32px', '40px']}
        height={['32px', '40px']}
        justifyContent={'center'}
        alignItems={'center'}
        borderRadius={'12px'}
        {...dayStateMap[status].dayTextWrapper}
      >
        <Span {...dayStyle}>{dayWithLocale.toFormat('d')}</Span>
      </Flex>
    </Box>
  )
}
