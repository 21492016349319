import {getItemFromLocalStorage} from '../utils/local-storage'
import {isNotNilAndNotEmpty} from '../utils/logic'
import * as enCommon from './common-en.json'
import * as en from './en.json'
import * as enNew from './en-new.json'

export enum LanguagesEnum {
  EN = 'en',
  HEB = 'heb',
  UA = 'ua',
  TOKEN = 'token',
}

export const getLocaleName = () => {
  const localeString = getItemFromLocalStorage('localeName')
  if (isNotNilAndNotEmpty(localeString)) {
    return localeString as LanguagesEnum
  }
  return LanguagesEnum.EN
}
export const INITIAL_LANGUAGE = getLocaleName()

export type AvailableTranslationKeys = keyof (typeof en & typeof enCommon & typeof enNew)

export type TranslatorScope = AvailableTranslationKeys | AvailableTranslationKeys[]
