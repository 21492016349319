import {dividerSettingsProps} from 'exsportia-components/dist/components/atoms/divider/settings'
import {TextSettingsProps} from 'exsportia-components/dist/components/atoms/text'
import {StyleCommonType, StyleSizeSpaceType} from 'exsportia-components/dist/types'

import {theme} from '../../theme/theme'

export type BookingCardSettingsProps = {
  width: StyleSizeSpaceType
  alignItems: StyleCommonType
  infoBlock: {
    p: StyleSizeSpaceType
    mr: StyleSizeSpaceType
    border: StyleCommonType
    width: StyleSizeSpaceType
    dateSettings: TextSettingsProps
    borderRadius: StyleSizeSpaceType
    labelSettings: TextSettingsProps
    durationSettings: TextSettingsProps
    dividerSettings: dividerSettingsProps
  }
}

export const defaultSettings: BookingCardSettingsProps = {
  width: '100%',
  alignItems: 'flex-start',
  infoBlock: {
    width: '100%',
    border: 'default-border',
    mr: theme.space.multiplier1_5x,
    p: theme.space.multiplier1_5x,
    borderRadius: 'multiplier2x',
    dateSettings: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'primary',
      m: `0 ${theme.space.multiplier} 0 0`,
    },
    durationSettings: {color: 'text-secondary', fontSize: '12px'},
    dividerSettings: {m: `${theme.space.multiplier} 0`},
    labelSettings: {
      fontSize: 'label',
      color: 'text-secondary',
      m: `0 0 ${theme.space.multiplier0_5x}`,
      lineHeight: '18px',
    },
  },
}

export const typeStylesMap = {default: defaultSettings}

export const additionalStylesMap = {}
