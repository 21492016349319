import React, {useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {AvailableTranslationKeys} from '../../../../locale/types'
import {useLocale} from '../../../../utils/i18n'
import {useModalActions} from '../../../../utils/modals'


const UnavailableTimeContent = () => {
  const {l} = useLocale()
  return (
    <div>
      <p>{l('notAvailable')}</p>
    </div>
  )
}

type validationSettingType = {
  redirectPath: string
  title: AvailableTranslationKeys
  submitButton: AvailableTranslationKeys
}

type validationSettingsMapType = {
  class: validationSettingType
  service: validationSettingType
}

const validationSettingsMap: (entityId: string) => validationSettingsMapType = (entityId) => ({
  class: {
    submitButton: 'select_sessions',
    title: 'sessions_unavailable_single',
    redirectPath: `../class/${entityId}/select-session`,
  },
  service: {
    submitButton: 'select_timeslot',
    title: 'timeslot_not_available',
    redirectPath: '../',
  }
})

export const useOpenBookingValidationModal = () => {
  const {openModal, closeAllModals} = useModalActions()
  const navigate = useNavigate()
  const {l} = useLocale()
  const {pathname} = useLocation()
  const pathArr = pathname.split('/').filter((el) => el !== '')

  const openBookingValidationModal = useCallback(({type}: { type: 'class' | 'service' }) => {
    const settings: validationSettingType = validationSettingsMap(pathArr[pathArr.length - 2])[type]
    openModal({
      title: l(settings.title),
      component: <UnavailableTimeContent />,
      footerConfig: {
        submitButton: {
          text: l(settings.submitButton),
          settings: {
            width: 'max-content',
          },
          onClick: () => {
            navigate(settings.redirectPath)
            closeAllModals()
          },
        },
        cancelButton: {
          text: l('cancelBooking'),
          settings: {
            width: 'max-content',
          },
          onClick: () => {
            navigate(`/${pathArr[0]}/`)
            closeAllModals()
          },
        },
      },
    })
  }, [openModal, closeAllModals, navigate, l, pathArr])

  return {
    openBookingValidationModal,
  }
}
