import {TextSettingsProps} from 'exsportia-components/dist/components/atoms/text'

export type SessionAvailabilitySubtitleSettingsProps = {
  availableSettings: TextSettingsProps
  unavailableSettings: TextSettingsProps
}

export const defaultSettings: SessionAvailabilitySubtitleSettingsProps = {
  availableSettings: {
    color: 'success',
    fontSize: 'label',
    lineHeight: '20px',
  },
  unavailableSettings: {
    color: 'error',
    fontSize: 'label',
    lineHeight: '20px',
  },
}
