import {Box, Flex, IconWithText, Image, Logo, Text, Theme} from 'exsportia-components'
import {TextType} from 'exsportia-components/dist/types'
import React, {FC, ReactNode, useCallback, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'

import {AreaQueries} from '../../api/area'
import {Instructor, Participant} from '../../api/booking/types'
import {InstructorService} from '../../api/instructors'
import {BookingCard} from '../../components/booking-card/booking-card'
import {BookingCode} from '../../components/booking-code/booking-code'
import {InstructorCard} from '../../components/instructor-card/instructor-card'
import {PlayspaceCard} from '../../components/playspace-card/playspace-card'
import {PricingDetails} from '../../components/pricing-details/pricing-details'
import {SubscriptionCard, SubscriptionCardProps} from '../../components/subscription-card/subscription-card'
import {ParticipantsList} from '../../components/timeslot-grid/components/participants-list'
import {PLACEMENT_MAP} from '../../constants/area-settings'
import {FULL_CANCEL_POLICY_OPTIONS_MAP} from '../../constants/cancel-policy'
import {
  checkIfWithRequestCancel,
  getParticipantsEquipment,
  getUsageString,
} from '../../helpers/data'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {useAuthState} from '../../services/auth/hooks'
import {BookingService} from '../../services/booking'
import {cancelBooking} from '../../services/booking/actions'
import {useBookingLoader} from '../../services/booking/hooks'
import {useServiceOptions} from '../../services/service-options/hooks'
import {Button} from '../../ui/components/button/button'
import {Chip} from '../../ui/components/chip/chip'
import {DividerWithContent} from '../../ui/components/divider-with-content/divider-with-content'
import {LabeledValueRow} from '../../ui/components/key-value-row/labeled-value-row'
import {LabeledSection} from '../../ui/components/labeled-section/labeled-section'
import {PaymentStatus} from '../../ui/components/payment-status/payment-status'
import {TextWithShowMore} from '../../ui/components/text-with-show-more/text-with-show-more'
import {TiteledSection} from '../../ui/components/titeled-section/titeled-section'
import {WBA_URL} from '../../utils/environment'
import {auth} from '../../utils/firebase'
import {useLocale, useLuxon} from '../../utils/i18n'
import {isNilOrEmpty, isNotNilAndNotEmpty} from '../../utils/logic'
import {useModalActions} from '../../utils/modals'
import {ContactUsPage} from '../contact-us/contact-us-page'
import {CurrenciesEnum} from '../select-service/select-service-page'

export type CancelBookingConfirmationModalProps = {
  cancelDetails: {
    amountForRefund: number
    bookingWithSubscription: boolean
    contactToVenue: boolean
    fee: number
    isPastBooking: boolean
    occupyUsage: boolean
    cancelPolicy: string
    currency: CurrenciesEnum
    isPaymentOnArrival: boolean
  } | null
}

const CancelBookingConfirmationModal: FC<CancelBookingConfirmationModalProps> = ({cancelDetails}) => {
  if (isNilOrEmpty(cancelDetails)) {
    return null
  }
  const {l} = useLocale()
  const {amountForRefund, bookingWithSubscription, fee, occupyUsage, cancelPolicy, currency, isPaymentOnArrival} =
    cancelDetails
  return (
    <Box width='100%'>
      <LabeledSection label={l('cancelPolicy')}>
        <Text
          text={
            (!isPaymentOnArrival ? l(FULL_CANCEL_POLICY_OPTIONS_MAP[cancelPolicy].description) : cancelPolicy) || '-'
          }
        />
      </LabeledSection>
      <DividerWithContent />
      <LabeledValueRow label={l('refundAmount')}>{l(`${currency}_symbolic`, {value: amountForRefund})}</LabeledValueRow>
      <DividerWithContent />
      <LabeledValueRow label={l('fee')}>{l(`${currency}_symbolic`, {value: fee})}</LabeledValueRow>
      {bookingWithSubscription && (
        <>
          <DividerWithContent />
          <LabeledValueRow label={l('refundSubscriptionUsage')}>
            {l(!occupyUsage ? 'yes' : 'no', {caseAction: 'upperCase'})}
          </LabeledValueRow>
        </>
      )}
    </Box>
  )
}

const ClassInstructorsSection: FC<{instructors: Instructor[]}> = ({instructors}) => {
  const {l} = useLocale()
  return (
    <LabeledSection label={l('instructors')}>
      {instructors.map((instructor, index) => (
        <Box
          mb='8px'
          key={`instructor-${index}`}
        >
          <InstructorCard
            name={instructor.name}
            image={
              <Image
                width='48px'
                height='48px'
                size='contain'
                src={instructor.profileImage.secure_url}
              />
            }
          />
        </Box>
      ))}
    </LabeledSection>
  )
}

const InstructorSection = ({teacherId}: {teacherId: string}) => {
  const {l} = useLocale()
  const {data: instructor, isLoading: isInstructorLoading} = useQuery(
    InstructorService.getInstructorQuery({params: {id: teacherId}}),
  )
  return (
    <LabeledSection label={l('instructors')}>
      <InstructorCard
        name={instructor?.name || ''}
        isLoading={isInstructorLoading}
        image={
          <Image
            width='48px'
            height='48px'
            size='contain'
            src={instructor?.profileImage.secure_url || ''}
          />
        }
      />
    </LabeledSection>
  )
}

const AreaSection = ({areaId}: {areaId: string}) => {
  const {l} = useLocale()
  const {data: area, isLoading: isAreaLoading} = useQuery(AreaQueries.getAreaQuery({params: {id: areaId}}))
  const placeSubtext = useMemo(() => {
    const subtext: TextType[] = area?.surfaceType ? [l(area.surfaceType)] : []
    if (area?.areaSize) {
      subtext.push(
        <span>
          {area.areaSize} m<sup>2</sup>
        </span>,
      )
    }
    if (area?.placement) {
      subtext.push(l(PLACEMENT_MAP[area.placement]))
    }
    return subtext
  }, [area, l])
  return (
    <LabeledSection label={l('playSpace')}>
      <PlayspaceCard
        name={area?.name}
        styleType={'square'}
        isLoading={isAreaLoading}
        subTextElements={placeSubtext}
        image={
          <Logo
            smallLogo={
              <Image
                height={'48px'}
                width={'72px'}
                size={'contain'}
                src={area?.media?.[0].secure_url}
              />
            }
            forceUse={'small'}
          />
        }
      />
    </LabeledSection>
  )
}

export const SubscriptionSection = ({isLoading, firstParticipant, usageData}: {
  isLoading: boolean
  usageData?: SubscriptionCardProps['usage']
  firstParticipant: (Participant & {membership?: {logo: {secure_url: string}; name: string}}) | undefined
}) => {
  const {l} = useLocale()
  if (!firstParticipant?.membership) {
    return null
  }
  return (
    <>
      <LabeledSection label={l('subscription')}></LabeledSection>
      <SubscriptionCard
        styleType='summary'
        isLoading={isLoading}
        usage={usageData}
        imageUrl={firstParticipant.membership.logo.secure_url}
        name={firstParticipant.membership.name}
      />
    </>
  )
}

type Params = {
  id: string
  venueId: string
}
// eslint-disable-next-line max-lines-per-function
export const BookingProfilePage = () => {
  const dispatch = useDispatch()
  const {l} = useLocale()
  const navigate = useNavigate()
  const luxon = useLuxon()
  const params = useParams<Params>()
  const {openModal, closeModal} = useModalActions()
  const {isLoggedIn} = useAuthState()
  const {setIsLoading, isLoading: isBookingLoading} = useBookingLoader()
  const {data: booking, isLoading, refetch} = useQuery(BookingService.queries.getBookingQuery({
    params: {id: params.id},
    cacheTime: 0,
  }))
  useEffect(() => {
    refetch()
  }, [params.id])
  const {getServiceConfigByServiceType} = useServiceOptions([])
  const isJustTheSpace = booking?.areaSpace ?? false
  const profileType = booking?.entity === 'class' ? 'class' : isJustTheSpace ? 'justTheSpace' : 'service'
  const {label, icon} = useMemo(
    () => getServiceConfigByServiceType(booking?.sportType),
    [isJustTheSpace, booking, getServiceConfigByServiceType],
  )
  const venue = useMemo(() => booking?.venue, [booking])
  const userId = useMemo(() => auth.currentUser?.uid, [auth, isLoggedIn])

  const handleOpenContactsModal = useCallback(() =>
    !booking ? null : openModal({
      title: l('contactUs'),
      component: <ContactUsPage venueId={params.venueId!} />,
      footerConfig: {
        disabled: true,
      },
    }), [booking, l, openModal])

  const cancelPolicyKey = booking?.cancelPolicy
    ? FULL_CANCEL_POLICY_OPTIONS_MAP[booking.cancelPolicy].description
    : undefined
  const cancelPolicyDescription =
    booking?.paymentType === null
      ? booking.cancelForPayOnLocationDesc
      : cancelPolicyKey ? l(cancelPolicyKey) : booking?.cancelPolicy ?? '-'

  const header = useMemo(() => {
    return (
      <Flex
        p={'16px 16px 0px'}
        gap={'8px'}
        flexDirection={'column'}
      >
        <Chip
          styleType={profileType}
          text={l(label)}
          icon={icon}
        />
        <h2>{booking?.name ?? l('name')}</h2>
      </Flex>
    )
  }, [booking?.name, icon, profileType, l, label])

  const prices = useMemo(() => {
    if (!booking) {
      return []
    }
    const priceAndDiscount = booking.participants.reduce(
      (acc, participant) => ({
        price: acc.price + (Number(participant.totalPrice) || 0) + (Number(participant.discount) || 0),
        discount: acc.discount + (Number(participant.discount) || 0),
      }),
      {price: 0, discount: 0},
    )
    return [
      {
        count: booking.participants.length,
        price: priceAndDiscount.price,
        discount: priceAndDiscount.discount,
        text: booking.name,
      },
      ...getParticipantsEquipment(booking.participants),
    ]
  }, [booking])

  const bookingDateTime = booking?.timestamp ? luxon.fromMillis(Number(booking.timestamp)) : luxon.now()
  const bookingDuration = l(booking?.duration === 'NO_DURATION' ? 'noDuration' : 'minutes_short', {
    value: booking?.duration ?? '-',
  })
  const withEquipments = booking?.participants.some((el) => isNotNilAndNotEmpty(el.equipments)) ?? false
  const paymentStatus = booking?.isCancelled ? 'cancelled' : booking?.isPaid ? 'paid' : 'unpaid'

  const openParticipantsModal = useCallback(() => {
    openModal({
      title: l('participants'),
      footerConfig: {disabled: true},
      component: (
        <ParticipantsList
          participants={booking?.participants || []}
          displayEquipmentCount={true}
        />
      ),
    })
  }, [booking?.participants, openModal])

  const handleCancelBooking = useCallback(() => {
    const isUserBookingOwner = booking?.userId === userId
    const cancelDetails = checkIfWithRequestCancel({booking, venue})
    const modalContentMap = {
      notLoggedIn: l('loginToCancel', {caseAction: 'capitalize'}),
      anotherAcc: l('thisAccCantBeUsedToCancel', {caseAction: 'capitalize'}),
      isPastBooking: l('thisBookingCantBeCancelled', {caseAction: 'capitalize'}),
      allowCancel: <CancelBookingConfirmationModal cancelDetails={cancelDetails} />,
      contactToVenue: (
        <Box>
          <Text
            text={l('contactVenueToCancel', {caseAction: 'capitalize'})}
            settings={{m: '0 0 16px 0'}}
          />
          <ContactUsPage venueId={params.venueId!} />
        </Box>
      ),
    }
    let modalContent: ReactNode | string = modalContentMap.allowCancel
    let isCancelAllowed = true
    if (cancelDetails?.contactToVenue) {
      modalContent = modalContentMap.contactToVenue
      isCancelAllowed = false
    }
    if (!isUserBookingOwner) {
      modalContent = modalContentMap.anotherAcc
      isCancelAllowed = false
    }
    if (cancelDetails?.isPastBooking) {
      modalContent = modalContentMap.isPastBooking
      isCancelAllowed = false
    }
    if (!isLoggedIn) {
      modalContent = modalContentMap.notLoggedIn
      isCancelAllowed = false
    }
    openModal({
      title: l('bookingCancellation', {caseAction: 'capitalize'}),
      component: (
        <Flex
          mb='32px'
          mt='16px'
        >
          {modalContent}
        </Flex>
      ),
      footerConfig: {
        disabled: !isCancelAllowed,
        submitButton: {
          onClick: !booking
            ? null
            : () => {
                setIsLoading(true)
                dispatch(
                  cancelBooking({
                    isPaid: booking.isPaid,
                    bookingId: booking.uid,
                    l,
                    callback: () => {
                      setIsLoading(false)
                      refetch()
                    },
                    rejectCallback: () => setIsLoading(false),
                  }),
                )
                closeModal()
              },
          text: l('confirm'),
        },
        cancelButton: {
          onClick: closeModal,
          text: l('back'),
        },
      },
      settings: {
        footer: {
          submitButton: {
            settings: {
              bg: 'primary',
            },
          },
          cancelButton: {
            settings: {
              color: 'primary',
            },
          },
        },
      },
    })
  }, [isLoggedIn, l, userId, booking, venue, checkIfWithRequestCancel])
  const handlePay = useCallback(() => {
    navigate(`/clear-view/${params.venueId}/payment?entityId=${params.id}&entity=booking_update`)
  }, [navigate, params.venueId, params.id])

  const aboutChildrenArray = useMemo(() => {
    if (isLoading) {
      return null
    }
    const arrayToReturn = []
    if (booking?.areaId) {
      arrayToReturn.push(<AreaSection areaId={booking.areaId} />)
    }
    if (booking?.teacherId) {
      arrayToReturn.push(<InstructorSection teacherId={booking.teacherId} />)
    }
    if (isNotNilAndNotEmpty(booking?.teachers)) {
      arrayToReturn.push(<ClassInstructorsSection instructors={booking!.teachers} />)
    }
    const firstParticipant: (Participant & {membership?: {logo: {secure_url: string}; name: string}}) | undefined =
      booking?.participants[0]
    if (firstParticipant?.membership) {
      arrayToReturn.push(
        <SubscriptionSection
          firstParticipant={firstParticipant}
          isLoading={isLoading}
          usageData={booking?.usageData}
        />,
      )
    }
    return arrayToReturn
  }, [isLoading, booking])

  return (
    <GridLayout header={header}>
      <Box>
        <TiteledSection title={l('about')}>{aboutChildrenArray}</TiteledSection>
        <TiteledSection title={l('details')}>
          <LabeledSection label={l('additionalInformation')}>
            <TextWithShowMore text={booking?.arrivalInstructions ?? '-'} />
          </LabeledSection>
          <LabeledSection label={l('specialRequest')}>
            {booking?.specialRequests ? booking.specialRequests : '-'}
          </LabeledSection>
          <LabeledSection label={l('labels_cancelPolicy')}>{cancelPolicyDescription || '-'}</LabeledSection>
        </TiteledSection>
        {booking && (
          <PricingDetails
            discount={booking.discount || 0}
            isLoading={isLoading}
            currency={booking.venue.currency}
            values={prices}
          />
        )}
      </Box>
      {booking && (
        <>
          <BookingCard
            duration={bookingDuration}
            bookingCode={booking.reference}
            qrLink={`${WBA_URL}/${booking.venueId}/booking/${params.id}`}
            dateAndTime={bookingDateTime.toUTC().toFormat('MMM dd, HH:mm')}
          />
          <TiteledSection title={l('booking')}>
            <LabeledValueRow label={l('dateAndTime')}>
              {bookingDateTime.toUTC().toFormat('HH:mm, MMM dd, yyyy')}
            </LabeledValueRow>
            <LabeledValueRow label={l('duration')}>{bookingDuration}</LabeledValueRow>
            <LabeledValueRow label={l('participants')}>
              <Flex
                cursor='pointer'
                onClick={openParticipantsModal}
              >
                {`${booking.participants.length} ${l('participants')}`}
              </Flex>
            </LabeledValueRow>
            <LabeledValueRow label={l('headers_paymentStatus')}>
              <PaymentStatus status={paymentStatus} />
            </LabeledValueRow>
            {paymentStatus === 'cancelled' && (booking.refund || 0) > 0 && (
              <LabeledValueRow label={l('refunded', {caseAction: 'capitalize'})}>
                {l(`${booking.venue.currency}_symbolic`, {value: booking.refund})}
              </LabeledValueRow>
            )}
            {isNotNilAndNotEmpty(booking.usageData) && booking.occupyUsage === false && paymentStatus === 'cancelled' && (
              <LabeledValueRow label={l('usage')}>
                <Text settings={{color: Theme?.colors?.notify}}>{getUsageString(l, booking.usageData, true)}</Text>
              </LabeledValueRow>
            )}
            <LabeledValueRow label={l('equipments')}>{l(withEquipments ? 'yes' : 'no')}</LabeledValueRow>
            <LabeledValueRow label={l('reference')}>
              <BookingCode code={booking.reference} />
            </LabeledValueRow>
            <Flex width='min-content'>
              <Button
                type='flat'
                onClick={handleOpenContactsModal}
              >
                <IconWithText
                  text={l('contactVenue', {caseAction: 'capitalize'})}
                  icon='phone'
                />
              </Button>
            </Flex>
            <Flex width='min-content'>
              <Button
                type='flat'
                onClick={() => window.open('https://wa.me/+66948951432')}
              >
                <IconWithText
                  text={l('get_help', {caseAction: 'capitalize'})}
                  icon='help'
                />
              </Button>
            </Flex>
            <Flex mt={Theme.spaces.multiplier3x} gap={Theme.spaces.multiplier2x} flexDirection='column'>
              {
                booking.paymentOnExsportia && <Button
                  type='primary'
                  onClick={handlePay}
                  disabled={booking.isCancelled || booking.isPaid}
                  isLoading={isBookingLoading || isLoading}
                >
                  {l('payNow')}
                </Button>
              }
              <Button
                type='cancel'
                onClick={handleCancelBooking}
                disabled={booking.isCancelled}
                isLoading={isBookingLoading || isLoading}
              >
                {l('cancelBooking')}
              </Button>
            </Flex>
          </TiteledSection>
        </>
      )}
    </GridLayout>
  )
}
