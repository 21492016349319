import {useFormikContext} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'

import {isNilOrEmpty} from '../../utils/logic'
import {basicActions} from './actions'
import {CreateClassBookingFormType} from './forms/create-class-booking-form'
import {CreateServiceBookingFormType} from './forms/create-service-booking-form'
import {basicSelectors} from './selectors'

export const useHomeRedirection = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const {values} = useFormikContext<
    | CreateClassBookingFormType
    | CreateServiceBookingFormType
  >()
  const bookingType = values.bookingType
  const pathArr = pathname.split('/').filter((el) => el !== '')
  const currentStep = pathArr[pathArr.length - 1]
  const isNeedRedirection =
    ((bookingType === 'service' || bookingType === 'space') &&
      isNilOrEmpty(values.service) &&
      currentStep !== 'select-playspace') ||
    (bookingType === 'teacher' && isNilOrEmpty(values.teacher) && isNilOrEmpty(values.service)) ||
    (bookingType === 'class' && values.sessionId === null && currentStep !== 'select-session')
  if (!isNeedRedirection) {
    return false
  }
  navigate(`/${pathArr[0]}/`)
  return true
}

export const useBookingLoader = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(basicSelectors.isLoading())
  const setIsLoading = (arg: boolean) => dispatch(basicActions.isLoading(arg))
  return {
    isLoading,
    setIsLoading,
  }
}
