import {showMessage} from 'exsportia-components'
import {Formik, FormikConfig} from 'formik'
import {DateTime} from 'luxon'
import {FC, PropsWithChildren, useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {object} from 'yup'

import {useLocale} from '../../../utils/i18n'
import {useModalActions} from '../../../utils/modals'
import {
  CONFIRMATION_VALIDATION,
  DATE_OF_BIRTH_VALIDATION,
  EMAIL_VALIDATION,
  PASSWORD_REPEAT_VALIDATION,
  PASSWORD_VALIDATION,
  PHONE_VALIDATION,
  USER_NAME_VALIDATION,
} from '../../../utils/validation'
import {AuthService} from '../index'

export type SignUpFormType = {
  email: string
  password: string
  passwordCheck: string
  firstName: string
  lastName: string
  venueId?: string
  phone: string
  avatar?: string | null
  dob?: DateTime | string
}

type CreateBookingFormProps = {
  email?: string
  venueId?: string
}

export const SignUpForm: FC<PropsWithChildren<CreateBookingFormProps>> = ({children, email = '', venueId}) => {
  const dispatch = useDispatch()
  const {l} = useLocale()
  const {closeAllModals} = useModalActions()
  const initialValues = useMemo<SignUpFormType>(
    () => ({
      email,
      password: '',
      passwordCheck: '',
      firstName: '',
      lastName: '',
      termsAndConditionsConfirm: false,
      phone: '',
      avatar: null,
    }),
    [],
  ) //eslint-disable-line

  const handleSubmit = useCallback<FormikConfig<SignUpFormType>['onSubmit']>(
    (values) => {
      const {email: emailToUse, firstName, lastName, phone, dob, avatar, password, passwordCheck} = values as SignUpFormType & {dob: DateTime}
      let valuesToSave: SignUpFormType & {dob: string} = {
        email: emailToUse,
        firstName,
        lastName,
        phone,
        password,
        passwordCheck,
        dob: dob.toFormat('dd/LL/yyyy'),
      }
      if (avatar !== null) {
        valuesToSave = {
          ...valuesToSave,
          avatar,
        }
      }
      dispatch(
        AuthService.actions.signUpRequestAction({
          ...valuesToSave,
          callback: () => {
            showMessage(l('registerSuccess'), 'success')
            closeAllModals()
          },
          venueId
        }),
      )
    },
    [closeAllModals, dispatch, l],
  )

  const validationSchema = useMemo(
    () =>
      object().shape({
        email: EMAIL_VALIDATION,
        password: PASSWORD_VALIDATION,
        passwordCheck: PASSWORD_REPEAT_VALIDATION,
        firstName: USER_NAME_VALIDATION,
        lastName: USER_NAME_VALIDATION,
        termsAndConditionsConfirm: CONFIRMATION_VALIDATION,
        phone: PHONE_VALIDATION,
        dob: DATE_OF_BIRTH_VALIDATION,
      }),
    [l],
  )

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}
