import {Formik, FormikConfig} from 'formik'
import {FC, PropsWithChildren, useCallback, useMemo} from 'react'
import {object} from 'yup'

import {Step} from '../../../modals/auth-flow/auth-modal'
import {ModalParams} from '../../../modals/auth-flow/types'
import {auth} from '../../../utils/firebase'
import {useLocale} from '../../../utils/i18n'
import {EMAIL_VALIDATION} from '../../../utils/validation'

export type CheckEmailFormType = {
  email: string
}

type CheckEmailFormProps = {
  initialEmail?: string
  setEmailToUse: React.Dispatch<React.SetStateAction<string | undefined>>
  navigateToStep: (step: Step, params: ModalParams) => void
}

// const EmailInfo = ({initialEmail}: {initialEmail: string}) => {
//   const {
//     values: {email},
//   } = useFormikContext<CheckEmailFormType>()
//   if (isNilOrEmpty(initialEmail) || initialEmail !== email) {
//     return null
//   }
//   return (
//     <Box my='12px'>
//       <InfoMessage
//         text={'This email address is known to the system'}
//         preset='success'
//       />
//     </Box>
//   )
// }

export const CheckEmailForm: FC<PropsWithChildren<CheckEmailFormProps>> = ({
  children,
  setEmailToUse,
  navigateToStep,
  initialEmail = '',
}) => {
  const {l} = useLocale()
  const initialValues = useMemo<CheckEmailFormType>(
    () => ({
      email: initialEmail,
    }),
    [initialEmail],
  )
  const handleSubmit = useCallback<FormikConfig<CheckEmailFormType>['onSubmit']>(
    async ({email}) => {
      try {
        setEmailToUse(email)
        const result = await auth.fetchSignInMethodsForEmail(email)
        if (result.includes('password')) {
          return navigateToStep('sign_in', {email})
        }
        navigateToStep('sign_up_free', {email})
      } catch (e: unknown) {
        // catch
      } finally {
        // do something
      }
    },
    [navigateToStep],
  )

  const validationSchema = useMemo(
    () =>
      object().shape({
        email: EMAIL_VALIDATION,
      }),
    [l],
  )

  return (
    <Formik<CheckEmailFormType>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <>
        {/* <EmailInfo initialEmail={initialEmail} /> */}
        {children}
      </>
    </Formik>
  )
}
