import {createAction} from 'redux-act'

import {createBasicActions} from '../../utils/redux'
import {initialState} from './constants'
import {UserState} from './types'

export const basicActions = createBasicActions<UserState>(initialState)
export type PayloadType = {
  entityId: string
  venueId?: string
  collection: string
  userId?: number | string
  successCallback?: () => void
}

export const setEntityForPayRequestAction = createAction<PayloadType>('setEntityForPayRequest')
