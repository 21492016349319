import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {WeekAvailableDays} from './types'

export type GetAvailableDaysByWeekParams = undefined
export type GetAvailableDaysByWeekQueryParams = {
  venueId?: string
  startOfWeek: number | string
  classId: string | null
}

export type GetAvailableDaysByWeekReturnType = WeekAvailableDays
export type GetAvailableDaysByWeekQuery = QueryMethod<
  GetAvailableDaysByWeekParams,
  GetAvailableDaysByWeekQueryParams,
  GetAvailableDaysByWeekReturnType
>

export const makeGetAvailableDaysByWeekEndpoint = () => 'classesSlots/availableWeekDays'

export const getAvailableDaysByWeekQuery: GetAvailableDaysByWeekQuery = ({query}) => ({
  queryKey: ['getVenueInstructorsQuery', query],
  cacheTime: 1000 * 300,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<GetAvailableDaysByWeekParams, GetAvailableDaysByWeekReturnType>(makeGetAvailableDaysByWeekEndpoint(), {
      params: config,
    }).then((res) => res.data),
})
