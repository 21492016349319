import {Flex} from 'exsportia-components'
import React, {FC, useCallback} from 'react'
import {useParams} from 'react-router-dom'

import {useAuthState} from '../services/auth/hooks'
import {MobileNavMenu} from './components/mobile-nav-menu'
import {SignInSignUpButtons} from './components/signInSignUpButtons'

export const Footer: FC = () => {
  const {venueId} = useParams()
  const {isLoggedIn, signIn, signUp} = useAuthState(venueId)
  const handleSignIn = useCallback(() => signIn(), [signIn])
  const handleSignUp = useCallback(() => signUp(), [signUp])
  return (
    <Flex
      display={['flex', 'none']}
      position='sticky'
      bottom='0'
      left='0'
      mt={'16px'}
      pb={['0', '16px']}
      backgroundColor={'bg'}
      justifyContent={'center'}
      height={['unset', '64px']}
    >
      <Flex
        width={'100%'}
        flexDirection={['column']}
        px={'8px'}
        justifyContent={'space-between'}
        alignItems={['center', 'start']}
      >
        <Flex
          pb={'8px'}
          mt={'8px'}
          height={'100%'}
          justifyContent='space-between'
          width={'100%'}
          alignItems={'center'}
          backgroundColor={['bg', 'unset']}
        >
          <Flex
            gap={'8px'}
            alignItems={'center'}
            justifyContent='space-between'
            width={'100%'}
          >
            {isLoggedIn ? (
              <MobileNavMenu />
            ) : (
              <SignInSignUpButtons
                handleSignIn={handleSignIn}
                handleSignUp={handleSignUp}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
