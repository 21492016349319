import {createAction} from 'redux-act'

import {createBasicActions} from '../../utils/redux'
import {initialState} from './constants'
import {OpnCreateTokenRequestDataType, PaymentsState, StripeCheckoutRequestDataType} from './types'

export const basicActions = createBasicActions<PaymentsState>(initialState)

export const createStripeCheckoutRequest = createAction<StripeCheckoutRequestDataType>('createStripeCheckoutRequest')
export const createOpmTokenRequest = createAction<OpnCreateTokenRequestDataType>('createOpmTokenRequest')
