import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {TerminalData} from './types'

export type GetTerminalParams = {
  userId?: string
  venueId?: string
}
export type GetTerminalQueryParams = undefined
export type GetTerminalQueryReturnType = TerminalData | null
export type GetTerminalQuery = QueryMethod<GetTerminalParams, GetTerminalQueryParams, GetTerminalQueryReturnType>

export const terminalEndpoint = 'payments/terminalName'

export const getTerminalQuery: GetTerminalQuery = ({params}) => {
  return {
    queryKey: ['getTerminalQuery'],
    cacheTime: 1000 * 6000,
    refetchOnWindowFocus: false,
    queryFn: () =>
      API.get<GetTerminalQueryParams, GetTerminalQueryReturnType>(terminalEndpoint, {
        params,
      }).then((res) => res.data),
  }
}
