import {IdTokenResult} from 'firebase/auth'

export const getItemFromLocalStorage = (itemName: string) => localStorage.getItem(itemName)
export const removeItemFromLocalStorage = (itemName: string) => localStorage.removeItem(itemName)
export const setItemToLocalStorage = (itemName: string, itemValue: string) => localStorage.setItem(itemName, itemValue)

export const getAuthToken = () => {
  const result = localStorage.getItem('accessToken')
  if (result) {
    return JSON.parse(result) as IdTokenResult
  }
  return null
} // eslint-disable-line
export const setAuthToken = (token: IdTokenResult) => localStorage.setItem('accessToken', JSON.stringify(token)) // eslint-disable-line
export const removeAuthToken = () => localStorage.removeItem('accessToken') // eslint-disable-line

export const getAuthTokenFromSession = () => JSON.parse(sessionStorage.getItem('accessToken') || '') // eslint-disable-line
export const setAuthTokenToSession = (token: string) => sessionStorage.setItem('accessToken', JSON.stringify(token)) // eslint-disable-line
export const removeAuthTokenFromSession = () => sessionStorage.removeItem('accessToken') // eslint-disable-line

export const getRefreshTokenFromSession = () => JSON.parse(sessionStorage.getItem('refreshToken') || '') // eslint-disable-line
export const setRefreshTokenToSession = (token: string) => sessionStorage.setItem('refreshToken', JSON.stringify(token)) // eslint-disable-line
export const removeRefreshTokenFromSession = () => sessionStorage.removeItem('refreshToken') // eslint-disable-line

export const getToken = () => getAuthToken() || getAuthTokenFromSession()
