import {showMessage} from 'exsportia-components'

import {API} from '../../networking/http'
import {MutationMethod} from '../helpers/types'
import {createBookingPayByTokenEndpoint} from './create-instructor-booking.mutation'
import {createBookingDraftEndpoint} from './create-service-booking.mutation'
import {ClassBookingDraft, PayByTokenClassBookingDraft} from './helpers'
import {Booking} from './types'

export type CreateClassBookingParams = undefined
export type CreateClassBookingMutationParams = ClassBookingDraft | PayByTokenClassBookingDraft
export type CreateDraftClassBookingMutationParams = PayByTokenClassBookingDraft
export type CreateClassBookingMutationReturnType = Booking[] & {
  bookingData: Booking[]
  bookingId: string
  uid: string
}
export type CreateClassBookingMutation = MutationMethod<
  CreateClassBookingParams,
  CreateClassBookingMutationParams,
  CreateClassBookingMutationReturnType
>

export const makeCreateClassBookingEndpoint = (id: string) => `bookings/class/${id}`

export const createClassBookingMutation: CreateClassBookingMutation = () => ({
  mutationFn: (bookingDraft) =>
    API.post<CreateClassBookingMutationParams, CreateClassBookingMutationReturnType>(
      makeCreateClassBookingEndpoint(bookingDraft.bookingId),
      {
        data: bookingDraft,
      },
    ).then((res) => res.data),
})

export const createClassBookingPayByTokenMutation: CreateClassBookingMutation = () => ({
  mutationFn: (bookingDraft) =>
    API.post<CreateClassBookingMutationParams, CreateClassBookingMutationReturnType>(
      createBookingPayByTokenEndpoint,
      bookingDraft,
    )
    .then((res) => res.data)
    .catch((error) => {
      showMessage(`Error payment booking: ${error?.response?.data?.message ?? 'Payment Failed'}`, 'error')
      throw error
    }),
})

export const createClassBookingDraftMutation: CreateClassBookingMutation = () => ({
  mutationFn: (bookingDraft) =>
    API.post<CreateDraftClassBookingMutationParams, CreateClassBookingMutationReturnType>(
      createBookingDraftEndpoint,
      bookingDraft,
    )
      .then((res) => res.data)
      .catch((error) => {
        showMessage(`Error payment booking: ${error?.response?.data?.message ?? 'Payment Failed'}`, 'error')
        throw error
      }),
})


