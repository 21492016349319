import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {PaymentMethod} from './types'

export type GetUserPaymentMethodsParams = {venueId?:string}
export type GetUserPaymentMethodsQueryParams = undefined

export type GetUserPaymentMethodsReturnType = {
  paymentMethods?: PaymentMethod[]
}
export type GetUserPaymentMethodsQuery = QueryMethod<
  GetUserPaymentMethodsParams,
  GetUserPaymentMethodsQueryParams,
  GetUserPaymentMethodsReturnType
>

export const makeGetUserPaymentMethodsEndpoint = () => 'payments/paymentMethods'

export const getUserPaymentMethodsQuery: GetUserPaymentMethodsQuery = ({params}) => ({
  queryKey: ['getUserPaymentMethodsQuery'],
  cacheTime: 1000 * 300,
  queryFn: () =>
    API.get<GetUserPaymentMethodsQueryParams, GetUserPaymentMethodsReturnType>(makeGetUserPaymentMethodsEndpoint(), {
      params,
    }).then((res) => res.data),
})
