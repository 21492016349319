import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {Member} from './types'

export type GetMemberByVenueParams = undefined
export type GetMemberByVenueQueryParams = {
  venueId?: string
  userId?: string
}

export type GetMemberByVenueQuery = QueryMethod<
  GetMemberByVenueParams,
  GetMemberByVenueQueryParams,
  Member
>

export const makeGetMemberByVenueEndpoint = () => 'members/byVenue'

export const getMemberByVenueQuery: GetMemberByVenueQuery = ({query}) => ({
  queryKey: ['getMemberByVenueQuery', query],
  cacheTime: 1000 * 300,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<GetMemberByVenueQueryParams, Member>(makeGetMemberByVenueEndpoint(), {
      params: config,
    }).then((res) => res.data),
})
