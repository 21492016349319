import './phone-input.scss'

import {Box, Label} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {FC} from 'react'
import PhoneInputBase from 'react-phone-input-2'

export type PhoneInputProps = {
  countryCode?: string
  label?: string
  id: string
}

export const PhoneInput: FC<PhoneInputProps> = ({countryCode, label, id}) => {
  const {values, setFieldValue, errors} = useFormikContext<any>()
  const onChangeToUse = (value: string) => {
    (async () => await setFieldValue(id, value ? `+${value}` : null))()
  }
  return (
    <>
      {label && (
        <Label
          settings={{
            color: errors[id] ? 'error' : 'text-secondary',
          }}
        >
          {label}
        </Label>
      )}
      <PhoneInputBase
        inputClass='phone-input'
        country={countryCode}
        specialLabel=''
        onChange={onChangeToUse}
        value={values[id]}
        inputStyle={{
          borderColor: errors[id] ? '#ED0A34' : 'rgba(237, 49, 99, 0.14)',
        }}
      />
      <Box
        mt='5px'
        mb='10px'
        fontSize='12px'
        color='error'
      >
        {errors[id]}
      </Box>
    </>
  )
}
