import './App.css'

import {ModalProvider, ToasterContainer} from 'exsportia-components'
import React from 'react'

import {Router} from './navigation'
import {QueryClientProvider} from './networking/QueryClientProvider'
import {Redux} from './redux'
import {Theme} from './theme'

export const App = () => {
  return (
    <Redux>
      <Theme>
        <ToasterContainer />
        <QueryClientProvider>
          <ModalProvider>
            <Router />
          </ModalProvider>
        </QueryClientProvider>
      </Theme>
    </Redux>
  )
}
