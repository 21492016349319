import {Divider, Flex, IconWithText, Text} from 'exsportia-components'
import {IconWithTextProps} from 'exsportia-components/dist/components/molecules/icon-with-text'
import {IconType, TextType} from 'exsportia-components/dist/types'
import React, {FC, useMemo} from 'react'

import {GradientLoader} from '../../ui/components/gradient-loader/gradient-loader'
import {typeStylesMap} from './settings'

export type PlayspaceCardProps = {
  name: TextType
  image?: IconType
  isLoading?: boolean
  styleType?: 'circle' | 'default' | 'square'
  subTextElements?: TextType[]
}

const iconWithTextdditionalStyles: IconWithTextProps['additionalStyles'] = ['mr12Icon', 'br16Icon']

export const PlayspaceCard: FC<PlayspaceCardProps> = ({
  name,
  image,
  isLoading = false,
  styleType = 'default',
  subTextElements = [],
}) => {
  const settings = useMemo(() => typeStylesMap[styleType], [styleType])
  const subTextMarkup = useMemo(
    () =>
      isLoading ? (
        <GradientLoader height={settings.subTextLoaderHeight} />
      ) : (
        subTextElements.map((element, index) => (
          <Flex
            key={`subTextElement${index}`}
            justifyContent='flex-start'
          >
            {index !== 0 && <Divider styleType='dot' />}
            {
              <Text
                text={element}
                settings={settings.iconWithTextSettings.subText}
              />
            }
          </Flex>
        ))
      ),
    [subTextElements, isLoading],
  )

  const nameMarkup = useMemo(
    () => (isLoading ? <GradientLoader height={settings.nameLoaderHeight} /> : name),
    [isLoading, name],
  )

  const imageMarkup = useMemo(
    () => (isLoading ? <GradientLoader height={settings.imageLoaderHeight} /> : image),
    [isLoading, image],
  )

  return (
    <Flex
      width={settings.width}
      height={settings.height}
    >
      <IconWithText
        title={nameMarkup}
        icon={imageMarkup}
        subText={subTextMarkup}
        additionalStyles={iconWithTextdditionalStyles}
        settings={settings.iconWithTextSettings}
      />
    </Flex>
  )
}
