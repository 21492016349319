import {CancelPolicyEnum} from '../api/booking/types'
import {TranslatorScope} from '../locale/types'
import {omit, optionsToMapObject} from '../utils/logic'

type CancelPolicyOption = {
  color: string
  hoursBeforeCancel: number
  label: TranslatorScope
  value: CancelPolicyEnum | 'CANCEL_BY_VENUE'
  description: TranslatorScope
}
export const CANCEL_POLICY_BY_VENUE = 'CANCEL_BY_VENUE'

export const CANCEL_POLICY_OPTIONS: CancelPolicyOption[] = [
  {
    color: '#F7B80B',
    hoursBeforeCancel: 12,
    label: 'semiFlexible',
    value: CancelPolicyEnum.SEMI_FLEXIBLE,
    description: 'semiFlexiblePolicy',
  },
  {
    value: CancelPolicyEnum.EASY,
    hoursBeforeCancel: 0,
    color: '#76D70F',
    label: 'easy',
    description: 'easyPolicy',
  },
  {
    value: CancelPolicyEnum.FLEXIBLE,
    hoursBeforeCancel: 4,
    color: '#F7B80B',
    label: 'flexible',
    description: 'flexiblePolicy',
  },
  {
    value: CancelPolicyEnum.MEDIUM,
    color: '#FF720B',
    hoursBeforeCancel: 24,
    label: 'medium',
    description: 'mediumPolicy',
  },
  {
    value: CancelPolicyEnum.STRICT,
    hoursBeforeCancel: 168,
    color: '#FF0808',
    label: 'strict',
    description: 'strictPolicy',
  },
]

export const FULL_CANCEL_POLICY_OPTIONS: CancelPolicyOption[] = [
  ...CANCEL_POLICY_OPTIONS,
  {
    value: CANCEL_POLICY_BY_VENUE,
    hoursBeforeCancel: 0,
    color: '#FF0808',
    label: 'byVenue',
    description: 'cancelByVenue',
  },
]

export const FULL_CANCEL_POLICY_OPTIONS_MAP = optionsToMapObject(FULL_CANCEL_POLICY_OPTIONS, (obj) =>
  omit(obj, ['value']),
)
