import {TagSettingsProps} from 'exsportia-components/dist/components/molecules/tag/settings'

import {theme} from '../../../theme/theme'

export type ChipSettingsProps = TagSettingsProps

export const serviceSettings: ChipSettingsProps = {
  width: 'max-content',
  height: 'max-content',
  bg: 'sport-bg',
  borderRadius: 'multiplier2x',
  // @ts-ignore
  p: `${theme.space.multiplier0_5x} ${theme.space.multiplier}`, // incorrect usage
  iconWithText: {
    text: {fontSize: 'tag', color: 'sport'},
    icon: {
      color: theme.colors.sport,
      width: theme.space.multiplier2x,
      height: theme.space.multiplier2x,
      iconWidth: theme.space.multiplier2x,
      iconHeight: theme.space.multiplier2x,
    },
  },
}

export const classSettings: ChipSettingsProps = {
  ...serviceSettings,
  bg: 'class-bg',
  iconWithText: {
    text: {color: 'class'},
    icon: {...serviceSettings.iconWithText?.icon, color: theme.colors.class},
  },
}

export const justTheSpaceSettings: ChipSettingsProps = {
  ...serviceSettings,
  bg: 'just-the-space-bg',
  iconWithText: {
    text: {color: 'just-the-space'},
    icon: {...serviceSettings.iconWithText?.icon, color: theme.colors['just-the-space']},
  },
}

export const typeStylesMap = {
  class: classSettings,
  service: serviceSettings,
  justTheSpace: justTheSpaceSettings,
}

export const additionalStylesMap = {}
