import {Box, Flex, Logo, P, Theme} from 'exsportia-components'
import React from 'react'

import {ExsportiaCompanyDetails} from '../../components/exsportia-company-details/exsportia-company-details'
import {InfoMessage} from '../../ui/components/info-message/info-message'
import {l} from '../../utils/i18n'

export const AccountRemoveSuccess: React.FC = () => (
  <Flex
    justifyContent='center'
    pt='160px'
  >
    <Flex gap='16px' flexDirection='column' maxWidth={['90%', '600px', null, null]}>
      <Logo bigLogo={Theme.logos.logoFull} />
      <P
        fontSize={'h1'}
        fontWeight={'bold'}
      >
          {l('success')}
      </P>
      <InfoMessage
        preset='success'
        text={l('removeAccountSuccessDesc')}
      />
      <Box mt='32px'>
        <ExsportiaCompanyDetails />
      </Box>
    </Flex>
  </Flex>
)
