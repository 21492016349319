import {IconSettingsProps} from 'exsportia-components/dist/components/atoms/icon'
import {IconType, StyleCommonType, StyleSizeSpaceType} from 'exsportia-components/dist/types'

import {theme} from '../../../theme/theme'
import {GradientLoaderProps} from '../gradient-loader/gradient-loader'

export type NavigationListSettingsProps = {
  chevronIcon?: IconType
  display?: StyleCommonType
  width?: StyleSizeSpaceType
  height?: StyleSizeSpaceType
  flexDirection?: StyleCommonType
  textLoader?: GradientLoaderProps
  elementMargin?: StyleSizeSpaceType
  chevronSettings?: IconSettingsProps
}

export const defaultSettings: NavigationListSettingsProps = {
  width: '100%',
  display: 'block',
  height: 'max-content',
  flexDirection: 'column',
  chevronIcon: 'chevronRightBig',
  chevronSettings: {color: theme.colors['icon-secondary']},
  textLoader: {height: 48, style: {margin: theme.space.multiplier}},
}
