import {Box} from 'exsportia-components'
import {useFormikContext} from 'formik'
import React, {FC} from 'react'
import {useQuery} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {EmptyList} from '../../components/empty-list/empty-list'
import {PlayspaceCard, PlayspaceCardProps} from '../../components/playspace-card/playspace-card'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {BookingService} from '../../services/booking'
import {CreateServiceBookingFormType} from '../../services/booking/forms/create-service-booking-form'
// import {CreateSpaceBookingFormType} from '../../services/booking/forms/create-space-booking-form'
import {NavigationList} from '../../ui/components/navigation-list/navigation-list'
import {ScrollableWrapper} from '../../ui/components/scrollable-wrapper/scrollable-wrapper'
import {useLocale} from '../../utils/i18n'
import {isNilOrEmpty} from '../../utils/logic'

const Header: FC = () => {
  const {l} = useLocale()
  return <SimpleGridLayoutHeader title={l('assignedPlayspaces')} />
}

const PlayspacesList = () => {
  const navigate = useNavigate()
  const {values, setValues} = useFormikContext<
    CreateServiceBookingFormType
  >()
  const isInstructorBooking = values.entity === 'teacher'
  const {data, isLoading} = useQuery(
    BookingService.queries.getPlayspacesQuery({
      query: {serviceId: values.serviceId!, isInstructor: values.entity === 'teacher'},
    }),
  )
  const playspacesSettings: PlayspaceCardProps[] = data
    ? data.map((playspace: any) => ({
        name: playspace.name,
        styleType: 'default',
        onClick: () => {
          setValues({
            ...values,
            area: playspace,
            date: playspace.closestAvailableDate || values.date
          })
          if (!isInstructorBooking && values.service!.selectedTeachers.length > 0) {
            return navigate('../select-instructor')
          }
          navigate('../select-date-time')
        },
        image: (
          <Box
            width='48px'
            height='48px'
            background={`url(${playspace.image}) no-repeat center center`}
            backgroundSize='cover'
          />
        ),
      }))
    : []
  if (isNilOrEmpty(data) && !isLoading) {
    return <EmptyList />
  }

  return (
    <NavigationList
      Component={PlayspaceCard}
      isLoading={isLoading}
      elementsSettings={playspacesSettings}
    />
  )
}

export const SelectPlayspacePage: FC = () => {

  return (
    <GridLayout
      withoutPadding
      header={<Header />}
    >
      <ScrollableWrapper>
        <Box pb='8px'>
          <PlayspacesList />
        </Box>
      </ScrollableWrapper>
    </GridLayout>
  )
}
