import {Flex, Tag, Text} from 'exsportia-components'
import {FC, useMemo, useState} from 'react'

import {useLocale} from '../../../utils/i18n'

export type TextWithShowMoreProps = {
  text?: string
  treshhold?: number
}

export const TextWithShowMore: FC<TextWithShowMoreProps> = ({text = '', treshhold = 200}) => {
  const {l} = useLocale()
  const [showMore, setShowMore] = useState(false)
  const withShowMore = useMemo(() => text.length > treshhold, [text])
  if (!withShowMore) {
    return <Text text={text} />
  }
  return (
    <Flex>
      <Flex
        flexDirection='column'
        jusifyContent='flex-end'
      >
        <Text
          text={text}
          settings={{croppedLines: showMore ? 'unset' : 3}}
          styleType={showMore ? 'default' : 'verticalCropped'}
        />
        <Flex
          mt='8px'
          justifyContent='center'
          width='100%'
        >
          <Flex
            mt='4px'
            cursor='pointer'
            onClick={() => setShowMore(!showMore)}
          >
            <Tag
              styleType='displayValue'
              text={showMore ? l('showLess') : l('showMore')}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
