/* eslint-disable max-lines */
import moment from 'moment'

import {safeRound, uidMaker, UIDTypesEnum} from '../../helpers/logic'
import {IntegratedSignUpFormType} from '../../services/auth/forms/integrated-sign-up-form'
import {CreateClassBookingFormType} from '../../services/booking/forms/create-class-booking-form'
// import {User} from "../../../api/user/types";
import {CreateServiceBookingFormType} from '../../services/booking/forms/create-service-booking-form'
import {
  BookingStateParticipant,
  CreateBookingDateData,
  CreateBookingParticipantData,
  CreateBookingParticipantMembership,
  CreateBookingParticipantMembershipData,
  CreateBookingPaymentData,
  CreateBookingServiceData,
  CreateBookingTimeData,
  // CreateBookingUserData,
  // CreateBookingVenueData
} from '../../services/booking/forms/types'
import {isNotNilAndNotEmpty, omit, pick} from '../../utils/logic'
import {AreaSelectArea} from '../area/types'
import {Session} from '../class-session/types'
// import {ServiceTypesEnum} from '../service/types'
import {CalculateBookingPriceQueryReturnType, CalculatedBookingPriceObject} from './calculate-booking-price'
import {CancelPolicyEnum, Instructor, ServiceItem} from './types'

/* const venueDataAdapter = (venue: Venue): CreateBookingVenueData => ({
  venueId: venue.uid
})*/

const sportDataAdapter = (sport: ServiceItem): CreateBookingServiceData => ({
  ...pick(sport, ['uid', 'multiPerTimeSlot', 'cancelPolicy']),
  sportId: sport.uid,
  activityName: sport.name,
  sportType: sport.sportType || sport.serviceType,
})

const dateDataAdapter = (date: string): CreateBookingDateData => ({date})

export const timeDataAdapter = (
  time: (CreateServiceBookingFormType)['time'],
  date: (CreateServiceBookingFormType)['date'],
  sport: ServiceItem,
): CreateBookingTimeData => {
  const noDuration = sport.pricePeriod === 'NO_DURATION'
  const startTime = moment.utc(time[0], 'x')
  const endTime = moment.utc(time[1] || time[0], 'x').utc()
  const minutesFromStartOfTheDay = startTime.hours() * 60 + startTime.minutes()
  return {
    time: minutesFromStartOfTheDay,
    duration: noDuration ? 'NO_DURATION' : moment(endTime).diff(moment(startTime), 'minutes'),
    date_timestamp: Number(moment.utc(date, 'DD/MM/YYYY').add(minutesFromStartOfTheDay, 'm').utc().format('x')),
  }
}

export const getDuration = ({
  start,
  end,
  isNoDuration = false
}: {
  start: number | null,
  end: number | null,
  isNoDuration?: boolean
}) => isNoDuration ? 'NO_DURATION' : moment(end).diff(moment(start), 'minutes')

export const membershipDataAdapter = (
  participants: BookingStateParticipant[],
  occupyUsage = false,
): CreateBookingParticipantMembershipData => {
  const updatedParticipants = participants.map((participant) => {
    return {
      ...omit(participant, ['defaultSubApplied', 'membershipActivity']),
      isMember: Boolean(participant.membership?.uid),
      ...(occupyUsage
        ? {
            membershipId: participant.membership?.uid,
            membershipName: participant.membership?.name ?? null,
            membershipBookingId: participant.membership?.membershipBookingId ?? null,
            membershipBookingReference: participant.membership?.membershipBookingReference ?? null,
          }
        : {}),
    }
  })
  return {participants: updatedParticipants, cancelPolicy: participants[0]?.membershipActivity?.cancelPolicy}
}

export const priceDataAdapter = (priceData: CalculateBookingPriceQueryReturnType[number]) => {
  return {
    totalPrice: priceData.price,
    discount: priceData.discount,
    sum: String(priceData.price),
    equipmentDiscount: 0,
    equipmentPrice: priceData.equipmentsTotalPrice,
    approximatePrice: priceData.price,
    occupyUsage: priceData.discount > 0 || (priceData.discount === 0 && priceData.price === 0),
  }
}

export const makePaymentData = (payOnLocation: boolean): CreateBookingPaymentData => ({
  isPaid: false,
  paymentId: null,
  paymentType: 'card',
  paymentOnArrival: payOnLocation,
})

const makeCommonBookingConfiguration = () => ({manual: false})

const makeClassBookingConfiguration = () => ({...makeCommonBookingConfiguration()})

const makeSportBookingConfiguration = () => ({...makeCommonBookingConfiguration()})

const makeTeacherBookingConfiguration = () => ({...makeCommonBookingConfiguration()})

const bookingConfigurationByBookingType = {
  class: makeClassBookingConfiguration,
  space: makeSportBookingConfiguration,
  service: makeSportBookingConfiguration,
  teacher: makeTeacherBookingConfiguration,
}

export const areaDataAdapter = (area: AreaSelectArea) => ({
  entity: 'sport',
  areaId: area.uid,
  placeEntity: 'area',
  entityName: area.name,
})

const teacherDataAdapter = (instructor: Instructor) => ({
  entity: 'sport',
  placeEntity: 'teacher',
  teacherId: instructor.uid,
  entityName: instructor.name,
})

const participantsDataAdapter = (
  participants: CreateBookingParticipantMembership[],
  priceDataObject: CalculatedBookingPriceObject,
): CreateBookingParticipantData => {
  const subscriptionActivityCancelPolicy = priceDataObject.cancelPolicy
  const updatedParticipants = participants.map((el, i) => {
    const participantAdditionalData = priceDataObject.participants[i] ?? {}
    const temp = {
      ...el,
      usedMinutesWithSub: participantAdditionalData.limitationCheckResult?.used.minutes ?? null,
      usedCreditsWithSub: participantAdditionalData.limitationCheckResult?.used.credits ?? null,
      ...pick(participantAdditionalData, [
        'totalPrice',
        'equipmentsTotalPrice',
        'discount',
        'activityGroupId',
        'equipments',
        'avatar',
      ]),
      membershipBookingId: participantAdditionalData.membershipBookingId || null,
      membershipId: participantAdditionalData.membershipId
    }
    return omit(temp, ['discountType', 'memberParticipantIndex', 'needEquipment', 'subscriptionStatus', 'membership']) // update types
  })
  const data = {peopleAmount: participants.length, participants: updatedParticipants}
  if (isNotNilAndNotEmpty(subscriptionActivityCancelPolicy) && participants[0]?.membershipBookingId) {
    return {...data, cancelPolicy: subscriptionActivityCancelPolicy}
  }
  return data
}

// const entityData = teacherDataAdapter(bookingState.teacher!)
const commonBookingAdapter = (data: {
  bookingState:
    | CreateClassBookingFormType
    | CreateServiceBookingFormType
}) => {
  const {bookingState} = data
  const {venueId, bookingType} = bookingState
  const bookingConfiguration = bookingConfigurationByBookingType[bookingType]()
  const date = bookingState.date
  const userSpecialRequest = bookingState.hasSpecialRequests ? bookingState.specialRequest : undefined

  const dateData = dateDataAdapter(date!)
  const paymentData = makePaymentData(true) // TODO: use state value

  return {
    venueId,
    ...dateData,
    ...paymentData,
    ...bookingConfiguration,
    systemType: 'BOOKING_WEB',
    specialRequests: userSpecialRequest,
    bookingId: uidMaker(8, UIDTypesEnum.NUMBERS),
    reference: uidMaker(6, UIDTypesEnum.UPPERS_AND_NUMBERS),
  }
}

export type CommonServiceBookingDraftType = Omit<IntegratedSignUpFormType, 'termsAndConditionsConfirm'> &
  ReturnType<typeof dateDataAdapter> &
  ReturnType<typeof makePaymentData> &
  ReturnType<typeof participantsDataAdapter> &
  ReturnType<typeof priceDataAdapter> &
  ReturnType<typeof sportDataAdapter> &
  ReturnType<typeof timeDataAdapter> & {
    venueId?: string
    manual: boolean
    isPaid?: boolean
    bookingId: string
    reference: string
    entity: string
    entityName: string
    paymentType?: string
    areaId?: string | null
    specialRequests?: string
    teacherId?: string | null
    useForParticipants?: boolean
    paymentMethodId?: string | null
  }

export const commonServiceBookingDataAdapter = (data: {
  bookingState: CreateServiceBookingFormType
  priceDataObjects: CalculatedBookingPriceObject[]
}): CommonServiceBookingDraftType => {
  const {bookingState, priceDataObjects} = data
  const service = bookingState.service!
  const participants = bookingState.participants
  const time = bookingState.time
  const date = bookingState.date
  const priceData = priceDataAdapter(priceDataObjects[0])
  const additionalDataIfFree =
    priceData.totalPrice === 0 // TODO fix in correct way (hot fix)
      ? {isPaid: true, paymentType: 'EXSPORTIA_PAY' as const}
      : {}
  const membershipData = membershipDataAdapter(participants, priceData.occupyUsage)
  const participantsWithMembership = membershipData.participants
  const commonValues = commonBookingAdapter(data)
  const placeEntityData = data.bookingState.bookingType === 'teacher' ? teacherDataAdapter(bookingState.teacher!) : areaDataAdapter(bookingState.area!)
  const secondaryPlaceEntityData = data.bookingState.bookingType === 'teacher'
    ? {areaId: bookingState.area?.uid}
    : {teacherId: bookingState.teacher?.uid}

  return {
    ...commonValues,
    ...membershipData,
    ...priceData,
    ...additionalDataIfFree,
    ...sportDataAdapter(service),
    ...timeDataAdapter(time, date, service),
    ...participantsDataAdapter(participantsWithMembership, priceDataObjects[0]),
    ...placeEntityData,
    ...secondaryPlaceEntityData,
    name: bookingState.name,
    email: bookingState.email,
    phone: bookingState.phone,
    uid: commonValues.bookingId,
    userId: bookingState.userId,
    paymentMethodId: bookingState.paymentMethodId,
    useForParticipants: bookingState.useForParticipants,
  }
}

export type PayByTokenServiceBookingDraft = {
  sum: string
  uid: string
  venueId?: string
  bookingId: string
  paymentMethodId?: string | null
  data: CommonServiceBookingDraftType,
}

export const commonServiceBookingPayByTokenAdapter = (data: {
  bookingState: CreateServiceBookingFormType
  priceDataObjects: CalculatedBookingPriceObject[]
}): PayByTokenServiceBookingDraft => {
  const {bookingState, priceDataObjects} = data
  const bookingData = commonServiceBookingDataAdapter({
    bookingState,
    priceDataObjects,
  })
  return {
    data: bookingData,
    uid: bookingData.uid,
    sum: bookingData.sum,
    venueId: bookingData.venueId,
    bookingId: bookingData.bookingId,
    paymentMethodId: bookingState.paymentMethodId,
  }
}

type SessionBookingDraft = ReturnType<typeof participantsDataAdapter> &
  ReturnType<typeof priceDataAdapter> & {
    bookingId: string
    slot: Partial<Session>
    slot_id: string
    entity: 'class'
    placeEntity: 'area'
    paymentMethodId?: string | null
    entityName: string
    activityName: string
    sportType: string
    cancelPolicy: CancelPolicyEnum | null
    useForParticipants?: boolean
  }

export type ClassBookingDraft = Omit<IntegratedSignUpFormType, 'termsAndConditionsConfirm'> &
  ReturnType<typeof commonBookingAdapter> & {
    objectsToSave: SessionBookingDraft[]
    totalPrice: number
    paymentMethodId?: string | null
    sum: string
    entity: 'class'
  }

export type PayByTokenClassBookingDraft = {
  sum: string
  uid: string
  venueId?: string
  bookingId: string
  data: ClassBookingDraft
  paymentMethodId?: string | null
}

export const classBookingAdapter = (data: {
  bookingState: CreateClassBookingFormType
  priceDataObjects: CalculatedBookingPriceObject[]
}): ClassBookingDraft => {
  const {bookingState, priceDataObjects} = data
  const participants = bookingState.participants
  const classData = bookingState.class!
  const session = bookingState.session!
  const priceData = priceDataObjects[0]
  const price = priceDataAdapter(priceData)
  const additionalDataIfFree =
    price.totalPrice === 0 // TODO fix in correct way (hot fix)
      ? {
          isPaid: true,
          paymentType: 'EXSPORTIA_PAY',
        }
      : {}
  const membershipData = membershipDataAdapter(participants, price.occupyUsage)
  const participantsWithMembership = membershipData.participants
  const item: SessionBookingDraft = {
    ...price,
    ...additionalDataIfFree,
    bookingId: uidMaker(8, UIDTypesEnum.NUMBERS),
    slot: {
      ...omit(session, [
        'slotFull',
        'spaces',
        'classData',
        'slotSpace',
        'slotBookings',
        'userBookedSlot',
        'availableSpaces',
        'userInWaitingList',
        'usersWhoMakeBookings',
      ]),
    },
    entity: 'class',
    placeEntity: 'area',
    entityName: classData.name,
    activityName: classData.name,
    sportType: classData.sportType,
    slot_id: bookingState.sessionId!,
    cancelPolicy: classData.cancelPolicy!,
    ...participantsDataAdapter(participantsWithMembership, priceData),
    useForParticipants: bookingState.useForParticipants,
  }
  const totalPrice = safeRound(item.totalPrice)

  return {
    ...commonBookingAdapter(data),
    totalPrice,
    entity: 'class',
    objectsToSave: [item],
    sum: String(totalPrice),
    name: bookingState.name,
    email: bookingState.email,
    phone: bookingState.phone,
    userId: bookingState.userId,
    paymentMethodId: bookingState.paymentMethodId,
  }
}

export const payByTokenClassBookingAdapter = (data: {
  bookingState: CreateClassBookingFormType
  priceDataObjects: CalculatedBookingPriceObject[]
}): PayByTokenClassBookingDraft => {
  const classBookingData = classBookingAdapter(data)

  return {
    data: classBookingData,
    sum: classBookingData.sum,
    uid: classBookingData.bookingId,
    venueId: classBookingData.venueId,
    bookingId: classBookingData.bookingId,
    paymentMethodId: classBookingData.paymentMethodId,
  }
}
