import {Box, Label, ListItem} from 'exsportia-components'
import {FC, useMemo} from 'react'
import {useQuery} from 'react-query'

import {GetVenueQueryReturnType} from '../../api/venue/get-venue'
import {VenueContact} from '../../api/venue/types'
import {VenueService} from '../../services/venue'
import {Button} from '../../ui/components/button/button'
import {useLocale} from '../../utils/i18n'
import {isNilOrEmpty} from '../../utils/logic'

const openWhatsAppChat = (value: VenueContact['value']) => {
  const whatsappUrl = `https://wa.me/${value}`
  window.open(whatsappUrl, '_blank')
}
const ContactsList: FC<{data?: GetVenueQueryReturnType}> = ({data}) => (
  <>
    {data?.contacts.sort().map(({type, text, value}, index) => {
      switch (type) {
        case 'WEBSITE':
          return (
            <ListItem
              key={`${index}_contact_item`}
              subText={text}
              text={
                <a
                  href={value}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {value}
                </a>
              }
              styleType='summary'
            />
          )
        case 'EMAIL':
          return (
            <ListItem
              key={`${index}_contact_item`}
              subText={text}
              text={
                <a
                  href={`mailto:${value}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {value}
                </a>
              }
              styleType='summary'
            />
          )
        case 'WHATSAPP':
          break
        default:
          return (
            <ListItem
              key={`${index}_contact_item`}
              subText={text}
              text={value}
              styleType='summary'
            />
          )
      }
    })}
  </>
)

export const ContactUsPage: FC<{venueId: string}> = ({venueId}) => {
  const {l} = useLocale()
  const {data: venueData} = useQuery(VenueService.queries.getVenueQuery({params: {id: venueId}}))

  const whatsappMarkup = useMemo(() => {
    const whatsappContact = venueData?.contacts.find((contact) => contact.type === 'WHATSAPP')
    if (isNilOrEmpty(whatsappContact)) {
      return null
    }
    return (
      <>
        <Label>{whatsappContact.text}</Label>
        <Button
          type='secondary'
          onClick={() => openWhatsAppChat(whatsappContact.value)}
        >
          {l('open_chat', {caseAction: 'capitalize'})}
        </Button>
      </>
    )
  }, [venueData?.contacts])

  return (
    <Box mb='16px'>
      <ContactsList data={venueData} />
      {whatsappMarkup}
    </Box>
  )
}
