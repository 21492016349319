// eslint-disable-next-line no-restricted-imports
import {Box, Button} from 'exsportia-components'
import {CommonButtonProps} from 'exsportia-components/dist/components/atoms/button'
import {FC, useMemo} from 'react'

import {useAppLoading} from '../../../services/global/hooks'
import {settings} from './settings'

export type TabButtonProps = CommonButtonProps & {
  isActive?: boolean
  isMobile?: boolean
}

export const TabButton: FC<TabButtonProps> = ({icon, text, isActive = false, onClick, isMobile = false}) => {
  const {isLoading} = useAppLoading()
  const settingsToUse = useMemo(
    () => settings[isMobile ? 'mobile' : 'default'][isActive ? 'active' : 'default'],
    [isActive, isMobile],
  )
  return (
    <Box>
      <Box
        overflow='hidden'
        position='relative'
      >
        <Button
          icon={icon}
          text={text}
          styleType='link'
          onClick={onClick}
          disabled={isLoading}
          settings={settingsToUse}
        />
        {!isMobile && isActive && (
          <Box
            position='absolute'
            height='4px'
            width='calc(100% - 32px)'
            bottom='0'
            left='16px'
            borderRadius='4px 4px 0 0'
            backgroundColor='rgba(237, 49, 99, 1)'
          />
        )}
      </Box>
    </Box>
  )
}
