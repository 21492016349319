import {Box} from 'exsportia-components'
import {FC, PropsWithChildren} from 'react'

import {useAuthState} from '../../../services/auth/hooks'

export const ScrollableWrapper: FC<PropsWithChildren<{isScrollable?: boolean}>> = ({children, isScrollable = true}) => {
  if (!isScrollable) {
    return <>{children}</>
  }
  const {isLoggedIn} = useAuthState()
  return (
    <Box
      overflow={'auto'}
      maxHeight={isLoggedIn ? '60dvh' : '59dvh'}
      position='relative'
    >
      {children}
    </Box>
  )
}
