import '../services/auth'
import '../services/booking'
import '../services/global'
import '../services/user'

import {combineReducers} from 'redux'

import {reducer as authReducer} from '../services/auth/reducer'
import {reducer as bookingReducer} from '../services/booking/reducer'
import {reducer as globalReducer} from '../services/global/reducer'
import {reducer as userReducer} from '../services/user/reducer'

export const rootReducer = combineReducers({
  booking: bookingReducer,
  global: globalReducer,
  auth: authReducer,
  user: userReducer,
})
