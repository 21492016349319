import {IconType, TextType} from 'exsportia-components/dist/types'
import React, {FC, useMemo} from 'react'

import {PlayspaceCard} from '../playspace-card/playspace-card'

export type InstructorCardProps = {
  name: TextType
  image: IconType
  subText?: TextType
  isLoading?: boolean
}

export const InstructorCard: FC<InstructorCardProps> = ({name, image, subText, isLoading = false}) => {
  const subTextArray = useMemo(() => [subText], [subText])

  return (
    <PlayspaceCard
      name={name}
      image={image}
      isLoading={isLoading}
      subTextElements={subTextArray}
    />
  )
}
