import {Box, Flex, Image, Text, Title} from 'exsportia-components'
import {ModalWindowProps} from 'exsportia-components/dist/components/layouts/modal'
import React, {FC, useEffect, useMemo, useState} from 'react'
import {NavigateFunction, useNavigate} from 'react-router-dom'

import {
  AppearanceTemplateElement,
  AppearanceTemplateElementType,
  AppearanceTemplateType,
} from '../../../api/display-settings/types'
import {ServiceTypeOption} from '../../../api/service/types'
import {InstructorCard} from '../../../components/instructor-card/instructor-card'
import {SubscriptionCard} from '../../../components/subscription-card/subscription-card'
import {TranslatorScope} from '../../../locale/types'
import {DownloadAppModal} from '../../../modals/download-app-modal/download-app-modal'
import {useServiceOptions} from '../../../services/service-options/hooks'
import {Button} from '../../../ui/components/button/button'
import {Chevron} from '../../../ui/components/chevron/chevron'
import {EntityCard} from '../../../ui/components/entity-card/entity-card'
import {LocaleOptions, useLocale} from '../../../utils/i18n'
import {isNilOrEmpty, isNotNilAndNotEmpty} from '../../../utils/logic'
import {useModalActions} from '../../../utils/modals'
import {validationTokensMap} from '../../select-service/select-service-page'

const entityTypes: Record<
  Exclude<AppearanceTemplateElementType, 'LIST'>,
  Exclude<AppearanceTemplateElementType, 'LIST'>
> = {
  CLASS: 'CLASS',
  SPACE: 'SPACE',
  TEACHER: 'TEACHER',
  SUBPAGE: 'SUBPAGE',
  SERVICE: 'SERVICE',
  SUBSCRIPTION: 'SUBSCRIPTION',
}

export type LayoutByAppearanceTemplateProps = {
  appearanceTemplate: AppearanceTemplateType
  setBackAction: React.Dispatch<React.SetStateAction<(() => void) | null>>
  collapsed?: boolean
  collapsible?: boolean
  collapseThreshold?: number
}

export type ComponentProps = {
  l: (scope: TranslatorScope, options?: LocaleOptions) => string
  item: AppearanceTemplateElement
  onClick: (appearanceTemplate: AppearanceTemplateType) => void
  navigate: NavigateFunction
  openModal: (modalConfig: ModalWindowProps) => void
  getServiceConfigByServiceType: (serviceType?: string | undefined) => ServiceTypeOption
}

const navComponentsMap: Record<string, FC<ComponentProps>> = {
  [entityTypes.SERVICE]: ({item, navigate, getServiceConfigByServiceType, l}) => (
    <EntityCard
      text={item.data!.name}
      icon={getServiceConfigByServiceType(item.data!.sportType).icon}
      disabled={!item.data!.permissionData.validated}
      styleType='service'
      onClick={() =>
        isNotNilAndNotEmpty(item.data!.teachersIds)
          ? navigate(`./instructor-service/${item.entityId}/select-instructor/`)
          : navigate(`./service/${item.entityId}/select-playspace/`)
      }
      subText={
        item.data!.permissionData.reason
          ? l(validationTokensMap[item.data!.permissionData.reason], {
              caseAction: 'capitalize',
            })
          : null
      }
    />
  ),
  [entityTypes.CLASS]: ({item, navigate, getServiceConfigByServiceType}) => (
    <EntityCard
      text={item.data!.name}
      styleType='class'
      onClick={() => navigate(`./class/${item.entityId}/select-session/`)}
      icon={getServiceConfigByServiceType(item.data!.sportType).icon}
    />
  ),
  [entityTypes.SPACE]: ({item, navigate, getServiceConfigByServiceType, l}) => (
    <EntityCard
      text={item.data!.name}
      icon={getServiceConfigByServiceType(item.data!.sportType).icon}
      onClick={() => navigate(`./space/${item.entityId}/select-playspace/`)}
      disabled={!item.data!.permissionData.validated}
      styleType='justTheSpace'
      subText={
        item.data!.permissionData.reason
          ? l(validationTokensMap[item.data!.permissionData.reason], {
              caseAction: 'capitalize',
            })
          : null
      }
    />
  ),
  [entityTypes.TEACHER]: ({item, navigate}) => (
    <Flex
      width='100%'
      onClick={() => navigate(`./instructor/${item.entityId}/select-service`)}
    >
      <InstructorCard
        name={item.data!.name}
        image={
          <Image
            width='48px'
            height='48px'
            size='contain'
            src={item.data!.profileImage.secure_url || ''}
          />
        }
      />
    </Flex>
  ),
  [entityTypes.SUBSCRIPTION]: ({item, openModal, l}) => (
    <Flex
      width='100%'
      onClick={() => {
        openModal({
          title: l('subscriptions'),
          component: <DownloadAppModal />,
          footerConfig: {disabled: true},
        })
      }}
    >
      <SubscriptionCard
        imageUrl={item.data!.logo.secure_url}
        name={item.data!.name}
      />
    </Flex>
  ),
  [entityTypes.SUBPAGE]: ({item, onClick}) => (
    <Flex
      mt='8px'
      width='100%'
    >
      <Button
        // @ts-ignore
        onClick={onClick}
        type='secondary'
      >
        {item.title}
      </Button>
    </Flex>
  ),
}

export const LayoutByAppearanceTemplate: FC<LayoutByAppearanceTemplateProps> = ({
  collapsible = false,
  // isRefetching,
  setBackAction,
  collapseThreshold = 3,
  appearanceTemplate,
}) => {
  const {l} = useLocale()
  const navigate = useNavigate()
  const {openModal} = useModalActions()
  const {getServiceConfigByServiceType} = useServiceOptions()
  const [appearanceTemplateToUse, setAppearanceTemplateToUse] = useState<AppearanceTemplateType>(appearanceTemplate)
  useEffect(() => {
    setAppearanceTemplateToUse(appearanceTemplate)
  }, [appearanceTemplate])
  const [subPageCollapseConfig, setSubPageCollapseConfig] = useState<{
    isUsed: boolean
    collapsed: boolean
    collapsible: boolean
    collapseThreshold: number
  }>({
    isUsed: false,
    collapsed: true,
    collapsible: false,
    collapseThreshold: 0,
  })
  const [collapsed, setCollapsed] = useState<boolean>(
    subPageCollapseConfig.isUsed ? subPageCollapseConfig.collapsed : true,
  )
  const [hiddenCount, setHiddenCount] = useState<number>(0)
  const collapseConfigToUse = useMemo(() => {
    if (subPageCollapseConfig.isUsed) {
      return subPageCollapseConfig
    }
    return {
      collapsible,
      collapseThreshold,
    }
  }, [collapseThreshold, collapsible, subPageCollapseConfig])
  const [title, setTitle] = useState<string>('')
  useEffect(() => {
    if (appearanceTemplate !== appearanceTemplateToUse) {
      return setBackAction(() => () => {
        setTitle('')
        setAppearanceTemplateToUse(appearanceTemplate)
        setSubPageCollapseConfig({isUsed: false, collapsed: true, collapsible: false, collapseThreshold: 0})
      })
    }
    setBackAction(null)
  }, [appearanceTemplateToUse, appearanceTemplate])
  const appearanceTemplateWithCollapsed: AppearanceTemplateType = useMemo(() => {
    const result: AppearanceTemplateType = []
    if (collapseConfigToUse.collapsible && collapsed) {
      for (let i = 0; i < collapseConfigToUse.collapseThreshold; i++) {
        result.push(appearanceTemplateToUse[i])
      }
      setHiddenCount(appearanceTemplateToUse.length - result.length)
      return result
    }
    return appearanceTemplateToUse
  }, [appearanceTemplateToUse, collapsed, subPageCollapseConfig])

  return (
    <>
      {title && (
        <Flex pl='16px'>
          <Title
            text={title}
            styleType='h2'
          />
        </Flex>
      )}
      {appearanceTemplateWithCollapsed.map((element, index) => {
        if (element.show === false) {
          return null
        }
        if (element.type === 'LIST') {
          return (
            <Box key={`fragment-${index}`}>
              <Flex
                mb='16px'
                mx='16px'
                gap='12px'
                flexDirection='column'
              >
                <Title
                  styleType='h2'
                  text={element.title}
                />
                {element.description && (
                  <Text text={element.description} settings={{croppedLines: 0}} />
                )}
              </Flex>
              <LayoutByAppearanceTemplate
                appearanceTemplate={element.items || []}
                key={`element-${index}`}
                setBackAction={setBackAction}
                collapsible={element.collapsible && ((element.items || []).length || 0) > element.collapseThreshold}
                collapseThreshold={element.collapseThreshold}
              />
            </Box>
          )
        }
        const Component = navComponentsMap[element.type]
        const isSubpageButton = element.type === 'SUBPAGE'
        if (isSubpageButton && isNilOrEmpty(element.items)) {
          return null
        }
        return (
          <Flex
            py='8px'
            px='16px'
            width='100%'
            alignItems='center'
            justifyContent='space-between'
            key={`navigationElement${index}`}
            backgroundHover={isSubpageButton ? 'unset' : 'rgba(0, 16, 61, 0.06)'}
          >
            <Component
              l={l}
              item={element}
              navigate={navigate}
              getServiceConfigByServiceType={getServiceConfigByServiceType}
              onClick={() => {
                setTitle(element.title)
                setAppearanceTemplateToUse(element.items!)
                setSubPageCollapseConfig({
                  isUsed: true,
                  collapsed: true,
                  collapseThreshold: element.collapseThreshold,
                  collapsible: element.collapsible && (element.items?.length || 0) > element.collapseThreshold,
                })
              }}
              openModal={openModal}
            />
            {!isSubpageButton && (
              <Chevron
                direction={'right'}
                styleType='simple'
              />
            )}
          </Flex>
        )
      })}
      {collapseConfigToUse.collapsible && (
        <Flex px='16px' mt='8px'>
          <Button
            type='secondary'
            onClick={() => setCollapsed((prev) => !prev)}
          >
            {collapsed ? `${l('showMore')} (${hiddenCount})` : l('showLess')}
          </Button>
        </Flex>
      )}
    </>
  )
}
