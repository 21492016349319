import {Box, Divider, Flex, Text} from 'exsportia-components'
import {TextType} from 'exsportia-components/dist/types'
import React, {FC} from 'react'

import {useLocale} from '../../utils/i18n'
import {BookingCode} from '../booking-code/booking-code'
import {QRCode} from '../qr-code/qr-code'
import {defaultSettings} from './settings'

export type BookingCardProps = {
  qrLink: string
  duration: TextType
  bookingCode: string
  dateAndTime: TextType
}

export const BookingCard: FC<BookingCardProps> = ({qrLink, duration, bookingCode, dateAndTime}) => {
  const {l} = useLocale()
  return (
    <Flex width={defaultSettings.width} alignItems={defaultSettings.alignItems}>
      <Box
        p={defaultSettings.infoBlock.p}
        mr={defaultSettings.infoBlock.mr}
        width={defaultSettings.infoBlock.width}
        border={defaultSettings.infoBlock.border}
        borderRadius={defaultSettings.infoBlock.borderRadius}
      >
        <Text
          text={l('dateAndTime')}
          settings={defaultSettings.infoBlock.labelSettings}
        />
        <Flex>
          <Text
            text={dateAndTime}
            settings={defaultSettings.infoBlock.dateSettings}
          />
          <Text
            text={`(${duration})`}
            settings={defaultSettings.infoBlock.durationSettings}
          />
        </Flex>
        <Divider settings={defaultSettings.infoBlock.dividerSettings} />
        <Text
          text={l('bookingReference')}
          settings={defaultSettings.infoBlock.labelSettings}
        />
        <BookingCode code={bookingCode} />
      </Box>
      <QRCode value={qrLink} />
    </Flex>
  )
}
