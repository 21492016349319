import {Box, Flex, InputText, Label, P} from 'exsportia-components'
import {useFormik} from 'formik'
import {useCallback} from 'react'
import {useNavigate} from 'react-router-dom'

import {Button} from '../../ui/components/button/button'
import {useLocale} from '../../utils/i18n'

export const VenueIdInputPage = ({}) => {
  const navigate = useNavigate()
  const {l} = useLocale()
  const formik = useFormik({
    initialValues: {
      venueId: '',
    },
    onSubmit: (values) => navigate(`/${values.venueId}`),
  })
  const handleSubmit = useCallback(() => formik.handleSubmit(), [formik])

  return (
    <Flex
      justifyContent='center'
      pt='160px'
    >
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <P
            mb='16px'
            fontSize={'h1'}
            fontWeight={'bold'}
          >
            {l('something_went_wrong')}
          </P>
          <Box mb='16px'>
            <Label settings={{color: 'text-secondary'}}>{l('venue_id')}</Label>
            <InputText
              id='venueId'
              name='venueId'
              onChange={formik.handleChange}
              type='text'
            />
          </Box>
          <Button
            onClick={handleSubmit}
            type='secondary'
          >
            {l('confirm')}
          </Button>
        </Box>
      </form>
    </Flex>
  )
}
