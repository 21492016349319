import {Box, Text} from 'exsportia-components'
import React, {FC} from 'react'

import {useLocale} from '../../utils/i18n'

export const CloseModalConfirmationModal: FC = () => {
  const {l} = useLocale()
  return (
    <Box>
      <Text text={l('allTextWillBeLost')} />
    </Box>
  )
}
