import {Theme} from 'exsportia-components'
import {Button as RawButton} from 'exsportia-components/dist/components/atoms'
import {ComponentProps} from 'react'

type ButtonStyle = Partial<ComponentProps<typeof RawButton>>

const primary: ButtonStyle = {styleType: 'primary', settings: {width: '100%', bg: 'primary'}}

const flat: ButtonStyle = {
  styleType: 'link',
  settings: {width: '100%', color: 'primary', icon: {color: Theme.colors.sport}, hover: {bg: 'unset'}, active: {bg: 'unset'}},
}

const flat_short: ButtonStyle = {
  ...flat,
  settings: {...flat.settings, p: 0, width: 'max-content'},
}


const secondary: ButtonStyle = {
  styleType: 'secondary',
  settings: {width: '100%'},
  additionalStyles: ['fullSize'],
}

const cancel: ButtonStyle = {
  ...secondary,
  settings: {
    ...secondary.settings,
    color: 'error'
  }
}

const primary_green: ButtonStyle = {
  ...primary,
  settings: {
    ...primary.settings,
    width: 'unset',
    bg: 'success'
  }
}

const icon: ButtonStyle = {
  ...flat,
  settings: {
    ...flat.settings,
    width: 'max-content',
    icon: {
      color: '#BFC1C7',
    },
  },
}

export const buttonStylesMap = {
  flat,
  icon,
  cancel,
  primary,
  secondary,
  flat_short,
  primary_green,
}
