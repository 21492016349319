import {LTRStickyStyles} from '../../components/timeslot-grid/constants'
import {CurrenciesEnum} from '../../pages/select-service/select-service-page'
import {Area, AreaSelectArea} from '../area/types'
import {Class} from '../class/types'
import {Service} from '../service/types'
import {Media, OpeningTimes} from '../types'
import {Venue} from '../venue/types'

export enum CancelPolicyEnum {
  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  STRICT = 'STRICT',
  FLEXIBLE = 'FLEXIBLE',
  SEMI_FLEXIBLE = 'SEMI_FLEXIBLE',
}

type InstructorBooking = {
  placeEntity: 'teacher'
  place: Instructor
}

type ServiceBooking = {
  placeEntity: 'service'
  place: AreaSelectArea
}

export type BookingUsageDataType = {
  amountByType: 'AMOUNT_BY_CREDITS' | 'AMOUNT_BY_ENTRIES' | 'AMOUNT_BY_MINUTES'
  used: number
}

export type Booking = {
  uid: string
  // placeEntity: 'service' | 'teacher'
  name: string
  // place: Area | Instructor
  time: string
  area?: Area & {profileImage?: {secure_url: string}}
  media: Media[]
  venue: Venue
  sport: Service | null
  class?: Class
  isPaid: boolean
  entity: 'class' | 'service'
  userId: string
  refund?: number
  areaId?: string
  venueId: string
  teacher?: Instructor
  currency: CurrenciesEnum
  duration: number | 'NO_DURATION'
  discount?: number
  teachers?: Instructor[]
  sportType: string
  reference: string
  timestamp: string
  areaSpace: boolean
  usageData?: BookingUsageDataType
  teacherId?: string
  classSlot?: Class & {areaName: string}
  totalPrice: number
  isCancelled: boolean
  paymentType?: string | null
  occupyUsage?: boolean
  cancelPolicy: CancelPolicyEnum | string
  participants: Participant[]
  date_timestamp?: number | string
  specialRequests?: string
  paymentMethodId?: string
  paymentOnExsportia?: boolean
  arrivalInstructions?: string
  allowUserToCancelBooking?: boolean
  cancelForPayOnLocationType?: 'DAYS' | 'HOURS'
  cancelForPayOnLocationDesc?: string
  cancelForPayOnLocationCount?: number
} & (InstructorBooking | ServiceBooking)

export type Participant = {
  equipments: Equipment[]
  avatar?: string
  name?: string
  membershipId?: string
  membershipBookingId?: string | null
  totalPrice?: number
  discount?: number
  usedCreditsWithSub?: number
  usedMinutesWithSub?: number
}

export type Equipment = {
  quantity: number
  price: number
  name: string
  label?: string
  value?: string
}

export enum TimeslotStatesEnum {
  PAST = 'PAST',
  SELECTED = 'SELECTED',
  BLOCKED = 'BLOCKED',
  DEFAULT = 'DEFAULT',
  NOT_SELECTABLE = 'NOT_SELECTABLE',
}

export type TimeSlot = {
  moment: string
  date_timestamp: string
  spaces: number
  modified?: {
    participants?: Participant[]
    duration?: number
    status?: string
    bookings?: Booking[]
  }
  participants?: Participant[]
  options: {
    fakeValue?: number
    state: TimeslotStatesEnum
    stickyState?: keyof typeof LTRStickyStyles
  }
}

export type InstructorPickValues =
  | 'availableTimeStart'
  | 'name'
  | 'openingTimes'
  | 'operatingHoursDiffer'
  | 'profileImage'
  | 'timeSlot'

// TODO: use unknown instead of any or idk just make it typesafe )
export type Instructor<T extends keyof Record<string, any> = InstructorPickValues> = Record<T, any> & {
  timeSlot: number
  uid: string
  name: string
  openingTimes: OpeningTimes
  availableTimeStart: number
  operatingHoursDiffer: boolean
  profileImage: {
    secure_url: string
  }
}

export type ServiceItem = {
  uid: string
  name: string
  icon: string // TODO: type icons
  price: number
  entity: 'service' | 'teacher'
  private: boolean
  minTime?: number
  maxTime?: number
  currency: CurrenciesEnum
  areasIds?: string[]
  areaSpace?: boolean
  maxPlayers: number
  minPlayers: number
  serviceType: string
  sportType?: string
  pricePeriod: number | 'NO_DURATION'
  cancelPolicy: CancelPolicyEnum
  teachersIds?: string[]
  longSlotView?: boolean
  selectedAreas: string[]
  accessForAnyone?: boolean
  selectedTeachers: string[]
  multiPerTimeSlot: boolean
  paymentOnArrival?: boolean
  multiplyPerPerson: boolean
  participantsNames?: boolean
  minTimeReservation?: number
  maxTimeReservation?: number
  paymentOnExsportia?: boolean
  howFarCanBookUnlimited?: boolean
  participantsNamesWhenFull?: boolean
  accessConfig?: {
    subscripions: string[]
  }
  permissionData: {
    validated: boolean
    reason?:
      'FOR_MEMBERS_ONLY' | 'FOR_NON_MEMBERS_ONLY' | 'FOR_SUBSCRIBERS_ONLY' | 'NOT_AVAILABLE' | 'ONLY_WITHOUT_SUBSCRIPION'
  }
  equipments: {
    name: string
    price: number
  }[]
} & (
  | {
      howFarCanBookUnlimited: false
      howFarCanBookAmount: number
    }
  | {
      howFarCanBookUnlimited: true
    }
)

export type Playspace = {
  uid: string
  name: string
  image: string
  operatingHoursDiffer: boolean
  openingTimes: OpeningTimes
  availableTimeStart: number
  private: boolean
}

export type PlayspacesList = Playspace[]

export type WeekAvailableDays = boolean[]

export type InstructorsList = Instructor[]

export type TimeSlotGrid = TimeSlot[]

export type ServicesList = ServiceItem[]
