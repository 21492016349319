import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {Area} from './types'

export type GetAreaParams = {
  id?: string | null
}
export type GetAreaQueryParams = undefined
export type GetAreaQueryReturnType = Area | null
export type GetAreaQuery = QueryMethod<GetAreaParams, GetAreaQueryParams, GetAreaQueryReturnType>

export const makeGetAreaEndpoint = ({id}: GetAreaParams) => `areas/${id}/mainInfo`

export const getAreaQuery: GetAreaQuery = ({query, params}) => ({
  queryKey: ['getAreaQuery', query],
  cacheTime: 1000 * 60,
  queryFn: ({queryKey: [_, config]}) => {
    if (params?.id) {
      return API.get<GetAreaQueryParams, GetAreaQueryReturnType>(makeGetAreaEndpoint(params), {
        params: config,
      }).then((res) => res.data)
    }
    return new Promise((res) => res(null))
  },
})
