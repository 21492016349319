import {DateTime} from 'luxon'

const isAndroid = (userAgent: string): boolean => userAgent.includes('Android')

const isIOS = (userAgent: string): boolean =>
  userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('iPod')

export const detectPlatform = (): 'android' | 'ios' | 'other' => {
  const userAgent: string = navigator.userAgent

  if (isAndroid(userAgent)) {
    return 'android'
  } else if (isIOS(userAgent)) {
    return 'ios'
  }

  return 'other'
}

export const currDateWithTimeZone = (timeZone: string) => {
  const currTimeZoneDate = DateTime.now().toUTC()
  const tzOffset = DateTime.now().setZone(timeZone).offset
  const currentDate = currTimeZoneDate
    .plus({minutes: tzOffset})
    .toMillis()
  return currentDate
}
