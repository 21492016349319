import {Box, Span} from 'exsportia-components'
import React, {FC, useCallback} from 'react'
import {useNavigate} from 'react-router-dom'

import {useAppLoading} from '../../../services/global/hooks'
import {Chevron} from '../../../ui/components/chevron/chevron'
import {GridLayoutHeader} from './grid-layout-header'

type Props = {
  title: string
  noBack?: boolean
  additionalOnBackAction?: () => void
  customBackAction?: () => void
}

export const SimpleGridLayoutHeader: FC<Props> = ({title, noBack = false, additionalOnBackAction, customBackAction}) => {
  const navigate = useNavigate()
  const {isLoading} = useAppLoading()
  const handleGoBack = useCallback(() => {
    if(isLoading) {
      return null
    }
    if (customBackAction) {
      return customBackAction()
    }
    if (additionalOnBackAction) {
      additionalOnBackAction()
    }
    navigate(-1)
  }, [navigate, isLoading, additionalOnBackAction, customBackAction])

  return (
    <GridLayoutHeader
      left={
        !noBack ? (
          <Box onClick={handleGoBack}>
            <Chevron
              direction={'left'}
              styleType={'simple'}
              disabled={isLoading}
            />
          </Box>
        ) : null
      }
      center={
        <Span
          fontSize={'h2'}
          fontWeight={'bold'}
        >
          {title}
        </Span>
      }
    />
  )
}
