import {showMessage} from 'exsportia-components'
import {Formik, FormikConfig} from 'formik'
import {FC, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {object} from 'yup'

import {useLocale} from '../../../utils/i18n'
import {useModalActions} from '../../../utils/modals'
import {EMAIL_VALIDATION, SIGN_IN_PASSWORD_VALIDATION} from '../../../utils/validation'
import {useAppLoading} from '../../global/hooks'
import {AuthService} from '../index'

export type SignInFormType = {
  email: string
  password: string
}

type CreateBookingFormProps = {
  email?: string
  venueId?: string
  setModalTitle?: (title: string) => void
}

export const SignInForm: FC<PropsWithChildren<CreateBookingFormProps>> = ({children, email = '', setModalTitle, venueId}) => {
  const dispatch = useDispatch()
  const {isLoading} = useAppLoading()
  const {closeAllModals} = useModalActions()
  const {l} = useLocale()
  const [isWrongPass, setIsWrongPass] = useState<boolean>(false)
  const initialValues = useMemo<SignInFormType>(() => ({email, password: ''}), []) //eslint-disable-line

  useEffect(() => {
    if (setModalTitle) {
      setModalTitle(l('signin'))
    }
  }, [l, setModalTitle])

  const callback = useCallback(() => {
    setIsWrongPass(false)
    showMessage(l('signInSuccess'), 'success')
    closeAllModals()
  }, [closeAllModals, l])

  const handleSubmit = useCallback<FormikConfig<SignInFormType>['onSubmit']>(
    (values) => {
      setIsWrongPass(true)
      dispatch(
        AuthService.actions.signInRequestAction({
          email: values.email,
          venueId,
          password: values.password,
          callback,
        }),
      )
    },
    [dispatch, callback, venueId],
  )

  const validationSchema = useMemo(
    () =>
      object().shape({
        email: EMAIL_VALIDATION,
        password: SIGN_IN_PASSWORD_VALIDATION,
      }),
    [l],
  )

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      initialErrors={!isLoading && isWrongPass ? {password: l('passwordVerify')} : {}}
    >
      <>
        {children}
      </>
    </Formik>
  )
}
