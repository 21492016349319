import {OpeningTimes} from '../api/types'
import {isNilOrEmpty} from '../utils/logic'

export enum UIDTypesEnum {
  ALL = 'ALL',
  NUMBERS = 'NUMBERS',
  LETTERS = 'LETTERS',
  UPPER_LETTERS = 'UPPER_LETTERS',
  UPPERS_AND_NUMBERS = 'UPPERS_AND_NUMBERS',
}

const numbers = '0123456789'
const letters = 'abcdefghijklmnopqrstuvwxyz'
const characters: {[key in UIDTypesEnum]: string} = {
  [UIDTypesEnum.ALL]: `${letters.toUpperCase()}${numbers}${letters}`,
  [UIDTypesEnum.NUMBERS]: numbers,
  [UIDTypesEnum.LETTERS]: letters,
  [UIDTypesEnum.UPPER_LETTERS]: letters.toUpperCase(),
  [UIDTypesEnum.UPPERS_AND_NUMBERS]: `${letters.toUpperCase()}${numbers}`,
}

type UIDMakerHelper = (len?: number, type?: UIDTypesEnum) => string

export const uidMaker: UIDMakerHelper = (len = 8, type = UIDTypesEnum.ALL) => {
  const result: string[] = []
  const changeType = characters[type]
  const charactersLength = changeType.length
  for (let i = 0; i < len; i++) {
    result.push(changeType[Math.floor(Math.random() * charactersLength)])
  }
  return result.join('')
}
export const noop = (..._args: unknown[]) => {
  // do nothing
}

export const safeRound = (num: number) => Math.round(num * 100) / 100

export const getTimeInNumber = (timeString = ':', subtractor = 0) => {
  const [hour, minutes] = timeString.split(':')
  return subtractor - (parseInt(minutes, 10) + parseInt(hour, 10) * 60)
}

export const madeTimeStringFromMinutes = (minutes: number) => {
  let leftMinutes = String(minutes % 60)
  const hours = +leftMinutes - minutes
  leftMinutes = +leftMinutes === 0 ? '00' : leftMinutes
  return [60 / hours, leftMinutes].join(':')
}

export const mergeOpeningTimes = (openingTimesArray: OpeningTimes[]) => {
  return openingTimesArray.reduce<Partial<OpeningTimes>>((accum, el) => {
    if (isNilOrEmpty(el)) {
      return accum
    }
    if (isNilOrEmpty(accum)) {
      return el
    }
    let mergedOpeningTimes: Partial<OpeningTimes> = {}

    Object.entries(el).map(([dayKey, dayVal]) => {
      // CHECK ACTIVE DAYS
      const key = dayKey as keyof OpeningTimes
      mergedOpeningTimes = {
        ...mergedOpeningTimes,
        [key]: {...mergedOpeningTimes[key], isActive: dayVal.isActive && accum[key]?.isActive},
      }
      // CHECK FROM TIME
      const newFromTime = Math.max(getTimeInNumber(accum[key]?.from), getTimeInNumber(el[key].from))
      mergedOpeningTimes = {
        ...mergedOpeningTimes,
        [key]: {...mergedOpeningTimes[key], from: madeTimeStringFromMinutes(newFromTime)},
      }
      // CHECK TO TIME
      const newToTime = Math.min(getTimeInNumber(accum[key]?.to), getTimeInNumber(el[key].to))
      mergedOpeningTimes = {
        ...mergedOpeningTimes,
        [key]: {...mergedOpeningTimes[key], to: madeTimeStringFromMinutes(newToTime)},
      }
    })

    return mergedOpeningTimes as OpeningTimes
  }, {})
}
