import {Box, Text} from 'exsportia-components'
import {IconType, TextType} from 'exsportia-components/dist/types'
import React, {FC, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'

import {VenueQueries} from '../../api/venue'
import {EmptyList} from '../../components/empty-list/empty-list'
import {InstructorCard} from '../../components/instructor-card/instructor-card'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {BookingService} from '../../services/booking'
import {NavigationList} from '../../ui/components/navigation-list/navigation-list'
import {ScrollableWrapper} from '../../ui/components/scrollable-wrapper/scrollable-wrapper'
import {useLocale} from '../../utils/i18n'
import {isNilOrEmpty} from '../../utils/logic'

export type ElementSettingsType = {
  name: TextType
  image: IconType
}

export type ElementsSettingsType = ElementSettingsType[] | []

const Header: FC<{entity: string}> = ({entity}) => {
  const {l} = useLocale()
  const {venueId} = useParams<{venueId: string}>()
  const {data} = useQuery(VenueQueries.getVenueQuery({params: {id: venueId}}))
  return (
    <SimpleGridLayoutHeader
      title={entity === 'teacher' ? data?.entityNames.teachers || l('instructors') : l('assignedInstructors')}
    />
  )
}

export const SelectInstructorOutOfFormPage: FC = () => {
  const {language} = useLocale()
  const navigate = useNavigate()
  const {venueId} = useParams<{venueId: string}>()
  if (!venueId) {
    return null
  }
  const {isLoading, data = []} = useQuery(
    BookingService.queries.getVenueInstructorsQuery({
      query: {
        venueId,
        // selectedDuration: 15,
      },
    }),
  )

  const elementsSettings: ElementsSettingsType = useMemo(
    () =>
      data
        .map((el) => ({
          onClick: () => {
            navigate(`../instructor/${el.uid}/select-service`, {state: {teacher: el}})
          },
          name: (
            <Text
              text={el.name}
              settings={{
                fontSize: 'h3',
                croppedLines: 1,
                fontWeight: 'bold',
                lineHeight: 'card-title-line-height',
              }}
            />
          ),
          image: (
            <Box
              width='48px'
              height='48px'
              borderRadius='50%'
              backgroundSize='cover'
              background={`url(${el.profileImage.secure_url}) no-repeat center center`}
            />
          ),
        })),
    [data, language], // eslint-disable-line
  )

  if (isNilOrEmpty(data) && !isLoading) {
    return (
      <GridLayout header={<Header entity={'teacher'} />}>
        <EmptyList />
      </GridLayout>
    )
  }

  return (
    <GridLayout
      withoutPadding
      header={<Header entity={'teacher'} />}
    >
      <ScrollableWrapper>
        <Box pb='8px'>
          <NavigationList
            isLoading={isLoading}
            Component={InstructorCard}
            elementsSettings={elementsSettings}
          />
        </Box>
      </ScrollableWrapper>
    </GridLayout>
  )
}
