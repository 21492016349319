import {Flex, Image} from 'exsportia-components'
import {MenuItemComplexProps} from 'exsportia-components/dist/components/organisms/menu-item'
import {FC, useCallback} from 'react'
import {useQuery} from 'react-query'

import {langNames} from '../../constants/common'
import {LanguagesEnum, TranslatorScope} from '../../locale/types'
import {DownloadAppModal} from '../../modals/download-app-modal/download-app-modal'
import {useAuthState} from '../../services/auth/hooks'
import {useAppLoading} from '../../services/global/hooks'
import {UserService} from '../../services/user'
import {DropdownMenu} from '../../ui/components/dropdown-menu/dropdown-menu'
import {auth} from '../../utils/firebase'
import {LocaleOptions, setLocale, useLocale} from '../../utils/i18n'
import {useModalActions} from '../../utils/modals'
import {NO_USER_PHOTO} from '../../utils/remote-content'

export const profileMenuOptions: (props: {
  l: (scope: TranslatorScope, options?: LocaleOptions) => string
  signOut: () => void
  isLoading: boolean
  openProfile: () => void,
  language: LanguagesEnum
}) => MenuItemComplexProps[] = ({l, signOut, isLoading, openProfile, language}) => [
  {
    name: 'profile',
    text: l('profile'),
    disabled: isLoading,
    onClick: openProfile,
  },
  {
    name: 'language',
    popperPosition: 'bottom',
    popperModifiers: ['offsetLeft'],
    text: l('language', {language: l(language as TranslatorScope), caseAction: 'capitalize'}),
    options: [
      {
        name: 'en',
        text: langNames.en,
        onClick: () => setLocale(LanguagesEnum.EN),
      },
      {
        name: 'heb',
        text: langNames.heb,
        onClick: () => setLocale(LanguagesEnum.HEB),
      },
      {
        name: 'ua',
        text: langNames.ua,
        onClick: () => setLocale(LanguagesEnum.UA),
      },
    ]
  },
  {
    name: 'signOut',
    onClick: signOut,
    text: l('logOut'),
    disabled: isLoading,
  },
]

export const ProfileMenu: FC = () => {
  const {l, language} = useLocale()
  const {isLoading} = useAppLoading()
  const {signOut} = useAuthState()
  const {openModal} = useModalActions()
  const {data} = useQuery(UserService.queries.GetUserQuery({cacheTime: 0, params: {id: auth.currentUser?.uid}}))
  const handleOpenProfile = useCallback(() => {
    openModal({
      component: <DownloadAppModal/>,
      title: l('profile', {caseAction: 'capitalize'}),
      footerConfig: {
        disabled: true
      }
    })
  }, [l, openModal])
  return (
    <DropdownMenu
      name='profile-menu'
      height='max-content'
      options={profileMenuOptions({l, signOut, isLoading, openProfile: handleOpenProfile, language})}
    >
      <Flex
        py='4px'
        cursor={'pointer'}
        justifyContent={'center'}
      >
        <Image
          width={'48px'}
          height={'48px'}
          borderRadius={'24px'}
          src={data?.avatar || NO_USER_PHOTO}
          title={data?.name || `${data?.firstName} ${data?.lastName}` || l('profile')}
        />
      </Flex>
    </DropdownMenu>
  )
}
