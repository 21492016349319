import {Box, Divider, Flex, Text} from 'exsportia-components'
import {IconWithTextProps} from 'exsportia-components/dist/components/molecules/icon-with-text'
import {IconType, TextType} from 'exsportia-components/dist/types'
import React, {FC, useCallback, useMemo} from 'react'

import {SessionSelectSession} from '../../api/class-session/types'
import {noop} from '../../helpers/logic'
import {TranslatorScope} from '../../locale/types'
import {CurrenciesEnum} from '../../pages/select-service/select-service-page'
import {GradientLoader} from '../../ui/components/gradient-loader/gradient-loader'
import {useLocale} from '../../utils/i18n'
import {useModalActions} from '../../utils/modals'
import {NO_USER_PHOTO} from '../../utils/remote-content'
import {ImagesStack} from '../images-stack/images-stack'
import {ParticipantsList} from '../timeslot-grid/components/participants-list'
import {InstructorSection} from './components/session-card_instructor-section'
import {defaultSettings} from './settings'

export type SessionCardProps = {
  disabled?: boolean
  price?: number
  time?: TextType
  duration?: TextType
  className?: TextType
  isLoading?: boolean
  onClick?: () => void
  availableSpots?: number
  instructorName?: TextType
  currency?: CurrenciesEnum
  instructorImage?: IconType
  disabledText?: TranslatorScope
  participants?: SessionSelectSession['participants'] | null
  isSessionAlreadyBooked?: boolean
}

const iconWithTextAdditionalStyles: IconWithTextProps['additionalStyles'] = ['mr12Icon']

export const SessionCard: FC<SessionCardProps> = ({
  time = '',
  price = 0,
  onClick,
  disabled = true,
  duration = 0,
  currency,
  className = '',
  isLoading = false,
  disabledText,
  participants,
  availableSpots = 0,
  instructorName = '',
  instructorImage = '',
  isSessionAlreadyBooked,
}) => {
  const {l} = useLocale()
  const {openModal} = useModalActions()
  const classTitleMarkup = useMemo(
    () =>
      isLoading ? (
        <GradientLoader height={defaultSettings.loaderHeights.classTitle} />
      ) : (
        <Text
          text={className}
          settings={defaultSettings.title}
        />
      ),
    [className, isLoading],
  )

  const classSubTextMarkup = useMemo(
    () =>
      isLoading ? (
        <GradientLoader height={defaultSettings.loaderHeights.classSubText} />
      ) : (
        <Flex>
          <Text
            text={time}
            settings={defaultSettings.subText}
          />
          <Divider
            styleType='dot'
            settings={defaultSettings.dot}
          />
          <Text
            text={duration}
            settings={defaultSettings.subText}
          />
          {isSessionAlreadyBooked && (
            <>
              <Divider
                styleType='dot'
                settings={defaultSettings.dot}
              />
              <Text
                text={l('is_already_booked', {caseAction: 'capitalize'})}
                settings={{
                  ...defaultSettings.subText,
                  color: 'notify'
                }}
              />
            </>
          )}
        </Flex>
      ),
    [time, duration, isLoading, isSessionAlreadyBooked],
  )

  const priceMarkup = useMemo(
    () =>
      isLoading ? (
        <Flex
          ml={defaultSettings.loaderHeights.priceMl}
          maxWidth={defaultSettings.loaderHeights.priceMaxWidth}
        >
          <GradientLoader height={defaultSettings.loaderHeights.price} />
        </Flex>
      ) : (
        <Text
          text={l(`${currency}_symbolic` as TranslatorScope, {value: price})}
          settings={defaultSettings.price}
        />
      ),
    [currency, price, isLoading],
  )

  const openParticipantsModalHandler = useCallback(
    (slotParticipants: SessionSelectSession['participants']) => {
      openModal({
        title: l('participants'),
        footerConfig: {
          disabled: true,
        },
        component: <ParticipantsList participants={slotParticipants} />,
      })
    },
    [l, openModal],
  )

  const participantsSortedByAvatar = useMemo(
    () => [...(participants || [])].sort((a) => (a.avatar === NO_USER_PHOTO ? 1 : -1)),
    [participants],
  )

  const onParticipantsClick = useCallback(
    () => openParticipantsModalHandler(participantsSortedByAvatar),
    [participantsSortedByAvatar],
  )

  const participantsAvatars = useMemo(
    () => participantsSortedByAvatar.map((participant) => participant.avatar || NO_USER_PHOTO),
    [participantsSortedByAvatar],
  )

  return (
    <Box
      cursor={!disabled ? 'pointer' : 'unset'}
      onClick={!disabled ? onClick : noop}
      p={defaultSettings.p}
      width={defaultSettings.width}
      border={defaultSettings.border}
      borderRadius={defaultSettings.borderRadius}
    >
      <Flex
        justifyContent={defaultSettings.classSection.justifyContent}
        mb={defaultSettings.classSection.mb}
      >
        <Box>
          {classTitleMarkup}
          {classSubTextMarkup}
        </Box>
        {priceMarkup}
      </Flex>
      <Flex
        alignItems={'center'}
        justifyContent={!(instructorName && instructorImage) ? 'space-between' : 'unset'}
        height={'32px'}
      >
        <InstructorSection
          {...{
            instructorName,
            instructorImage,
            availableSpots,
            iconWithTextAdditionalStyles,
            disabledText,
          }}
        />
        <Flex
          width='100%'
          justifyContent='flex-end'
        >
          <ImagesStack
            images={participantsAvatars}
            onStackClick={onParticipantsClick}
            imageSize={32}
            maxImagesToStack={3}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
