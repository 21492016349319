import {Label, Span, TextareaField as RawTextArea} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {ChangeEvent, FC, PropsWithChildren, useCallback} from 'react'

type Props = {
  id: string
  disabled?: boolean
  placeholder?: string
  label?: string
  required?: boolean
}

export const TextArea: FC<PropsWithChildren<Props>> = ({
  disabled = false,
  id,
  placeholder,
  required = false,
  label,
}) => {
  const {values, errors, setFieldValue} = useFormikContext<any>()
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(id, e.target.value)
    },
    [id, setFieldValue],
  )
  return (
    <>
      {label && (
        <Label settings={{color: Number(errors[id]?.length) > 0 ? 'error' : 'text-secondary'}}>
          {label}
          {required && <Span color={'error'}>*</Span>}
        </Label>
      )}
      <RawTextArea
        // @ts-ignore
        onChange={handleChange}
        height={'50px'}
        name={id}
        id={id}
        disabled={disabled}
        value={values[id]}
        placeholder={placeholder}
        error={errors[id]}
      />
    </>
  )
}
