import {Box, Flex, Text, Title} from 'exsportia-components'
import {TextType} from 'exsportia-components/dist/types'
import React, {FC, useMemo} from 'react'

import {TranslatorScope} from '../../locale/types'
import {GradientLoader} from '../../ui/components/gradient-loader/gradient-loader'
import {useLocale} from '../../utils/i18n'
import {defaultSettings} from './settings'

export type PricingElementType = {
  count: number
  price: number
  text: TextType
}

export type PricingDetailsProps = {
  isLoading: boolean
  currency: TextType
  values: PricingElementType[]
  discount: number
}

const boldTextSettings = {fontWeight: 'bold'}

export const PricingDetails: FC<PricingDetailsProps> = ({values, currency, isLoading, discount}) => {
  const {l} = useLocale()
  const xSettings = useMemo(
    () => ({m: defaultSettings.elementSettings.xMargin, color: defaultSettings.elementSettings.xColor}),
    [],
  )

  const valuesMarkup = useMemo(
    () => (
      <>
        {isLoading ? (
          <GradientLoader />
        ) : (
          values.map((element, index) => (
            <Flex
              key={`pricing-details-${index}`}
              justifyContent={defaultSettings.elementSettings.justifyContent}
              mt={index === 0 ? 0 : defaultSettings.elementSettings.marginTop}
            >
              <Flex
                mr={defaultSettings.elementSettings.priceMr}
                maxWidth={defaultSettings.elementSettings.textMaxWidth}
              >
                <Flex>
                  <Text
                    settings={boldTextSettings}
                    text={element.count}
                  />
                </Flex>
                <Flex>
                  <Text
                    text='×'
                    settings={xSettings}
                  />
                </Flex>
                <Text text={element.text} />
              </Flex>
              <Text
                settings={defaultSettings.elementSettings.priceSettings}
                text={l(`${currency}_symbolic` as TranslatorScope, {value: Number(element.price)})}
              />
            </Flex>
          ))
        )}
        {discount !== 0 && <Flex
          mt={defaultSettings.elementSettings.marginTop}
          justifyContent={defaultSettings.summarySettings.justifyContent}
        >
          <Title
            styleType={defaultSettings.summarySettings.titleStyleType}
            text={l('discount')}
          />
          {isLoading ? (
            <Flex width='40%'>
              <GradientLoader />
            </Flex>
          ) : (
            <Title
              styleType={defaultSettings.summarySettings.titleStyleType}
              text={`-${l(`${currency}_symbolic` as TranslatorScope, {
                value: discount,
              })}`}
            />
          )}
        </Flex>}
        <Flex
          mt={defaultSettings.summarySettings.mt}
          justifyContent={defaultSettings.summarySettings.justifyContent}
        >
          <Title
            styleType={defaultSettings.summarySettings.titleStyleType}
            text={l('total')}
          />
          {isLoading ? (
            <Flex width='40%'>
              <GradientLoader />
            </Flex>
          ) : (
            <Title
              styleType={defaultSettings.summarySettings.titleStyleType}
              text={l(`${currency}_symbolic` as TranslatorScope, {
                value: values.reduce((acc, {price}) => Number(price) + acc, 0) - discount,
              })}
            />
          )}
        </Flex>
      </>
    ),
    [currency, l, values, xSettings, isLoading],
  )

  return (
    <Box>
      <Flex mb={defaultSettings.titleSettings.mb}>
        <Title
          styleType={defaultSettings.titleSettings.styleType}
          text={l('labels_pricingAndPayments')}
        />
      </Flex>
      {valuesMarkup}
    </Box>
  )
}
