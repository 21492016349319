import {Box, Field, Flex, Text} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {FC, useMemo} from 'react'

import {SignUpFormType} from '../../../services/auth/forms/sign-up-form'
import {useAppLoading} from '../../../services/global/hooks'
import {Button} from '../../../ui/components/button/button'
import {useLocale} from '../../../utils/i18n'

type AvatarSignUpStepProps = {
  handleGoToNextStep: () => void
  backAction?: (e: Event) => void
}

export const AvatarSignUpStep: FC<AvatarSignUpStepProps> = ({handleGoToNextStep, backAction}) => {
  const {l} = useLocale()
  const {isLoading} = useAppLoading()
  const {validateForm, setFieldValue, values} = useFormikContext<SignUpFormType>()
  const handleNext = () => {
    (async () => {
      const validateResult = await validateForm()
      if (!validateResult.phone) {
        handleGoToNextStep()
      }
    })()
  }
  const setAvatar = (value: string[]) => {
    (async () => await setFieldValue('avatar', value[0]))()
  }
  const selectedAvatar = useMemo(() => values.avatar || null, [values])

  return (
    <Box pb={'24px'}>
      <Flex mb='16px'>
        <Text text={l('chooseProfileImage', {caseAction: 'capitalize'})} />
      </Flex>
      <Field
        fieldType='media'
        setFieldValue={(_, value) => setAvatar(value)}
        input={{
          id: 'avatar',
          name: 'avatar',
          // @ts-ignore
          compressedMaxSize: 0.5,
          compressedMaxWidthOrHeight: 800,
          settings: {
            cropSize: {width: 300, height: 300},
          }
        }}
      />
      {selectedAvatar && (
        <Flex
          mt='16px'
          overflow='hidden'
          borderRadius='16px'
        >
          <img
            src={selectedAvatar}
            style={{width: '100%'}}
          />
        </Flex>
      )}
      <Flex
        gap='16px'
        mt={'16px'}
      >
        {backAction && (
          <Button
            disabled={isLoading}
            type='secondary'
            onClick={backAction}
          >
            {l('back')}
          </Button>
        )}
        <Button onClick={handleNext}>{l('next')}</Button>
      </Flex>
    </Box>
  )
}
