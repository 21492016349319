import {Flex} from 'exsportia-components'
import React, {useEffect, useState} from 'react'
import ReactSwitch from 'react-switch'

// settings
import {defaultSettings, typeStylesMap, withTextSettings} from './settings'
// /////////////////////////////////////////////////////////////////////////////////////////////////

export type ToggleProps = {
  checked?: boolean
  disabled?: boolean
  onChange?: (value: boolean) => void
  disabledClick?: boolean
  onText?: boolean | string
  offText?: boolean | string
  styleType?: 'default' | 'small' | 'withText'
}

export const Toggle = ({
  onText = false,
  offText = false,
  checked = false,
  disabled = false,
  disabledClick = false,
  styleType = 'default',
  onChange = () => null,
}: ToggleProps) => {

  const generateLabel = (label: boolean | string, status: 'off' | 'on') => (
    <Flex {...(withTextSettings.label[status])}>
      {label}
    </Flex>
  )

  const typeStyles = typeStylesMap[styleType]
  const settingsToUse = {
    ...defaultSettings,
    ...typeStyles,
  }
  const [isChecked, setIsChecked] = useState(checked)
  const onChangeHandler = () => {
    if (disabledClick) {
      return
    }
    const newValue = !isChecked
    setIsChecked(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  return (
    <Flex>
      <ReactSwitch
        disabled={disabled}
        checked={isChecked}
        onChange={onChangeHandler}
        width={settingsToUse.width}
        height={settingsToUse.height}
        onColor={settingsToUse.onBgColor}
        offColor={settingsToUse.offBgColor}
        checkedIcon={generateLabel(onText, 'on')}
        uncheckedIcon={generateLabel(offText, 'off')}
        handleDiameter={settingsToUse.handleDiameter}/>
    </Flex>
  )
}
