import axios, {AxiosHeaderValue, AxiosRequestConfig, HeadersDefaults} from 'axios'
import {DateTime} from 'luxon'
import {CANCEL} from 'redux-saga'

import {auth} from './firebase'
import {getAuthToken, setAuthToken} from './local-storage'

export const axiosInstance = axios.create()

type MakeRequestHeadersHelper = (_headers?: AxiosRequestConfig['headers']) => Promise<AxiosRequestConfig['headers']>
export const makeRequestHeaders: MakeRequestHeadersHelper = async (headers = {}) => {
  const tokenData = getAuthToken()
  let token = ''
  if (tokenData && DateTime.now().plus({minute: 2}) < DateTime.fromMillis(Number(tokenData.expirationTime))) {
    token = tokenData.token
  } else {
    const updatedTokenData = await auth.currentUser?.getIdTokenResult(true)
    if (updatedTokenData) {
      setAuthToken(updatedTokenData)
      token = updatedTokenData.token
    }
  }
  const sessionHeaders: AxiosRequestConfig['headers'] = {}
  if (token) {
    sessionHeaders.Authorization = `Bearer ${token}`
  }
  return {...sessionHeaders, ...headers}
}

export const sendRequest = async <T>(
  method: 'delete' | 'get' | 'patch' | 'post' | 'put',
  endpoint: string,
  options = {} as AxiosRequestConfig,
) => {
  const source = axios.CancelToken.source()
  const headers = await makeRequestHeaders(options.headers)
  const token = headers?.Authorization || ''
  // Added remove headers.Authorization because it is not deletes properly and makes request with old token
  if (token) {
    axiosInstance.defaults.headers = headers as HeadersDefaults & {[key: string]: AxiosHeaderValue}
    // eslint-disable-next-line
  } else if (axiosInstance.defaults.headers.common?.Authorization) {
    delete axiosInstance.defaults.headers.common.Authorization
  } else if (axiosInstance.defaults.headers.Authorization) {
    delete axiosInstance.defaults.headers.Authorization
  }
  const request = axiosInstance[method]<T>(endpoint, {...options, cancelToken: source.token})
  // @ts-ignore
  request[CANCEL] = () => source.cancel()
  return request
}
