import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {PlayspacesList} from './types'

export type GetPlayspacesParams = undefined
export type GetPlayspacesQueryParams = {
  serviceId: string
  isInstructor: boolean
}
export type GetPlayspacesQueryReturnType = PlayspacesList
export type GetPlayspacesQuery = QueryMethod<
  GetPlayspacesParams,
  GetPlayspacesQueryParams,
  GetPlayspacesQueryReturnType
>

export const makeGetPlayspacesEndpoint = () => 'areas/venue/getAreasForEntity/'

export const getPlayspacesQuery: GetPlayspacesQuery = ({query}) => ({
  queryKey: ['getPlayspacesQuery', query],
  cacheTime: 1000 * 60,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<GetPlayspacesQueryParams, GetPlayspacesQueryReturnType>(makeGetPlayspacesEndpoint(), {
      params: config,
    }).then((res) => res.data),
})
