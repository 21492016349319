import {API} from '../../networking/http'
import {MutationMethod} from '../helpers/types'
import {User} from '../user/types'

export type SignUpParams = undefined
export type SignUpMutationParams = Pick<
  User,
  'avatar' | 'dob' | 'dobStatus' | 'email' | 'firstName' | 'firstNameStatus' | 'genderStatus' | 'lastName' | 'lastNameStatus' | 'name' | 'phone' | 'role' | 'withPassword'
> & {password: string}
export type SignUpMutationReturnType = User
export type SignUpMutation = MutationMethod<SignUpParams, SignUpMutationParams, SignUpMutationReturnType>

export const makeSignUpEndpoint = () => 'user'

export const signUpMutation: SignUpMutation = () => ({
  mutationFn: (user) =>
    API.post<SignUpMutationParams, SignUpMutationReturnType>(makeSignUpEndpoint(), user).then((res) => res.data),
})
