import {Box, Divider, Flex, Label, Text, Title} from 'exsportia-components'
import {TextSettingsProps} from 'exsportia-components/dist/components/atoms/text'
import {FC, useCallback, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {BookingQueries} from '../../api/booking'
import {Booking} from '../../api/booking/types'
import {noop} from '../../helpers/logic'
import {useServiceOptions} from '../../services/service-options/hooks'
import {EntityCard} from '../../ui/components/entity-card/entity-card'
import {ExsportiaIcon} from '../../ui/components/exsportia-icon/exsportia-icon'
import {useLocale, useLuxon} from '../../utils/i18n'
import {isNotNilAndNotEmpty} from '../../utils/logic'

const payStatusChipBGColors = {
  paid: 'success',
  unpaid: 'notify',
  cancelled: 'error',
}

const chipSettings: TextSettingsProps = {color: 'text-contrast', fontSize: '13px'}

export const BookingCardForList: FC<{booking: Booking; isWaiting?: boolean}> = ({booking, isWaiting = false}) => {
  const {l} = useLocale()
  const navigate = useNavigate()
  const luxon = useLuxon()
  const {remove} = useQuery(BookingQueries.getBookingQuery({}))
  const onClick = useCallback(() => {
    remove()
    navigate(`${booking.uid}`)
  }, [booking])
  const serviceOrClass = booking.class || booking.sport || booking.classSlot
  const {getServiceConfigByServiceType} = useServiceOptions([serviceOrClass?.sportType])
  const serviceType = useMemo(
    () => getServiceConfigByServiceType(serviceOrClass?.sportType),
    [serviceOrClass, getServiceConfigByServiceType],
  )
  const payStatus = booking.isCancelled ? 'cancelled' : booking.isPaid ? 'paid' : 'unpaid'
  const withSubscription = !isWaiting && booking.participants.some((el) => isNotNilAndNotEmpty(el.membershipId))
  const bookingType = isNotNilAndNotEmpty(booking.class)
    ? 'class'
    : booking.sport?.areaSpace
    ? 'justTheSpace'
    : 'service'

  console.log(booking)

  const bgUrl = useMemo(
    () =>
      booking.area?.media?.[booking.area.thumbIndex].secure_url ||
      booking.area?.profileImage?.secure_url ||
      booking.classSlot?.media?.[booking.classSlot.thumbIndex || 0].secure_url ||
      booking.sport?.media?.[booking.sport.thumbIndex || 0]?.secure_url,
    [booking],
  )

  const titleName = useMemo(() => booking.area?.name || booking.classSlot?.areaName || booking.sport?.name, [booking])
  return (
    <Box
      cursor={isWaiting ? 'default' : 'pointer'}
      onClick={isWaiting ? noop : onClick}
      pb='12px'
      borderRadius='16px'
      overflow='hidden'
      border='1px solid rgba(0, 16, 61, 0.12)'
    >
      <Flex
        p='12px'
        width='100%'
        height='216px'
        mb='12px'
        backgroundSize='cover'
        position='relative'
        background={`url(${bgUrl}) no-repeat center`}
      >
        <Flex
          height='108px'
          position='absolute'
          bottom='0'
          left='0'
          width='100%'
          background='linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%)'
        />
        {withSubscription && (
          <Flex
            p='3px 8px 3px 4px'
            bg={'primary'}
            height='max-content'
            borderRadius='8px'
            mr='8px'
          >
            <Flex
              mr='4px'
              height='18px'
              alignItems='center'
            >
              <ExsportiaIcon
                icon='favoriteS'
                color='bg'
                w='16px'
                h='16px'
              />
            </Flex>
            <Text
              text={l('with_subscription', {caseAction: 'capitalize'})}
              settings={chipSettings}
            />
          </Flex>
        )}
        {!isWaiting && (
          <Box
            p='3px 8px'
            bg={payStatusChipBGColors[payStatus]}
            height='max-content'
            borderRadius='8px'
          >
            <Text
              text={l(payStatus)}
              settings={chipSettings}
            />
          </Box>
        )}
        <Box
          position='absolute'
          bottom='0'
          left='0'
          m='12px'
        >
          <Title
            text={titleName}
            settings={{color: 'text-contrast'}}
            styleType='h2'
          />
          <Title
            styleType='h4'
            settings={{color: 'text-contrast'}}
            text={booking.venue.name}
          />
        </Box>
      </Flex>
      <Flex px='12px'>
        <EntityCard
          text={serviceOrClass?.name}
          icon={serviceType.icon}
          styleType={bookingType}
          subText={
            <Flex alignItems='center'>
              <Label>{luxon.fromMillis(Number(booking.timestamp)).toUTC().toFormat('DD, HH:mm')}</Label>
              {!isWaiting && (
                <>
                  <Divider styleType='dot' />
                  <Label>{l('minutes_short', {value: booking.duration})}</Label>
                </>
              )}
            </Flex>
          }
        />
      </Flex>
    </Box>
  )
}
