import {Flex, ListItem} from 'exsportia-components'
import {IconType, TextType} from 'exsportia-components/dist/types'
import React, {FC, useMemo} from 'react'

import {noop} from '../../../helpers/logic'
import {GradientLoader} from '../gradient-loader/gradient-loader'
import {defaultSettings, typeStylesMap} from './settings'

export type EntityCardProps = {
  text?: TextType
  icon?: IconType
  subText?: TextType
  isLoading?: boolean
  roundedIcon?: boolean
  onClick?: (e: Event) => void
  styleType?: keyof typeof typeStylesMap
  disabled?: boolean
}

export const EntityCard: FC<EntityCardProps> = ({
  text,
  icon,
  subText,
  onClick,
  roundedIcon,
  isLoading = false,
  styleType = 'service',
  disabled = false
}) => {
  const listItemMarkup = useMemo(
    () => (
      <ListItem
        icon={isLoading ? <GradientLoader height={defaultSettings.iconLoaderHeight} /> : icon}
        text={isLoading ? <GradientLoader height={defaultSettings.textLoaderHeight} /> : text}
        subText={isLoading ? <GradientLoader height={defaultSettings.subTextLoaderHeight} /> : subText}
        settings={{
          iconWithText: {
            settings: typeStylesMap[styleType].iconWithText,
            additionalStyles: roundedIcon ? [] : ['br12Icon'],
          },
        }}
      />
    ),
    [styleType, roundedIcon, icon, text, subText, isLoading],
  )

  return (
    <Flex
      onClick={disabled ? noop : onClick}
      p={defaultSettings.p}
      width={defaultSettings.width}
      height={defaultSettings.height}
      display={defaultSettings.display}
      alignItems={defaultSettings.alignItems}
      maxWidth='calc(100% - 40px)'
      cursor={(typeof onClick === 'function' && !disabled) ? 'pointer' : 'default'}
    >
      {listItemMarkup}
    </Flex>
  )
}
