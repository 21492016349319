import {Box, Flex} from 'exsportia-components'
import {FC, useCallback} from 'react'

import {useAppLoading} from '../../services/global/hooks'
import {Button} from '../../ui/components/button/button'
import {useLocale} from '../../utils/i18n'

type SignInSignUpButtonsType = {
  handleSignIn: () => void
  handleSignUp: () => void
  display?: string[] | string
}

export const SignInSignUpButtons: FC<SignInSignUpButtonsType> = ({handleSignIn, handleSignUp, display}) => {
  const {l} = useLocale()
  const {isLoading} = useAppLoading()
  const handleSignUpToUse = useCallback(() => (isLoading ? null : handleSignUp()), [handleSignUp, isLoading])
  const handleSignInToUse = useCallback(() => (isLoading ? null : handleSignIn()), [handleSignIn, isLoading])
  return (
    <Flex
      width='100%'
      display={display || 'flex'}
    >
      <Flex
        width='100%'
        minWidth='240px'
        flexDirection={'row'}
        display='flex'
      >
        <Button
          disabled={isLoading}
          onClick={handleSignUpToUse}
        >
          {l('sign_up_free', {caseAction: 'capitalize'})}
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleSignInToUse}
          type={'flat'}
        >
          {l('signin', {caseAction: 'capitalize'})}
        </Button>
      </Flex>
      <Box display={['block', 'none']}></Box>
    </Flex>
  )
}
