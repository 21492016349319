import {Box, Flex, Span, Text} from 'exsportia-components'
import {useFormikContext} from 'formik'
import React, {FC, PropsWithChildren, useCallback, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'

// import {BookingQueries} from '../../api/booking'
import {ServiceItem} from '../../api/booking/types'
import {Class} from '../../api/class/types'
import {IntegratedSignUpFormType} from '../../services/auth/forms/integrated-sign-up-form'
import {useAuthState} from '../../services/auth/hooks'
import {CreateClassBookingFormType} from '../../services/booking/forms/create-class-booking-form'
import {CreateServiceBookingFormType} from '../../services/booking/forms/create-service-booking-form'
import {useBookingLoader} from '../../services/booking/hooks'
import {useSrollMainFrame} from '../../services/global/hooks'
import {UserService} from '../../services/user'
import {VenueService} from '../../services/venue'
import {Button} from '../../ui/components/button/button'
import {DividerWithContent} from '../../ui/components/divider-with-content/divider-with-content'
import {CheckBox} from '../../ui/components/input/checkbox'
import {Input} from '../../ui/components/input/input'
import {auth} from '../../utils/firebase'
import {useLocale} from '../../utils/i18n'
import {isNotNilAndNotEmpty} from '../../utils/logic'
import {useModalActions} from '../../utils/modals'

export type BookingCommonType = {
  class?: Class | null
  service?: ServiceItem | null
} & (
  | CreateClassBookingFormType
  | CreateServiceBookingFormType
)

type UseInfoSection = {
  isLoading: boolean
  withPaymentStep?: boolean
  bookingState: BookingCommonType
  setBookingStateValues: (values: BookingCommonType) => void
}

const SpacedForm: FC<PropsWithChildren> = ({children}) => {
  return (
    <Flex
      flexDirection={'column'}
      gap='8px'
    >
      {children && Array.isArray(children) && children.map((child, index) => <Box key={`child-${index}`}>{child}</Box>)}
    </Flex>
  )
}

export const UserInfoSection: FC<UseInfoSection> = ({isLoading, bookingState, withPaymentStep = true}) => {
  const {l} = useLocale()
  const {setMainFrameIsScrollable} = useSrollMainFrame()
  const {isLoggedIn, signIn} = useAuthState()
  const {isLoading: isBookingLoading} = useBookingLoader()
  const params = useParams<{venueId: string}>()
  const {data: venueData} = useQuery(VenueService.queries.getVenueQuery({params: {id: params.venueId}}))
  const {openModal} = useModalActions()
  const {data: user} = useQuery(UserService.queries.GetUserQuery({cacheTime: 0, params: {id: auth.currentUser?.uid}}))
  const handleSignIn = useCallback(() => signIn(), [signIn])
  const {handleSubmit, setValues, values} = useFormikContext<IntegratedSignUpFormType>()
  const {service, class: cls} = bookingState
  const {paymentOnArrival = true, paymentOnExsportia = false} = service || cls || {}
  useEffect(() => {
    setMainFrameIsScrollable(false)
    return () => setMainFrameIsScrollable(true)
  }, [])
  useEffect(() => {
    setValues({
      ...values,
      userId: user?.uid || '',
      name: user?.name || '',
      email: user?.email || '',
      phone: user?.phone || '',
    })
  }, [user]) // eslint-disable-line
  const requireRegistration = !paymentOnArrival && paymentOnExsportia

  const handleOpenTermsOrPivacy = useCallback(
    (type: 'privacyPolicy' | 'termsAndConditions') => {
      const text = venueData?.[type]
      const titlesMap = {
        termsAndConditions: l('termsConditions'),
        privacyPolicy: l('privacyPolicy'),
      }
      return openModal({
        component: (
          <Flex
            maxHeight='50dvh'
            overflow='auto'
            mb='24px'
          >
            {text}
          </Flex>
        ),
        title: titlesMap[type],
        footerConfig: {
          disabled: true,
        },
      })
    },
    [venueData],
  )

  const buttonText = useMemo(() => {
    let text = l('book')
    if (values.isRequestedToCreateAccount && !isLoggedIn) {
      text = l('create')
    } else if (withPaymentStep) {
      text = l('next')
    }
    return text
  }, [values, isLoggedIn, requireRegistration, l, withPaymentStep])
  const submitButtonMarkup = useMemo(() => {
    const isLoadingVal = isLoading || isBookingLoading
    return (
      <Box
        mt={'8px'}
        opacity={isLoadingVal ? '80%' : '100%'}
      >
        <Button
          disabled={isLoadingVal}
          isLoading={isLoadingVal}
          // @ts-ignore
          onClick={handleSubmit}
        >
          {buttonText}
        </Button>
      </Box>
    )
  }, [buttonText, handleSubmit, isLoading, l, withPaymentStep, isBookingLoading])

  return (
    <Box>
      {!isLoggedIn && (
        <Box>
          <Box pb={'8px'}>
            <Button
              type={'secondary'}
              onClick={handleSignIn}
            >
              {l('signin')}
            </Button>
          </Box>
          <DividerWithContent content={<Span lineHeight={1}>{l('or')}</Span>} />
        </Box>
      )}
      <SpacedForm>
        <Input
          required
          id={'phone'}
          label={l('phoneNumber')}
          disabled={isLoggedIn && isNotNilAndNotEmpty(user?.phone) && Boolean(values.phone)}
        />
        <Input
          required
          id={'email'}
          label={l('email')}
          disabled={isLoggedIn && isNotNilAndNotEmpty(user?.email) && Boolean(values.email)}
        />
        <Input
          required
          id={'name'}
          label={l('fullName')}
          disabled={isLoggedIn && isNotNilAndNotEmpty(user?.name)}
        />
        {/* @ts-ignore*/}
        {!isLoggedIn && (
          <Box py={'8px'}>
            <CheckBox
              disabled={requireRegistration}
              id={'isRequestedToCreateAccount'}
              label={l('auto_create_account_checkbox')}
            />
          </Box>
        )}
        <Flex mb='12px'>
          <CheckBox id='termsAndConditionsConfirm'>
            <Flex>
              <Text>
                {l('i_agree', {caseAction: 'capitalize'})}
                {' '}
                <Flex
                  display='inline-flex'
                  onClick={() => handleOpenTermsOrPivacy('termsAndConditions')}
                  cursor='pointer'
                >
                  <Text settings={{textDecoration: 'underline'}}>{l('termsConditions')}</Text>
                </Flex>
                {l('and')}
                <Flex
                  cursor='pointer'
                  display='inline-flex'
                  onClick={() => handleOpenTermsOrPivacy('privacyPolicy')}
                >
                  <Text settings={{textDecoration: 'underline'}}>{l('privacyPolicy')}</Text>
                </Flex>
                {' '}
                {l('of')}
                {' '}
                {venueData?.name}
              </Text>
            </Flex>
          </CheckBox>
        </Flex>
      </SpacedForm>
      {submitButtonMarkup}
    </Box>
  )
}
