import axios from 'axios'

import {QueryMethod} from '../helpers/types'

export type GetLocationParams = undefined
export type GetLocationQueryParams = undefined
export type GetLocationQueryReturnType = {
  country_code: string
} | null
export type GetLocationQuery = QueryMethod<GetLocationParams, GetLocationQueryParams, GetLocationQueryReturnType>

export const getLocationEndpoint = 'https://ipapi.co/json/'

export const getLocationQuery: GetLocationQuery = () => ({
  queryKey: ['GetLocationQuery'],
  cacheTime: 1000 * 60 * 60 * 60,
  queryFn: () => {
    return axios.get<GetLocationQueryParams, {
      data: GetLocationQueryReturnType
      status: number
    }>(getLocationEndpoint).then((res) => {
      if (res.status === 200) {
        return res.data
      }
      return null
    })
  },
})
