import {Box, Flex} from 'exsportia-components'
import {DateTime, Interval} from 'luxon'
import React, {FC, useEffect, useMemo, useState} from 'react'

import {TimeSlotGrid, TimeslotStatesEnum} from '../../api/booking/types'
import {useLocale, useLuxon} from '../../utils/i18n'
import {isNilOrEmpty} from '../../utils/logic'
import {TimeSlotsList} from './components/timeslots-list'
import {StickyStatesEnum} from './types'

type Params = {
  prevSlots: TimeSlotGrid
  firstPoint: number | null
  secondPoint: number | null
  maxTime: number
  minTime: number
  timeSlot: number
  timeSlotsPerRow: number
  setMaxParticipants: (qty: number) => void
  prevMaxParticipants: number
}
const blockSlotsBeforeBlockedIfMaxTime = (slots: TimeSlotGrid, minTime: number, timeSlot: number) =>
  slots.reduce((acc: TimeSlotGrid, slot, index) => {
    const accToUse =
      slot.options.state === TimeslotStatesEnum.BLOCKED && index * timeSlot < minTime
        ? acc.map((prevSlot) => ({
            ...prevSlot,
            options: {
              ...prevSlot.options,
              state: TimeslotStatesEnum.BLOCKED,
            },
          }))
        : acc
    return [...accToUse, slot]
  }, [])
const updateMaxParticipants = ({
  slotTimestamp,
  firstPoint,
  secondPoint,
  slotSpaces,
  setMaxParticipants,
  prevMaxParticipants,
}: Pick<Params, 'firstPoint' | 'prevMaxParticipants' | 'secondPoint' | 'setMaxParticipants'> & {
  slotTimestamp: number
  slotSpaces: number
}) => {
  const isNotSelectedPoints = !firstPoint && !secondPoint
  const isMaxPatricipantsIsMoreThenPrevious = slotSpaces > prevMaxParticipants
  if (isMaxPatricipantsIsMoreThenPrevious || isNotSelectedPoints) {
    return
  }
  const isSlotIsBetweenSelected =
    firstPoint && secondPoint && slotTimestamp >= firstPoint && slotTimestamp <= secondPoint
  const isSlotIsFirstSelected = firstPoint && slotTimestamp === firstPoint
  const isSlotIsSecondSelected = secondPoint && slotTimestamp === secondPoint
  if (isSlotIsBetweenSelected || isSlotIsFirstSelected || isSlotIsSecondSelected) {
    setMaxParticipants(slotSpaces)
    return
  }
  return
}
export const updateSlots = ({
  prevSlots,
  firstPoint,
  secondPoint,
  maxTime,
  timeSlot,
  minTime,
  timeSlotsPerRow,
  setMaxParticipants,
  prevMaxParticipants,
}: Params) => {
  /*
    [ ] - available slot
    [#] - blocked slot
    [*] - selected slot
  ^ - targeting slot to press

  CASE 1:
  [ ][ ][ ][ ][ ][#][ ][ ][ ][ ][ ][#][ ][ ][ ][ ][ ]
  ^
  [#][#][#][#][#][#][ ][*][ ][ ][ ][#][#][#][#][#][#]

  CASE 2:
  [ ][ ][ ][ ][ ][#][ ][ ][ ][ ][ ][#][ ][ ][ ][ ][ ]
  ^
  [ ][ ][ ][*][ ][#][#][#][#][#][#][#][#][#][#][#][#]

  CASE 3:
  [ ][ ][ ][ ][ ][#][ ][ ][ ][ ][ ][#][ ][ ][ ][ ][ ]
  ^
  [#][#][#][#][#][#][#][#][#][#][#][#][ ][*][ ][ ][ ]
  */
  let blockAfter = false
  let maxTimeCounter = 0
  let firstPointPassed = false
  const slotsTemp: TimeSlotGrid = []
  for (let i = 0; i < prevSlots.length; i++) {
    const slot = {...prevSlots[i]}
    let state: TimeslotStatesEnum = slot.options.state
    const slotTimestamp = Number(slot.date_timestamp)
    let stickyState = StickyStatesEnum.DEFAULT
    let selectStart = firstPoint
    let selectEnd = secondPoint
    if (firstPoint && secondPoint && firstPoint !== secondPoint) {
      const firstPointMoment = DateTime.fromMillis(firstPoint).toUTC()
      const secondPointMoment = DateTime.fromMillis(secondPoint).toUTC()
      let selectStartMoment = firstPointMoment
      let selectEndMoment = secondPointMoment
      if (firstPointMoment.startOf('minute').toMillis() > secondPointMoment.startOf('minute').toMillis()) {
        selectEndMoment = firstPointMoment
        selectStartMoment = secondPointMoment
        selectEnd = firstPoint
        selectStart = secondPoint
      }
      if (
        Interval.fromDateTimes(selectStartMoment.startOf('minute'), selectEndMoment.endOf('minute')).contains(
          DateTime.fromMillis(slotTimestamp),
        )
      ) {
        state = TimeslotStatesEnum.SELECTED
        stickyState = StickyStatesEnum.BOTH
        // code below disable stickiness on the start and end of row
        switch (true) {
          case (slotTimestamp === selectStart && i % timeSlotsPerRow === timeSlotsPerRow - 1) ||
            (slotTimestamp === selectEnd && i % timeSlotsPerRow === 0):
            stickyState = StickyStatesEnum.DEFAULT
            break
          case slotTimestamp === selectEnd || i % timeSlotsPerRow === timeSlotsPerRow - 1:
            stickyState = StickyStatesEnum.LEFT
            break
          case slotTimestamp === selectStart || i % timeSlotsPerRow === 0:
            stickyState = StickyStatesEnum.RIGHT
            break
        }
      }
    }
    updateMaxParticipants({
      slotTimestamp,
      firstPoint,
      secondPoint,
      slotSpaces: slot.spaces,
      setMaxParticipants,
      prevMaxParticipants,
    })
    if (firstPoint === slotTimestamp || secondPoint === slotTimestamp) {
      state = TimeslotStatesEnum.SELECTED
    }
    if (firstPoint) {
      if (slotTimestamp === firstPoint) {
        firstPointPassed = true
      }
      if (firstPointPassed) {
        maxTimeCounter += timeSlot
      }
      if ((firstPointPassed && state === TimeslotStatesEnum.BLOCKED) || maxTimeCounter > maxTime) {
        blockAfter = true
      }
      if (blockAfter) {
        state = TimeslotStatesEnum.BLOCKED
      }
    }

    slot.options = {
      ...slot.options,
      state,
      stickyState,
    }

    slotsTemp.push(slot)
  }

  let blockAfterBackward = false
  let maxTimeCounterBackward = 0
  let firstPointPassedBackward = false
  const slotsTempBackward: TimeSlotGrid = []
  for (let i = slotsTemp.length; i > 0; i--) {
    const index = i - 1
    const slot = {...slotsTemp[index]}
    let state = slot.options.state
    const slotTimestamp = Number(slot.date_timestamp)
    if (firstPoint) {
      if (slotTimestamp === firstPoint) {
        firstPointPassedBackward = true
      }
      if (firstPointPassedBackward) {
        maxTimeCounterBackward += timeSlot
      }
      if ((firstPointPassedBackward && state === TimeslotStatesEnum.BLOCKED) || maxTimeCounterBackward > maxTime) {
        blockAfterBackward = true
      }
      if (blockAfterBackward) {
        state = TimeslotStatesEnum.BLOCKED
      }
    }

    slot.options = {...slot.options, state}

    slotsTempBackward.push(slot)
  }

  const slotsAfterBackwards = slotsTempBackward.reverse()
  // handle min time reservation
  const minimalNumberOfSlotsInARow = minTime / timeSlot
  slotsAfterBackwards.forEach((slot, index) => {
    let isItPossibleToSelectMinimalTime = true
    const isPreviousSlotBlocked = slotsAfterBackwards[index - 1]?.options.state === TimeslotStatesEnum.BLOCKED
    const isPreviousSlotPast = slotsAfterBackwards[index - 1]?.options.state === TimeslotStatesEnum.PAST
    const isCurrentSlotNotBlocked = slot.options.state !== TimeslotStatesEnum.BLOCKED
    if ((isPreviousSlotBlocked || isPreviousSlotPast) && isCurrentSlotNotBlocked) {
      isItPossibleToSelectMinimalTime = false
      for (let i = 0; i <= minimalNumberOfSlotsInARow - 1; i++) {
        if (
          isNilOrEmpty(slotsAfterBackwards[index + i]) ||
          slotsAfterBackwards[index + i]?.options.state === TimeslotStatesEnum.BLOCKED
        ) {
          break
        }
        if (i === minimalNumberOfSlotsInARow - 1) {
          isItPossibleToSelectMinimalTime = true
        }
      }
    }
    if (!isItPossibleToSelectMinimalTime && slot.options.state !== TimeslotStatesEnum.PAST) {
      slot.options.state = TimeslotStatesEnum.BLOCKED
    }
  })

  return slotsAfterBackwards
}

const makeTimeSelectInitialState = (time: null[] | [number | null, number | null]) => {
  const result = [...time]
  if (time[1]) {
    result[1] = DateTime.fromMillis(Number(time[1])).toUTC().toMillis()
  }
  return result
}

const getInitialSecondPoint = (time: null[] | [number | null, number | null], timeslot: number) => {
  if (!time[1]) {
    return null
  }
  return time[1] - timeslot * 60000
}

type TimeSelectProps = {
  isLoading?: boolean
  date: string
  timeSlot: number
  slots: TimeSlotGrid
  noDuration: boolean
  minTimeReservation?: number
  maxTimeReservation?: number
  clearErrors: () => void
  bookingType?: 'SPORT' | 'TEACHER'
  addError: (error: string) => void
  time: null[] | [number | null, number | null]
  onChange: (_firstPoint: number | null, _secondPoint: number | null) => void
}

export const TimeSelect: FC<TimeSelectProps> = ({
  date,
  time,
  slots,
  timeSlot,
  onChange,
  addError,
  noDuration,
  minTimeReservation,
  maxTimeReservation,
  clearErrors,
  isLoading = false,
}) => {
  // const { l } = useLocale();
  const initialTime = makeTimeSelectInitialState(time)
  const [firstPoint, setFirstPoint] = useState<number | null>(initialTime[0])
  const [secondPoint, setSecondPoint] = useState<number | null>(getInitialSecondPoint(time, timeSlot))
  const luxon = useLuxon()
  const {l} = useLocale()
  useEffect(() => {
    // reset selected time if date changed
    if (luxon.fromMillis(Number(time[0])).toUTC().toFormat('dd/MM/yyyy') !== date) {
      setFirstPoint(null)
      setSecondPoint(null)
    }
  }, [date, luxon]) //eslint-disable-line
  const minTimeReservationToUse = minTimeReservation ? Math.round(minTimeReservation / timeSlot) * timeSlot : timeSlot
  const maxTimeReservationToUse = maxTimeReservation || timeSlot * 100
  useEffect(() => {
    // validate input
    const sorted = [firstPoint, secondPoint].sort((a, b) => Number(a) - Number(b))
    /* if (isNotNilAndNotEmpty(sorted[0])) {
      if (isNotNilAndNotEmpty(sorted[1])) {
        sorted[1] = luxon.fromMillis(sorted[1]).plus({ minute: timeSlot }).toUTC().toMillis()
      } else {
        sorted[1] = luxon.fromMillis(sorted[0]).plus({ minute: timeSlot }).toUTC().toMillis()
      }
    } else {
      sorted.reverse()
      if (isNotNilAndNotEmpty(sorted[0])) {
        sorted[1] = luxon.fromMillis(sorted[0]).plus({ minute: timeSlot }).toUTC().toMillis()
      }
    }*/

    // const minTimeReservationToUse = minTimeReservation;
    // const maxTimeReservationToUse = maxTimeReservation || timeSlot * 100;
    const closestAllowedTimeline = luxon
      .fromMillis(Number(sorted[0]))
      .plus({minute: minTimeReservationToUse - timeSlot})
      .toUTC()
      .toMillis()
    const farestAllowedTimeline = luxon
      .fromMillis(Number(sorted[0]))
      .plus({minute: maxTimeReservationToUse})
      .toUTC()
      .toMillis()

    const selectedReservationTime =
      sorted[1] && sorted[0]
        ? luxon.fromMillis(sorted[1]).diff(luxon.fromMillis(sorted[0])).valueOf() / 60000 + timeSlot
        : 0

    const isAfterClosestAllowedTimeline = sorted[1]
      ? luxon.fromMillis(sorted[1]).startOf('second') >= luxon.fromMillis(closestAllowedTimeline).startOf('second')
      : true
    const isBeforeFarestAllowedTimeline = sorted[1]
      ? luxon.fromMillis(sorted[1]).startOf('second') <= luxon.fromMillis(farestAllowedTimeline).startOf('second')
      : true

    if (!sorted[0] || !sorted[1]) {
      clearErrors()
    }

    if (sorted[1] && selectedReservationTime > maxTimeReservationToUse) {
      // @ts-ignore TODO: ADD LOCALE
      addError(l('max_reservation_time_error', {value: maxTimeReservationToUse}))
    }
    if (sorted[1] && selectedReservationTime < minTimeReservationToUse) {
      // @ts-ignore TODO: ADD LOCALE
      addError(l('min_reservation_time_error', {value: minTimeReservationToUse}))
    }

    if (sorted[0] && sorted[1] && isAfterClosestAllowedTimeline && isBeforeFarestAllowedTimeline) {
      clearErrors()
    }

    onChange(...(sorted as [number | null, number | null]))
  }, [firstPoint, secondPoint]) //eslint-disable-line
  useEffect(() => {
    // set second point as first if first was deleted
    if (!firstPoint && secondPoint) {
      setFirstPoint(secondPoint)
      setSecondPoint(null)
    }
  }, [firstPoint]) //eslint-disable-line

  const slotsToUse = useMemo(
    () => (minTimeReservation ? blockSlotsBeforeBlockedIfMaxTime(slots, minTimeReservationToUse, timeSlot) : slots),
    [minTimeReservation, slots, timeSlot],
  )

  return (
    <Box paddingHorizontal='m'>
      <Flex
        width='100%'
        flexWrap='wrap'
        flexDirection='row'
        alignItems='center'
        alignContent='flex-start'
        justifyContent='flex-start'
      >
        <TimeSlotsList
          slots={slotsToUse}
          timeSlot={timeSlot}
          isLoading={isLoading}
          firstPoint={firstPoint}
          noDuration={noDuration}
          secondPoint={secondPoint}
          setFirstPoint={setFirstPoint}
          setSecondPoint={setSecondPoint}
          minTimeReservation={minTimeReservationToUse}
          maxTimeReservation={maxTimeReservationToUse}
        />
      </Flex>
    </Box>
  )
}
