import {calculateBookingPriceQuery} from './calculate-booking-price'
import {createClassBookingDraftMutation, createClassBookingMutation, createClassBookingPayByTokenMutation} from './create-class-booking.mutation'
import {createInstructorBookingMutation, createInstructorBookingPayByTokenMutation} from './create-instructor-booking.mutation'
import {
  createServiceBookingDraftMutation,
  createServiceBookingMutation,
  createServiceBookingPayByTokenMutation
} from './create-service-booking.mutation'
import {getAvailableDaysByWeekQuery} from './get-available-days-by-week'
import {getBookingQuery, getDraftEntityQuery} from './get-booking'
import {getInstructorServicesQuery} from './get-instructor-services'
import {getPlayspacesQuery} from './get-playspaces'
import {getServiceTimeslotGridQuery} from './get-service-timeslot-grid'
import {getUserBookingsQuery} from './get-user-bookings'
import {getVenueClassSessionsByDateQuery} from './get-venue-class-sessions-by-date'
import {getVenueInstructorsQuery} from './get-venue-instructors'
import {getVenueServicesQuery} from './get-venue-services'

export const BookingQueries = {
  getBookingQuery,
  getPlayspacesQuery,
  getDraftEntityQuery,
  getUserBookingsQuery,
  getVenueServicesQuery,
  getVenueInstructorsQuery,
  getInstructorServicesQuery,
  calculateBookingPriceQuery,
  getServiceTimeslotGridQuery,
  getAvailableDaysByWeekQuery,
  getVenueClassSessionsByDateQuery,
}

export const BookingMutations = {
  createClassBookingMutation,
  createServiceBookingMutation,
  createClassBookingDraftMutation,
  createInstructorBookingMutation,
  createServiceBookingDraftMutation,
  createClassBookingPayByTokenMutation,
  createServiceBookingPayByTokenMutation,
  createInstructorBookingPayByTokenMutation,
}
