import {Theme} from 'exsportia-components'
import {IconWithTextSettingsType} from 'exsportia-components/dist/components/molecules/icon-with-text'
import {StyleSizeSpaceType} from 'exsportia-components/dist/types'

export type PlayspaceCardSettingsProps = {
  width?: StyleSizeSpaceType
  height?: StyleSizeSpaceType
  nameLoaderHeight?: number | string
  imageLoaderHeight?: number | string
  subTextLoaderHeight?: number | string
  iconWithTextSettings: IconWithTextSettingsType & {
    title: {
      m: StyleSizeSpaceType
    },
  }
}

export const commonSettings: PlayspaceCardSettingsProps = {
  width: '100%',
  nameLoaderHeight: '24px',
  height: 'max-content',
  subTextLoaderHeight: '18px',
  imageLoaderHeight: Theme.spaces.multiplier6x,
  iconWithTextSettings: {
    width: '100%',
    subText: {
      m: '2px 0 0 0',
      display: 'flex',
      styleType: 'cropped',
      fontSize: Theme.fonts.label,
      color: Theme.colors.textSecondary,
      lineHeight: Theme.fonts.labelLineHeight,
    },
    textBox: {width: '100%'},
    icon: {overflow: 'hidden'},
    title: {
      m: 0,
      fontWeight: '400',
      lineHeight: '20px',
      fontSize: Theme.fonts.labelLineHeight,
    },
  },
}

export const circleSettings: PlayspaceCardSettingsProps = {
  ...commonSettings,
  iconWithTextSettings: {
    ...commonSettings.iconWithTextSettings,
    icon: {
      ...commonSettings.iconWithTextSettings.icon,
      borderRadius: '50%',
      width: `${Theme.spaces.multiplier6x}px`,
      height: `${Theme.spaces.multiplier6x}px`,
    },
  },
}

const squareSettings: PlayspaceCardSettingsProps = {
  ...commonSettings,
  iconWithTextSettings: {
    ...commonSettings.iconWithTextSettings,
    icon: {
      ...commonSettings.iconWithTextSettings.icon,
      borderRadius: '8px',
      width: `${Theme.spaces.multiplier9x}px`,
      height: `${Theme.spaces.multiplier6x}px`,
    },
  },
}

export const typeStylesMap = {
  default: circleSettings,
  square: squareSettings,
  circle: circleSettings,
}

export const additionalStylesMap = {}
