import {A, Box, Flex, Text} from 'exsportia-components'
import {FC} from 'react'

import packageInfo from '../../../package.json'
import {useAuthState} from '../../services/auth/hooks'
import {useLocale} from '../../utils/i18n'
import {PRIVACY_POLICY, TERMS_AND_CONDITIONS} from '../../utils/remote-content'

export const PreFooter: FC = () => {
  const {l} = useLocale()
  const {isLoggedIn} = useAuthState()
  const version: string = packageInfo.version
  return (
    <Flex
      position='absolute'
      bottom={isLoggedIn ? ['72px', '0'] : ['56px', '0']}
      backgroundColor={'bg'}
      py={['0', '8px']}
      px='8px'
      flexDirection={'row'}
      width='100%'
      justifyContent='space-between'
      alignItems='center'
      gap='12px'
    >
      <Text settings={{fontSize: '16px', display: ['none', 'block']}}>{'© 2023 Exsportia Ltd.'}</Text>
      <A
        target={'_blank'}
        href={PRIVACY_POLICY}
        settings={{fontSize: '16px'}}
      >
        {l('privacyPolicy')}
      </A>
      <A
        target={'_blank'}
        href={TERMS_AND_CONDITIONS}
        settings={{fontSize: '16px'}}
      >
        {l('termsConditions')}
      </A>
      <Box>
        <Text
          settings={{fontSize: ['13px', '16px'], lineHeight: ['16px', '20px']}}
          text={`v.${version}`}
        />
      </Box>
    </Flex>
  )
}
