import {Box, Flex, Span} from 'exsportia-components'
import {FC, Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'

import {DisplaySettingsQueries} from '../../api/display-settings'
import {VenueQueries} from '../../api/venue'
import {GetVenueDisplaySettingsQueryReturnType} from '../../api/venue/get-venue-display-settings'
import {noop} from '../../helpers/logic'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {DownloadAppModal} from '../../modals/download-app-modal/download-app-modal'
import {AuthService} from '../../services/auth'
import {Button} from '../../ui/components/button/button'
import {ExsportiaIcon} from '../../ui/components/exsportia-icon/exsportia-icon'
import {GradientLoader} from '../../ui/components/gradient-loader/gradient-loader'
import {useLocale} from '../../utils/i18n'
import {removeItemFromLocalStorage} from '../../utils/local-storage'
import {isNilOrEmpty} from '../../utils/logic'
import {useModalActions} from '../../utils/modals'
import {LayoutByAppearanceTemplate} from './components/layout-by-appearance-template'

const OptionButton: FC<{
  icon: string
  text: string
  navigate: () => void
}> = ({icon, text, navigate}) => (
  <Button
    onClick={navigate}
    type={'secondary'}
  >
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      gap={'8px'}
    >
      <ExsportiaIcon icon={icon} />
      <Span fontSize='16px'>{text}</Span>
    </Flex>
  </Button>
)

const defaultEntitiesOrder: GetVenueDisplaySettingsQueryReturnType['entitiesOrder'] = [
  'service',
  'class',
  'teacher',
  'justTheSpace',
  'subscription',
]

export const SelectBookingTypePage = () => {
  const navigate = useNavigate()
  // const {openModal} = useModalActions()
  const {openModal} = useModalActions()
  const [backAction, setBackAction] = useState<(() => void) | null>(null)
  const {venueId} = useParams<{venueId: string}>()
  const {data, error} = useQuery(VenueQueries.getVenueQuery({params: {id: venueId}, query: {id: venueId}, retry: 3}))
  const isLoggedIn = useSelector(AuthService.selectors.isLoggedIn())
  const {
    data: appearanceTemplate,
    isRefetching,
    refetch: refetchAppearanceTemplate,
    isLoading: isAppearanceTemplateLoading,
  } = useQuery(DisplaySettingsQueries.getAppearanceTemplateByIdQuery({params: {id: data?.appearanceTemplateId}}))
  const isLoading = useMemo(() => isAppearanceTemplateLoading || isRefetching, [isAppearanceTemplateLoading, data])
  const needDefaultView = useMemo(() => isNilOrEmpty(appearanceTemplate) && !isLoading, [appearanceTemplate])
  useEffect(() => {
    refetchAppearanceTemplate()
  }, [isLoggedIn])
  useEffect(() => {
    if (error?.response?.status === 404) {
      navigate('/')
    }
  }, [error])
  const {l} = useLocale()
  const handleNavigateToServices = useCallback(() => {
    navigate('./select-service')
  }, [navigate])
  const handleNavigateToSpaces = useCallback(() => {
    navigate('./select-space-service')
  }, [navigate])
  const handleNavigateToInstructors = useCallback(() => {
    navigate('./select-instructor')
  }, [navigate])
  const handleNavigateToClass = useCallback(() => {
    navigate('./select-class')
  }, [navigate])

  const handlerOpenSubscriptionsPlug = useCallback(() => {
    openModal({
      component: <DownloadAppModal />,
      footerConfig: {disabled: true},
      title: l('subscriptions'),
    })
  }, [])
  useEffect(() => {
    removeItemFromLocalStorage('isDownloadHeaderClosed')
  }, [])

  const header = useMemo(
    () => (
      <SimpleGridLayoutHeader
        noBack={backAction === null}
        customBackAction={backAction || noop}
        title={l('welcome_with_venue_name', {venueName: data?.name || 'Venue'})}
      />
    ),
    [data, isLoading, backAction],
  )

  const optionsByType = useMemo(
    () => ({
      service: (
        <OptionButton
          text={data?.entityNames.sports || l('services')}
          icon={'shoppingCart'}
          navigate={handleNavigateToServices}
        />
      ),
      class: (
        <OptionButton
          text={data?.entityNames.classes || l('classes')}
          icon={'usersOutline24'}
          navigate={handleNavigateToClass}
        />
      ),
      teacher: (
        <OptionButton
          text={data?.entityNames.teachers || l('instructors')}
          icon={'user'}
          navigate={handleNavigateToInstructors}
        />
      ),
      justTheSpace: (
        <OptionButton
          text={data?.entityNames.areaHireOnly || l('areaHireOnly')}
          icon={'storeFrontOutline'}
          navigate={handleNavigateToSpaces}
        />
      ),
      subscription: (
        <OptionButton
          text={data?.entityNames.subscriptions || l('subscriptions')}
          icon={'crownAction'}
          navigate={handlerOpenSubscriptionsPlug}
        />
      ),
    }),
    [l, navigate, data?.entityNames, isLoading],
  )

  const optionButtons: (JSX.Element | null)[] = useMemo(
    () => defaultEntitiesOrder.map((type, index) => <Fragment key={index}>{optionsByType[type]}</Fragment>),
    [optionsByType, defaultEntitiesOrder],
  )

  return (
    <Box>
      <GridLayout
        withoutPadding
        header={header}
      >
        {needDefaultView ? (
          <Flex
            p='12px'
            gap={'16px'}
            flexDirection={'column'}
          >
            {optionButtons}
          </Flex>
        ) : (
          <Flex
            py='16px'
            gap={'16px'}
            flexDirection={'column'}
          >
            {appearanceTemplate && !isRefetching ? (
              <LayoutByAppearanceTemplate
                setBackAction={setBackAction}
                appearanceTemplate={appearanceTemplate}
              />
            ) : (
              <Flex
                px='16px'
                gap='16px'
                flexDirection='column'
              >
                <GradientLoader height={'48px'} />
                <GradientLoader height={'48px'} />
                <GradientLoader height={'48px'} />
              </Flex>
            )}
          </Flex>
        )}
      </GridLayout>
    </Box>
  )
}
