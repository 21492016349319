import {Formik, FormikConfig} from 'formik'
import {FC, useCallback, useMemo, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'

import {AreaSelectArea} from '../../../api/area/types'
import {BookingQueries} from '../../../api/booking'
import {commonServiceBookingDataAdapter, commonServiceBookingPayByTokenAdapter, PayByTokenServiceBookingDraft} from '../../../api/booking/helpers'
import {Instructor, ServiceItem} from '../../../api/booking/types'
import {getEntityDataByIdFromAppearanceSettings} from '../../../api/helpers/data'
import {auth} from '../../../utils/firebase'
import {useLuxon} from '../../../utils/i18n'
import {useModalActions} from '../../../utils/modals'
import {defaultUserValues, IntegratedSignUpFormType} from '../../auth/forms/integrated-sign-up-form'
import {useHandleDraftEntityStatusChange} from '../../payments/hooks/handle-draft-entity-status-change.hook'
import {usePaymentProvider} from '../../payments/hooks/payment-provider-form.provider'
import {useBookingLoader} from '../hooks'
import {BookingService} from '../index'
import {RedirectionComponent} from './components/redirection-component'
import {CreateServiceBookingFormType} from './create-service-booking-form'
import {useOpenBookingValidationModal} from './hooks/open-booking-validation-modal.hook'
import {BookingStateParticipant} from './types'

export type CreateInstructorBookingFormType = IntegratedSignUpFormType & {
  date: string
  venueId?: string
  areaId?: string
  participants: BookingStateParticipant[]
  hasSpecialRequests: boolean
  specialRequest: string
  bookingType: 'service' | 'space' | 'teacher'
  entity: 'service' | 'teacher'
  paymentMethodId: string | null
  maxParticipants: number
  minParticipants: number
  isJustTheSpace: boolean
  serviceId: string | null
  service: ServiceItem | null
  teacherId?: string | null
  teacher: Instructor | null
  area: AreaSelectArea | null
  userId: string | null | undefined
  time: null[] | [number | null, number | null]
  useForParticipants?: boolean
  coverEquipment?: boolean
  paymentType?: 'oneTimePayment' | 'payNow' | 'payOnLocation'
  flowStartsFromService?: boolean
}

type Params = {
  venueId: string
  instructorId?: string
  serviceId?: string
}

export const CreateInstructorBookingForm: FC = () => {
  const luxon = useLuxon()
  const [entityId, setEntityId] = useState<string | null>(null)
  const {closeAllModals} = useModalActions()
  const navigate = useNavigate()
  const {venueId, instructorId, serviceId} = useParams<Params>()
  const {isLoading, setIsLoading} = useBookingLoader()
  const {openPaymentForm} = usePaymentProvider()
  const {data: priceDataObjects} = useQuery(BookingQueries.calculateBookingPriceQuery({enabled: false}))
  const {mutate: createDraftBooking} = useMutation(BookingService.mutations.createServiceBookingDraftMutation({}))
  // const [mustGoToHomePage, setMustGoToHomePage] = useState(false)

  // const initialUserId = useMemo(() => auth.currentUser?.uid, [])

  if (!venueId || (!instructorId && !serviceId)) {
    return null
  }

  const {state} = useLocation()

  let instructor: Instructor | null = null
  let serviceData: ServiceItem | null = null
  let flowStartsFromService = false

  if (instructorId) {
    instructor = getEntityDataByIdFromAppearanceSettings(venueId, instructorId) as Instructor
  }
  if (serviceId) {
    flowStartsFromService = true
    serviceData = getEntityDataByIdFromAppearanceSettings(venueId, serviceId) as ServiceItem
  }

  // useEffect(() => {
  //   setMustGoToHomePage(true)
  // }, [auth.currentUser?.uid])

  const {mutate: createServiceBooking} = useMutation(BookingService.mutations.createInstructorBookingMutation({}))
  const {mutate: createServiceBookingPayByToken} = useMutation(
    BookingService.mutations.createInstructorBookingPayByTokenMutation({}),
  )
  const {setStartRefetch} = useHandleDraftEntityStatusChange({
    entity: 'booking',
    entityId,
    isLoading,
    setIsLoading,
  })
  const {openBookingValidationModal} = useOpenBookingValidationModal()
  const initialValues = useMemo<CreateServiceBookingFormType>(
    () => ({
      area: null,
      participants: [],
      entity: 'teacher',
      venueId: venueId!,
      minParticipants: 1,
      specialRequest: '',
      time: [null, null],
      teacher: instructor,
      service: serviceData,
      isJustTheSpace: false,
      paymentMethodId: null,
      flowStartsFromService,
      bookingType: 'teacher',
      hasSpecialRequests: false,
      maxParticipants: Infinity,
      serviceId: serviceId || null,
      teacherId: instructorId || null,
      date: luxon.now().toFormat('dd/MM/yyyy'),
      ...defaultUserValues,
    }),
    [venueId, instructor, serviceData, serviceId, instructorId],
  )

  if (state?.teacher) {
    initialValues.teacher = state.teacher
    initialValues.teacherId = state.teacher.uid
  }

  const handleSubmit = useCallback<FormikConfig<CreateInstructorBookingFormType>['onSubmit']>((values) => {
    if (!priceDataObjects) {
      return
    }
    // if (initialUserId !== auth.currentUser?.uid && serviceData?.accessForAnyone !== false) {
    //   return openBookingValidationModal({type: 'service'})
    // }
    const isPayNow = !!(values.paymentMethodId && values.paymentType !== 'payOnLocation')
    const createBookingFunction = isPayNow ? createServiceBookingPayByToken : createServiceBooking
    const dataForAdapter = {
      bookingState: {
        ...values,
        userId: auth.currentUser?.uid,
      },
      priceDataObjects,
    }
    let adaptedData = null
    let dataForDraft: PayByTokenServiceBookingDraft | null = null
    if (values.paymentType !== 'payOnLocation') {
      adaptedData = commonServiceBookingPayByTokenAdapter(dataForAdapter)
      dataForDraft = adaptedData
    } else {
      adaptedData = commonServiceBookingDataAdapter(dataForAdapter)
    }
    if (values.paymentType === 'oneTimePayment') {
      if (dataForDraft === null) {
        return
      }
      createDraftBooking(
        adaptedData,
        {
          onSuccess: () => {
            setStartRefetch(true)
            setEntityId(dataForDraft?.bookingId || null)
            openPaymentForm && openPaymentForm({
              entity: 'booking',
              bookingId: dataForDraft?.bookingId,
              totalPrice: dataForDraft?.data.totalPrice,
              startProcessCallback: () => setIsLoading(true),
            })
          }
        }
      )
    } else {
      setEntityId(null)
      setIsLoading(true)
      createBookingFunction(
        adaptedData,
        {
          onSuccess: (booking) => {
            closeAllModals()
            setIsLoading(false)
            navigate(`/${venueId}/bookings/${booking.uid}`)
          },
          onError: (error) => {
            console.log(error)
            setIsLoading(false)
            if (error.response?.status === 409) {
              return openBookingValidationModal({type: 'service'})
            }
          },
        },
      )
    }
  }, [venueId, priceDataObjects, createServiceBooking, createServiceBookingPayByToken, setIsLoading, navigate, closeAllModals])

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <RedirectionComponent>
        <Outlet />
      </RedirectionComponent>
    </Formik>
  )
}
