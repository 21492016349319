import {API} from '../../networking/http'
import {CurrenciesEnum} from '../../pages/select-class/select-class-page'
import {Equipment} from '../booking/types'
import {QueryMethod} from '../helpers/types'
import {Class} from './types'

export type GetVenueClassesParams = undefined
export type GetVenueClassesQueryParams = {
  venueId?: string
  instructorId?: string | null
}

export type GetVenueClassesReturnType = (Class & {
  currency: CurrenciesEnum
  equipments: Equipment[]
  closestSessionTimestamp: number
  icon: string // TODO: type icons
  private: boolean
})[]
export type GetVenueClassesQuery = QueryMethod<
  GetVenueClassesParams,
  GetVenueClassesQueryParams,
  GetVenueClassesReturnType
>

export const makeGetVenueClassesEndpoint = () => 'classes/allClasses'

export const getVenueClassesQuery: GetVenueClassesQuery = ({query}) => ({
  queryKey: ['getVenueClassesQuery', query],
  cacheTime: 1000 * 300,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<GetVenueClassesQueryParams, GetVenueClassesReturnType>(makeGetVenueClassesEndpoint(), {
      params: config,
    }).then((res) => res.data),
})
