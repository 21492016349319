import {useQuery} from 'react-query'

import {getAppearanceTemplateByIdQuery} from '../display-settings/get-appearance-template'
import {AppearanceTemplateElement} from '../display-settings/types'
import {VenueQueries} from '../venue'

export const getEntityDataByIdFromAppearanceSettings: (
  venueId: string,
  id: string,
) => AppearanceTemplateElement['data'] | null = (venueId, id) => {
  const {data: venueData} = useQuery(VenueQueries.getVenueQuery({params: {id: venueId}}))
  const {data: settings} = useQuery(getAppearanceTemplateByIdQuery({params: {id: venueData?.appearanceTemplateId}}))
  let entityToReturn: AppearanceTemplateElement['data']
  if (settings) {
    (settings as AppearanceTemplateElement[]).forEach((el) => {
      if (el.data && el.entityId === id) {
        entityToReturn = el.data
        return
      }
      if (el.items) {
        (el.items as AppearanceTemplateElement[]).forEach((item) => {
          if (item.data && item.entityId === id) {
            entityToReturn = item.data
            return
          }
        })
      }
    })
  }
  return entityToReturn || null
}
