import {TranslatorScope} from '../../locale/types'
import {exsportiaIcons} from '../../ui/components/exsportia-icon/exsportia-icon'
import {CancelPolicyEnum} from '../booking/types'
import {Media} from '../types'

export type ServiceTypeOption = {
  label: TranslatorScope
  icon: keyof typeof exsportiaIcons
  uid?: string
  areaSpace?: boolean
  value: ServiceTypesEnum | SpaceTypesEnum
}

export type Service = {
  uid: string
  name: string
  media?: Media[]
  sportType: ServiceTypesEnum
  areaSpace?: boolean
  thumbIndex?: number
  pricePeriod: number | 'NO_DURATION'
  cancelPolicy: CancelPolicyEnum | null
  multiPerTimeSlot: boolean
  arrivalInstructions?: string
}

export enum SpaceTypesEnum {
  AREA_GYM = 'AREA_GYM',
  HALL = 'HALL',
  PARK = 'PARK',
  POOL = 'POOL',
  LANE = 'LANE',
  ROOM = 'ROOM',
  RING = 'RING',
  RINK = 'RINK',
  BIKE = 'BIKE',
  ROOF = 'ROOF',
  TRACK = 'TRACK',
  PITCH = 'PITCH',
  ARENA = 'ARENA',
  BOARD = 'BOARD',
  COURT = 'COURT',
  HOUSE = 'HOUSE',
  SPACE = 'SPACE',
  TABLE = 'TABLE',
  OTHER = 'OTHER',
  BEACH = 'BEACH',
  STUDIO = 'STUDIO',
  GARDEN = 'GARDEN',
  COURSE = 'COURSE',
  MACHINE = 'MACHINE',
  BALCONY = 'BALCONY',
  TERRACE = 'TERRACE',
  SIMULATOR = 'SIMULATOR',
  STUDIO_AREA = 'STUDIO_AREA',
  BOAT = 'BOAT',
}

export enum ServiceTypesEnum {
  EIGHT_BALL_POOL = '8_BALL_POOL',
  ABSEILING = 'ABSEILING',
  ACRODANCE = 'ACRODANCE',
  ACUPUNCTURE = 'ACUPUNCTURE',
  AERIAL_ACROBATICS = 'AERIAL_ACROBATICS',
  AEROBATICS = 'AEROBATICS',
  AEROBICS = 'AEROBICS',
  ACROBATIC_GYMNASTICS = 'ACROBATIC_GYMNASTICS',
  AIRSOFT = 'AIRSOFT',
  AIKIDO = 'AIKIDO',
  AMERICAN_FOOTBALL = 'AMERICAN_FOOTBALL',
  ARCHERY = 'ARCHERY',
  ARNIS = 'ARNIS',
  ART_AND_CRAFT = 'ART_AND_CRAFT',
  ATHLETICS = 'ATHLETICS',
  ATV = 'ATV',
  AXE_THROWING = 'AXE_THROWING',
  BADMINTON = 'BADMINTON',
  BALLET = 'BALLET',
  BANANA_RAFTING = 'BANANA_RAFTING',
  BANDY = 'BANDY',
  BANZAI_SKYDIVING = 'BANZAI_SKYDIVING',
  BASE_JUMPING = 'BASE_JUMPING',
  BASEBALL = 'BASEBALL',
  BIATHLON = 'BIATHLON',
  BASKETBALL = 'BASKETBALL',
  BEACH_VOLLEYBALL = 'BEACH_VOLLEYBALL',
  BEACH_TENNIS = 'BEACH_TENNIS',
  BMX = 'BMX',
  BOATING = 'BOATING',
  BOBSLEIGH = 'BOBSLEIGH',
  BODYBOARDING = 'BODYBOARDING',
  BOULDER_CLIMBING = 'BOULDER_CLIMBING',
  BOWLING_TENPIN = 'BOWLING_TENPIN',
  BOWLS = 'BOWLS',
  BOXING = 'BOXING',
  BOX_AND_BELLZ = 'BOX_AND_BELLZ',
  BOX_AND_FLOW = 'BOX_AND_FLOW',
  BRAZILIAN_JIUJIDTZU = 'BRAZILIAN_JIUJIDTZU',
  CAMPING = 'CAMPING',
  CANOEING = 'CANOEING',
  CLAY_MODELING = 'CLAY_MODELING',
  CROQUET = 'CROQUET',
  CAPOEIRA = 'CAPOEIRA',
  CHESS = 'CHESS',
  CARDIO_TRAINING = 'CARDIO_TRAINING',
  CASTERBOADRDING = 'CASTERBOADRDING',
  CATCH_GAMES = 'CATCH_GAMES',
  CHARRERIA = 'CHARRERIA',
  CHEERLEADING = 'CHEERLEADING',
  CHILEAN_RODEO = 'CHILEAN_RODEO',
  CIRCUIT_TRAINING = 'CIRCUIT_TRAINING',
  CONTEMPARY_DANCING = 'CONTEMPARY_DANCING',
  BODY_BUILDING = 'BODY_BUILDING',
  CRAZY_GOLF = 'CRAZY_GOLF',
  CRICKET = 'CRICKET',
  CLIMBING = 'CLIMBING',
  CROSS_COUNTRY = 'CROSS_COUNTRY',
  CROSS_COUNTRY_SKIING = 'CROSS_COUNTRY_SKIING',
  CROSSFIT = 'CROSSFIT',
  CURLING = 'CURLING',
  CYCLING = 'CYCLING',
  DANCING = 'DANCING',
  DANDI_BIYO = 'DANDI_BIYO',
  DARTS = 'DARTS',
  DIVING = 'DIVING',
  DODGEBALL = 'DODGEBALL',
  DRAG_RACING = 'DRAG_RACING',
  DRIFTING = 'DRIFTING',
  EMS = 'EMS',
  EQUESTRIAN = 'EQUESTRIAN',
  ENGLISH_POOL = 'ENGLISH_POOL',
  FENCING = 'FENCING',
  FISHING = 'FISHING',
  FITNESS = 'FITNESS',
  FLAMENCO = 'FLAMENCO',
  FLYING_WINGSUIT = 'FLYING_WINGSUIT',
  FLYING_DISC = 'FLYING_DISC',
  FOOTBALL_TENNIS = 'FOOTBALL_TENNIS',
  FOOTBALL = 'FOOTBALL',
  FORMULA_DRIVING = 'FORMULA_DRIVING',
  FUNCTIONAL = 'FUNCTIONAL',
  FUSION_DANCING = 'FUSION_DANCING',
  FREEBOARDING = 'FREEBOARDING',
  GA_GA = 'GA_GA',
  GLIDING = 'GLIDING',
  GROUP_TRAINING = 'GROUP_TRAINING',
  FOOTVOLLEY = 'FOOTVOLLEY',
  FIELD_HOCKEY = 'FIELD_HOCKEY',
  GOLF = 'GOLF',
  GOLF_SIMULATION = 'GOLF_SIMULATION',
  GUSHTIGIRI = 'GUSHTIGIRI',
  GYM = 'GYM',
  GRAVITY = 'GRAVITY',
  GRAVITY_YOGA = 'GRAVITY_YOGA',
  GYMNASTICS = 'GYMNASTICS',
  HANDBALL = 'HANDBALL',
  HANG_GLIDING = 'HANG_GLIDING',
  HIKING = 'HIKING',
  HIT = 'HIT',
  HIP_HOP_DANCING = 'HIP_HOP_DANCING',
  HORSE_RIDING = 'HORSE_RIDING',
  HUMAN_POWERED_AIRCRAFT = 'HUMAN_POWERED_AIRCRAFT',
  ICE_HOCKEY = 'ICE_HOCKEY',
  ICE_SKATING = 'ICE_SKATING',
  INDOOR_SKIING = 'INDOOR_SKIING',
  INTRODUCTION_CLASSES = 'INTRODUCTION_CLASSES',
  IRISH_DANCING = 'IRISH_DANCING',
  FOOT_GOLF = 'FOOT_GOLF',
  TRIATHLON = 'TRIATHLON',
  JAZZ_DANCING = 'JAZZ_DANCING',
  JET_SKI = 'JET_SKI',
  JUDO = 'JUDO',
  JIUJITSU = 'JIUJITSU',
  INSTRUMENT = 'INSTRUMENT',
  KABADDI = 'KABADDI',
  KANGOO_JUMP = 'KANGOO_JUMP',
  KARATE = 'KARATE',
  KARTING = 'KARTING',
  KEEP_AWAY = 'KEEP_AWAY',
  KICKBOXING = 'KICKBOXING',
  KIN_BALL = 'KIN_BALL',
  KITE_SURFING = 'KITE_SURFING',
  KRAV_MAGA = 'KRAV_MAGA',
  BLADESPORTS = 'BLADESPORTS',
  KNEEBOARDING = 'KNEEBOARDING',
  KUNG_FU = 'KUNG_FU',
  LACROSSE = 'LACROSSE',
  LASER_TAG = 'LASER_TAG',
  LINE_DANCING = 'LINE_DANCING',
  LONG_BOARDING = 'LONG_BOARDING',
  LYRICAL_DANCING = 'LYRICAL_DANCING',
  MMA = 'MMA',
  MOUNTAIN_BIKING = 'MOUNTAIN_BIKING',
  MODEL_AIRCRAFT = 'MODEL_AIRCRAFT',
  MODERN_DANCING = 'MODERN_DANCING',
  MINI_GOLF = 'MINI_GOLF',
  MOTOCROSS = 'MOTOCROSS',
  MOUNTAINBOARDING = 'MOUNTAINBOARDING',
  MOVEMENT = 'MOVEMENT',
  MUSIC = 'MUSIC',
  NETBALL = 'NETBALL',
  NEWCOMB_BALL = 'NEWCOMB_BALL',
  NINJA = 'NINJA',
  OIL_WRESTLING = 'OIL_WRESTLING',
  ONE_ON_ONE_TRAINING = 'ONE_ON_ONE_TRAINING',
  OPEN_TRAINING = 'OPEN_TRAINING',
  OPEN_GYM = 'OPEN_GYM',
  PADEL = 'PADEL',
  PADDLEBOARDING = 'PADDLEBOARDING',
  PAINTBALL = 'PAINTBALL',
  PALETA_FRONTON = 'PALETA_FRONTON',
  PARACHUTING = 'PARACHUTING',
  PERSONAL_TRAINING = 'PERSONAL_TRAINING',
  PARAGLIDING = 'PARAGLIDING',
  PARAMOTORING = 'PARAMOTORING',
  PARKOUR = 'PARKOUR',
  PATO = 'PATO',
  PHYSIOTHERAPY = 'PHYSIOTHERAPY',
  PICKLEBALL = 'PICKLEBALL',
  PILATES = 'PILATES',
  PITCH_AND_PUTT = 'PITCH_AND_PUTT',
  POLE_DANCING = 'POLE_DANCING',
  POLO = 'POLO',
  POTTERY = 'POTTERY',
  POTTERY_PAINTING = 'POTTERY_PAINTING',
  POWERED_HANGLIDING = 'POWERED_HANGLIDING',
  QUIDDITCH = 'QUIDDITCH',
  RACING = 'RACING',
  RAFTING = 'RAFTING',
  RALLY_DRIVING = 'RALLY_DRIVING',
  RALLY_RACING = 'RALLY_RACING',
  RC_RACING = 'RC_RACING',
  RHYTHMIC_GYMNASTICS = 'RHYTHMIC_GYMNASTICS',
  RIVERBOARDING = 'RIVERBOARDING',
  RUNNING = 'RUNNING',
  ROCK_CLIMBING = 'ROCK_CLIMBING',
  ROLLERSKATING = 'ROLLERSKATING',
  ROUNDERS = 'ROUNDERS',
  ROWING = 'ROWING',
  RUGBY = 'RUGBY',
  SAILING = 'SAILING',
  SKELETON = 'SKELETON',
  SAMBO = 'SAMBO',
  SANDBOARDING = 'SANDBOARDING',
  SCOOTERING = 'SCOOTERING',
  SEGWAYING = 'SEGWAYING',
  SEPAK_TAKRAW = 'SEPAK_TAKRAW',
  SERVICES = 'SERVICES',
  SERVICE_INCALL = 'SERVICE_INCALL',
  SERVICE_OUTCALL = 'SERVICE_OUTCALL',
  SHOOTING = 'SHOOTING',
  SPIKELBALL = 'SPIKELBALL',
  SINGING = 'SINGING',
  SKIMBOARDING = 'SKIMBOARDING',
  SKIING = 'SKIING',
  SKYDIVING = 'SKYDIVING',
  SKYSURFING = 'SKYSURFING',
  SNOWBOARDING = 'SNOWBOARDING',
  SNOWKITING = 'SNOWKITING',
  SNOOKER = 'SNOOKER',
  SPEED_BOATING = 'SPEED_BOATING',
  SPINNING = 'SPINNING',
  SPRAY_TAN = 'SPRAY_TAN',
  STRENGTH = 'STRENGTH',
  SQUASH = 'SQUASH',
  STREETBOARDING = 'STREETBOARDING',
  SKATEBOARDING = 'SKATEBOARDING',
  STREETLUGE = 'STREETLUGE',
  SUMO_WRESTLING = 'SUMO_WRESTLING',
  SURFING = 'SURFING',
  SWIMMING = 'SWIMMING',
  TABLE_FOOTBALL = 'TABLE_FOOTBALL',
  TRACK_CYCLING = 'TRACK_CYCLING',
  TABATA = 'TABATA',
  TABLE_TENNIS = 'TABLE_TENNIS',
  TAEKWONDO = 'TAEKWONDO',
  TAI_CHI = 'TAI_CHI',
  TAP_DANCING = 'TAP_DANCING',
  TENNIS = 'TENNIS',
  TEMPO_STRENGTH = 'TEMPO_STRENGTH',
  TUBING = 'TUBING',
  TUG_OF_WAR = 'TUG_OF_WAR',
  THAI_BOXING = 'THAI_BOXING',
  TRAMPOLINING = 'TRAMPOLINING',
  TRAINING_DANCING = 'TRAINING_DANCING',
  ULTRALIGHT_AVIATION = 'ULTRALIGHT_AVIATION',
  VOLLEYBALL = 'VOLLEYBALL',
  WAKE_BOARDING = 'WAKE_BOARDING',
  WAKESURFING = 'WAKESURFING',
  WALKING = 'WALKING',
  WATSU = 'WATSU',
  WATER_POLO = 'WATER_POLO',
  WATER_VOLLEYBALL = 'WATER_VOLLEYBALL',
  WEIGHT_LIFTING = 'WEIGHT_LIFTING',
  WINDSURFING = 'WINDSURFING',
  WRESTLING = 'WRESTLING',
  YACHT_RACING = 'YACHT_RACING',
  YOGA = 'YOGA',
  YUKIGASSEN = 'YUKIGASSEN',
  ZIP_LINING = 'ZIP_LINING',
  TAG = 'TAG',
  ZUMBA = 'ZUMBA',
  DRONE_RACING = 'DRONE_RACING',
  MASSAGE = 'MASSAGE',
  SWEDISH = 'SWEDISH',
  THAI = 'THAI',
  HOT_STONE = 'HOT_STONE',
  AROMATHERAPY = 'AROMATHERAPY',
  DEEP_TISSUE = 'DEEP_TISSUE',
  SHIATSU = 'SHIATSU',
  SPORTS = 'SPORTS',
  ART = 'ART',
  MYOFASCIAL = 'MYOFASCIAL',
  PREGNANCY = 'PREGNANCY',
  MEDICAL = 'MEDICAL',
  CUPPING = 'CUPPING',
  POST_NATAL = 'POST_NATAL',
  CRANIOSACRAL = 'CRANIOSACRAL',
  INTRO_MASSAGE = 'INTRO_MASSAGE',
  TRIGGER_POINT = 'TRIGGER_POINT',
  ASHIATSU = 'ASHIATSU',
  BURMESE = 'BURMESE',
  BMS = 'BMS',
  AMATSU = 'AMATSU',
  LOMILOMI = 'LOMILOMI',
  ABHYANGA = 'ABHYANGA',
  PIZHICHIL = 'PIZHICHIL',
  UDVARTANA = 'UDVARTANA',
  GARSHANA = 'GARSHANA',
  SHIRODHARA = 'SHIRODHARA',
  ALL_CLASSES = 'ALL_CLASSES',
  FLEXIBILITY = 'FLEXIBILITY',
  STRETCHING = 'STRETCHING',
  CATWALKING = 'CATWALKING',
  FUSION = 'FUSION',
  ACCESS = 'ACCESS',
  BJJ_GI = 'BJJ_GI',
  BJJ_NO_GI = 'BJJ_NO_GI',
  BREATH_WORK = 'BREATH_WORK',
  BREATH_WORK_ICE_BATH = 'BREATH_WORK_ICE_BATH',
  ICE_BATH = 'ICE_BATH',
  OPEN_MAT = 'OPEN_MAT',
  TEACHER_TRAINING = 'TEACHER_TRAINIG',
  METCON = 'METCON',
  SPA = 'SPA',
  SPA_ACCESS = 'SPA_ACCESS',
}
