import {spawn} from 'redux-saga/effects'

import {authWatcherSaga} from '../services/auth/sagas'
import {bookingWatcherSaga} from '../services/booking/sagas'
import {globalWatcherSaga} from '../services/global/sagas'
import {paymentsWatcherSaga} from '../services/payments/sagas'
import {userWatcherSaga} from '../services/user/saga'

export function* rootSaga() {
  yield spawn(globalWatcherSaga)
  yield spawn(authWatcherSaga)
  yield spawn(userWatcherSaga)
  yield spawn(paymentsWatcherSaga)
  yield spawn(bookingWatcherSaga)
}
