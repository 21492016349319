import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'

export type GetVenueDisplaySettingsParams = {
  venueId?: string
}
export type GetVenueDisplaySettingsQueryParams = undefined

export type Entity = 'class' | 'justTheSpace' | 'service' | 'subscription' | 'teacher'
export type GetVenueDisplaySettingsQueryReturnType = {
  appLinks: {
    app_icon: string
    app_name: string
    ios: string
    android: string
    other: string
  }
  entitiesOrder?: Entity[]
  availableEntities?: Entity[]
}
export type GetVenueDisplaySettingsQuery = QueryMethod<
  GetVenueDisplaySettingsParams,
  GetVenueDisplaySettingsQueryParams,
  GetVenueDisplaySettingsQueryReturnType
>

export const makeGetVenueDisplaySettingsEndpoint = ({venueId}: GetVenueDisplaySettingsParams) =>
  `venues/${venueId}/settings/display`

export const getVenueDisplaySettingsQuery: GetVenueDisplaySettingsQuery = ({query, params}) => ({
  queryKey: ['getVenueDisplaySettingsQuery', query],
  cacheTime: 1000 * 300,
  queryFn: ({queryKey: [_, config]}) => {
    if (params?.venueId) {
      return API.get<GetVenueDisplaySettingsQueryParams, GetVenueDisplaySettingsQueryReturnType>(
        makeGetVenueDisplaySettingsEndpoint(params),
        {
          params: config,
        },
      ).then((res) => res.data)
    }
    return new Promise((_res, rej) => {
      rej('missing parameter')
    })
  },
})
