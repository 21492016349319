import {Box, Flex} from 'exsportia-components'
import {FC, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {Outlet, useParams} from 'react-router-dom'

import {VenueQueries} from '../api/venue'
import {GlobalService} from '../services/global'
import {PaymentProviderProvider} from '../services/payments/hooks/payment-provider-form.provider'
import {PreFooter} from './components/pre-footer'
import {Footer} from './footer'
import {Header} from './header'

export const Layout: FC = () => {
  const language = useSelector(GlobalService.selectors.language())
  const {venueId} = useParams<{venueId: string}>()
  const {data: venueData} = useQuery(VenueQueries.getVenueQuery({params: {id: venueId}}))
  const [bgImageURL, setBgImageURL] = useState<string | null>(null)
  useEffect(() => {
    if (venueData?.media) {
      setBgImageURL(venueData.media[venueData.thumbIndex || 0].secure_url || '')
    }
  }, [venueData])

  return useMemo(
    () => (
      <Flex
        position='relative'
        minHeight={'100dvh'}
        backgroundSize='cover'
        flexDirection={'column'}
        backgroundPosition='center'
        justifyContent={'space-between'}
        background={`url(${bgImageURL}) no-repeat fixed`}
      >
        <Box>
          <Header />
          <PaymentProviderProvider>
            <Outlet />
          </PaymentProviderProvider>
        </Box>
        <PreFooter />
        <Footer />
      </Flex>
    ),
    [language, venueData, bgImageURL],
  )
}
