import {API} from '../../networking/http'
import {MutationMethod} from '../helpers/types'


export type CalculatedBookingPriceObject = {
  type?: string
  image_uri?: string,
  expires_at?: number,
  authorize_uri?: string
  download_uri?: string,
}

export type CreateOpnChargeParams = undefined
export type CreateOpnChargeMutationParams = {
  token: string
  userId?: string | null,
  isWba: boolean
  venueId: string
  entityId: string
  totalPrice: number
  entity: string | null
}
export type CreateOpnChargeMutationReturnType = CalculatedBookingPriceObject
export type CreateOpnChargeMutation = MutationMethod<
  CreateOpnChargeParams,
  CreateOpnChargeMutationParams,
  CreateOpnChargeMutationReturnType
>

export const CreateOpnChargeEndpoint = 'payments/opn/charge'

export const createOpnChargeMutation: CreateOpnChargeMutation = () => ({
  mutationFn: (data) =>
    API.post<CreateOpnChargeMutationParams, CreateOpnChargeMutationReturnType>(
      CreateOpnChargeEndpoint,
      data,
    ).then((res) => res.data),
})
