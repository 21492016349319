export const TP_API_URL_TEST = 'https://eu-test.oppwa.com'
export const TP_API_URL_PROD = 'https://eu-prod.oppwa.com'

export type envDomainType = {
  URL: string
}

export type APIDomainsMapType = {
  development: envDomainType
  staging: envDomainType
  preprod: envDomainType
  production: envDomainType
}

export const TP_API_URL_MAP: APIDomainsMapType = {
  development: {
    URL: TP_API_URL_TEST,
  },
  staging: {
    URL: TP_API_URL_TEST,
  },
  preprod: {
    URL: TP_API_URL_TEST,
  },
  production: {
    URL: TP_API_URL_PROD,
  },
}

const env = process.env.REACT_APP_ENV ?? 'development'

// export const APIDomain = 'http://192.168.50.99:5001/exsportia-dev/us-central1/develop/'
// export const APIDomain = 'https://us-central1-exsportia-dev.cloudfunctions.net/develop/'
export const TP_API_URL = TP_API_URL_MAP[env].URL
