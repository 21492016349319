  import {Flex, Icon, Theme, Title} from 'exsportia-components'
import {FC, useMemo} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {Button} from '../../ui/components/button/button'
import {useLocale} from '../../utils/i18n'

export const PaymentFail: FC = () => {
  const {l} = useLocale()
  const params = useParams<{venueId: string}>()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const handleClickGoToVenue = () => {
    navigate(`/${params.venueId}`)
  }
  const queryData: {entity: string | null; entityId: string | null} = useMemo(() => {
    const entityId = searchParams.get('entityId')
    const entity = searchParams.get('entity')
    return {
      entity,
      entityId,
    }
  }, [searchParams])
  const handleClickGoToEntity = () => {
    if (!queryData.entity || !queryData.entityId) {
      return
    }
    navigate(`/clear-view/${params.venueId}/payment?entityId=${queryData.entityId}&entity=${queryData.entity}`)
  }
  return (
    <GridLayout
      header={
        <Flex p='8px' justifyContent='center'>
          <Title settings={{textAlign: 'center'}} text={'Payment Fail'} />
        </Flex>
      }
    >
      <Flex
        alignItems='center'
        flexDirection='column'
        gap={Theme?.spaces?.multiplier2x}
      >
        <Icon
          settings={{
            width: '120px',
            height: '120px',
            iconWidth: '120px',
            iconHeight: '120px',
            color: Theme.colors.error,
          }}
          icon={'blocked'}
        />
        {(queryData.entityId && queryData.entity) && <Button onClick={handleClickGoToEntity}>{l('tryAgain')}</Button>}
        {params.venueId && <Button type='secondary' onClick={handleClickGoToVenue}>{l('backToVenue')}</Button>}
      </Flex>
    </GridLayout>
  )
}
