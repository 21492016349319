import {Box, IconWithText} from 'exsportia-components'
import {FC, useMemo} from 'react'

import {BookingUsageDataType} from '../../api/booking/types'
import {getUsageString} from '../../helpers/data'
import {GradientLoader} from '../../ui/components/gradient-loader/gradient-loader'
import {useLocale} from '../../utils/i18n'

export type SubscriptionCardProps = {
  imageUrl: string | null
  name: string
  styleType?: 'default' | 'summary'
  isLoading?: boolean
  usage?: BookingUsageDataType
}

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  imageUrl,
  name,
  styleType = 'default',
  isLoading,
  usage,
}) => {
  const {l} = useLocale()
  const settingsMap = {
    default: {
      iconWidth: '48px',
      iconHeight: '32px',
      borderRadius: '4px',
    },
    summary: {
      iconWidth: '72px',
      iconHeight: '48px',
      borderRadius: '8px',
    },
  }
  const settingsToUse = settingsMap[styleType]
  const imageMarkup = useMemo(
    () =>
      isLoading ? (
        <GradientLoader height={settingsToUse.iconHeight} />
      ) : imageUrl ? (
        <Box
          width={settingsToUse.iconWidth}
          height={settingsToUse.iconHeight}
          borderRadius={settingsToUse.borderRadius}
          background={`url(${imageUrl}) center/cover`}
        />
      ) : null,
    [isLoading, imageUrl],
  )

  return (
    <IconWithText
      text={name}
      subText={getUsageString(l, usage)}
      settings={{icon: {width: settingsToUse.iconWidth, height: settingsToUse.iconHeight}}}
      icon={imageMarkup}
    />
  )
}
