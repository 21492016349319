import {LTRStickyStyles} from './constants'

export enum StickyStatesEnum {
  LEFT = 'LEFT',
  BOTH = 'BOTH',
  RIGHT = 'RIGHT',
  DEFAULT = 'DEFAULT',
}

export type TimeSlotStickyStyle = {
  thumb: (typeof LTRStickyStyles)[keyof typeof LTRStickyStyles]['thumb']
  container: (typeof LTRStickyStyles)[keyof typeof LTRStickyStyles]['container']
}

export type TimeSlotStickyStylesMap = {
  [key in StickyStatesEnum]: TimeSlotStickyStyle
}
