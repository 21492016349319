import {boolean, object, ref, string} from 'yup'

import {l} from './i18n'

// REGULAR EXPRESSIONS
export const NO_SPACES_RE = /^\S*$/
export const PHONE_RE = /^\+[\d]+$/
export const AT_LEAST_az = /^(?=.*[a-z])/
export const AT_LEAST_AZ = /^(?=.*[A-Z])/
export const AT_LEAST_09 = /^(?=.*[0-9])/
export const NUMBERS_ONLY_RE = /^[.0-9/-]+$/
export const NO_SPACES_AT_START_RE = /^[^-\s]/
export const NO_TWO_SPACES_RE = /^(?:(?!\s{2}).)*$/
export const LETTERS_ONLY_RE = /^[^0-9!@#$%*=+_{}~/]+$/
export const ONLY_ENGLISH_EMAIL = /^[A-Za-z0-9._%+\-@|{}!?#$&*=^/'"`]+$/y
export const AT_LEAST_SC = /^(?=.*[!?@#$§№%^.,&*(){}:;`'"<>|+±=~_\-[\]])/
export const ONLY_ENGLISH_PASSWORD = /^[A-Za-z0-9!?@#$§№%^.,&*(){}:;`'"<>|+±=~_\-[\]]+$/y

export const CONFIRMATION_VALIDATION = boolean().isTrue(l('required'))

export const DATE_OF_BIRTH_VALIDATION = object().required(l('required'))

export const USER_NAME_VALIDATION = string()
  .required(l('required'))
  .required('Required')
  .matches(NO_TWO_SPACES_RE, 'No multiple spaces are allowed')
  .matches(NO_SPACES_AT_START_RE, 'No spaces at start are allowed')
  .max(
    80,
    l('max_validation', {
      value: 80,
    }),
  )

export const PASSWORD_REPEAT_VALIDATION = string()
  .oneOf([ref('password')], l('passwords_must_match'))
  .required(l('required'))

export const PHONE_VALIDATION_NOT_REQUIRED = string()
  .min(
    6,
    l('min_validation', {
      value: 6,
    }),
  )
  .max(
    15,
    l('max_validation', {
      value: 15,
    }),
  )
  .matches(NO_SPACES_RE, l('no_spaces'))
  .matches(PHONE_RE, l('phone_validation'))

export const PHONE_VALIDATION = PHONE_VALIDATION_NOT_REQUIRED.required(l('required'))

export const EMAIL_VALIDATION = string()
  .lowercase()
  .required(l('required'))
  .min(
    6,
    l('min_validation', {
      value: 6,
    }),
  )
  .max(
    80,
    l('max_validation', {
      value: 80,
    }),
  )
  .matches(NO_SPACES_RE, l('no_spaces'))
  .matches(ONLY_ENGLISH_EMAIL, l('only_latin'))
  .email(l('email_validation'))

export const PASSWORD_VALIDATION = string()
  .min(6, l('password_validation_short', {value: 6}))
  .max(160, l('password_validation_long', {value: 160}))
  .matches(NO_SPACES_RE, l('password_validation_spaces_not_allowed'))
  .matches(AT_LEAST_az, l('password_validation_lowercase_missing'))
  .matches(AT_LEAST_AZ, l('password_validation_uppercase_missing'))
  .matches(AT_LEAST_09, l('password_validation_number_missing'))
  .matches(AT_LEAST_SC, l('password_validation_special_character_missing'))
  .required(l('required'))

export const SIGN_IN_PASSWORD_VALIDATION = string()
    .required(l('required'))
