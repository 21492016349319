import {API} from '../../networking/http'
import {CurrenciesEnum} from '../../pages/select-service/select-service-page'
import {BookingStateParticipant} from '../../services/booking/forms/types'
import {QueryMethod} from '../helpers/types'
// import {ServiceTypesEnum} from '../service/types'
import {DiscountType} from '../types'
import {CancelPolicyEnum, Equipment} from './types'

type ServiceBasedOption = {
  start: number
  end: number
  bookingType: 'SPORT' | 'TEACHER'
  areaId?: string
  sportId: string
  teacherId?: string
}

type ClassBasedOption = {
  bookingType: 'CLASS'
  slotId?: string | null
  classId: string | null
  sportType?: string
}

type Option = {
  venueId?: string
  userId?: string
  date?: string
  participants: BookingStateParticipant[]
  useForParticipants?: boolean
  duration?: number | string
  coverEquipment?: boolean
  timeSlot?: number
} & (ClassBasedOption | ServiceBasedOption)

export type SubscriptionStatus = string

export type LimitationCheckResultType = {
  minutes?: number
  entries?: number
  credits?: number
}
export type LimitationCheckResultTypeKey = keyof LimitationCheckResultType

export type CalculatedBookingPriceParticipantsType = (BookingStateParticipant & {
  limitationCheckResult?: {
    used: LimitationCheckResultType
    overdraft: LimitationCheckResultType
  }
  subscriptionStatus?: SubscriptionStatus
  membershipName: string | null
  activityGroupId: string | null
  membershipBookingId: string | null
  totalPrice: number
  discount: number
  discountType?: DiscountType
  needEquipment?: boolean
  equipmentsTotalPrice: number
})[]

export type CalculatedBookingPriceObject = {
  currency: CurrenciesEnum
  price: number
  equipments: Equipment[]
  numberOfSlots: number
  numberOfPeople: number
  equipmentsTotalPrice: number
  activity: string
  cancelPolicy?: CancelPolicyEnum | null
  duration: {
    minutes: number
    hours: number
  }
  discount: number
  participants: CalculatedBookingPriceParticipantsType
  activityTotalPrice: number
}

export type CalculateBookingPriceParams = undefined
export type CalculateBookingPriceQueryParams = {
  options: Option[] | null
}
export type CalculateBookingPriceQueryReturnType = CalculatedBookingPriceObject[]
export type CalculateBookingPriceQuery = QueryMethod<
  CalculateBookingPriceParams,
  CalculateBookingPriceQueryParams | null,
  CalculateBookingPriceQueryReturnType
>

export const calculateBookingPriceEndpoint = 'bookings/calculate/bookingPrice'

export const calculateBookingPriceQuery: CalculateBookingPriceQuery = ({query, ...rest}) => ({
  queryKey: ['calculateBookingPriceQuery'],
  queryFn: () =>
    API.post<CalculateBookingPriceQueryParams | null | undefined, CalculateBookingPriceQueryReturnType>(
      calculateBookingPriceEndpoint,
      {...query},
    ).then((res) => res.data),
  ...rest,
})
