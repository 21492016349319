import {Formik} from 'formik'
import {FC, PropsWithChildren, useCallback, useMemo} from 'react'
import {object} from 'yup'

import {Subscription} from '../../../api/subscriptions/types'
import {USER_NAME_VALIDATION} from '../../../utils/validation'

export type EditParticipantFormProps = {
  initialName: string
  handleSubmit: (values: {name: string; subscription?: Subscription, memberParticipantIndex: number | null, avatar: string | null}) => void
  subscriptions?: Subscription[]
  initialSubscriptionBookingId?: string
  initialMemberParticipantIndex: number | null
}

export type EditParticipantFormType = {
  name: string
  subscription?: Subscription
  membershipId?: string | null
  memberParticipantIndex: number | null
  avatar: string | null
}

export const EditParticipantForm: FC<PropsWithChildren<EditParticipantFormProps>> = ({
  handleSubmit,
  initialName,
  children,
  initialSubscriptionBookingId,
  subscriptions,
  initialMemberParticipantIndex = null,
}) => {
  const initialValues = useMemo(
    () => ({
      avatar: null,
      name: initialName,
      subscription: subscriptions?.find((el) => el.membershipBookingId === initialSubscriptionBookingId),
      memberParticipantIndex: initialMemberParticipantIndex
    }),
    [initialName, initialSubscriptionBookingId],
  )

  const onSubmit = useCallback(({name, subscription, memberParticipantIndex, avatar}: EditParticipantFormType) => {
    handleSubmit({name, subscription, memberParticipantIndex, avatar})
  }, [])
  const validationSchema = useMemo(
    () =>
      object().shape({
        name: USER_NAME_VALIDATION,
      }),
    [],
  )
  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {children}
      </Formik>
    </>
  )
}
