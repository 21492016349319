import {Box, Divider, Flex, Theme} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'

import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {useAuthState} from '../../services/auth/hooks'
import {useBookingLoader} from '../../services/booking/hooks'
import {PaymentService} from '../../services/payments'
import {UserService} from '../../services/user'
import {Button} from '../../ui/components/button/button'
import {RadioButton} from '../../ui/components/radio-button'
import {auth} from '../../utils/firebase'
import {useLocale} from '../../utils/i18n'
import {ClassSummarySection} from '../booking-summary/class-summary-section'
import {ServiceSummarySection} from '../booking-summary/service-summary-section'
import {BookingCommonType} from '../booking-summary/user-info-section'
import {UserPaymentsMethods} from './components/user-payments-type-modal'

const paymentTypes = ['payOnLocation', 'payNow', 'oneTimePayment']

const Header: FC = () => {
  const {l} = useLocale()
  return <SimpleGridLayoutHeader title={l('paymentMethod')} />
}

type PaymentEntityType = 'class' | 'service'

type UserPaymentTypeModal = {
  type: PaymentEntityType
}

export const UserPaymentTypeModal: FC<UserPaymentTypeModal> = ({type}) => {
  const {l} = useLocale()
  const navigate = useNavigate()
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const {isLoggedIn} = useAuthState()
  const [loggedInState, setLoggedInState] = useState(isLoggedIn)
  const [paymentType, setPaymentType] = useState(paymentTypes[0])
  const params = useParams<{venueId: string}>()
  const {data: terminal} = useQuery(PaymentService.queries.getTerminalQuery({params: {venueId: params.venueId}}))
  const {data, remove} = useQuery(UserService.queries.getUserPaymentMethodsQuery({params: {venueId: params.venueId}}))
  const {isLoading: isBookingLoading} = useBookingLoader()
  const {data: user} = useQuery(UserService.queries.GetUserQuery({params: {id: auth.currentUser?.uid}}))
  const {submitForm, setValues, values: bookingState} = useFormikContext<BookingCommonType>()
  const {service, class: cls} = bookingState
  const {paymentOnArrival = true, paymentOnExsportia = false} = service || cls || {}
  useEffect(() => {
    remove()
  }, [user])
  useEffect(() => {
    if ((loggedInState && !isLoggedIn)) {
      navigate(-2)
      setValues({...bookingState, participants: []})
    }
    setLoggedInState(isLoggedIn)
  }, [isLoggedIn, bookingState])
  useEffect(() => {
    if (!paymentOnArrival) {
      setPaymentType(paymentTypes[1])
    }
  }, [paymentOnArrival])
  useEffect(() => {
    if (paymentType !== paymentTypes[1]) {
      setPaymentMethod(null)
    }
  }, [paymentType])
  useEffect(() => {
    if (data?.paymentMethods && data.paymentMethods.length !== 0) {
      setPaymentType(paymentTypes[1])
      setPaymentMethod(data.paymentMethods[0].uid)
    }
  }, [data?.paymentMethods])
  const handleSubmitWithPayment = useCallback(
    ({paymentMethodId}: {paymentMethodId: string | null}) => {
      setValues({...bookingState, paymentMethodId, paymentType: 'payNow'})
      submitForm()
    },
    [bookingState, setValues, submitForm],
  )

  useEffect(() => {
    if (!user?.uid && !paymentOnArrival) {
      navigate(-1)
    } else if (!user?.uid && paymentOnArrival) {
      setPaymentType(paymentTypes[0])
    }
  }, [user, paymentOnArrival, navigate])

  const handleClickButton = useCallback(() => {
    if (paymentType === paymentTypes[1]) {
      return handleSubmitWithPayment({paymentMethodId: paymentMethod})
    } else if (paymentType === paymentTypes[2]) {
      setValues({...bookingState, paymentType: 'oneTimePayment'})
      submitForm()
      return
    }
    setValues({...bookingState, paymentType: 'payOnLocation'})
    submitForm()
  }, [submitForm, paymentType, paymentMethod, bookingState, handleSubmitWithPayment])
  const submitButtonMarkup = useMemo(() => {
    const text = paymentType === paymentTypes[0]
      ? l('book')
      : paymentType === paymentTypes[2]
        ? l('continue')
        : l('pay')
    return (
      <Button
        disabled={isBookingLoading}
        onClick={handleClickButton}
        isLoading={isLoading || isBookingLoading}
      >
        {text}
      </Button>
    )
  }, [handleClickButton, isLoading, paymentType, isBookingLoading])

  return (
    <GridLayout header={<Header />}>
      <Box>
        <Box mb='16px'>
          <Flex alignItems='center' height={Theme?.spaces?.multiplier * 6}>
            <RadioButton
              text={l('pay_on_location', {caseAction: 'capitalize'})}
              disabled={!paymentOnArrival || isLoading || isBookingLoading}
              checked={paymentType === paymentTypes[0]}
              onChange={() => setPaymentType(paymentTypes[0])}
            />
          </Flex>
          <Divider />
          {
            paymentOnExsportia && terminal?.oneTimePayment && (
            <Flex alignItems='center' height={Theme?.spaces?.multiplier * 6}>
              <RadioButton
                disabled={isLoading || isBookingLoading}
                checked={paymentType === paymentTypes[2]}
                onChange={() => setPaymentType(paymentTypes[2])}
                text={l('exsportiaPay', {caseAction: 'capitalize'})}
              />
            </Flex>
            )
          }
          <Divider />
          {
            paymentOnExsportia && terminal && (
            <>
              <Box>
                <UserPaymentsMethods
                  isLoading={isLoading || isBookingLoading}
                  venueId={params.venueId}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={(paymentMethodId: string) => {
                    setPaymentType(paymentTypes[1])
                    setPaymentMethod(paymentMethodId)
                  }} />
              </Box>
            </>
          )
        }
        </Box>
        {submitButtonMarkup}
      </Box>
      <Box>
        {type === 'class' ? (
          <ClassSummarySection setIsLoading={setIsLoading} />
        ) : (
          <ServiceSummarySection setIsLoading={setIsLoading} />
        )}
      </Box>
    </GridLayout>
  )
}
