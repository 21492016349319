import {StyleCommonType, StyleSizeSpaceType} from 'exsportia-components/dist/types'

export type DividerWithContentSettingsProps = {
  display?: StyleCommonType
  width?: StyleSizeSpaceType
  height?: StyleSizeSpaceType
  alignItems?: StyleCommonType
  contentSettings?: {
    p?: StyleSizeSpaceType
    top?: StyleSizeSpaceType
    left?: StyleSizeSpaceType
    width?: StyleSizeSpaceType
    border?: StyleSizeSpaceType
    position?: StyleSizeSpaceType
    transform?: StyleSizeSpaceType
    borderRadius?: StyleSizeSpaceType
    backgroundColor?: StyleSizeSpaceType
  }
}

export const defaultSettings: DividerWithContentSettingsProps = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: 'max-content',
  contentSettings: {
    width: 'max-content',
    border: 'default-border',
    borderRadius: '32px',
    p: '4px 16px',
  },
}

export const typeStylesMap = {default: defaultSettings}

export const additionalStylesMap = {}
