import {A, Box, Flex, Image, Logo, P, Tag, Theme} from 'exsportia-components'
import React, {useCallback, useEffect, useState} from 'react'

import {ExsportiaCompanyDetails} from '../../components/exsportia-company-details/exsportia-company-details'
import {useAuthState} from '../../services/auth/hooks'
import {useAppDownloadData} from '../../services/venue/hooks'
import {Button} from '../../ui/components/button/button'
import {InfoMessage} from '../../ui/components/info-message/info-message'
import {l} from '../../utils/i18n'

export const AccountRemove: React.FC = () => {
  const {isLoggedIn, signIn, removeAccount} = useAuthState()
  const [removeAccountPressed, setRemoveAccountPressed] = useState(false)
  const {appIcon, downloadLink, isLoading, appName} = useAppDownloadData()
  const handleDownloadApp = useCallback(() => {
    // eslint-disable-next-line no-alert
    window.open(downloadLink, '_blank')
  }, [downloadLink, isLoading, appIcon])

  useEffect(() => {
    setRemoveAccountPressed(false)
  }, [isLoggedIn])

  const handleSignIn = useCallback(() => {
    signIn()
  }, [signIn])

  const handleRemoveAccount = () => {
    setRemoveAccountPressed(true)
    removeAccount()
  }


  return (
    <Flex
      justifyContent='center'
      pt='160px'
    >
      <Flex
        flexDirection='column'
        gap='16px'
        bg={'white'}
        borderRadius={'24px'}
        padding={'16px'}
        maxWidth={['90%', '600px', null, null]}
      >
        <Flex
          width={'100%'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Flex
            alignItems={'center'}
            gap={'16px'}
          >
            <Box
              onClick={handleDownloadApp}
              width={'56px'}
              height={'56px'}
            >
              <Image
                src={appIcon}
                width={'56px'}
                height={'56px'}
                borderRadius={'14px'}
              />
            </Box>
            <P
              fontSize={'h1'}
              fontWeight={'bold'}
            >
              {appName || 'Air Gym'}
            </P>
          </Flex>
          <Flex
            alignItems={'center'}
            justifyContend={'flex-end'}
          >
            <P
              fontSize={'h2'}
              fontWeight={'bold'}
            >
              by
            </P>
            <Box mt={'6px'} ml={'2px'}>
              <Logo bigLogo={Theme.logos.logoFull} />
            </Box>
          </Flex>
        </Flex>
        <P
          mt={'16px'}
          fontSize={'h1'}
          fontWeight={'bold'}
        >
            {l('removeAccount')}
        </P>
        <InfoMessage
          preset='error'
          text={l('removeAccountWarning')}
        />
        <P>{l('pleaseSignInFirst')}</P>
        <Flex maxWidth='300px' alignItems={'center'} gap={'16px'}>
          <Tag
            text={`${l('step')} 1`}
            styleType={'displayValue'}
            settings={{
              iconWithText: {text: {color: 'white'}},
              bg: isLoggedIn ? 'success' : 'icon-secondary',
              height: '30px',
              width: '90px',
            }}
          />
          <Button onClick={handleSignIn}>{l('signin')}</Button>
        </Flex>
        <Box maxWidth='300px'>
          <Flex maxWidth='300px' alignItems={'center'} gap={'16px'}>
            <Tag
              text={`${l('step')} 2`}
              styleType={'displayValue'}
              settings={{
                iconWithText: {text: {color: 'white'}},
                bg: removeAccountPressed ? 'success' : 'icon-secondary',
                height: '30px',
                width: '90px',
              }}
            />
            <Button
              onClick={handleRemoveAccount}
              disabled={!isLoggedIn}
            >
              {l('removeAccount')}
            </Button>
          </Flex>
        </Box>
        <Box mt='32px'>
          <P>{l('needHelp')}</P>
          <A color={'primary'} href='mailto:support@exsportia.com'>
            {l('request_account_remove')}
          </A>
        </Box>
        <Box mt='32px'>
          <ExsportiaCompanyDetails />
        </Box>
      </Flex>
    </Flex>
  )
}
