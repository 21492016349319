import {Box, Flex, Label, Title} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {FC, useCallback, useMemo, useState} from 'react'

import {MemberParticipantType} from '../../../api/member/types'
import {Subscription} from '../../../api/subscriptions/types'
import {SubscriptionCard} from '../../../components/subscription-card/subscription-card'
import {EditParticipantFormType} from '../../../services/booking/forms/edit-participant-form'
import {Button} from '../../../ui/components/button/button'
import {Input} from '../../../ui/components/input/input'
import {RadioButton} from '../../../ui/components/radio-button'
import {useLocale} from '../../../utils/i18n'
import {isNotNilAndNotEmpty} from '../../../utils/logic'
import {NO_USER_PHOTO} from '../../../utils/remote-content'
import {MemberParticipant} from './add-participant-modal'

export const EditParticipantModal: FC<{
  subscriptions?: (Subscription | undefined)[]
  memberParticipants?: MemberParticipantType[]
  initialMemberParticipantIndex: number | null
  omittedMemberParticipants: number[]
  isLoggedIn: boolean
  allowedSubscriptionsList?: (string | null)[]
  anySubscriptions?: boolean
}> = ({
  subscriptions,
  memberParticipants = [],
  initialMemberParticipantIndex,
  omittedMemberParticipants = [],
  isLoggedIn,
  allowedSubscriptionsList = [],
  anySubscriptions = true,
}) => {
  const {l} = useLocale()
  const {handleSubmit, values, setValues} = useFormikContext<EditParticipantFormType>()
  const [selectedMemberParticipantIndex, setSelectedMemberParticipantIndex] = useState<number | null>(
    isLoggedIn ? initialMemberParticipantIndex : null,
  )
  const [selectedSubscriptionIndex, setSelectedSubscriptionIndex] = useState<number | undefined>(
    subscriptions?.findIndex((el) => el?.membershipId === values.subscription?.membershipId),
  )
  const subscriptionsOptions = useMemo(
    () =>
      subscriptions
        ? [
            ...subscriptions,
            {
              uid: null,
              membershipId: null,
              membershipName: l('no_subscription', {caseAction: 'capitalize'}),
              cancelPolicy: null,
              disabled: isNotNilAndNotEmpty(allowedSubscriptionsList) && !anySubscriptions,
              logo: {
                secure_url: null,
              },
            },
          ]
        : [],
    [subscriptions, anySubscriptions],
  )
  const onSubmit = useCallback(() => {
    const valuesToSave = {
      ...values,
      name:
        selectedMemberParticipantIndex === null ? values.name : memberParticipants[selectedMemberParticipantIndex].name,
      avatar: selectedMemberParticipantIndex === null ? values.avatar : null,
      membershipId: selectedSubscriptionIndex ? subscriptionsOptions[selectedSubscriptionIndex]?.uid : null,
      memberParticipantIndex: selectedMemberParticipantIndex,
      subscription:
        selectedSubscriptionIndex !== undefined && selectedSubscriptionIndex !== subscriptionsOptions.length - 1
          ? subscriptionsOptions[selectedSubscriptionIndex]
          : null,
    }
    setValues(valuesToSave as EditParticipantFormType)
    handleSubmit()
  }, [setValues, values, subscriptionsOptions, selectedSubscriptionIndex])
  const onParticipantChange = useCallback(
    (index: number) => {
      setSelectedMemberParticipantIndex(index === memberParticipants.length ? null : index)
      setValues({
        ...values,
        name:
          selectedMemberParticipantIndex === null
            ? values.name
            : memberParticipants[selectedMemberParticipantIndex].name,
        avatar:
          selectedMemberParticipantIndex === null
            ? NO_USER_PHOTO
            : memberParticipants[selectedMemberParticipantIndex].avatar?.secure_url || NO_USER_PHOTO,
      })
    },
    [selectedMemberParticipantIndex, values, setValues],
  )

  return (
    <Box pb={'16px'}>
      {isLoggedIn && (
        <Title
          text={l('selectParticipant')}
          styleType='h3'
          settings={{my: '16px'}}
        />
      )}
      <Flex
        display={isLoggedIn ? 'flex' : 'none'}
        flexDirection='column'
        mb='16px'
      >
        {[...memberParticipants, {name: l('other'), avatar: {secure_url: NO_USER_PHOTO}}].map(
          ({name, avatar}, index) => (
            <Flex
              key={index}
              mt='8px'
              display={omittedMemberParticipants.some((el) => el === index) ? 'none' : 'flex'}
            >
              <RadioButton
                onChange={() => onParticipantChange(index)}
                checked={
                  selectedMemberParticipantIndex === index ||
                  (index === memberParticipants.length && selectedMemberParticipantIndex === null)
                }
                text={
                  <Flex cursor='pointer'>
                    <MemberParticipant
                      name={name}
                      avatar={avatar}
                    />
                  </Flex>
                }
              />
            </Flex>
          ),
        )}
      </Flex>
      <Flex
        display={selectedMemberParticipantIndex === null ? 'flex' : 'none'}
        flexDirection='column'
      >
        <Label>{l('name')}</Label>
        <Input id='name' />
      </Flex>
      {isNotNilAndNotEmpty(subscriptions) && (
        <Flex
          flexDirection='column'
          mt='16px'
          mb='32px'
          gap='8px'
          maxWidth={['300px', '600px']}
        >
          <Title
            styleType='h3'
            text={l('selectSubscription')}
          />
          {subscriptionsOptions.map((subscription, index) =>
            subscription ? (
              <RadioButton
                onChange={() => setSelectedSubscriptionIndex(index)}
                checked={
                  index === selectedSubscriptionIndex ||
                  (index === subscriptionsOptions.length - 1 && selectedSubscriptionIndex === -1)
                }
                disabled={
                  isNotNilAndNotEmpty(allowedSubscriptionsList) &&
                  !allowedSubscriptionsList.includes(subscription.membershipId) &&
                  !anySubscriptions
                }
              >
                <SubscriptionCard
                  name={subscription.membershipName}
                  imageUrl={subscription.logo.secure_url}
                />
              </RadioButton>
            ) : null,
          )}
        </Flex>
      )}
      <Button onClick={onSubmit}>{l('save')}</Button>
    </Box>
  )
}
