import {Box, IconWithText, Text} from 'exsportia-components'
import {IconWithTextProps} from 'exsportia-components/dist/components/molecules/icon-with-text'
import {IconType, TextType} from 'exsportia-components/dist/types'
import React, {memo} from 'react'

import {TranslatorScope} from '../../../locale/types'
import {useLocale} from '../../../utils/i18n'
import {SessionAvailabilitySubtitle} from '../../session-availability-subtitle/session-availability-subtitle'
import {defaultSettings} from '../settings'

type Props = {
  instructorName: TextType
  instructorImage?: IconType
  availableSpots: number
  iconWithTextAdditionalStyles: IconWithTextProps['additionalStyles']
  disabledText?: TranslatorScope
}

const notificationTextSettings = {
  fontSize: 'label',
  lineHeight: '20px',
  color: 'notify',
}

export const InstructorSection = memo<Props>(
  ({instructorName, instructorImage, availableSpots, iconWithTextAdditionalStyles, disabledText}) => {
    const {l} = useLocale()
    return instructorName && instructorImage ? (
      <IconWithText
        icon={
          <Box
            backgroundSize='cover'
            height='32px'
            width='32px'
            background={`url(${instructorImage}) no-repeat center`}
          />
        }
        title={instructorName}
        subText={
          disabledText ? (
            <Text
              text={l(disabledText, {caseAction: 'capitalize'})}
              settings={notificationTextSettings}
            />
          ) : (
            <SessionAvailabilitySubtitle availableSpots={availableSpots} />
          )
        }
        settings={defaultSettings.instructor}
        additionalStyles={iconWithTextAdditionalStyles}
      />
    ) : disabledText ? (
      <Text
        text={l(disabledText, {caseAction: 'capitalize'})}
        settings={notificationTextSettings}
      />
    ) : (
      <SessionAvailabilitySubtitle availableSpots={availableSpots} />
    )
  },
)
