import {Box, Flex, P} from 'exsportia-components'
import {FC, PropsWithChildren} from 'react'

import {theme} from '../../../theme/theme'

type Props = {
  label: string
}
export const LabeledValueRow: FC<PropsWithChildren<Props>> = ({label, children}) => {
  return (
    <Flex
      py={theme.space.multiplier0_5x}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <P
        color={'text-secondary'}
        fontSize={'tag'}
      >
        {label}
      </P>
      <Box>{children}</Box>
    </Flex>
  )
}
