import {AuthMutations, AuthQueries} from '../../api/auth'
import {
  addLeadRequestAction,
  basicActions,
  checkEmailRequestAction,
  recoverPasswordRequestAction,
  removeAccountRequestAction,
  signInRequestAction,
  signOutRequestAction,
  signUpRequestAction,
} from './actions'
import {basicSelectors} from './selectors'

export const AuthService = {
  actions: {
    ...basicActions,
    checkEmailRequestAction,
    signUpRequestAction,
    signInRequestAction,
    signOutRequestAction,
    removeAccountRequestAction,
    recoverPasswordRequestAction,
    addLeadRequestAction,
  },
  selectors: {...basicSelectors},
  queries: AuthQueries,
  mutations: AuthMutations,
} as const
