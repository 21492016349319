import {Divider, Flex} from 'exsportia-components'
import React, {FC, useMemo} from 'react'

import {defaultSettings} from './settings'

export type DividerWithContentProps = {
  content?: Element | React.ReactNode | number | string
}

export const DividerWithContent: FC<DividerWithContentProps> = ({content}) => {
  const contentMarkup = useMemo(
    () =>
      content ? (
        <Flex
          p={defaultSettings.contentSettings?.p}
          width={defaultSettings.contentSettings?.width}
          border={defaultSettings.contentSettings?.border}
          minWidth={defaultSettings.contentSettings?.width}
          borderRadius={defaultSettings.contentSettings?.borderRadius}
        >
          {content}
        </Flex>
      ) : null,
    [content],
  )

  return (
    <Flex
      width={defaultSettings.width}
      height={defaultSettings.height}
      display={defaultSettings.display}
      alignItems={defaultSettings.alignItems}
    >
      <Divider />
      {contentMarkup}
      <Divider />
    </Flex>
  )
}
