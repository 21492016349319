import {Box, Flex, Image, Span} from 'exsportia-components'
import {FC, useCallback, useState} from 'react'

import {detectPlatform} from '../../helpers/other'
import {TEMP_BANNED_APP, useAppDownloadData} from '../../services/venue/hooks'
import {Button} from '../../ui/components/button/button'
import {GradientLoader} from '../../ui/components/gradient-loader/gradient-loader'
import {useLocale} from '../../utils/i18n'
import {setItemToLocalStorage} from '../../utils/local-storage'

export const DownloadAppHeader: FC = () => {
  const platform = detectPlatform()
  const {l} = useLocale()
  const {downloadLink, appIcon, appName, isLoading} = useAppDownloadData()
  const [isClosed, setIsClosed] = useState<boolean>(false)
  const handleDownloadApp = useCallback(() => {
    // eslint-disable-next-line no-alert
    window.open(downloadLink, '_blank')
  }, [downloadLink, isLoading, appIcon])
  const closeDownloadHeader = useCallback(() => {
    setIsClosed(true)
    setItemToLocalStorage('isDownloadHeaderClosed', 'true')
  }, [])
  if (
    (platform !== 'android' && platform !== 'ios')
    || isClosed
    || appName === TEMP_BANNED_APP
    || (!isLoading && !downloadLink)
  ) {
    return null
  }
  return (
    <Flex
      bg='#FFFFFF'
      p='8px 8px 8px 0'
      alignItems={'center'}
    >
      <Button
        onClick={closeDownloadHeader}
        type='icon'
        icon='close'
      />
      <Flex
        flexGrow={1}
        gap={'8px'}
        mr={'8px'}
        alignItems={'center'}
      >
      {
        isLoading
          ? (
            <GradientLoader
              height={'56px'}
            />
          )
          : (
            <>
              <Box
                width={'56px'}
                height={'56px'}
              >
                <Image
                  src={appIcon}
                  width={'56px'}
                  height={'56px'}
                  borderRadius={'14px'}
                />
              </Box>
              <Flex
                flexDirection={'column'}
                justifyContent={'center'}
              >
                <Span
                  cursor={'pointer'}
                  fontWeight={'bold'}
                  variant={'body'}
                >
                  {l('get_the_app', {caseAction: 'capitalize'})}
                </Span>
                <Span
                  cursor={'pointer'}
                  color={'gray'}
                  variant={'footnote'}
                >
                  {l('access_all_features', {caseAction: 'capitalize'})}
                </Span>
              </Flex>
            </>
          )
      }
      </Flex>
      <Button
        isLoading={isLoading}
        type={'primary_green'}
        onClick={handleDownloadApp}
      >
        {l('use_app', {caseAction: 'capitalize'})}
      </Button>
    </Flex>
  )
}
