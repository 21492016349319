import {Box, Flex, Icon, Text} from 'exsportia-components'
import {Formik} from 'formik'
import {FC, useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {object} from 'yup'

import {Button} from '../../../ui/components/button/button'
import {Input} from '../../../ui/components/input/input'
import {useLocale} from '../../../utils/i18n'
import {isNotNilAndNotEmpty} from '../../../utils/logic'
import {PASSWORD_REPEAT_VALIDATION, PASSWORD_VALIDATION} from '../../../utils/validation'
import {useAppLoading} from '../../global/hooks'
import {resetPasswordConfirmRequestAction} from '../actions'
import {OobCode} from '../types'

export type ResetPasswordFormType = {
  password: string
  passwordCheck: string
}

const initialValues: ResetPasswordFormType = {
  password: '',
  passwordCheck: '',
}

const validationSchema = object().shape({
  password: PASSWORD_VALIDATION,
  passwordCheck: PASSWORD_REPEAT_VALIDATION,
})

export const ResetPasswordForm: FC<{oobCode: OobCode}> = ({oobCode}) => {
  const dispatch = useDispatch()
  const {l} = useLocale()
  const {isLoading} = useAppLoading()
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isSomethingWrong, setIsSomethingWrong] = useState<boolean>(false)
  const onSubmit = useCallback(({password}: ResetPasswordFormType) => {
    dispatch(
      resetPasswordConfirmRequestAction({
        oobCode,
        password,
        callback: () => setIsSuccess(true),
        onError: () => setIsSomethingWrong(true),
      }),
    )
  }, [])
  if (isSuccess) {
    return (
      <>
        <Flex justifyContent='center'>
          <Icon
            settings={{
              color: '#0DC268',
              width: '120px',
              height: '120px',
              iconHeight: '120px',
              iconWidth: '120px',
            }}
            icon={'doneIllustration'}
          />
        </Flex>
        <Text
          settings={{color: '#0DC268', textAlign: 'center'}}
          text={l('done')}
        />
      </>
    )
  }
  return (
    <>
      {isSomethingWrong && <Text settings={{fontSize: '18px', color: '#ED0A34', textAlign: 'center'}} text={l('expired')}/>}
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({handleSubmit, errors}) => (
          <Box pb='24px'>
            <Box>
              <Input
                id={'password'}
                type='password'
                label={l('newPassword', {caseAction: 'capitalize'})}
              />
            </Box>
            <Box>
              <Input
                id={'passwordCheck'}
                type='password'
                label={l('repeatNewPassword', {caseAction: 'capitalize'})}
              />
            </Box>
            <Button
              isLoading={isLoading}
              disabled={isNotNilAndNotEmpty(errors)}
              onClick={() => handleSubmit()}
            >
              {l('reset')}
            </Button>
          </Box>
        )}
      </Formik>
    </>
  )
}
