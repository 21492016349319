import {showMessage} from 'exsportia-components'
import {call, takeLeading} from 'redux-saga/effects'

import {APIDomain} from '../../networking/constants'
import {sendRequest} from '../../utils/axios'
import {resType} from '../user/saga'
import {
  cancelBooking,
  CancelBookingPayloadType,
  checkClassPasscode,
  CheckClassPasscodePayloadType,
  checkServicePasscode,
  CheckServicePasscodePayloadType,
} from './actions'

export function* checkClassPasscodeRequest(payload: CheckClassPasscodePayloadType) {
  const {classId, code, callback, rejectCallback} = payload
  try {
    const endpoint = `${APIDomain}classes/private`
    const res: resType = yield call<any>(sendRequest, 'post', endpoint, {classId, password: code})
    if (res.status === 200 && typeof callback === 'function') {
      callback()
    } else {
      if (typeof rejectCallback === 'function') {
        rejectCallback()
      }
    }
  } catch (error: any) {
    if (typeof rejectCallback === 'function') {
      rejectCallback()
    }
    console.log(error)
  }
}

export function* checkServicePasscodeRequest(payload: CheckServicePasscodePayloadType) {
  const {sportId, password, callback, rejectCallback} = payload
  try {
    const endpoint = `${APIDomain}areas/private`
    const res: resType = yield call<any>(sendRequest, 'post', endpoint, {sportId, password})
    if (res.status === 200 && typeof callback === 'function') {
      callback()
    } else {
      if (typeof rejectCallback === 'function') {
        rejectCallback()
      }
    }
  } catch (error: any) {
    if (typeof rejectCallback === 'function') {
      rejectCallback()
    }
    console.log(error)
  }
}

export function* cancelBookingRequest(payload: CancelBookingPayloadType) {
  const {bookingId, callback, rejectCallback, l, isPaid} = payload
  try {
    const endpoint = `${APIDomain}bookings/${bookingId}/cancel`
    const res: resType = yield call<any>(sendRequest, isPaid ? 'put' : 'post', endpoint)
    if (res.status === 200) {
      showMessage(l('cancelled', {caseAction: 'capitalize'}), 'success')
    }
    if (typeof callback === 'function') {
      callback()
    }
  } catch (error: any) {
    if (error?.response?.status === 400) {
      showMessage(error?.response?.data?.message || l('something_went_wrong'), 'error')
    } else {
      showMessage(l('something_went_wrong', {caseAction: 'capitalize'}), 'error')
      console.log(error)
    }
    if (typeof rejectCallback === 'function') {
      rejectCallback()
    }
  }
}

export function* bookingWatcherSaga() {
  yield takeLeading(checkClassPasscode, function* (action) {
    yield checkClassPasscodeRequest(action.payload)
  }),
    yield takeLeading(checkServicePasscode, function* (action) {
      yield checkServicePasscodeRequest(action.payload)
    }),
    yield takeLeading(cancelBooking, function* (action) {
      yield cancelBookingRequest(action.payload)
    })
}
