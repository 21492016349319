import {Box, Flex, Label, Span} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'

import {Equipment} from '../../api/booking/types'
import {ClassQueries} from '../../api/class'
import {MemberQueries} from '../../api/member'
import {MemberParticipantType} from '../../api/member/types'
import {SubscriptionsQueries} from '../../api/subscriptions'
import {Subscription} from '../../api/subscriptions/types'
import {VenueQueries} from '../../api/venue'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {useAuthState} from '../../services/auth/hooks'
import {CreateServiceBookingFormType} from '../../services/booking/forms/create-service-booking-form'
import {EditParticipantForm, EditParticipantFormProps} from '../../services/booking/forms/edit-participant-form'
import {BookingStateParticipant} from '../../services/booking/forms/types'
import {UserService} from '../../services/user'
import {Button} from '../../ui/components/button/button'
import {ExsportiaIcon} from '../../ui/components/exsportia-icon/exsportia-icon'
import {ScrollableWrapper} from '../../ui/components/scrollable-wrapper/scrollable-wrapper'
import {auth} from '../../utils/firebase'
import {useLocale} from '../../utils/i18n'
import {isNotNilAndNotEmpty} from '../../utils/logic'
import {useModalActions} from '../../utils/modals'
import {NO_USER_PHOTO} from '../../utils/remote-content'
import {EditEquipmentsModal} from './components/edit-equipments-modal'
import {EditParticipantModal} from './components/edit-participant-modal'
import {Participant} from './components/participant'
import {createBookingStateParticipant} from './helpers'

const Header: FC = () => {
  const {l} = useLocale()
  return <SimpleGridLayoutHeader title={l('participants')} />
}

const bookingTypeMap = {
  service: 'SPORT',
  teacher: 'TEACHER',
  class: 'CLASS',
  space: 'SPORT',
}

// eslint-disable-next-line max-lines-per-function
export const SetParticipantsPage: FC = () => {
  const navigate = useNavigate()
  const {l} = useLocale()
  const {openModal, closeModal} = useModalActions()
  const params = useParams<{venueId: string}>()
  const {data: venueData} = useQuery(VenueQueries.getVenueQuery({params: {id: params.venueId}}))
  const {values, setValues, validateForm} = useFormikContext<
    CreateServiceBookingFormType & {
      classEquipments?: Equipment[]
      classId?: string
      teacherId?: string
      duration?: number | string
      sessionId?: string
      sportType?: string
      coverEquipment?: boolean
    }
  >()
  const {
    minParticipants,
    maxParticipants,
    service,
    participants: prevParticipants,
    classEquipments,
    bookingType,
    useForParticipants,
  } = values
  const {data: classData, isLoading: isClassDataLoading} = useQuery(
    ClassQueries.getClassQuery({params: {id: values.classId}}),
  )
  const classEquipmentsToUse = useMemo(
    () => (isNotNilAndNotEmpty(classEquipments) ? classEquipments : classData?.equipments),
    [classEquipments, classData?.equipments],
  )
  const {
    data: subscriptionsUnsorted,
    mutate,
    isLoading: isSubscriptionsLoading,
  } = useMutation(SubscriptionsQueries.validateUserSubscriptionsMutation({}))
  const isLoading: boolean = useMemo(
    () => isSubscriptionsLoading || isClassDataLoading,
    [isSubscriptionsLoading, isClassDataLoading],
  )
  const subscriptions = useMemo(
    () =>
      classData?.anySubscriptions && isNotNilAndNotEmpty(values.classId)
        ? subscriptionsUnsorted
        : [
            ...(subscriptionsUnsorted || []).filter(
              ({membershipId}) => classData?.accessConfig.subscriptions.includes(membershipId),
            ),
            ...(subscriptionsUnsorted || []).filter(
              ({membershipId}) => !classData?.accessConfig.subscriptions.includes(membershipId),
            ),
          ],
    [subscriptionsUnsorted, classData],
  )
  useEffect(() => {
    if (!venueData) {
      return
    }
    mutate({
      options: [
        {
          venueId: venueData.uid,
          sportId: values.serviceId,
          areaId: values.area?.uid,
          teacherId: values.teacherId,
          bookingType: bookingTypeMap[values.bookingType],
          classId: values.classId,
          date: values.date,
          userId: auth.currentUser?.uid,
          consideredDate: values.date,
          sportType: classData?.sportType,
          duration: values.duration,
          isConsideredOption: true,
          slotId: values.sessionId,
        },
      ],
    })
  }, [venueData, values, classData, auth.currentUser])
  const maxParticipantsToUse = maxParticipants || service?.maxPlayers || Infinity
  const {isLoggedIn} = useAuthState()
  const {data: user} = useQuery(UserService.queries.GetUserQuery({cacheTime: 0, params: {id: auth.currentUser?.uid}}))
  const {data: member} = useQuery(
    MemberQueries.getMemberByVenueQuery({
      query: {
        venueId: venueData?.uid,
        userId: user?.uid,
      },
    }),
  )
  const [isSubscriptionAllocable, setIsSubscriptionAllocable] = useState(false)
  const [isSubscriptionShared, setIsSubscriptionShared] = useState(useForParticipants)
  const myParticipants: MemberParticipantType[] = useMemo(
    () => [
      {
        name: user?.name || `${user?.firstName} ${user?.lastName}`,
        avatar: {
          secure_url: user?.avatar || NO_USER_PHOTO,
        },
      },
      ...(member?.participants || []),
    ],
    [member?.participants, user],
  )
  const initialState = useMemo<BookingStateParticipant[]>(() => {
    const result: BookingStateParticipant[] = []
    if (isNotNilAndNotEmpty(values.participants)) {
      const selectedSubscription = subscriptions?.find(
        (sub) => values.participants[0].membership?.membershipBookingId === sub.membershipBookingId,
      )
      setIsSubscriptionAllocable(
        (selectedSubscription?.useForParticipants &&
          (service?.multiplyPerPerson || isNotNilAndNotEmpty(classData?.uid))) ||
          false,
      )
      setIsSubscriptionShared(
        (values.useForParticipants && (service?.multiplyPerPerson || isNotNilAndNotEmpty(classData?.uid))) || false,
      )
      return values.participants
    }
    if (user) {
      if (subscriptions?.[0] && isLoggedIn) {
        setIsSubscriptionAllocable(
          (subscriptions[0].useForParticipants &&
            (service?.multiplyPerPerson || isNotNilAndNotEmpty(classData?.uid))) ||
            false,
        )
        setIsSubscriptionShared(
          (subscriptions[0].useForParticipants &&
            (service?.multiplyPerPerson || isNotNilAndNotEmpty(classData?.uid))) ||
            false,
        )
        result.push(
          createBookingStateParticipant({
            name: user.name,
            avatar: user.avatar,
            memberParticipantIndex: 0,
            coverEquipment: subscriptions[0].coverEquipment,
            membership: {
              logo: subscriptions[0].logo,
              membershipBookingId: subscriptions[0].membershipBookingId,
              membershipBookingReference: subscriptions[0].membershipBookingReference,
              cancelPolicy: subscriptions[0].cancelPolicy,
              name: subscriptions[0].membershipName,
              uid: subscriptions[0].membershipId,
            },
          }),
        )
      } else {
        result.push(createBookingStateParticipant({name: user.name, avatar: user.avatar, memberParticipantIndex: 0}))
      }
    }
    for (let i = result.length; i < minParticipants; i++) {
      result.push(
        createBookingStateParticipant({name: l('counted_participant', {value: i + 1, caseAction: 'capitalize'})}),
      )
    }
    return result
  }, [l, minParticipants, user, subscriptions, values])
  const [participants, setParticipants] = useState(initialState)
  useEffect(() => {
    setParticipants(initialState)
  }, [initialState])
  const addParticipant = useCallback(() => {
    return setParticipants((prevState) => [
      ...prevState,
      createBookingStateParticipant({
        name: l('counted_participant', {value: prevState.length + 1, caseAction: 'capitalize'}),
      }),
    ])
  }, [l, member, prevParticipants, participants])

  useEffect(() => {
    // set/unset user on login/logout
    const updatedParticipants = [...participants]
    if (user) {
      if (subscriptions?.[0] && isLoggedIn) {
        setIsSubscriptionAllocable(
          (subscriptions[0].useForParticipants &&
            (service?.multiplyPerPerson || isNotNilAndNotEmpty(classData?.uid))) ||
            false,
        )
        setIsSubscriptionShared(
          (subscriptions[0].useForParticipants &&
            (service?.multiplyPerPerson || isNotNilAndNotEmpty(classData?.uid))) ||
            false,
        )
        updatedParticipants[0] = createBookingStateParticipant({
          name: user.name,
          avatar: user.avatar,
          coverEquipment: subscriptions[0].coverEquipment,
          memberParticipantIndex: 0,
          membership: {
            logo: subscriptions[0].logo,
            cancelPolicy: subscriptions[0].cancelPolicy,
            membershipBookingId: subscriptions[0].membershipBookingId,
            membershipBookingReference: subscriptions[0].membershipBookingReference,
            name: subscriptions[0].membershipName,
            uid: subscriptions[0].membershipId,
          },
        })
      } else {
        updatedParticipants[0] = createBookingStateParticipant({
          name: user.name,
          avatar: user.avatar,
          memberParticipantIndex: 0,
        })
      }
    } else {
      updatedParticipants[0] = createBookingStateParticipant({
        name: l('counted_participant', {value: 1, caseAction: 'capitalize'}),
      })
    }
    setParticipants(updatedParticipants)
  }, [user, subscriptions]) //eslint-disable-line

  const equipmentsToUse = useMemo(
    () => service?.equipments || classEquipmentsToUse,
    [service, classEquipmentsToUse],
  )
  useEffect(() => {
    // update non-user and not-edited participants
    const updatedParticipants = participants.map((participant, i) => {
      if (participant.edited || participant.avatar) {
        return participant
      }
      return {...participant, name: l('counted_participant', {value: i + 1, caseAction: 'capitalize'})}
    })
    setParticipants(updatedParticipants)
  }, [l, participants.length]) //eslint-disable-line

  const removeParticipant = useCallback(
    (index: number) => {
      const newParticipants = [...participants]
      newParticipants.splice(index, 1)
      setParticipants(newParticipants)
    },
    [participants],
  )

  const handleEditParticipant = useCallback(
    (index: number) => {
      const handleSetParticipant = ({
        name,
        subscription,
        memberParticipantIndex,
        avatar,
      }: {
        avatar: string | null
        name: string
        subscription?: Subscription
        memberParticipantIndex: number | null
      }) => {
        const participantsToSave = [...participants]
        if (index === 0) {
          setIsSubscriptionAllocable(
            (subscription?.useForParticipants && (service?.multiplyPerPerson || isNotNilAndNotEmpty(classData?.uid))) ||
              false,
          )
          if (participants[index].membershipId !== subscription?.membershipId) {
            setIsSubscriptionShared(
              subscription?.useForParticipants && (service?.multiplyPerPerson || isNotNilAndNotEmpty(classData?.uid)),
            )
          }
        }
        participantsToSave[index] = {
          ...participantsToSave[index],
          name,
          avatar: avatar || participantsToSave[index]?.avatar || NO_USER_PHOTO,
          coverEquipment: subscription?.coverEquipment,
          membershipId: subscription?.membershipId,
          membershipBookingId: subscription?.uid,
          memberParticipantIndex,
          membership: subscription
            ? {
                logo: subscription.logo,
                uid: subscription.membershipId,
                name: subscription.membershipName,
                cancelPolicy: subscription.cancelPolicy,
                membershipBookingId: subscription.membershipBookingId,
                membershipBookingReference: subscription.membershipBookingReference,
              }
            : null,
        }
        setParticipants(participantsToSave)
        closeModal()
      }
      openModal({
        component: (
          <EditParticipantForm
            handleSubmit={handleSetParticipant as EditParticipantFormProps['handleSubmit']}
            initialName={participants[index].name || ''}
            initialSubscriptionBookingId={isLoggedIn ? participants[index].membership?.membershipBookingId : undefined}
            subscriptions={isLoggedIn ? subscriptions : []}
            initialMemberParticipantIndex={
              participants[index].memberParticipantIndex || participants[index].memberParticipantIndex === 0
                ? participants[index].memberParticipantIndex!
                : null
            }
          >
            <EditParticipantModal
              isLoggedIn={isLoggedIn}
              allowedSubscriptionsList={
                isNotNilAndNotEmpty(service?.accessConfig?.subscripions)
                  ? service?.accessConfig?.subscripions
                  : classData?.accessConfig.subscriptions
              }
              initialMemberParticipantIndex={
                participants[index].memberParticipantIndex || participants[index].memberParticipantIndex === 0
                  ? participants[index].memberParticipantIndex!
                  : null
              }
              subscriptions={isLoggedIn && index === 0 ? subscriptions : undefined}
              memberParticipants={myParticipants}
              anySubscriptions={classData?.anySubscriptions || classData?.accessForAnyone}
              omittedMemberParticipants={participants.reduce((acc: number[], cur) => {
                if (
                  (cur.memberParticipantIndex || cur.memberParticipantIndex === 0) &&
                  participants[index].memberParticipantIndex !== cur.memberParticipantIndex
                ) {
                  return [...acc, cur.memberParticipantIndex]
                }
                return acc
              }, [])}
            />
          </EditParticipantForm>
        ),
        title: participants[index].name,
        footerConfig: {
          disabled: true,
        },
      })
    },
    [l, participants, openModal, closeModal, subscriptions],
  )

  const handleEditParticipantEquipment = useCallback(
    (index: number) => {
      const handleSetParticipantEquipments = (equipments: Equipment[]) => {
        const participantsToSave = [...participants]
        participantsToSave[index] = {
          ...participantsToSave[index],
          equipments,
        }
        setParticipants(participantsToSave)
        closeModal()
      }
      openModal({
        title: l('equipment'),
        footerConfig: {disabled: true},
        component: (
          <EditEquipmentsModal
            serviceEquipments={equipmentsToUse}
            handleSubmit={handleSetParticipantEquipments}
            currency={venueData?.currency}
            initialEquipments={participants[index].equipments}
          />
        ),
      })
    },
    [l, participants, equipmentsToUse, openModal, closeModal, venueData],
  )

  const participantsMarkup = useMemo(
    () =>
      participants.map((participant, index) => (
        <Participant
          index={index}
          isLoading={isLoading}
          name={participant.name}
          image={participant.avatar}
          key={`participant-${index}`}
          currency={venueData?.currency}
          equipments={participant.equipments}
          onEdit={() => handleEditParticipant(index)}
          onEquipmentEdit={() => handleEditParticipantEquipment(index)}
          withEquipment={isNotNilAndNotEmpty(equipmentsToUse) && equipmentsToUse.length > 0}
          isSubscriptionsAvailable={isLoggedIn && isNotNilAndNotEmpty(subscriptions) && index === 0}
          onRemove={index !== 0 && participants.length > minParticipants ? () => removeParticipant(index) : null}
          selectedSubscriptionName={participant.membership?.name}
          isSubscriptionAllocable={isSubscriptionAllocable && index === 0 && participants.length > 1}
          handleSetIsApplySubscriptionForParticipants={setIsSubscriptionShared}
          isSubscriptionShared={isSubscriptionShared}
        />
      )),
    [
      participants,
      handleEditParticipantEquipment,
      handleEditParticipant,
      subscriptions,
      isLoggedIn,
      isSubscriptionAllocable,
      isSubscriptionShared,
      isLoading,
    ],
  )

  const handleNextClick = useCallback(() => {
    setValues({
      ...values,
      participants,
      useForParticipants: isSubscriptionShared,
      coverEquipment: participants[0].coverEquipment,
      sportType: classData?.sportType,
    })
    const errors = validateForm()
    if (!Object.keys(errors).length) {
      navigate('../summary')
    }
  }, [navigate, participants, isSubscriptionShared, classData]) // eslint-disable-line

  return (
    <GridLayout header={<Header />}>
      <ScrollableWrapper>
        <Box>
          <Box mb='16px'>
            <Box
              maxHeight='480px'
              overflow='auto'
            >
              {participantsMarkup}
            </Box>
            <Button
              disabled={participants.length >= maxParticipantsToUse}
              type='flat'
              onClick={addParticipant}
            >
              <Flex alignItems='end'>
                <ExsportiaIcon
                  icon='plus'
                  color='primary'
                />
                <Span ml='8px'>{l('addParticipant')}</Span>
              </Flex>
            </Button>
          </Box>
          <Flex
            justifyContent='center'
            mb='16px'
          >
            <Label>
              {l('participants_limit_single', {
                value: maxParticipantsToUse,
                entity: l(bookingType),
                declension: ['participants_limit_single_genitive', 'participants_limit_plural'],
              })}
            </Label>
          </Flex>
          <Button onClick={handleNextClick}>{l('next')}</Button>
        </Box>
      </ScrollableWrapper>
    </GridLayout>
  )
}
