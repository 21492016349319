import {Box, Flex, RadioItem} from 'exsportia-components'
import {StyleCommonType, StyleSizeSpaceType, TextType} from 'exsportia-components/dist/types'
import React, {FC, PropsWithChildren} from 'react'

export type RadioButtonProps = {
  text?: TextType
  checked?: boolean
  disabled?: boolean
  onChange?: (e: Event) => void
}

export type CustomRadioProps = {
  checked?: boolean
  component?: React.ReactNode
  settings?: {
    bg?: StyleCommonType
    m?: StyleSizeSpaceType
    p?: StyleSizeSpaceType
    cursor?: StyleCommonType
    border?: StyleCommonType
    position?: StyleCommonType
    width?: StyleSizeSpaceType
    height?: StyleSizeSpaceType
    alignItems?: StyleCommonType
    justifyContent?: StyleCommonType
    borderRadius?: StyleSizeSpaceType
  }
}

const CustomRadio = ({checked}: CustomRadioProps): React.ReactElement => (
  <Box
    height='max-content'
    width='max-content'
  >
    <Flex
      bg='transparent'
      cursor={'pointer'}
      borderRadius={'50%'}
      alignItems={'center'}
      width={['20px', '24px']}
      height={['20px', '24px']}
      justifyContent={'center'}
      m={'0 12px 0 0'}
      borderWidth='2px'
      borderStyle='solid'
      borderColor={checked ? '#ED3163' : 'primary-secondary'}
    >
      {checked && (
        <Box
          bg={'primary'}
          borderRadius={'12px'}
          width={['12px', '16px']}
          height={['12px', '16px']}
        />
      )}
    </Flex>
  </Box>
)

export const RadioButton: FC<PropsWithChildren<RadioButtonProps>> = ({text, checked, onChange, disabled, children}) => (
  <RadioItem
    disabled={disabled}
    text={text}
    value={checked}
    onChange={onChange}
    settings={{}}
  >
    <Flex alignItems='center'>
      {(children || text) && <CustomRadio checked={checked} />}
      {children || text}
    </Flex>
  </RadioItem>
)
