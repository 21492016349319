import {LanguagesEnum} from '../../locale/types'
import {createBasicSelectors, makeSelect} from '../../utils/redux'
import {initialState} from './constants'
import {GlobalState} from './types'

const select = makeSelect<GlobalState>('global')

export const basicSelectors = createBasicSelectors<GlobalState>(initialState, select)

export const someCoolSelector = select<LanguagesEnum[], string>((state) => {
  const language = state.get('language')
  return [language, language]
})
