import {Counter, Flex, IconWithText, Label} from 'exsportia-components'
import {FC, useCallback, useMemo, useState} from 'react'

import {Equipment, ServiceItem} from '../../../api/booking/types'
import {TranslatorScope} from '../../../locale/types'
import {Button} from '../../../ui/components/button/button'
import {useLocale} from '../../../utils/i18n'
import {useModalActions} from '../../../utils/modals'

type EditEquipmentsModalProps = {
  currency?: string
  initialEquipments?: Equipment[]
  serviceEquipments?: ServiceItem['equipments']
  handleSubmit: (equipments: Equipment[]) => void
}

export const EditEquipmentsModal: FC<EditEquipmentsModalProps> = ({
  currency,
  handleSubmit,
  initialEquipments,
  serviceEquipments,
}) => {
  const {l} = useLocale()
  const {closeModal} = useModalActions()

  const [equipments, setEquipments] = useState<Equipment[]>(
    (serviceEquipments || []).map((eq) => ({
      name: eq.name,
      value: eq.name,
      label: eq.name,
      price: eq.price,
      quantity: initialEquipments?.find((initialEq) => initialEq.name === eq.name)?.quantity || 0,
    })),
  )
  const handleSetEquipmentQuantity = useCallback(
    (index: number, isPlus: boolean) => {
      const newEquipments = [...equipments]
      newEquipments[index] = {
        ...newEquipments[index],
        quantity: newEquipments[index].quantity + (isPlus ? 1 : -1),
      }
      setEquipments(newEquipments)
    },
    [equipments],
  )
  const equipmentsToDisplay = useMemo(() => {
    return equipments.map((equipment, index) => (
      <Flex
        key={index}
        justifyContent='space-between'
        mb='16px'
        position='relative'
      >
        <Flex
          gap='12px'
          alignItems='center'
          maxWidth={['55%', '70%']}
        >
          <IconWithText text={`${equipment.name},`} />
          <Label settings={{fontSize: '16px'}}>
            {l(`${currency || 'USD'}_symbolic` as TranslatorScope, {value: equipment.price})}
          </Label>
        </Flex>
        <Counter
          min={0}
          onClickMinus={() => handleSetEquipmentQuantity(index, false)}
          onClickPlus={() => handleSetEquipmentQuantity(index, true)}
          value={equipment.quantity}
        />
      </Flex>
    ))
  }, [equipments])
  const onSubmit = useCallback(() => {
    const equipmentToSave = equipments.filter((eq) => eq.quantity > 0)
    handleSubmit(equipmentToSave)
  }, [equipments, handleSubmit])
  return (
    <Flex
      flexDirection='column'
      mb='16px'
      position='relative'
    >
      {equipmentsToDisplay}
      <Label>{l('additional_venue_fees')}</Label>
      <Flex
        justifyContent='flex-end'
        mt='16px'
      >
        <Flex
          width={['100%', '50%']}
          gap='16px'
        >
          <Button
            type='secondary'
            onClick={closeModal}
          >
            {l('cancel')}
          </Button>
          <Button onClick={onSubmit}>{l('save')}</Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
