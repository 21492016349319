import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {InstructorsList} from './types'

export type GetVenueInstructorsParams = undefined
export type GetVenueInstructorsQueryParams = {
  venueId: string
  selectedDuration?: number
}

export type GetVenueInstructorsReturnType = InstructorsList
export type GetVenueInstructorsQuery = QueryMethod<
  GetVenueInstructorsParams,
  GetVenueInstructorsQueryParams,
  GetVenueInstructorsReturnType
>

export const makeGetVenueInstructorsEndpoint = () => 'teachers/list/withAvailableTime'

export const getVenueInstructorsQuery: GetVenueInstructorsQuery = ({query}) => ({
  queryKey: ['getVenueInstructorsQuery', query],
  cacheTime: 1000 * 300,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<GetVenueInstructorsQueryParams, GetVenueInstructorsReturnType>(makeGetVenueInstructorsEndpoint(), {
      params: config,
    }).then((res) => res.data),
})
