import {Flex} from 'exsportia-components'
import {FC, useMemo} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'

import {TabButton, TabButtonProps} from '../../ui/components/tab-button/tab-button'
import {LocaleOptions, useLocale} from '../../utils/i18n'

const defaultLOptions: LocaleOptions = {
  caseAction: 'capitalize',
}

export type NavMenuProps = {
  tabsSettings?: TabButtonProps[]
  notForMobile?: boolean
}

export const NavMenu: FC<NavMenuProps> = ({tabsSettings, notForMobile = false}) => {
  const {l} = useLocale()
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const {venueId} = useParams()
  const menuSettings: TabButtonProps[] = useMemo(() => {
    if (tabsSettings) {
      return tabsSettings
    }
    const pathArr = pathname.split('/').filter((el) => el !== '')
    const activeTab = pathArr[1] === 'bookings' ? 'bookings' : 'home'
    return [
      {
        text: l('home', defaultLOptions),
        isActive: activeTab === 'home',
        onClick: () => navigate(`/${venueId}`),
      },
      {
        text: l('bookings', defaultLOptions),
        isActive: activeTab === 'bookings',
        onClick: () => navigate(`/${venueId}/bookings`),
      },
    ]
  }, [l, pathname, venueId, tabsSettings])
  return (
    <Flex
      justifyContent='space-between'
      width='max-content'
      display={notForMobile ? ['none', 'flex'] : 'flex'}
      height='64px'
    >
      {menuSettings.map((settings, index) => (
        <TabButton
          key={`nav-tab-${index}`}
          icon={settings.icon}
          text={settings.text}
          onClick={settings.onClick}
          isActive={settings.isActive}
        />
      ))}
    </Flex>
  )
}
