// export const APIDomain = 'http://127.0.0.1:5001/exsportia-dev/us-central1/develop/'
export const APIDevDomain = 'https://us-central1-exsportia-dev.cloudfunctions.net/develop/'
const APIStagingDomain = 'https://us-central1-exsportia-staging.cloudfunctions.net/staging/'
const APIProductionDomain = 'https://us-central1-exsportia-prod.cloudfunctions.net/production/'

export type envDomainType = {
  URL: string
}

export type APIDomainsMapType = {
  development: envDomainType
  staging: envDomainType
  preprod: envDomainType
  production: envDomainType
}

export const APIDomainsMap: APIDomainsMapType = {
  development: {
    URL: APIDevDomain,
  },
  staging: {
    URL: APIDevDomain,
  },
  preprod: {
    URL: APIStagingDomain,
  },
  production: {
    URL: APIProductionDomain,
  },
}

const env = process.env.REACT_APP_ENV ?? 'development'

// export const APIDomain = 'http://192.168.50.99:5001/exsportia-dev/us-central1/develop/'
// export const APIDomain = 'https://us-central1-exsportia-dev.cloudfunctions.net/develop/'
export const APIDomain = APIDomainsMap[env].URL
