import {FC, useCallback, useEffect, useMemo, useState} from 'react'

import {SignUpForm} from '../../../services/auth/forms/sign-up-form'
import {useLocale} from '../../../utils/i18n'
import {AvatarSignUpStep} from './avatar-sign-up-step'
import {DobSignUpStep} from './dob-sign-up-step'
import {NameSignUpStep} from './name-sign-up-step'
import {PasswordSignUpStep} from './password-sign-up-step'
import {PhoneSignUpStep} from './phone-sign-up-step'

type SignUpStepProps = {
  email?: string
  venueId?: string
  backAction?: (e: Event) => void
  setModalTitle?: (title: string) => void
}

const steps: FC<{
  handleGoToNextStep: () => void
  backAction?: (e: Event) => void
}>[] = [
  NameSignUpStep,
  PhoneSignUpStep,
  DobSignUpStep,
  AvatarSignUpStep,
  PasswordSignUpStep,
]

export const SignUpStep: FC<SignUpStepProps> = ({email, backAction, venueId, setModalTitle}) => {
  const [step, setStep] = useState(0)
  const {l} = useLocale()
  const handleGoToNextStep = useCallback((index: number) => () => setStep(index + 1), [])
  const handleGoBack = useCallback((index: number) => () => setStep(index - 1), [])
  useEffect(() => {
    if (setModalTitle) {
      setModalTitle(l('sign_up_free', {caseAction: 'capitalize'}))
    }
  }, [l, setModalTitle])
  const stepsToUse = useMemo(
    () =>
      steps.map((Component, index) => (
        <Component
          handleGoToNextStep={handleGoToNextStep(index)}
          backAction={index === 0 ? backAction : handleGoBack(index)}
        />
      )),
    [],
  )

  return (
    <SignUpForm
      email={email}
      venueId={venueId}
    >
      {stepsToUse[step]}
    </SignUpForm>
  )
}
