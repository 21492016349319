import {applyMiddleware, compose, createStore as createReduxStore, Middleware} from 'redux'
import {createLogger} from 'redux-logger'
import createSagaMiddleware, {Saga} from 'redux-saga'

import {logger} from '../monitoring/logger'
import {rootSaga} from '../saga/root-saga'
import {isDevelopment} from '../utils/environment'
import {rootReducer} from './reducer'

const initialState = {}

const createStore = (sagas: Saga[], ...extraMiddlewares: Middleware[]) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, errorInfo) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (isDevelopment) {
        return console.log(error, errorInfo)
      }
      logger.error(error, 'saga-global', {sagaStack: errorInfo.sagaStack})
    },
  })
  const middlewares = [sagaMiddleware, ...extraMiddlewares]
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (isDevelopment) {
    middlewares.push(createLogger({collapsed: true}))
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // eslint-disable-line no-underscore-dangle
  const store = createReduxStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)))
  sagas.forEach((saga) => sagaMiddleware.run(saga))
  return store
}
export const store = createStore([rootSaga])
