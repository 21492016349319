import {Box, CheckBox as RawCheckBox, Flex} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {ChangeEvent, FC, PropsWithChildren, useCallback, useMemo} from 'react'

import {theme} from '../../../theme/theme'

type Props = {
  id: string
  disabled?: boolean
  label?: string
  children?: React.ReactNode | string
}

const getSettings = ({disabled}: {disabled: boolean}) => ({
  iconWithText: {text: {whiteSpace: 'unset'}},
  checkBoxOnIcon: {color: theme.colors.primary},
  alignItems: 'center',
  opacity: disabled ? 0.5 : 1,
})

export const CheckBox: FC<PropsWithChildren<Props>> = ({disabled = false, id, label, children}) => {
  const {values, errors, setFieldValue} = useFormikContext<any>()
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(id, e.target.checked)
    },
    [id, setFieldValue],
  )
  const settings = useMemo(() => getSettings({disabled}), [disabled])
  return (
    <Flex
      flexDirection='column'
      opacity={settings.opacity}
    >
      <Flex
        gap={'8px'}
        alignItems='center'
        opacity={settings.opacity}
      >
        <RawCheckBox
          // @ts-ignore
          onChange={handleChange}
          name={id}
          id={id}
          settings={settings}
          disabled={disabled}
          value={values[id]}
          // error={errors[id]}
        />
        {label}
        {children}
      </Flex>
      {
        errors[id]
        && (
          <Box
            mt='5px'
            mb='10px'
            fontSize='12px'
            color='error'
          >
            {errors[id]}
          </Box>
        )
      }
    </Flex>
  )
}
