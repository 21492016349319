import {Divider, Flex, IconWithText, Text, Title} from 'exsportia-components'
import {IconType, TextType} from 'exsportia-components/dist/types'
import React, {FC, useMemo} from 'react'

import {GradientLoader} from '../../ui/components/gradient-loader/gradient-loader'
// import {GradientLoader} from '../../ui/components/gradient-loader/gradient-loader'
import {defaultSettings} from './settings'

export type ClassCardProps = {
  venueName: TextType
  image: IconType
  subTextElements: TextType[]
  isLoading?: boolean
  className?: TextType
}

export const ClassCard: FC<ClassCardProps> = ({venueName, image, subTextElements, className, isLoading = false}) => {
  const subTextElementsToUse = useMemo(
    () =>
      isLoading ? (
        <GradientLoader height={'18px'} />
      ) : (
        <Flex>
          {subTextElements.map((element, index) => (
            <Flex key={`subTextElement${index}`}>
              {index !== 0 && <Divider styleType='dot' />}
              {<Text text={element} />}
            </Flex>
          ))}
        </Flex>
      ),
    [subTextElements, isLoading],
  )

  return (
    <IconWithText
      additionalStyles={['mr12Icon']}
      icon={isLoading ? <GradientLoader height={'72px'} /> : image}
      title={
        isLoading ? (
          <GradientLoader height={'13px'} />
        ) : (
          <Text settings={defaultSettings.subText}>{venueName}</Text>
        )
      }
      text={
        isLoading ? (
          <GradientLoader height={'18px'} />
        ) : (
          <Title
            styleType='h3'
            text={className}
          />
        )
      }
      subText={subTextElementsToUse}
      settings={defaultSettings}
    />
  )
}
