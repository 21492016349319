import {TranslatorScope} from '../../locale/types'
import {Media, OpeningTimes} from '../types'

export enum SurfaceTypesEnum {
  acrylic = 'acrylic',
  artificial = 'artificial',
  grass = 'grass',
  astro = 'astro',
  turf = 'turf',
  cement = 'cement',
  clay = 'clay',
  concrete = 'concrete',
  dirt = 'dirt',
  glass = 'glass',
  gravel = 'gravel',
  hardWood = 'hardWood',
  ice = 'ice',
  mapleWood = 'mapleWood',
  plastic = 'plastic',
  rubber = 'rubber',
  sand = 'sand',
  soft = 'soft',
  canvas = 'canvas',
  synthetic = 'synthetic',
  tarmac = 'tarmac',
  water = 'water',
  other = 'other',
}

export enum PlacementTypeEnum {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR',
}

export type Area = {
  uid: string
  name: string
  media?: Media[]
  timeSlot: number
  areaSize?: number
  thumbIndex: number
  openingTimes: OpeningTimes
  operatingHoursDiffer: boolean
  surfaceType: SurfaceTypesEnum & TranslatorScope
  placement?: PlacementTypeEnum & TranslatorScope
}

export type AreaSelectArea = {
  uid: string
  name: string
  image?: string
  timeSlot: number
  areaSize?: number
  openingTimes: OpeningTimes
  operatingHoursDiffer: boolean
  closestAvailableDate?: string | null
  surfaceType: SurfaceTypesEnum & TranslatorScope
  placement?: PlacementTypeEnum & TranslatorScope
}
