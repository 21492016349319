import {theme} from '../../../theme/theme'

export const defaultSettings = {
  width: 68,
  height: 40,
  onText: false,
  offText: false,
  handleDiameter: 32,
  onBgColor: theme.colors.primary,
  offBgColor: '#BFC1C7',
  onTextColor: theme.colors['text-contrast'],
  offTextColor: theme.colors['text-default'],
}

export const smallSettings = {
  width: 38,
  height: 24,
  handleDiameter: 20,
}

export const withTextSettings = {
  width: 84,
  label: {
    on: {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px',
      color: theme.colors['text-contrast'],
    },
    off: {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px',
      color: theme.colors['text-default'],
    },
  },
}

export const typeStylesMap = {
  small: smallSettings,
  default: defaultSettings,
  withText: withTextSettings,
}
