import {Box, Flex, Icon, Text} from 'exsportia-components'
import React, {ComponentProps, FC, useMemo} from 'react'

import {theme} from '../../../theme/theme'
import {ExsportiaIcon} from '../exsportia-icon/exsportia-icon'

type Presets = 'error' | 'success'

type PresetsMap = {
  [key in Presets]: {
    iconColor: (typeof theme.colors)[keyof typeof theme.colors]
    backgroundColor: (typeof theme.colors)[keyof typeof theme.colors]
    icon: ComponentProps<typeof ExsportiaIcon>['icon']
  }
}

const presets: PresetsMap = {
  success: {
    iconColor: theme.colors.success,
    backgroundColor: theme.colors.green_halftone,
    icon: 'info',
  },
  error: {
    iconColor: theme.colors.error,
    backgroundColor: theme.colors.red_halftone,
    icon: 'error',
  },
}

type Props = {
  text: string[] | string
  preset: Presets
}

export const InfoMessage: FC<Props> = ({text, preset}) => {
  const {icon, iconColor, backgroundColor} = useMemo(() => presets[preset], [preset])
  const iconSettings = useMemo(() => ({color: iconColor}), [iconColor])
  return (
    <Flex
      p={'12px'}
      borderRadius={'12px'}
      flexDirection={'row'}
      alignItems={'center'}
      backgroundColor={backgroundColor}
    >
      <Box>
        <Icon
          icon={icon}
          settings={iconSettings}
        />
      </Box>
      <Box
        ml={'12px'}
        width={'85%'}
      >
        <Text settings={{croppedLines: 'unset'}}>{text}</Text>
      </Box>
    </Flex>
  )
}
