import {call, takeLeading} from 'redux-saga/effects'

import {APIDomain} from '../../networking/constants'
import {sendRequest} from '../../utils/axios'
import {PayloadType, setEntityForPayRequestAction} from './actions'

export type resType = {
  status: number
  data?: {
    message?: string
  }
}

export function* setEntityForPayRequest(action: PayloadType) {
  try {
    const {collection, venueId, userId, entityId, successCallback} = action
    const endpoint = `${APIDomain}payments/entityForPay`
    const options = {
      collection,
      draft: {userId, venueId},
      entityId,
    }
    const res: resType = yield call<any>(sendRequest, 'post', endpoint, options)
    if (res.status === 200) {
      if (typeof successCallback === 'function') {
        successCallback()
      }
    }
  } catch (e: any) {
    console.log(e)
  }
}

export function* userWatcherSaga() {
  yield takeLeading(setEntityForPayRequestAction, function* (action) {
    yield setEntityForPayRequest(action.payload)
  })
}
