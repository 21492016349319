import {Box, Flex, IconWithText, Title} from 'exsportia-components'
import {Formik} from 'formik'
import {Dispatch, FC, SetStateAction, useCallback, useState} from 'react'

import {MemberParticipantType} from '../../../api/member/types'
import {BookingStateParticipant} from '../../../services/booking/forms/types'
import {Button} from '../../../ui/components/button/button'
import {Input} from '../../../ui/components/input/input'
import {RadioButton} from '../../../ui/components/radio-button'
import {useLocale} from '../../../utils/i18n'
import {useModalActions} from '../../../utils/modals'
import {NO_USER_PHOTO} from '../../../utils/remote-content'
import {createBookingStateParticipant} from '../helpers'

export type AddParticipantModalProps = {
  memberParticipants: MemberParticipantType[]
  nextNumber: number
  setParticipants: Dispatch<SetStateAction<BookingStateParticipant[]>>
  setSelectedMemberParticipantsIndexes: Dispatch<SetStateAction<number[]>>
  selectedMemberParticipantsIndexes: number[]
}

export const MemberParticipant: FC<MemberParticipantType> = ({name, avatar}) => (
  <IconWithText
    settings={{
      icon: {
        width: '32px',
        height: '32px',
        m: '0 16px 0 0',
        overflow: 'hidden',
        iconHeight: '32px',
        iconWidth: '32px',
        bg: 'rgba(0, 16, 61, 0.06)',
        color: 'rgba(191, 193, 199, 1)',
      },
      title: {fontSize: '18px'},
    }}
    icon={
      <Box background={`url(${avatar?.secure_url || NO_USER_PHOTO}) no-repeat center/cover`} height='32px' width='32px' />
    }
    text={name}
  />
)

const getInitialSelectedIndex = (
  memberParticipants: MemberParticipantType[],
  selectedMemberParticipantsIndexes: number[],
) => {
  const foundIndex = memberParticipants.findIndex((_, index) => !selectedMemberParticipantsIndexes.includes(index))
  if (foundIndex === -1) {
    return memberParticipants.length
  }
  return foundIndex
}

export const AddParticipantModal: FC<AddParticipantModalProps> = ({
  memberParticipants,
  nextNumber,
  setParticipants,
  setSelectedMemberParticipantsIndexes,
  selectedMemberParticipantsIndexes,
}) => {
  const {l} = useLocale()

  const {closeModal} = useModalActions()
  const [selectedParticipantIndex, setSelectedParticipantIndex] = useState(
    getInitialSelectedIndex(memberParticipants, selectedMemberParticipantsIndexes),
  )
  const handleSelect = useCallback((index: number) => {
    setSelectedParticipantIndex(index)
  }, [])
  const handleSubmitForm = useCallback(
    (values: {name: string}) => {
      setSelectedMemberParticipantsIndexes((prevState) => [...prevState, selectedParticipantIndex])
      setParticipants((prevState: BookingStateParticipant[]) => [
        ...prevState,
        selectedParticipantIndex === memberParticipants.length
          ? createBookingStateParticipant({
              name:
                values.name.length !== 0
                  ? values.name
                  : l('counted_participant', {value: nextNumber + 1, caseAction: 'capitalize'}),
            })
          : createBookingStateParticipant({
              name: memberParticipants[selectedParticipantIndex].name,
              avatar: memberParticipants[selectedParticipantIndex].avatar?.secure_url,
              memberParticipantIndex: selectedParticipantIndex,
            }),
      ])
      closeModal()
    },
    [selectedParticipantIndex],
  )
  return (
    <>
      <Title
        text={l('selectParticipant')}
        styleType='h3'
        settings={{my: '16px'}}
      />
      {[...memberParticipants, {name: l('other'), avatar: {secure_url: NO_USER_PHOTO}}].map(({name, avatar}, index) => (
        <Flex
          key={index}
          mt='8px'
          display={
            selectedMemberParticipantsIndexes.some((el) => el === index && index !== memberParticipants.length)
              ? 'none'
              : 'flex'
          }
        >
          <RadioButton
            onChange={() => handleSelect(index)}
            checked={selectedParticipantIndex === index}
            text={
              <Flex cursor='pointer'>
                <MemberParticipant
                  name={name}
                  avatar={avatar}
                />
              </Flex>
            }
          />
        </Flex>
      ))}
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={{
          name: l('counted_participant', {value: nextNumber + 1, caseAction: 'capitalize'}),
        }}
      >
        {({handleSubmit}) => (
          <>
            {selectedParticipantIndex === memberParticipants.length && (
              <Box mt='12px'>
                <Input
                  label={l('name')}
                  id='name'
                />
              </Box>
            )}
            <Flex
              justifyContent='end'
              my='16px'
            >
              <Flex
                gap='8px'
                width='50%'
              >
                <Button
                  type='cancel'
                  onClick={() => closeModal()}
                >
                  {l('cancel')}
                </Button>
                <Button
                  // @ts-ignore
                  onClick={handleSubmit}
                >
                  {l('save')}
                </Button>
              </Flex>
            </Flex>
          </>
        )}
      </Formik>
    </>
  )
}
