import {FC, JSX, useCallback, useMemo, useState} from 'react'

import {CheckEmailForm} from '../../services/auth/forms/check-email-form'
import {SignInForm} from '../../services/auth/forms/sign-in-form'
import {CheckEmailStep} from './check-email-step'
import {SignInStep} from './sign-in-step'
import {SignUpStep} from './sign-up-step/sign-up-step'
import {ModalParams} from './types'

type Props = {
  email?: string
  venueId?: string
  setModalTitle?: (title: string) => void
}

export type Step = 'check_email' | 'sign_in' | 'sign_up_free'

export const AuthModal: FC<Props> = ({email, venueId, setModalTitle}) => {
  const [step, setStep] = useState<Step>('check_email')
  const [params, setParams] = useState<ModalParams>({email})
  const [emailToUse, setEmailToUse] = useState<string | undefined>(email)
  const handleNavigateToStep = useCallback((stepToSet: Step, paramsToSet?: ModalParams) => {
    setStep(stepToSet)
    if (paramsToSet) {
      setParams(paramsToSet)
    }
  }, [])
  const steps = useMemo<{[key: string]: JSX.Element}>(
    () => ({
      check_email: (
        <CheckEmailForm
          initialEmail={emailToUse}
          setEmailToUse={setEmailToUse}
          navigateToStep={handleNavigateToStep}
        >
          <CheckEmailStep />
        </CheckEmailForm>
      ),
      sign_in: (
        <SignInForm
          venueId={venueId}
          email={emailToUse}
          setModalTitle={setModalTitle}
        >
          <SignInStep backAction={() => handleNavigateToStep('check_email')} />
        </SignInForm>
      ),
      sign_up_free: (
        <SignUpStep
          email={emailToUse}
          venueId={venueId}
          backAction={() => handleNavigateToStep('check_email')}
          setModalTitle={setModalTitle}
        />
      ),
    }),
    [handleNavigateToStep, params.email],
  )

  return steps[step]
}
