import {Flex} from 'exsportia-components'
import {useFormikContext} from 'formik'
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'

import {Participant, TimeSlot, TimeSlotGrid, TimeslotStatesEnum} from '../../../api/booking/types'
import {CreateServiceBookingFormType} from '../../../services/booking/forms/create-service-booking-form'
import {GradientLoader} from '../../../ui/components/gradient-loader/gradient-loader'
import {useLuxon} from '../../../utils/i18n'
import {isNilOrEmpty} from '../../../utils/logic'
import {updateSlots} from '../timeslot-grid'
import {StickyStatesEnum} from '../types'
import {TimeSlotItem} from './timeslot-item'

type TimeSlotsListProps = {
  isLoading?: boolean
  timeSlot: number
  slots: TimeSlotGrid
  noDuration: boolean
  firstPoint: number | null
  secondPoint: number | null
  setFirstPoint: (_: number | null) => void
  setSecondPoint: (_: number | null) => void
  minTimeReservation: number
  maxTimeReservation: number
}

const LoaderRow: FC = () => (
  <Flex
    mb='24px'
    width='100%'
  >
    <GradientLoader height='48px' />
  </Flex>
)

const TimeLoader: FC = () => (
  <>
    {Array.from({length: 6}, () => null).map((_, index) => (
      <LoaderRow key={`timeloader-element-${index}`} />
    ))}
  </>
)

const updateSlotsParticipants = ({slots, timeSlot}: {slots: TimeSlot[]; timeSlot: number}) =>
  slots.map((slot, index) => {
    if (isNilOrEmpty(slot.modified)) {
      return slot
    }
    const isMultiple = slot.modified.status === 'multiple'
    if (!isMultiple) {
      const bookingSlotsDuration = slot.modified.duration ? slot.modified.duration / timeSlot : 1
      for (let i = 1; i < bookingSlotsDuration; i++) {
        slots[index + i] = {
          ...slots[index + i],
          participants: [...(slot.modified.participants || [])],
        }
      }
      return {
        ...slot,
        participants: [...(slot.participants || []), ...(slot.modified.participants || [])],
      }
    }
    const slotBookings = slot.modified.bookings
    if (!slotBookings) {
      return slot
    }
    let bookingsParticipants: Participant[] = []
    slotBookings.forEach((booking) => {
      bookingsParticipants = [...bookingsParticipants, ...booking.participants]
    })
    return {
      ...slot,
      participants: [...(slot.participants || []), ...bookingsParticipants],
    }
  })

export const TimeSlotsList: FC<TimeSlotsListProps> = ({
  slots,
  timeSlot,
  isLoading,
  firstPoint,
  noDuration,
  secondPoint,
  setFirstPoint,
  setSecondPoint,
  minTimeReservation,
  maxTimeReservation,
}) => {
  const luxon = useLuxon()
  const {
    setFieldValue,
    values: {service, maxParticipants},
  } = useFormikContext<CreateServiceBookingFormType>()
  const [clickComposite, setClickComposite] = useState<{
    selectedPoint: number | null
    marker: number
    fakeValue?: number
  }>({
    selectedPoint: secondPoint || firstPoint,
    marker: Math.random(),
  })
  const handleSetSelectedPoint = useCallback((point: number | null, fakeValue?: number) => {
    setClickComposite({selectedPoint: point, fakeValue, marker: Math.random()})
  }, [])
  useEffect(() => {
    if (firstPoint) {
      return setSecondPoint(clickComposite.selectedPoint)
    }
    const selectedPoint = clickComposite.fakeValue || clickComposite.selectedPoint
    setFirstPoint(selectedPoint)
    if (minTimeReservation && selectedPoint) {
      return setSecondPoint(
        luxon
          .fromMillis(Number(selectedPoint))
          .plus({minutes: minTimeReservation - timeSlot})
          .toUTC()
          .toMillis(),
      )
    }
    setSecondPoint(null)
  }, [clickComposite.marker]) //eslint-disable-line
  const onPress = useCallback((slotTimestamp: number, fakeValue?: number) => {
    setFieldValue('maxParticipants', service?.maxPlayers || Infinity)
    if (noDuration) {
      setFirstPoint(slotTimestamp)
      setSecondPoint(slotTimestamp)
      return
    }
    handleSetSelectedPoint(slotTimestamp, fakeValue)
  }, []) //eslint-disable-line
  const onPressReversed = useCallback(
    (slotTimestamp: number) => {
      if (firstPoint) {
        return handleSetSelectedPoint(slotTimestamp)
      }
      handleSetSelectedPoint(
        luxon
          .fromMillis(slotTimestamp)
          .minus({minutes: minTimeReservation - timeSlot})
          .toUTC()
          .toMillis(),
      )
      // handleSetSelectedPoint(slotTimestamp)
    },
    [luxon, minTimeReservation, firstPoint],
  ) //eslint-disable-line
  const timeSlotsPerRow = service?.longSlotView ? 1 : 4
  const slotsToUse = useMemo(() => {
    if (!firstPoint && !secondPoint) {
      return service?.longSlotView ? updateSlotsParticipants({slots, timeSlot}) : slots
    }
    const updatedSlots = updateSlots({
      setMaxParticipants: (qty: number) => {
        setFieldValue('maxParticipants', qty)
      },
      prevSlots: slots,
      maxTime: maxTimeReservation,
      minTime: minTimeReservation,
      timeSlot,
      firstPoint,
      secondPoint,
      timeSlotsPerRow,
      prevMaxParticipants: maxParticipants || service?.maxPlayers || Infinity,
    })
    return service?.longSlotView ? updateSlotsParticipants({slots: updatedSlots, timeSlot}) : updatedSlots
  }, [firstPoint, secondPoint, minTimeReservation, maxTimeReservation, slots, timeSlot])
  return (
    <>
      {isLoading ? (
        <TimeLoader />
      ) : (
        slotsToUse.map((slotData: TimeSlot, i: number) => {
          const state: TimeslotStatesEnum = slotData.options.state
          const slotTimestamp = Number(slotData.date_timestamp)
          const stickyState = slotData.options.stickyState || StickyStatesEnum.DEFAULT
          const participants = slotData.participants
          /* if (service?.longSlotView) {
            return (
              <LongTimeSlotItem
                state={state}
                spaces={slotData.spaces}
                onClick={onPress}
                key={`time-slot-${i}`}
                participants={participants}
                setFirstPoint={setFirstPoint}
                slotTimestamp={slotTimestamp}
                setSecondPoint={setSecondPoint}
                onPressReversed={onPressReversed}
                setSelectedPoint={handleSetSelectedPoint}
                isSelectEnd={slotTimestamp === secondPoint}
                isSelectStart={slotTimestamp === firstPoint}
                showParticipants={service.participantsNames}
                showParticipantsWhenFull={service.participantsNamesWhenFull}
              />
            )
          }*/
          return (
            <TimeSlotItem
              state={state}
              spaces={slotData.spaces}
              onClick={onPress}
              key={`time-slot-${i}`}
              stickyState={stickyState}
              participants={participants}
              setFirstPoint={setFirstPoint}
              slotTimestamp={slotTimestamp}
              setSecondPoint={setSecondPoint}
              onPressReversed={onPressReversed}
              timeSlotsPerRow={timeSlotsPerRow}
              fakeValue={Number(slotData.options.fakeValue)}
              setSelectedPoint={handleSetSelectedPoint}
              isSelectEnd={slotTimestamp === secondPoint}
              isSelectStart={slotTimestamp === firstPoint}
              showParticipants={service?.participantsNames}
              showParticipantsWhenFull={service?.participantsNamesWhenFull}
            />
          )
        })
      )}
    </>
  )
}
