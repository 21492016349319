import {FC, PropsWithChildren} from 'react'

import {useHomeRedirection} from '../../hooks'

export const RedirectionComponent: FC<PropsWithChildren> = ({children}) => {
  const isRedirection = useHomeRedirection()
  if (isRedirection) {
    return null
  }
  return <>{children}</>
}
