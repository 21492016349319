import {Box, showMessage} from 'exsportia-components'
import React, {FC, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'

import {ServiceItem} from '../../api/booking/types'
import {VenueQueries} from '../../api/venue'
import {EmptyList} from '../../components/empty-list/empty-list'
import {PrivateCodeModal} from '../../components/private-code-modal/private-code-modal'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {TranslatorScope} from '../../locale/types'
import {BookingService} from '../../services/booking'
import {checkServicePasscode} from '../../services/booking/actions'
import {useBookingLoader} from '../../services/booking/hooks'
import {useServiceOptions} from '../../services/service-options/hooks'
import {EntityCard} from '../../ui/components/entity-card/entity-card'
import {NavigationList} from '../../ui/components/navigation-list/navigation-list'
import {ScrollableWrapper} from '../../ui/components/scrollable-wrapper/scrollable-wrapper'
import {TiteledSection} from '../../ui/components/titeled-section/titeled-section'
import {auth} from '../../utils/firebase'
import {useLocale} from '../../utils/i18n'
import {isNilOrEmpty} from '../../utils/logic'
import {useModalActions} from '../../utils/modals'

export enum CurrenciesEnum {
  'ILS' = 'ILS',
  'UAH' = 'UAH',
  'EUR' = 'EUR',
  'USD' = 'USD',
}

type ServiceOption = {
  text: string
  icon: string // TODO: icon type
  subText?: string[]
  styleType: 'justTheSpace' | 'service'
  onClick: () => void
}

type ServiceMapByType = {
  [key in ServiceItem['serviceType']]: ServiceOption[]
}

export const validationTokensMap: Record<NonNullable<ServiceItem['permissionData']['reason']>, TranslatorScope> = {
  FOR_MEMBERS_ONLY: 'for_members_only',
  FOR_SUBSCRIBERS_ONLY: 'only_with_subscription',
  ONLY_WITHOUT_SUBSCRIPION: 'only_without_subscription',
  FOR_NON_MEMBERS_ONLY: 'for_non_members_only',
  NOT_AVAILABLE: 'entity_is_not_available',
}

export const SelectServicePage: FC<{isJustTheSpace?: boolean}> = ({isJustTheSpace = false}) => {
  const dispatch = useDispatch()
  const {l} = useLocale()
  const navigate = useNavigate()
  const {openModal, closeModal} = useModalActions()
  const {setIsLoading} = useBookingLoader()
  const {venueId} = useParams<{venueId: string}>()
  if (!venueId) {
    return null
  }
  const {data, isLoading, refetch} = useQuery(
    BookingService.queries.getVenueServicesQuery({
      query: {venueId, isJustTheSpace},
    }),
  )
  const {getServiceConfigByServiceType} = useServiceOptions(data?.map((service) => service.serviceType))
  const {data: venueData} = useQuery(VenueQueries.getVenueQuery({params: {id: venueId}}))
  useEffect(() => {
    refetch()
  }, [auth.currentUser?.uid])

  const serviceListSettings = useMemo(
    () =>
      data
        ? data.reduce<Partial<ServiceMapByType>>(
            (acc, service) => ({
              ...acc,
              [service.serviceType]: [
                ...(acc[service.serviceType] || []),
                {
                  text: service.name,
                  disabled: !service.permissionData.validated,
                  icon: getServiceConfigByServiceType(service.serviceType).icon,
                  styleType: isJustTheSpace ? 'justTheSpace' : 'service',
                  subText: service.permissionData.validated
                    ? [l(`${service.currency}_short_single` as TranslatorScope, {value: service.price})]
                    : [
                        l(validationTokensMap[service.permissionData.reason!] as TranslatorScope, {
                          caseAction: 'capitalize',
                        }),
                      ],
                  onClick: () => {
                    const handleSelectService = () => navigate(
                      isJustTheSpace
                      ? `../space/${service.uid}/select-playspace/`
                        : isNilOrEmpty(service.teachersIds)
                          ? `../service/${service.uid}/select-playspace`
                          : `../instructor-service//${service.uid}/select-instructor`,
                      {state: {service}},
                    )
                    if (!service.private) {
                      handleSelectService()
                      return
                    }
                    const handleCheckPassCode = (code: string) => {
                      setIsLoading(true)
                      dispatch(
                        checkServicePasscode({
                          password: code,
                          sportId: service.uid,
                          callback: () => {
                            closeModal()
                            handleSelectService()
                            showMessage(l('success'), 'success')
                            setIsLoading(false)
                          },
                          rejectCallback: () => {
                            setIsLoading(false)
                            showMessage(l('error'), 'error')
                          },
                        }),
                      )
                    }
                    openModal({
                      title: l('privateSession'),
                      footerConfig: {
                        disabled: true,
                      },
                      component: <PrivateCodeModal handleCheckCode={handleCheckPassCode} />,
                    })
                  },
                },
              ],
            }),
            {},
          )
        : {},
    [data, l, getServiceConfigByServiceType], // eslint-disable-line
  )

  const header = useMemo(() => {
    const title = l('services')
    return <SimpleGridLayoutHeader title={title} />
  }, [l, venueData?.entityNames])

  if (isNilOrEmpty(data) && !isLoading) {
    return (
      <GridLayout header={header}>
        <EmptyList />
      </GridLayout>
    )
  }

  return (
    <GridLayout
      withoutPadding
      header={header}
    >
      <ScrollableWrapper>
        <Box pb={'8px'}>
          {isLoading && (
            <NavigationList
              Component={EntityCard}
              isLoading={isLoading}
              elementsSettings={[]}
            />
          )}
          {Object.entries<ServiceOption[]>(
            serviceListSettings as {[key in ServiceItem['serviceType']]: ServiceOption[]},
          ).map(([key, val], index) => (
            <TiteledSection
              withPL
              key={index}
              title={l(getServiceConfigByServiceType(key).label)}
            >
              <NavigationList
                Component={EntityCard}
                isLoading={isLoading}
                elementsSettings={val}
              />
            </TiteledSection>
          ))}
        </Box>
      </ScrollableWrapper>
    </GridLayout>
  )
}
