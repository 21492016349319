import {PlacementTypeEnum} from '../api/area/types'
import {TranslatorScope} from '../locale/types'
import {optionsToMapObject} from '../utils/logic'

type PlacementOption = {
  value: PlacementTypeEnum
  label: TranslatorScope
}

export const PLACEMENT_OPTIONS: PlacementOption[] = [
  {value: PlacementTypeEnum.INDOOR, label: 'indoor'},
  {value: PlacementTypeEnum.OUTDOOR, label: 'outdoor'},
]

export const PLACEMENT_MAP = optionsToMapObject(PLACEMENT_OPTIONS, (obj) => obj.label)
