import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {Class} from './types'

export type GetClassParams = {
  id?: string | null
}
export type GetClassQueryParams = undefined
export type GetClassQueryReturnType = Class | null
export type GetClassQuery = QueryMethod<GetClassParams, GetClassQueryParams, GetClassQueryReturnType>

export const makeGetClassEndpoint = ({id}: GetClassParams) => `classes/${id}`

export const getClassQuery: GetClassQuery = ({query, params}) => ({
  queryKey: ['getClassQuery', query],
  cacheTime: 1000 * 60,
  queryFn: ({queryKey: [_, config]}) => {
    if (params?.id) {
      return API.get<GetClassQueryParams, GetClassQueryReturnType>(makeGetClassEndpoint(params), {
        params: config,
      }).then((res) => res.data)
    }
    return new Promise((res) => res(null))
  },
})
