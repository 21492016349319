// eslint-disable-next-line no-restricted-imports
import {Button as RawButton, Flex} from 'exsportia-components'
import {ComponentProps, FC, PropsWithChildren, useCallback, useMemo} from 'react'

import {Loader} from '../loader/loader'
import {buttonStylesMap} from './settings'

type Props = {
  disabled?: boolean
  onClick?: ComponentProps<typeof RawButton>['onClick']
  type?: 'cancel' | 'flat_short' | 'flat' | 'icon' | 'primary_green' | 'primary' | 'secondary'
  icon?: string
  isLoading?: boolean
}

export const Button: FC<PropsWithChildren<Props>> = ({
  icon,
  type = 'primary',
  children,
  onClick,
  disabled = false,
  isLoading = false,
}) => {
  const settings = useMemo(() => buttonStylesMap[type], [type])
  const onClickToUse = useCallback((e: any) => !isLoading && onClick?.(e), [onClick, isLoading])
  const childrenToUse = useMemo(
    () => (isLoading ? <Loader type={type === 'primary' ? 'contrast' : 'default'} /> : children),
    [children, isLoading],
  )

  return (
    <RawButton
      {...settings}
      disabled={disabled}
      onClick={onClickToUse}
      icon={icon}
    >
      <Flex opacity={disabled ? 0.5 : 1}>{childrenToUse}</Flex>
    </RawButton>
  )
}
