import {Map as ImmutableMap} from 'immutable'

/**
 * Interface of extended Immutable.Map that not allows to set or get value not included to init generic type.
 *
 * Don't use it if you expect Map to have flexible structure.
 * @package Immutable helper
 */
export type TypedMap<T extends {}> = {
  get<K extends keyof T>(_name: K): T[K]
  // @ts-ignore
  set<K extends keyof T>(_name: K, _value: T[K]): this
} & ImmutableMap<keyof T, T[keyof T]>

/**
 * Extended Immutable.Map that not allows to set or get value not included to init generic type.
 *
 * Don't use it if you expect Map to have flexible structure.
 * @return Immutable.Map with ITypedMap interface
 * @package Immutable helper
 */
export function Map<T extends {}>(value: T) {
  return ImmutableMap(value) as TypedMap<T>
}
