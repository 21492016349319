import {Flex} from 'exsportia-components'
import {TextType} from 'exsportia-components/dist/types'
import {FC} from 'react'

type Props = {
  left?: TextType
  center: TextType
  right?: TextType
}

export const GridLayoutHeader: FC<Props> = ({left, center, right}) => {
  const isOnlyLeft = !right && left
  const isOnlyRight = !left && right
  return (
    <Flex
      px={'16px'}
      height={'max-content'}
      minHeight='56px'
      justifyContent={'space-between'}
      alignItems={'center'}
      borderBottom={'default-border'}
    >
      <Flex
        flexGrow={1}
        justifyContent={'center'}
        alignItems={'center'}
        zIndex={isOnlyRight ? -9999 : 'unset'}
      >
        {isOnlyRight && right}
        {left}
      </Flex>
      <Flex
        flexGrow={100}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {center}
      </Flex>
      <Flex
        flexGrow={1}
        justifyContent={'center'}
        alignItems={'center'}
        zIndex={isOnlyLeft ? -9999 : 'unset'}
      >
        {isOnlyLeft && left}
        {right}
      </Flex>
    </Flex>
  )
}
