import {Box, Text} from 'exsportia-components'
import {IconType, TextType} from 'exsportia-components/dist/types'
import {useFormikContext} from 'formik'
import React, {FC, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'

import {VenueQueries} from '../../api/venue'
import {EmptyList} from '../../components/empty-list/empty-list'
import {InstructorCard} from '../../components/instructor-card/instructor-card'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {BookingService} from '../../services/booking'
import {CreateServiceBookingFormType} from '../../services/booking/forms/create-service-booking-form'
// import {CreateSpaceBookingFormType} from '../../services/booking/forms/create-space-booking-form'
import {NavigationList} from '../../ui/components/navigation-list/navigation-list'
import {ScrollableWrapper} from '../../ui/components/scrollable-wrapper/scrollable-wrapper'
import {useLocale} from '../../utils/i18n'
import {isNilOrEmpty, isNotNilAndNotEmpty} from '../../utils/logic'

export type ElementSettingsType = {
  name: TextType
  image: IconType
}

export type ElementsSettingsType = ElementSettingsType[] | []

const Header: FC<{entity: string}> = ({entity}) => {
  const {l} = useLocale()
  const {venueId} = useParams<{venueId: string}>()
  const {data} = useQuery(VenueQueries.getVenueQuery({params: {id: venueId}}))
  return (
    <SimpleGridLayoutHeader
      title={entity === 'teacher' ? data?.entityNames.teachers || l('instructors') : l('assignedInstructors')}
    />
  )
}

export const SelectInstructorPage: FC = () => {
  const {language} = useLocale()
  const navigate = useNavigate()
  const params = useParams<{venueId: string}>()
  const {values, setFieldValue} = useFormikContext<
    CreateServiceBookingFormType
  >()
  const {isLoading, data = []} = useQuery(
    BookingService.queries.getVenueInstructorsQuery({
      query: {
        venueId: params.venueId!,
        // selectedDuration: 15,
      },
    }),
  )

  const isTeacher = values.entity === 'teacher'

  const elementsSettings: ElementsSettingsType = useMemo(
    () =>
      data
        .filter((el) => isTeacher
          ? isNotNilAndNotEmpty(values.service?.teachersIds) ? values.service?.teachersIds.includes(el.uid) : true
          : (values.service?.selectedTeachers || []).includes(el.uid))
        .map((el) => ({
          onClick: async () => {
            await Promise.all([setFieldValue('teacher', el), setFieldValue('teacherId', el.uid)])
            navigate(
              isTeacher ?
                values.flowStartsFromService
                  ? isNotNilAndNotEmpty(values.service?.selectedAreas) ? '../select-playspace' : '../select-date-time'
                  : '../select-service'
                : '../select-date-time')
          },
          name: (
            <Text
              text={el.name}
              settings={{
                fontSize: 'h3',
                croppedLines: 1,
                fontWeight: 'bold',
                lineHeight: 'card-title-line-height',
              }}
            />
          ),
          image: (
            <Box
              width='48px'
              height='48px'
              borderRadius='50%'
              backgroundSize='cover'
              background={`url(${el.profileImage.secure_url}) no-repeat center center`}
            />
          ),
        })),
    [data, language, isTeacher], // eslint-disable-line
  )

  if (isNilOrEmpty(data) && !isLoading) {
    return (
      <GridLayout header={<Header entity={values.entity} />}>
        <EmptyList />
      </GridLayout>
    )
  }

  return (
    <GridLayout
      withoutPadding
      header={<Header entity={values.entity} />}
    >
      <ScrollableWrapper>
        <Box pb='8px'>
          <NavigationList
            isLoading={isLoading}
            Component={InstructorCard}
            elementsSettings={elementsSettings}
          />
        </Box>
      </ScrollableWrapper>
    </GridLayout>
  )
}
