import {Box, Flex} from 'exsportia-components'
import {useFormikContext} from 'formik'
import React, {FC, useCallback, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'

import {OpeningTimes} from '../../api/types'
import {InlineCalendar} from '../../components/inline-calendar/inline-calendar'
import {SelectedTimeRow} from '../../components/selected-time-row/selected-time-row'
import {TimeSelect} from '../../components/timeslot-grid/timeslot-grid'
import {mergeOpeningTimes} from '../../helpers/logic'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {LanguagesEnum} from '../../locale/types'
import {BookingService} from '../../services/booking'
import {CreateServiceBookingFormType} from '../../services/booking/forms/create-service-booking-form'
import {VenueService} from '../../services/venue'
import {Button} from '../../ui/components/button/button'
import {DividerWithContent} from '../../ui/components/divider-with-content/divider-with-content'
import {InfoMessage} from '../../ui/components/info-message/info-message'
import {ScrollableWrapper} from '../../ui/components/scrollable-wrapper/scrollable-wrapper'
import {useLocale, useLuxon} from '../../utils/i18n'
import {isNotNilAndNotEmpty} from '../../utils/logic'

export const SelectBookingDateTimePage: FC = () => {
  const {l} = useLocale()
  const luxon = useLuxon()
  const params = useParams<{venueId: string}>()
  const navigate = useNavigate()
  const {setFieldValue, values, setFieldError, setValues, errors} = useFormikContext< CreateServiceBookingFormType>()
  const handleDayClick = useCallback(
    (date: string) => {
      setFieldValue('date', date)
    },
    [setFieldValue],
  )
  const {data, isLoading} = useQuery(
    BookingService.queries.getServiceTimeslotGridQuery({
      query: {
        areaId: values.area?.uid,
        date: values.date,
        participantsQty: values.participants.length || 1,
        sportId: values.serviceId!,
        teacherId: !values.isJustTheSpace ? values.teacher?.uid : null,
      },
    }),
  )
  const {data: venueData} = useQuery(VenueService.queries.getVenueQuery({params: {id: params.venueId}}))
  const addError = useCallback(
    (message: string) => {
      setFieldError('time', message)
    },
    [setFieldError],
  )

  const clearErrors = useCallback(() => {
    setFieldError('time', undefined)
  }, [setFieldError])
  const timeslot =
    values.entity === 'teacher' && !values.isJustTheSpace ? values.teacher?.timeSlot || 0 : values.area?.timeSlot || 0

  const onTimeChange = useCallback(
    (from: number | null = null, to: number | null = null) => {
      const toDraft = to
        ? luxon
            .fromMillis(to)
            .plus({
              minute: timeslot,
            })
            .toMillis()
        : null
      setFieldValue('time', [from, toDraft]).catch(() => console.log('error while setting time'))
    },
    [setFieldValue, values.date],
  ) //eslint-disable-line

  const handleNavigateToNextStep = useCallback(() => {
    const validated = Object.keys(errors).length === 0
    if (validated) {
      setFieldValue('participants', [])
      navigate('../set-participants')
    }
  }, [errors, navigate])

  const openingTimes = useMemo(() => {
    if (!venueData) {
      return {}
    }
    const openingTimesToMerge = [venueData.openingTimes]
    if (values.isJustTheSpace) {
      return values.area?.operatingHoursDiffer ? values.area.openingTimes : venueData.openingTimes
    }
    if (values.teacher?.operatingHoursDiffer) {
      openingTimesToMerge.push(values.teacher.openingTimes)
    }
    if (values.area?.operatingHoursDiffer) {
      openingTimesToMerge.push(values.area.openingTimes)
    }
    return mergeOpeningTimes(openingTimesToMerge)
  }, [venueData, values])

  const filterDate = useCallback(
    (day: string) => {
      if (!values.service) {
        return true
      }
      const dayOfWeek = luxon
        .fromFormat(day, 'dd/MM/yyyy')
        .setLocale(LanguagesEnum.EN)
        .toFormat('cccc')
        .toLowerCase() as keyof OpeningTimes
      const selectedDayOpeningTimes = openingTimes[dayOfWeek]
      if (!selectedDayOpeningTimes) {
        return false
      }
      const startOfTodayLuxon = luxon.now().startOf('day')
      const startOfCurrentDayLuxon = luxon.fromFormat(day, 'dd/MM/yyyy').startOf('day')
      let isActive = selectedDayOpeningTimes.isActive
      isActive = isActive && startOfCurrentDayLuxon.toMillis() >= startOfTodayLuxon.toMillis()
      if (!values.service.howFarCanBookUnlimited) {
        const maxDayTimeStamp = startOfTodayLuxon.plus({day: values.service.howFarCanBookAmount}).toMillis()
        isActive = isActive && maxDayTimeStamp - startOfCurrentDayLuxon.toMillis() >= 0
      }
      return isActive
    },
    [luxon, openingTimes, values.service],
  )

  const additionalBackAction = useCallback(() => {
    setValues({
      ...values,
      time: [null, null],
      date: luxon.now().toFormat('dd/MM/yyyy'),
    })
  }, [setFieldValue])

  const header = useMemo(
    () => (
      <SimpleGridLayoutHeader
        additionalOnBackAction={additionalBackAction}
        title={l('selectTime')}
      />
    ),
    [l],
  )

  return (
    <GridLayout
      withoutPadding
      header={header}
    >
      <ScrollableWrapper>
        <Box
          pt='16px'
          position='sticky'
          top='0px'
          left='0px'
          backgroundColor='bg'
          zIndex='2'
        >
          <Box px={'16px'}>
            <InlineCalendar
              filterDate={filterDate}
              selectedDate={values.date}
              onDayClick={handleDayClick}
            />
          </Box>
          <Box py={'8px'}>
            <DividerWithContent content={luxon.fromFormat(values.date, 'dd/MM/yyyy').toFormat('ccc, MMM d, yyyy')} />
          </Box>
        </Box>
        <Box px={'16px'}>
          <TimeSelect
            date={values.date}
            time={values.time}
            slots={data || []}
            addError={addError}
            timeSlot={timeslot}
            onChange={onTimeChange}
            isLoading={isLoading}
            noDuration={values.service?.pricePeriod === 'NO_DURATION'}
            clearErrors={clearErrors}
            minTimeReservation={values.service?.minTimeReservation || values.service?.minTime || 0}
            maxTimeReservation={values.service?.maxTimeReservation || values.service?.maxTime || Infinity}
          />
          <Box
            bg={'#FFFFFF'}
            pb={'16px'}
            position={'sticky'}
            bottom='0'
            left='0'
          >
            {errors.time && (
              <Box
                width={'100%'}
                pb={'8px'}
              >
                <InfoMessage
                  text={errors.time}
                  preset={'error'}
                />
              </Box>
            )}
            <Flex
              pt={'12px'}
              gap='12px'
              alignItems='center'
            >
              {isNotNilAndNotEmpty(values.time) && <SelectedTimeRow time={values.time} />}
              <Button
                disabled={Boolean(!!Object.keys(errors).length || !values.time[0] || !values.time[1])}
                onClick={handleNavigateToNextStep}
              >
                {l('next')}
              </Button>
            </Flex>
          </Box>
        </Box>
      </ScrollableWrapper>
    </GridLayout>
  )
}
