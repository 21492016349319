import {TextSettingsProps} from 'exsportia-components/dist/components/atoms/text'
import {TitleProps} from 'exsportia-components/dist/components/atoms/title'
import {StyleCommonType, StyleSizeSpaceType} from 'exsportia-components/dist/types'

import {theme} from '../../theme/theme'

export type PricingDetailsSettingsProps = {
  width: StyleSizeSpaceType
  height: StyleSizeSpaceType
  titleSettings: {
    mb: StyleSizeSpaceType
    styleType: TitleProps['styleType']
  }
  summarySettings: {
    mt: StyleSizeSpaceType
    justifyContent: StyleCommonType
    titleStyleType: TitleProps['styleType']
  }
  elementSettings: {
    xColor: StyleCommonType
    height: StyleSizeSpaceType
    xMargin: StyleSizeSpaceType
    priceMr: StyleSizeSpaceType
    marginTop: StyleSizeSpaceType
    justifyContent: StyleCommonType
    textMaxWidth: StyleSizeSpaceType
    priceSettings: TextSettingsProps
  }
}

export const defaultSettings: PricingDetailsSettingsProps = {
  width: '100%',
  height: 'max-content',
  titleSettings: {styleType: 'h3', mb: theme.space.multiplier},
  summarySettings: {
    titleStyleType: 'h6',
    justifyContent: 'space-between',
    mt: theme.space.multiplier3x,
  },
  elementSettings: {
    textMaxWidth: '90%',
    height: 'max-content',
    justifyContent: 'space-between',
    xColor: 'text-secondary',
    priceMr: theme.space.multiplier2x,
    marginTop: theme.space.multiplier,
    xMargin: `0 ${theme.space.multiplier0_5x}`,
    priceSettings: {width: 'max-content'},
  },
}

export const typeStylesMap = {default: defaultSettings}

export const additionalStylesMap = {}
