import {Box} from 'exsportia-components'
import {useFormikContext} from 'formik'
import React, {FC, memo, useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {IntegratedSignUpForm, IntegratedSignUpFormType} from '../../services/auth/forms/integrated-sign-up-form'
import {useAuthState} from '../../services/auth/hooks'
import {useLocale} from '../../utils/i18n'
import {ClassSummarySection} from './class-summary-section'
import {ServiceSummarySection} from './service-summary-section'
import {BookingCommonType, UserInfoSection} from './user-info-section'

const Header: FC = memo(() => {
  const {l} = useLocale()
  return <SimpleGridLayoutHeader title={l('customer')} />
})

type Props = {
  type: 'class' | 'service'
}

export const BookingSummary: FC<Props> = ({type}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [wholePrice, setWholePrice] = useState<number | undefined>()
  const {isLoggedIn} = useAuthState()
  const [loggedInState, setLoggedInState] = useState(isLoggedIn)
  const navigate = useNavigate()
  const {submitForm, setValues, values: bookingState} = useFormikContext<BookingCommonType>()
  const {service, class: cls} = bookingState
  const {paymentOnExsportia = false} = service || cls || {}
  const withPaymentStep = useMemo(() => paymentOnExsportia && wholePrice !== 0, [paymentOnExsportia, wholePrice])
  const handleBook = useCallback(
    (values: IntegratedSignUpFormType) => {
      setValues({...bookingState, ...values, paymentType: 'payOnLocation'})
      if (values.isRequestedToCreateAccount && !isLoggedIn) {
        return
      }
      if (withPaymentStep) {
        return navigate('../set-payment-method')
      }
      submitForm()
    },
    [isLoggedIn, withPaymentStep, bookingState, setValues, submitForm],
  )
  useEffect(() => {
    if (loggedInState && !isLoggedIn) {
      navigate(-1)
      setValues({...bookingState, participants: []})
    }
    setLoggedInState(isLoggedIn)
  }, [isLoggedIn, bookingState])

  return (
    <GridLayout header={<Header />}>
      <Box>
        <IntegratedSignUpForm callback={handleBook}>
          <UserInfoSection
            withPaymentStep={withPaymentStep}
            isLoading={isLoading}
            setBookingStateValues={setValues}
            bookingState={bookingState}
          />
        </IntegratedSignUpForm>
      </Box>
      <Box>
        {type === 'class' ? (
          <ClassSummarySection
            setIsLoading={setIsLoading}
            setWholePrice={setWholePrice}
          />
        ) : (
          <ServiceSummarySection
            setIsLoading={setIsLoading}
            setWholePrice={setWholePrice}
          />
        )}
      </Box>
    </GridLayout>
  )
}
