import {API} from '../../networking/http'
import {auth} from '../../utils/firebase'
import {QueryMethod} from '../helpers/types'
import {User} from './types'

export type GetUserParams = {id?: string}
export type GetUserQueryParams = undefined
export type GetUserQueryReturnType = User | null
export type GetUserQuery = QueryMethod<GetUserParams, GetUserQueryParams, GetUserQueryReturnType>

export const makeGetUserEndpoint = ({id}: GetUserParams) => `user/${id}/native`

export const getUserQuery: GetUserQuery = ({params}) => ({
  queryKey: ['GetUserQuery'],
  cacheTime: 1000 * 60 * 60,
  refetchOnWindowFocus: false,
  queryFn: ({queryKey: [_, config]}) => {
    if (params?.id && auth.currentUser?.uid && auth.currentUser.uid === params.id) {
      return API.get<GetUserQueryParams, GetUserQueryReturnType>(makeGetUserEndpoint(params), {params: config}).then(
        (res) => {
          return res.data
        },
      )
    }
    return new Promise((res) => res(null))
  },
})
