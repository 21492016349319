import {envsUrlsMap} from './environment'

export const NO_USER_PHOTO =
  'https://firebasestorage.googleapis.com/v0/b/exsportia-dev.appspot.com/o/system-images%2Favatar.jpg?alt=media&token=f219d025-f702-40cc-a0fd-c859bda0e199' // eslint-disable-line

export const DEFAULT_VENUE_IMAGE =
  'https://firebasestorage.googleapis.com/v0/b/exsportia-dev.appspot.com/o/system-images%2Fvenue.png?alt=media&token=1fdfec67-a3ef-46f6-957c-92e9f9919649' // eslint-disable-line

const env = process.env.REACT_APP_ENV || 'production'

export const TERMS_AND_CONDITIONS = `${envsUrlsMap[env].VMS}/terms-and-conditions`

export const PRIVACY_POLICY = `${envsUrlsMap[env].VMS}/privacy-policy`
