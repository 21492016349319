import './settings/styles.scss'

import {Box, Field, Flex, Text} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {FC, useMemo} from 'react'

import {SignUpFormType} from '../../../services/auth/forms/sign-up-form'
import {useAppLoading} from '../../../services/global/hooks'
import {Button} from '../../../ui/components/button/button'
import {useLocale, useLuxon} from '../../../utils/i18n'

type DobSignUpStepProps = {
  handleGoToNextStep: () => void
  backAction?: (e: Event) => void
}

export const DobSignUpStep: FC<DobSignUpStepProps> = ({handleGoToNextStep, backAction}) => {
  const {l} = useLocale()
  const luxon = useLuxon()
  const {isLoading} = useAppLoading()
  const {validateForm, setFieldValue, values, errors} = useFormikContext<SignUpFormType>()
  const handleNext = () => {
    (async () => {
      const validateResult = await validateForm()
      if (!validateResult.phone) {
        handleGoToNextStep()
      }
    })()
  }
  const setDob = (value: Date) => {
    (async () => await setFieldValue('dob', luxon.fromJSDate(value)))()
  }
  const selectedDateString = useMemo(() => (values.dob ? values.dob.toLocaleString() : ''), [values])

  return (
    <Box pb={'24px'}>
      <Flex mb='16px'>
        <Text text={l('enter_your_dob', {caseAction: 'capitalize'})} />
      </Flex>
      {/* @ts-ignore */}
      <Field
        label={l('dob')}
        fieldType='datePicker'
        setFieldValue={(_, value) => setDob(value)}
        input={{
          id: 'dob',
          name: 'dob',
          settings: {
            calendarSettings: {
              popperTransition: 'none',
              width: '100%'
            },
          }
        }}
        {...{
          maxDate: new Date(),
          externalValue: selectedDateString,
        }}
      />
      <Box
        mt='5px'
        mb='10px'
        fontSize='12px'
        color='error'
      >
        {errors.dob}
      </Box>
      <Flex
        gap='16px'
        mt={'16px'}
      >
        {backAction && (
          <Button
            disabled={isLoading}
            type='secondary'
            onClick={backAction}
          >
            {l('back')}
          </Button>
        )}
        <Button onClick={errors.dob ? () => null : handleNext}>{l('next')}</Button>
      </Flex>
    </Box>
  )
}
