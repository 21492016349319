import {createAction, createReducer, Reducer, SimpleActionCreator} from 'redux-act'
import {createSelector, Selector} from 'reselect'

import {Store} from '../redux/types'
import {TypedMap} from './immutable'

// just close this file right now

export type ActionsMap<T> = {
  [key in keyof Required<T>]: SimpleActionCreator<Required<T>[key]>
} & {clear: SimpleActionCreator<void>}

type Combiner<T extends {}, X, Z> = (state: TypedMap<T>, _data?: Z) => X

// type SelectFunction<T extends {}> = <X extends any, Z extends any>(
type SelectFunction<T extends {}> = <X, Z>(combiner: Combiner<T, X, Z>) => (params?: Z) => Selector<Z, X>

export function createBasicActions<T extends {}>(initialState: TypedMap<T>): ActionsMap<T> {
  return initialState.reduce((acc, _, key: keyof T) => ({...acc, [key]: createAction(key as string)}), {
    clear: createAction('clear'),
  } as any)
}

export function createBasicReducer<T extends {}>(
  initialState: TypedMap<T>,
  basicActions: ActionsMap<any>,
): Reducer<typeof initialState> {
  const reducer: Reducer<typeof initialState> = createReducer({}, initialState)
  initialState.forEach((_, key) => {
    reducer.on(basicActions[key], (state: TypedMap<T>, val: T[typeof key]) => state.set(key, val))
  })
  reducer.on(basicActions.clear, () => initialState)
  return reducer
}

/**
 * @param featureStoreName specific field from global store related to feature
 * @returns returns selector for specified global store field
 */
export function selectFeatureStore(featureStoreName: keyof Store): Selector<any, Store[keyof Store]> {
  return (store: Store): Store[keyof Store] => store[featureStoreName]
}

/**
 * @param featureStoreName specific field from global store related to feature
 * @returns function that take selector function as argument and returns valid selector
 */
export function makeSelect<T extends {}>(featureStoreName: keyof Store): SelectFunction<T> {
  return (combiner) => (data) =>
    createSelector(
      selectFeatureStore(featureStoreName),
      // @ts-ignore
      (state) => combiner(state, data),
    )
}

/**
 * @param initialState initial state of corresponding reducer
 * @param selectFoo select function created using makeSelect and targeted to corresponding reducer
 * @returns object with simple selectors for each field of state
 */
export function createBasicSelectors<T extends {}>(initialState: TypedMap<T>, selectFoo: SelectFunction<T>) {
  return initialState.reduce<{[key in keyof T]: () => Selector<T, T[key]>}>(
    (acc, _, key: keyof T) => ({...acc, [key]: selectFoo<T[typeof key], T[typeof key]>((state) => state.get(key))}),
    {} as any,
  )
}
