import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {ServicesList} from './types'

export type GetVenueServicesParams = undefined
export type GetVenueServicesQueryParams = {
  venueId?: string
  isJustTheSpace: boolean
}

export type GetVenueServicesReturnType = ServicesList
export type GetVenueServicesQuery = QueryMethod<
  GetVenueServicesParams,
  GetVenueServicesQueryParams,
  GetVenueServicesReturnType
>

export const makeGetVenueServicesEndpoint = () => 'services/all'

export const getVenueServicesQuery: GetVenueServicesQuery = ({query}) => ({
  queryKey: ['getVenueServicesQuery', query],
  cacheTime: 1000 * 300,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<GetVenueServicesQueryParams, GetVenueServicesReturnType>(makeGetVenueServicesEndpoint(), {
      params: config,
    }).then((res) => res.data),
})
