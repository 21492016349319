import {createAction} from 'redux-act'

import {SignUpMutationParams} from '../../api/auth/sign-up.mutation'
import {createBasicActions} from '../../utils/redux'
import {initialState} from './constants'
import {RequestStatusType} from './forms/forgot-password-form'
import {AuthState, OobCode} from './types'

export const basicActions = createBasicActions<AuthState>(initialState)

export const checkEmailRequestAction = createAction<{
  email: string
}>('checkEmailRequest')

export const signInRequestAction = createAction<{
  email: string
  password: string
  venueId?: string
  callback?: () => void
  addLeadData?: {
    venueId?: string
    body: SignUpMutationParams
  }
}>('signInRequest')

export const signUpRequestAction = createAction<{
  email: string
  password: string
  firstName: string
  lastName: string
  venueId?: string
  dob: string
  avatar?: string | null
  phone: string
  callback: () => void
}>('signInRequest')

export const addLeadRequestAction = createAction<SignUpMutationParams & {venueId: string; userId?: string}>(
  'addLeadRequest',
)

export const recoverPasswordRequestAction = createAction<{
  email: string
  callback: (status: RequestStatusType) => void
}>('recoverPasswordRequest')

export const resetPasswordConfirmRequestAction = createAction<{
  oobCode: OobCode
  password: string
  callback: () => void
  onError: () => void
}>('resetPasswordConfirmRequest')

export type removeAccountPayload = {
  callback?: () => void
}

export const signOutRequestAction = createAction<void>('signOutRequest')
export const removeAccountRequestAction = createAction<removeAccountPayload>('removeAccountRequestAction')
