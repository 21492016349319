import {Flex} from 'exsportia-components'
import {useCallback} from 'react'

import {TEMP_BANNED_APP, useAppDownloadData} from '../../services/venue/hooks'
import {Button} from '../../ui/components/button/button'
import {useLocale} from '../../utils/i18n'

export const DownloadAppModal = () => {
  const {l} = useLocale()
  const {downloadLink, appName, isLoading} = useAppDownloadData()
  const handleDownloadApp = useCallback(() => {
    window.open(downloadLink, '_blank')
  }, [downloadLink])
  return (
    <>
      {l('feature_in_development_download_app')}
      <Flex m='16px 0 24px'>
        <Button
          disabled={appName === TEMP_BANNED_APP || (!isLoading && !downloadLink)}
          isLoading={isLoading}
          onClick={handleDownloadApp}
        >
          {l('download_app', {caseAction: 'capitalize'})}
        </Button>
      </Flex>
    </>
  )
}
