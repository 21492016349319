import {LimitationCheckResultTypeKey} from '../api/booking/calculate-booking-price'
import {optionsToLabelMapObject, optionsToMapObject} from '../utils/logic'

export const DISCOUNT_TYPE_FREE = 'FREE'
export const DISCOUNT_TYPE_NEW_FIXED_PRICE = 'FIXED_PRICE'
export const DISCOUNT_TYPE_DISCOUNT_BY_NUMBER = 'BY_NUMBER'
export const DISCOUNT_TYPE_DISCOUNT_BY_PERCENTAGE = 'BY_PERCENTAGE'

export const AMOUNT_BY_ENTRIES = 'AMOUNT_BY_ENTRIES'
export const AMOUNT_BY_MINUTES = 'AMOUNT_BY_MINUTES'
export const AMOUNT_BY_CREDITS = 'AMOUNT_BY_CREDITS'

export const DISCOUNT_TYPE_OPTIONS = [
  {value: DISCOUNT_TYPE_FREE, label: 'free'},
  {value: DISCOUNT_TYPE_DISCOUNT_BY_PERCENTAGE, label: 'discountByProc'},
]

export const USAGE_TYPE_OPTIONS = [
  {value: AMOUNT_BY_ENTRIES, label: 'byEntries'},
  {value: AMOUNT_BY_MINUTES, label: 'byMinutes'},
  {value: AMOUNT_BY_CREDITS, label: 'byCredits'},
]

export const USAGE_TYPE_SUBSCRIPTION_OPTIONS = [
  {value: AMOUNT_BY_ENTRIES, label: 'entries'},
  {value: AMOUNT_BY_MINUTES, label: 'minutes'},
  {value: AMOUNT_BY_CREDITS, label: 'credits'},
]

export const USAGE_TYPE_AMOUNT_BY_OPTIONS = [
  {value: AMOUNT_BY_ENTRIES, label: 'entries'},
  {value: AMOUNT_BY_MINUTES, label: 'min'},
  {value: AMOUNT_BY_CREDITS, label: 'credits'},
]

export const USAGE_TYPE_SUBSCRIPTION_OPTIONS_MAP = optionsToMapObject(USAGE_TYPE_SUBSCRIPTION_OPTIONS, (obj) => obj.label)
export const USAGE_TYPE_SUBSCRIPTION_OPTIONS_LABEL_MAP: Record<LimitationCheckResultTypeKey, string> = optionsToLabelMapObject(USAGE_TYPE_SUBSCRIPTION_OPTIONS, (obj) => obj.value)

export const USAGE_TYPE_AMOUNT_BY_OPTIONS_MAP = optionsToMapObject(USAGE_TYPE_AMOUNT_BY_OPTIONS, (obj) => obj.label)

export const USAGE_TYPE_OPTIONS_MAP = optionsToMapObject(USAGE_TYPE_OPTIONS, (obj) => obj.label)

export const DISCOUNT_TYPE_OPTIONS_MAP = optionsToMapObject(DISCOUNT_TYPE_OPTIONS, (obj) => obj.label)