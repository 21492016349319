import {QRCode as QRCommon} from 'exsportia-components'
import React, {FC} from 'react'

import {defaultSettings} from './settings'

export type QRCodeProps = {
  value: string
}

export const QRCode: FC<QRCodeProps> = ({value}) => {
  return (
    <QRCommon
      value={value}
      styleType='default'
      settings={defaultSettings}
    />
  )
}
