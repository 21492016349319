/* eslint-disable max-lines-per-function */
import {Box, Flex, P} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {DateTime} from 'luxon'
import React, {FC, useCallback, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'

import {BookingQueries} from '../../api/booking'
import {CalculateBookingPriceQueryParams, CalculatedBookingPriceObject, LimitationCheckResultType, LimitationCheckResultTypeKey} from '../../api/booking/calculate-booking-price'
import {Participant} from '../../api/booking/types'
import {ClassCard} from '../../components/class-card/class-card'
import {InstructorCard} from '../../components/instructor-card/instructor-card'
import {PlayspaceCard} from '../../components/playspace-card/playspace-card'
import {PricingDetails} from '../../components/pricing-details/pricing-details'
import {SubscriptionCard} from '../../components/subscription-card/subscription-card'
import {ParticipantsList} from '../../components/timeslot-grid/components/participants-list'
import {FULL_CANCEL_POLICY_OPTIONS_MAP} from '../../constants/cancel-policy'
import {USAGE_TYPE_SUBSCRIPTION_OPTIONS_LABEL_MAP} from '../../constants/usage-type'
import {getParticipantsEquipment} from '../../helpers/data'
import {AreaService} from '../../services/area'
import {CreateClassBookingFormType} from '../../services/booking/forms/create-class-booking-form'
import {ClassService} from '../../services/class'
import {ClassSessionsService} from '../../services/class-sessions'
import {useServiceOptions} from '../../services/service-options/hooks'
import {VenueService} from '../../services/venue'
import {theme} from '../../theme/theme'
import {CheckBox} from '../../ui/components/input/checkbox'
import {TextArea} from '../../ui/components/input/text-area'
import {LabeledValueRow} from '../../ui/components/key-value-row/labeled-value-row'
import {LabeledSection} from '../../ui/components/labeled-section/labeled-section'
import {TiteledSection} from '../../ui/components/titeled-section/titeled-section'
import {auth} from '../../utils/firebase'
import {LocaleOptions, useLocale, useLuxon} from '../../utils/i18n'
import {isNotNilAndNotEmpty} from '../../utils/logic'
import {useModalActions} from '../../utils/modals'
import {NO_USER_PHOTO} from '../../utils/remote-content'

const defaultLOptions: LocaleOptions = {caseAction: 'capitalize'}

export const ClassSummarySection: FC<{
  setIsLoading: (arg: boolean) => void
  setWholePrice?: (arg?: number) => void
// eslint-disable-next-line max-lines-per-function
}> = ({setIsLoading, setWholePrice}) => {
  const {l, language} = useLocale()
  const luxon = useLuxon()
  const {openModal} = useModalActions()
  const params = useParams<{venueId: string}>()
  const {data: venueData, isLoading: isVenueLoading} = useQuery(
    VenueService.queries.getVenueQuery({params: {id: params.venueId}}),
  )
  const {values, setFieldValue} = useFormikContext<CreateClassBookingFormType>()
  const {data: classData, isLoading: isClassLoading} = useQuery(
    ClassService.queries.getClassQuery({params: {id: values.classId}}),
  )
  const {getServiceConfigByServiceType} = useServiceOptions([classData?.sportType])
  const {data: areaData} = useQuery(AreaService.queries.getAreaQuery({params: {id: values.areaId}}))
  const {data: classSessionData} = useQuery(
    ClassSessionsService.queries.getClassSessionQuery({params: {id: values.sessionId}}),
  )
  const queryData: CalculateBookingPriceQueryParams = useMemo(() => ({
    options: [
      {
        bookingType: 'CLASS',
        venueId: venueData?.uid,
        classId: values.classId,
        slotId: values.sessionId,
        sportType: values.sportType,
        coverEquipment: values.coverEquipment,
        userId: values.userId || auth.currentUser?.uid || undefined,
        useForParticipants: values.useForParticipants,
        date: luxon.fromMillis(Number(classSessionData?.date_timestamp)).toFormat('dd/MM/yyyy'),
        participants: values.participants.map((el) => {
          const elToStave = {...el}
          delete elToStave.membership
          delete elToStave.membershipActivity
          return elToStave
        }),
      },
    ],
  }), [venueData, classSessionData?.date_timestamp])
  const {data, isLoading} = useQuery(BookingQueries.calculateBookingPriceQuery({
    enabled: !!values.participants.length && !!classSessionData?.date_timestamp,
    query: queryData,
    refetchOnWindowFocus: false,
  }))
  const wholeDiscount = useMemo(() => (data ? data.reduce((acc, el) => acc + Number(el.discount), 0) : 0), [data])
  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading, setIsLoading])
  useEffect(() => {
    setWholePrice && setWholePrice(data?.[0].price)
  }, [isLoading, data, setWholePrice])
  const {bookingDateTime, bookingDuration} = useMemo<{
    bookingDateTime: DateTime
    bookingDuration: string
  }>(() => {
    if (!classSessionData) {
      // VALIDATE FORM AND NAVIGATE
      return {bookingDateTime: luxon.now(), bookingDuration: '-'}
    }
    return {
      bookingDateTime: luxon.fromMillis(Number(classSessionData.date_timestamp)),
      bookingDuration: l('minutes_short', {value: classSessionData.duration}),
    }
  }, [l, luxon, classSessionData])
  const {label} = useMemo(() => getServiceConfigByServiceType(classData?.sportType), [classData])

  const cancelPolicyToUse = values.participants[0]?.membership?.cancelPolicy ?? classData?.cancelPolicy
  const cancelPolicyKey = cancelPolicyToUse && FULL_CANCEL_POLICY_OPTIONS_MAP[cancelPolicyToUse].description
  const cancelPolicyDescription = cancelPolicyKey ? l(cancelPolicyKey) : cancelPolicyToUse

  const bookingPriceData = useMemo(() => {
    if (!data) {
      return []
    }
    return [
      ...data.map((el: CalculatedBookingPriceObject) => {
        const timeArr = []
        if (el.duration.hours) {
          timeArr.push(l('hours_short', {value: el.duration.hours}))
        }
        if (el.duration.minutes) {
          timeArr.push(l('minutes_short', {value: el.duration.minutes}))
        }
        const priceAndDiscount = el.participants.reduce(
          (acc, participant) => ({
            price: Number(acc.price) + Number(participant.totalPrice) + Number(participant.discount),
            discount: Number(acc.discount) + Number(participant.discount),
          }),
          {
            price: 0,
            discount: 0,
          },
        )
        return {
          discount: priceAndDiscount.discount,
          count: el.numberOfPeople,
          price: priceAndDiscount.price,
          text: l('service_price_element', {time: timeArr.join(' '), service: l(label)}),
        }
      }),
      ...getParticipantsEquipment(data.reduce<Participant[]>((acc, el) => [...acc, ...el.participants], [])),
    ]
  }, [data, l]) // eslint-disable-line

  const usageData: any = useMemo(
    () =>
      data
        ? data[0]?.participants.reduce(
            (acc, participant) => {
              const used: LimitationCheckResultType | null = participant.limitationCheckResult?.used || null
              if (!used) {
                return acc
              }
              const firstPropName: LimitationCheckResultTypeKey | null = Object.keys(used)[0] as LimitationCheckResultTypeKey
              return {
                used: acc.used + (used[firstPropName] || 0),
                amountByType: USAGE_TYPE_SUBSCRIPTION_OPTIONS_LABEL_MAP[firstPropName],
              }
            },
            {
              used: 0,
              amountByType: USAGE_TYPE_SUBSCRIPTION_OPTIONS_LABEL_MAP.entries
            },
          )
        : null,
    [data],
  )

  const playspaceCard = useMemo(() => {
    if (!areaData) {
      return null
    }
    let subTextElements: any[] = []
    if (isNotNilAndNotEmpty(areaData.areaSize)) {
      subTextElements = [areaData.areaSize]
    }
    if (isNotNilAndNotEmpty(areaData.surfaceType)) {
      subTextElements = [...subTextElements, l(areaData.surfaceType!, {caseAction: 'capitalize'})]
    }
    return (
      <PlayspaceCard
        styleType='square'
        image={
          <Box
            backgroundSize='cover'
            height='48px'
            width='72px'
            background={`url(${areaData.media?.[areaData.thumbIndex].secure_url}) no-repeat center`}
          />
        }
        name={areaData.name}
        subTextElements={subTextElements}
      />
    )
  }, [values, language])

  const openParticipantsModal = useCallback(() => {
    openModal({
      title: l('participants'),
      footerConfig: {
        disabled: true,
      },
      component: (
        <ParticipantsList
          participants={values.participants}
          displayEquipmentCount={true}
        />
      ),
    })
  }, [values.participants, openModal])

  useEffect(() => {
    setFieldValue('session', classSessionData || null)
  }, [classSessionData])

  useEffect(() => {
    setFieldValue('class', classData || null)
  }, [classData])

  return (
    <Box>
      <ClassCard
        isLoading={isVenueLoading || isClassLoading}
        venueName={venueData?.name}
        className={classData?.name}
        subTextElements={[`${values.participants.length} ${l('participants')}`]}
        image={
          <Box
            backgroundSize='cover'
            height='72px'
            width='72px'
            background={`url(${
              classData?.media ? classData.media[classData.thumbIndex || 0].secure_url : NO_USER_PHOTO
            }) no-repeat center`}
          />
        }
      />
      <TiteledSection title={l('booking_information', defaultLOptions)}>
        <LabeledValueRow label={l('dateAndTime')}>
          {bookingDateTime.toUTC().toFormat('HH:mm, MMM dd, yyyy')}
        </LabeledValueRow>
        <LabeledValueRow label={l('duration')}>{bookingDuration}</LabeledValueRow>
        <LabeledValueRow label={l('participants')}>
          <Flex
            cursor='pointer'
            onClick={openParticipantsModal}
          >
            {`${values.participants.length} ${l('participants')}`}
          </Flex>
        </LabeledValueRow>
      </TiteledSection>
      <TiteledSection title={l('about', defaultLOptions)}>
        {playspaceCard && <LabeledSection label={l('playSpace', defaultLOptions)}>{playspaceCard}</LabeledSection>}
        {values.instructors && isNotNilAndNotEmpty(values.instructors) && (
          <LabeledSection label={l('instructors')}>
            {values.instructors.map((instructor, index) => (
              <Box
                mt={'8px'}
                key={`instructor-card-${index}`}
              >
                <InstructorCard
                  key={instructor.uid || instructor.name}
                  name={instructor.name}
                  image={
                    <Box
                      backgroundSize='cover'
                      height='48px'
                      width='48px'
                      background={`url(${instructor.profileImage?.secure_url || NO_USER_PHOTO}) no-repeat center`}
                    />
                  }
                />
              </Box>
            ))}
          </LabeledSection>
        )}
      </TiteledSection>
      {values.participants[0]?.membership && (
        <TiteledSection title={l('subscription')}>
          <SubscriptionCard
            styleType='summary'
            isLoading={isLoading}
            usage={usageData}
            imageUrl={values.participants[0].membership.logo?.secure_url || ''}
            name={values.participants[0].membership.name}
          />
        </TiteledSection>
      )}
      <TiteledSection title={l('extra_details', defaultLOptions)}>
        <Box>
          <Flex
            py={theme.space.multiplier0_5x}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <P fontSize={'tag'}>{l('specialRequest')}</P>
            <Box>
              <CheckBox id={'hasSpecialRequests'} />
            </Box>
          </Flex>
          {values.hasSpecialRequests && (
            <Box pt={'8px'}>
              <TextArea id={'specialRequest'} />
            </Box>
          )}
        </Box>
        {cancelPolicyDescription && (
          <Box pt={'8px'}>
            <LabeledSection label={l('cancelPolicy')}>{cancelPolicyDescription}</LabeledSection>
          </Box>
        )}
      </TiteledSection>
      <PricingDetails
        discount={wholeDiscount}
        isLoading={isLoading}
        currency={venueData?.currency ?? ''}
        values={bookingPriceData}
      />
    </Box>
  )
}
