import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {Booking} from './types'

export type GetBookingParams = {
  id?: string | null
}
export type GetDraftEntityParams = {
  entityId: string | null
}
export type GetBookingQueryParams = {
  entity: string | null;
  entityId: string | null
}
export type GetBookingQueryReturnType = Booking | null
export type DraftEntityLineType = {
  text: string
  price: number
  subtract?: boolean
}
export type getDraftEntityQueryReturnType = {
  totalPrice: number
  entityId: string
  isPaid: boolean
  isCancelled?: boolean
  status: string
  lines: DraftEntityLineType[]
} | null
export type GetBookingQuery = QueryMethod<GetBookingParams, GetBookingQueryParams, GetBookingQueryReturnType>
export type GetDraftEntityQuery = QueryMethod<GetDraftEntityParams, GetBookingQueryParams, getDraftEntityQueryReturnType>

export const makeGetBookingEndpoint = ({id}: GetBookingParams) => `bookings/${id}`

export const getBookingQuery: GetBookingQuery = ({query, params, ...rest}) => ({
  queryKey: ['getBookingQuery', query],
  cacheTime: 1000 * 60,
  queryFn: ({queryKey: [_, config]}) => {
    if (params?.id) {
      return API.get<GetBookingQueryParams, GetBookingQueryReturnType>(makeGetBookingEndpoint(params), {
        params: config,
      }).then((res) => res.data)
    }
    return new Promise((res) => res(null))
  },
  ...rest,
})

export const makeGetDraftEntityEndpoint = ({entityId}: GetDraftEntityParams) => `bookings/draft/${entityId}`

export const getDraftEntityQuery: GetDraftEntityQuery = ({query, params, ...rest}) => ({
  queryKey: ['getDraftEntityQuery', query],
  cacheTime: 1000 * 60 * 1,
  queryFn: ({queryKey: [_, config]}) => {
    if (params?.entityId) {
      return API.get<GetBookingQueryParams, getDraftEntityQueryReturnType>(makeGetDraftEntityEndpoint(params), {
        params: config,
      }).then((res) => res.data)
    }
    return new Promise((res) => res(null))
  },
  ...rest,
})
