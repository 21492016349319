import {Flex, Icon, Text, Theme, Title} from 'exsportia-components'
import {FC, useMemo} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {Button} from '../../ui/components/button/button'
import {useLocale} from '../../utils/i18n'

export const PaymentSuccess: FC = () => {
  const {l} = useLocale()
  const navigate = useNavigate()
  const params = useParams<{venueId: string}>()
  const [searchParams] = useSearchParams()
  const queryData: {entity: string | null; entityId: string | null} = useMemo(() => {
    const entityId = searchParams.get('entityId')
    const entity = searchParams.get('entity')
    return {
      entity,
      entityId,
    }
  }, [searchParams])
  const handleClickGoToEntity = () => {
    if (!queryData.entity || !queryData.entityId) {
      return
    }
    if (queryData.entity === 'booking' || queryData.entity === 'booking_update' ) {
      navigate(`/${params.venueId}/bookings/${queryData.entityId}`)
    } else {
      navigate(`/clear-view/${params.venueId}/payment?entityId=${queryData.entityId}&entity=${queryData.entity}`)
    }
  }
  const handleClickGoToVenue = () => {
    navigate(`/${params.venueId}`)
  }

  return (
    <GridLayout
      header={
        <Flex
          p='8px'
          justifyContent='center'
        >
          <Title
            settings={{textAlign: 'center'}}
            text={l('paymentSuccessful')}
          />
        </Flex>
      }
    >
      <Flex
        alignItems='center'
        gap={Theme?.spaces?.multiplier2x}
        flexDirection='column'
      >
        <Icon
          settings={{
            color: '#0DC268',
            width: '120px',
            height: '120px',
            iconWidth: '120px',
            iconHeight: '120px',
          }}
          icon={'doneIllustration'}
        />
        <Text
          settings={{textAlign: 'center'}}
          text={l('you_can_close_tab', {caseAction: 'capitalize'})}
        />
        {(queryData.entityId && queryData.entity) && <Button onClick={handleClickGoToEntity}>{l('viewDetails')}</Button>}
        {params.venueId && <Button type='secondary' onClick={handleClickGoToVenue}>{l('backToVenue')}</Button>}
      </Flex>
    </GridLayout>
  )
}
