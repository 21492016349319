import {Box, P} from 'exsportia-components'
import {FC, PropsWithChildren} from 'react'

type Props = {
  label: string
}
export const LabeledSection: FC<PropsWithChildren<Props>> = ({label, children}) => {
  return (
    <Box>
      <P
        color={'text-secondary'}
        fontSize={'label'}
      >
        {label}
      </P>
      <Box py={'4px'}>{children}</Box>
    </Box>
  )
}
