// eslint-disable-next-line no-restricted-imports
import {Box, Flex, IconWithText, Image, Label, Logo, Text} from 'exsportia-components'
import {isFunction} from 'formik'
import React, {FC, useMemo} from 'react'

import {Equipment} from '../../../api/booking/types'
import {TranslatorScope} from '../../../locale/types'
import {Button} from '../../../ui/components/button/button'
import {ExsportiaIcon} from '../../../ui/components/exsportia-icon/exsportia-icon'
import {GradientLoader} from '../../../ui/components/gradient-loader/gradient-loader'
import {Toggle} from '../../../ui/components/toggle'
import {useLocale} from '../../../utils/i18n'
import {isNilOrEmpty} from '../../../utils/logic'

export type ParticipantProps = {
  index?: number
  name: string
  image?: string
  onEdit?: ((e: Event) => void) | null
  onRemove?: ((e: Event) => void) | null
  onEquipmentEdit?: ((index: number) => void) | null
  equipments?: Equipment[]
  currency?: string
  withEquipment?: boolean
  displayEquipmentCount?: boolean
  isSubscriptionsAvailable?: boolean
  selectedSubscriptionName?: string
  isSubscriptionAllocable?: boolean
  isSubscriptionShared?: boolean
  handleSetIsApplySubscriptionForParticipants?: (value: boolean) => void
  isLoading?: boolean
}

const EquipmentsSection: FC<{
  index: number
  currency?: string
  equipments?: ParticipantProps['equipments']
  onEquipmentEdit: (index: number) => void
}> = ({equipments, onEquipmentEdit, index, currency}) => {
  const {l} = useLocale()

  const equipmentCount = (equipments || []).reduce((acc: number, cur: Equipment) => acc + (cur.quantity || 0), 0)

  if (isNilOrEmpty(equipments) || equipmentCount === 0) {
    return (
      <Button
        type='secondary'
        onClick={() => onEquipmentEdit(index)}
      >
        {l('add_equipment', {caseAction: 'capitalize'})}
      </Button>
    )
  }
  return (
    <Flex
      flexWrap='wrap'
      gap='8px'
      width='100%'
      position='relative'
    >
      {equipments.map((eq) => {
        if (eq.quantity === 0) {
          return
        }
        return (
          <Flex
            borderRadius='32px'
            p={'4px 12px'}
            height='32px'
            border='1px solid rgba(0, 16, 61, 0.12)'
            alignItems='center'
            gap='4px'
            mr='4px'
            width='max-content'
            maxWidth='100%'
            justifyContent='space-between'
          >
            <Flex minWidth={'30px'}>{`${eq.quantity}${' x '}`}</Flex>
            <IconWithText
              settings={{maxWidth: ['80%', '84%']}}
              text={eq.name}
            />
            <Label settings={{fontSize: '16px'}}>
              {l(`${currency || 'USD'}_symbolic` as TranslatorScope, {value: eq.price})}
            </Label>
          </Flex>
        )
      })}
      <Flex
        borderRadius='32px'
        p={'4px 12px'}
        height='32px'
        alignItems='center'
        gap='4px'
        mr='4px'
        backgroundColor='primary-secondary'
        cursor='pointer'
        onClick={() => onEquipmentEdit(index)}
      >
        <ExsportiaIcon
          icon='edit'
          w={16}
          h={16}
        />
        {l('edit')}
      </Flex>
    </Flex>
  )
}

export const Participant: FC<ParticipantProps> = ({
  index,
  name,
  image,
  onRemove,
  onEdit,
  onEquipmentEdit,
  equipments,
  currency,
  withEquipment = false,
  isSubscriptionAllocable,
  selectedSubscriptionName,
  displayEquipmentCount = false,
  isSubscriptionsAvailable = false,
  isSubscriptionShared,
  handleSetIsApplySubscriptionForParticipants,
  isLoading,
}) => {
  const {l} = useLocale()
  const subText = useMemo(() => {
    if (isLoading) {
      return <GradientLoader height={20} />
    }
    if (selectedSubscriptionName) {
      return (
        <Flex maxWidth={['200px', '400px']}>
          <IconWithText
            icon='crownAction'
            settings={{icon: {color: '#FF9E00'}, text: {color: '#FF9E00'}}}
            text={selectedSubscriptionName}
          />
        </Flex>
      )
    }
    if ((!displayEquipmentCount || !equipments?.length) && !isSubscriptionsAvailable) {
      return null
    }
    if (isSubscriptionsAvailable) {
      return (
        <IconWithText
          icon='crownAction'
          settings={{icon: {color: 'green'}}}
          text={l('subscription_available', {caseAction: 'capitalize'})}
        />
      )
    }
    const equipmentCount = (equipments || []).reduce<number>((acc, eq) => acc + eq.quantity, 0)
    return `${l('equipment')} (${equipmentCount})`
  }, [isSubscriptionsAvailable, displayEquipmentCount, equipments, selectedSubscriptionName, isLoading])
  return (
    <Box mb='16px'>
      <Flex
        alignItems='center'
        my='8px'
        height='48px'
      >
        <IconWithText
          settings={{
            icon: {
              width: '48px',
              height: '48px',
              m: '0 16px 0 0',
              overflow: 'hidden',
              iconHeight: '48px',
              iconWidth: '48px',
              bg: 'rgba(0, 16, 61, 0.06)',
              color: 'rgba(191, 193, 199, 1)',
            },
            title: {fontSize: '18px'},
          }}
          icon={
            image ? (
              <Logo
                smallLogo={
                  <Image
                    src={image}
                    width={'100%'}
                    height='52px'
                  />
                }
                forceUse={'small'}
              />
            ) : (
              'user'
            )
          }
          title={name}
          subText={subText}
        />
        {onEdit && (
          <Button
            type='icon'
            icon='edit'
            onClick={onEdit}
            disabled={isLoading}
          />
        )}
        {onRemove && (
          <Button
            type='icon'
            icon='deleteIcon'
            onClick={onRemove}
            disabled={isLoading}
          />
        )}
      </Flex>
      {isSubscriptionAllocable === true && (
        <Flex
          justifyContent='space-between'
          py='12px'
        >
          <Text>{l('apply_subscription_to_all_participants', {caseAction: 'capitalize'})}</Text>
          <Toggle
            styleType='small'
            checked={isSubscriptionShared}
            onChange={handleSetIsApplySubscriptionForParticipants}
          />
        </Flex>
      )}
      {isFunction(onEquipmentEdit) && withEquipment && (
        <EquipmentsSection
          index={index!}
          currency={currency}
          onEquipmentEdit={onEquipmentEdit}
          equipments={equipments}
        />
      )}
    </Box>
  )
}
