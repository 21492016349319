import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {Session} from './types'

export type GetClassSessionParams = {
  id?: string | null
}
export type GetClassSessionQueryParams = undefined
export type GetClassSessionQueryReturnType = Session | null
export type GetClassSessionQuery = QueryMethod<
  GetClassSessionParams,
  GetClassSessionQueryParams,
  GetClassSessionQueryReturnType
>

export const makeGetClassSessionEndpoint = ({id}: GetClassSessionParams) => `classesSlots/${id}/profileData`

export const getClassSessionQuery: GetClassSessionQuery = ({query, params}) => ({
  queryKey: ['getClassSessionQuery', query],
  cacheTime: 1000 * 60,
  queryFn: ({queryKey: [_, config]}) => {
    if (params?.id) {
      return API.get<GetClassSessionQueryParams, GetClassSessionQueryReturnType>(makeGetClassSessionEndpoint(params), {
        params: config,
      }).then((res) => res.data)
    }
    return new Promise((res) => res(null))
  },
})
