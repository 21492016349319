import {Text} from 'exsportia-components'
import React, {FC, useMemo} from 'react'

import {useLocale} from '../../utils/i18n'
import {defaultSettings} from './settings'

export type SessionAvailabilitySubtitleProps = {
  availableSpots: number
}

export const SessionAvailabilitySubtitle: FC<SessionAvailabilitySubtitleProps> = ({availableSpots}) => {
  const {l} = useLocale()
  const hasAvailableSpots = useMemo(() => availableSpots !== 0, [availableSpots])
  const settingsToUse = useMemo(
    () => (hasAvailableSpots ? defaultSettings.availableSettings : defaultSettings.unavailableSettings),
    [hasAvailableSpots],
  )
  const textToUse = useMemo(
    () =>
      hasAvailableSpots
        ? l('spots_available_plural', {
            value: availableSpots,
            declension: ['spots_available_plural', 'spots_available_single_genitive'],
          })
        : l('full', {caseAction: 'capitalize'}),
    [hasAvailableSpots, availableSpots, l],
  )

  return (
    <Text
      text={textToUse}
      settings={settingsToUse}
    />
  )
}
