import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {ServiceTypeOption} from './types'

export type GetServiceOptionsParams = undefined
export type GetServiceOptionsQueryParams = {
  serviceTypes?: (string | undefined)[]
}
export type GetServiceOptionsQueryReturnType = ServiceTypeOption[]
export type GetServiceOptionsQuery = QueryMethod<
  GetServiceOptionsParams,
  GetServiceOptionsQueryParams,
  GetServiceOptionsQueryReturnType
>

export const serviceOptionsEndpoint = 'serviceOptions/get-service-options-by-types'

export const getServiceOptionsQuery: GetServiceOptionsQuery = ({query, ...rest}) => ({
  queryKey: ['GetServiceOptionsQuery', query],
  cacheTime: 1000 * 60 * 5,
  ...rest,
  queryFn: ({queryKey: [_, config]}) => {
    return API.get<GetServiceOptionsQueryParams, GetServiceOptionsQueryReturnType>(serviceOptionsEndpoint, {
      params: config,
    }).then((res) => res.data)
  },
})
