import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {checkoutIdData} from './types'

export type GetCheckoutIdParams = {
  userId?: string
  venueId?: string
}
export type GetPaymentCheckoutIdParams = GetCheckoutIdParams & {
  totalPrice?: number
}
export type GetCheckoutIdQueryParams = undefined
export type GetCheckoutIdQueryReturnType = checkoutIdData | null
export type GetCheckoutIdQuery = QueryMethod<
  GetCheckoutIdParams,
  GetCheckoutIdQueryParams,
  GetCheckoutIdQueryReturnType
>
export type GetPaymentCheckoutIdQuery = QueryMethod<
  GetPaymentCheckoutIdParams,
  GetCheckoutIdQueryParams,
  GetCheckoutIdQueryReturnType
>

export const copyAndPayEndpoint = 'payments/copyAndPay'
export const totalProcessingCheckoutEndpoint = 'payments/totalProcessing/checkout'

export const getCheckoutIdQuery: GetCheckoutIdQuery = ({query, params}) => {
  return {
    queryKey: ['getCheckoutIdQuery', query],
    cacheTime: 1000 * 6000,
    queryFn: () => {
      return API.post<GetCheckoutIdQueryParams, GetCheckoutIdQueryReturnType>(copyAndPayEndpoint, {
        ...params,
      }).then((res) => res.data)
    },
  }
}

export const getPaymentCheckoutIdQuery: GetPaymentCheckoutIdQuery = ({query, params}) => {
  return {
    queryKey: ['getPaymentCheckoutIdQuery', query],
    cacheTime: 1000 * 6000,
    queryFn: () => {
      return API.post<GetPaymentCheckoutIdParams, GetCheckoutIdQueryReturnType>(totalProcessingCheckoutEndpoint, {
        ...params,
      }).then((res) => res.data)
    },
  }
}
