import {AxiosRequestConfig} from 'axios'

import {sendRequest} from '../utils/axios'
import {APIDomain} from './constants'

export type RequestConfig<T> = AxiosRequestConfig<T>

const getAPIURL = (endpoint: string): string => `${APIDomain}${endpoint}`

export const API = {
  get: <L, T>(endpoint: string, options = {}) =>
    sendRequest<T>('get', getAPIURL(endpoint), options as RequestConfig<L>),
  post: <L, T>(endpoint: string, options = {}) =>
    sendRequest<T>('post', getAPIURL(endpoint), options as RequestConfig<L>),
  delete: <L, T>(endpoint: string, options = {}) =>
    sendRequest<T>('delete', getAPIURL(endpoint), options as RequestConfig<L>),
  put: <L, T>(endpoint: string, options = {}) =>
    sendRequest<T>('put', getAPIURL(endpoint), options as RequestConfig<L>),
  patch: <L, T>(endpoint: string, options = {}) =>
    sendRequest<T>('patch', getAPIURL(endpoint), options as RequestConfig<L>),
}
