import {Box, Divider, P} from 'exsportia-components'
import React, {FC, PropsWithChildren} from 'react'

type Props = {
  title: string
  withPL?: boolean
}
export const TiteledSection: FC<PropsWithChildren<Props>> = ({title, withPL, children}) => {
  return (
    <Box>
      <Box
        pt='16px'
        pl={withPL ? '16px' : 0}
      >
        <P
          fontSize={'h2'}
          fontWeight={'bold'}
        >
          {title}
        </P>
      </Box>
      <Box py={'8px'}>
        {children && Array.isArray(children)
          ? children.reduce((acc, val, i) => {
              if (i !== children.length - 1 && typeof val === 'object') {
                return acc.concat(val, <Divider />)
              }
              return acc.concat(val)
            }, [])
          : children}
      </Box>
    </Box>
  )
}
