import {call, put, takeLatest, takeLeading} from 'redux-saga/effects'

import {APIDomain} from '../../networking/constants'
import {sendRequest} from '../../utils/axios'
import {setItemToLocalStorage} from '../../utils/local-storage'
import {basicActions, getUpdatedLocalizations, setAppLoadingState} from './actions'

export function* setAppLoadingStateSaga() {
  yield put(basicActions.isLoading(true))
  yield call(console.log, 'app loaded')
}

export function* getUpdatedLocalizationsSaga() {
  try {
    const res: Awaited<ReturnType<typeof sendRequest>> = yield call<any>(sendRequest, 'get', `${APIDomain}localization/localization/wba`)
    if (res.status === 200) {
      const {en, ua, heb} = res.data as Record<string, Record<string, string>>
      setItemToLocalStorage('wba-localization-en', JSON.stringify(en))
      setItemToLocalStorage('wba-localization-ua', JSON.stringify(ua))
      setItemToLocalStorage('wba-localization-heb', JSON.stringify(heb))
    }
  } catch (e: any) {
    console.log(e)
  }
}

export function* globalWatcherSaga() {
  yield takeLeading(setAppLoadingState, setAppLoadingStateSaga)
  yield takeLatest(getUpdatedLocalizations, getUpdatedLocalizationsSaga)
}
