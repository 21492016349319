import {dividerSettingsProps} from 'exsportia-components/dist/components/atoms/divider/settings'
import {TextSettingsProps} from 'exsportia-components/dist/components/atoms/text'
import {IconWithTextSettingsType} from 'exsportia-components/dist/components/molecules/icon-with-text'
import {StyleCommonType, StyleSizeSpaceType} from 'exsportia-components/dist/types'

export type SessionCardSettingsProps = {
  p: StyleSizeSpaceType
  border: StyleCommonType
  title: TextSettingsProps
  price: TextSettingsProps
  width: StyleSizeSpaceType
  dot: dividerSettingsProps
  subText: TextSettingsProps
  borderRadius: StyleSizeSpaceType
  instructor: IconWithTextSettingsType
  loaderHeights: {
    price: number | string
    priceMl: StyleSizeSpaceType
    classTitle: number | string
    classSubText: number | string
    instructorIcon: number | string
    instructorTitle: number | string
    priceMaxWidth: StyleSizeSpaceType
    instructorSubText: number | string
  }
  classSection: {
    mb: StyleSizeSpaceType
    justifyContent: StyleCommonType
  }
}

export const defaultSettings: SessionCardSettingsProps = {
  width: '100%',
  p: '12px',
  borderRadius: '12px',
  border: 'default-border',
  loaderHeights: {
    price: '20px',
    classTitle: '24px',
    classSubText: '18px',
    instructorIcon: '32px',
    instructorTitle: '16px',
    instructorSubText: '13px',
    priceMl: '16px',
    priceMaxWidth: '32px',
  },
  classSection: {mb: '10px', justifyContent: 'space-between'},
  title: {
    m: '0 0 2px 0',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: ['20px', '24px'],
  },
  subText: {fontSize: 'label'},
  dot: {bg: 'text-primary'},
  instructor: {
    title: {fontSize: '16px', lineHeight: '20px'},
    icon: {
      width: '32px',
      height: '32px',
      overflow: 'hidden',
      borderRadius: '50%',
    },
  },
  price: {fontSize: '18px', color: 'primary'},
}

export const typeStylesMap = {default: defaultSettings}

export const additionalStylesMap = {}
