import {Flex} from 'exsportia-components'
import {Formik} from 'formik'
import {FC, useCallback} from 'react'

import {useBookingLoader} from '../../services/booking/hooks'
import {Button} from '../../ui/components/button/button'
import {Input} from '../../ui/components/input/input'
import {useLocale} from '../../utils/i18n'
import {useModalActions} from '../../utils/modals'

export type PrivateCodeModalProps = {
  handleCheckCode: (code: string) => void
}

export type PrivateCodeFormType = {
  privateCode: string
}

export const PrivateCodeModal: FC<PrivateCodeModalProps> = ({handleCheckCode}) => {
  const {l} = useLocale()
  const {isLoading} = useBookingLoader()
  const {closeModal} = useModalActions()
  const initialValues: PrivateCodeFormType = {
    privateCode: '',
  }
  const onSubmit = useCallback((values: PrivateCodeFormType) => handleCheckCode(values.privateCode), [handleCheckCode])
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({handleSubmit, values}) => (
        <>
          <Input
            type='text'
            label={l('passcode')}
            id='privateCode'
          />
          <Flex
            justifyContent='flex-end'
            my='24px'
          >
            <Flex
              width='50%'
              gap='12px'
            >
              <Button
                disabled={isLoading}
                onClick={closeModal}
                type='cancel'
              >
                {l('cancel')}
              </Button>
              <Button
                // @ts-ignore
                onClick={() => handleSubmit(values)}
                isLoading={isLoading}
              >
                {l('check')}
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </Formik>
  )
}
