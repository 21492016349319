// import {showMessage} from 'exsportia-components'
import {signInWithCustomToken} from 'firebase/auth'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'

import {BookingQueries} from '../../../api/booking'
import {PaymentsMutations} from '../../../api/payments'
import {PAYMENT_PROVIDER_OPN, PAYMENT_PROVIDER_STRIPE, PAYMENT_PROVIDER_TOTAL_PROCESSING} from '../../../constants/payments'
import {APIDevDomain, APIDomain} from '../../../networking/constants'
import {auth} from '../../../utils/firebase'
import {OPN_PUBLIC_KEY} from '../../../utils/opn-provider'
// import {WBA_URL} from '../../../utils/environment'
import {sentry} from '../../../utils/sentry'
import {BookingService} from '../../booking'
import {useBookingLoader} from '../../booking/hooks'
import {UserService} from '../../user'
import {PaymentService} from '..'
import {handleLinkCallbackArgsType} from './payment-provider-form.provider'

type entity = string | null
type OpenPaymentFormArgsType = {totalPrice?: number, bookingId?: string, entity: entity, startProcessCallback?: () => void}
export type openPaymentFormType = (params: OpenPaymentFormArgsType) => void

type OpnCreateChargeArguments = { token: string, totalPrice: number, bookingId?: string, entity: entity }

export const useTriggerProviderPayment = ({handleLinkCallback}: {handleLinkCallback: (args: handleLinkCallbackArgsType) => void}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {isLoading, setIsLoading} = useBookingLoader()
  const [bookingId, setBookingId] = useState<string | null>(null)
  const {venueId} = useParams<{venueId: string}>()
  const {data: terminal} = useQuery(PaymentService.queries.getTerminalQuery({params: {venueId}}))
  const {data: user} = useQuery(UserService.queries.GetUserQuery({params: {id: auth.currentUser?.uid}}))
  const {data} = useQuery(BookingQueries.calculateBookingPriceQuery({enabled: false}))
  const {mutate: createOpnCharge} = useMutation(PaymentsMutations.createOpnChargeMutation({}))
  const queryEnabled = useMemo(() => bookingId !== null, [bookingId])
  const {
    data: booking,
  } = useQuery(BookingService.queries.getBookingQuery({
    enabled: queryEnabled,
    params: {id: bookingId},
    refetchOnWindowFocus: false,
    refetchInterval: isLoading && queryEnabled ? 1000 * 2 : false,
  }))
  useEffect(() => {
    const handleMessage = (event: { data: { token: string } }) => {
      const token = event.data.token
      if (!token) {
        return
      }
      signInWithCustomToken(auth, token)
        .then((userCredential) => {
          console.log('Authenticated user:', userCredential.user)
        })
        .catch((error: Error) => {
          sentry.error(error, 'authentication pass error')
        })
    }
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])
  useEffect(() => {
    if (booking && bookingId === booking.uid && booking.isPaid) {
      handleLinkCallback({})
      setIsLoading(false)
      setBookingId(null)
      navigate(`/${venueId}/bookings/${bookingId}`)
    }
  }, [booking, bookingId, handleLinkCallback, setIsLoading, navigate, venueId])
  const handleOpnCreateCharge = useCallback(({token, totalPrice, bookingId: draftBookingId, entity}: OpnCreateChargeArguments) => {
    if (!venueId || !draftBookingId) {
      return null
    }
    createOpnCharge({
      token,
      entity,
      venueId,
      totalPrice,
      isWba: true,
      userId: user?.uid || null,
      entityId: draftBookingId,
    },
    {
      onSuccess: (payment) => {
        if (payment.type === 'promptpay') {
          handleLinkCallback({
            type: 'image',
            url: payment.image_uri,
            expires_at: payment.expires_at,
            downloadUrl: payment.download_uri,
          })
        } else if (payment.authorize_uri) {
          handleLinkCallback({
            url: payment.authorize_uri
          })
        }
      },
      onError: () => {
        setBookingId(null)
      },
    })
  }, [setIsLoading, createOpnCharge])

  const openPaymentForm = useCallback(({totalPrice, bookingId: draftBookingId, entity, startProcessCallback}: OpenPaymentFormArgsType) => {
    if (!venueId || !draftBookingId || !totalPrice) {
      return null
    }
    if (entity === 'booking' || entity === 'booking_update') {
      setBookingId(draftBookingId)
    }
    if (terminal?.provider === PAYMENT_PROVIDER_OPN) {
      const OmiseCard = (window as any).OmiseCard
      if (OmiseCard) {
        OmiseCard.configure({
          publicKey: OPN_PUBLIC_KEY,
        })
        OmiseCard.open({
          style: {
            ...(terminal.style || {}),
          },
          amount: Math.round(totalPrice * 100),
          currency: 'THB',
          otherPaymentMethods: terminal.paymentMethods || [],
          defaultPaymentMethod: terminal.defaultPaymentMethod || 'credit_card',
          onCreateTokenSuccess: (token: string) => {
            if (startProcessCallback) {
              startProcessCallback()
            }
            handleOpnCreateCharge({token, totalPrice, bookingId: draftBookingId, entity})
          },
          onFormClosed: () => {
            setBookingId(null)
          }
        })
      }
    } else if (terminal?.provider === PAYMENT_PROVIDER_STRIPE) {
      // dispatch(createStripeCheckoutRequest({userId: user.uid, venueId, isWba: true}))
    } else if (terminal?.provider === PAYMENT_PROVIDER_TOTAL_PROCESSING) {
      // const fullLink = `${WBA_URL}/clear-view/${venueId}/user/${user.uid}/payment/?bookingId=${draftBookingId}`
      // handleLinkCallback(fullLink)
      // window.open(`/clear-view/${venueId}/user/${user.uid}/payment`, '_blank')
    } else if (terminal?.name) {
      if (startProcessCallback) {
        startProcessCallback()
      }
      const TZPaymentData = {
        venueId,
        isWba: 'true',
        currency: '1',
        tranmode: 'A',
        sum: totalPrice.toFixed(2),
        pdesc: draftBookingId || 'payment',
        fail_url_address: `${APIDomain}payments/${draftBookingId}/booking/afterSave`,
        success_url_address: `${APIDomain}payments/${draftBookingId}/booking/afterSave`,
        notify_url_address: `${APIDevDomain}payments/${draftBookingId}/booking/beforeSave`,
      }
      const actionUrl = `https://direct.tranzila.com/${terminal.name}/iframenew.php`
      const generatedUrl = `${actionUrl}?${new URLSearchParams(TZPaymentData).toString()}`
      handleLinkCallback({
        url: generatedUrl
      })
    }
  }, [user, data, terminal, dispatch, venueId, handleLinkCallback])

  return {
    openPaymentForm,
  }
}
