import {useMemo} from 'react'
import {useQuery} from 'react-query'

import {ServiceTypeOption} from '../../api/service/types'
import {isNilOrEmpty, omit, optionsToMapObject} from '../../utils/logic'
import {ServiceOptionsService} from '.'
import {DEFAULT_SERVICE_OPTION} from './constants'

export const useServiceOptions = (serviceTypes?: (string | undefined)[]) => {
  const {data: all = [], isLoading} = useQuery(
    ServiceOptionsService.queries.getServiceOptionsQuery({
      refetchOnWindowFocus: false,
      query: {
        serviceTypes: isNilOrEmpty(serviceTypes) ? [] : serviceTypes,
      },
    }),
  )

  return useMemo(() => {
    const services = all.filter((item) => item.areaSpace === false)
    const spaces = all.filter((item) => item.areaSpace === true)
    const spacesMap = optionsToMapObject(spaces as ServiceTypeOption[], (obj) => omit(obj, ['value']))
    const servicesMap = optionsToMapObject(services as ServiceTypeOption[], (obj) => omit(obj, ['value']))
    const allMap = {...servicesMap, ...spacesMap}
    return {
      allMap,
      spaces,
      services,
      isLoading,
      getServiceConfigByServiceType: (serviceType?: string): ServiceTypeOption => {
        return !serviceType || !Object.keys(allMap).includes(serviceType)
          ? DEFAULT_SERVICE_OPTION
          : (allMap[serviceType] as ServiceTypeOption)
      },
    }
  }, [all, isLoading])
}
