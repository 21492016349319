import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {ServicesList} from './types'

export type GetInstructorServicesParams = undefined
export type GetInstructorServicesQueryParams = {
  venueId?: string
  instructorId?: string | null
}

export type GetInstructorServicesReturnType = ServicesList
export type GetInstructorServicesQuery = QueryMethod<
  GetInstructorServicesParams,
  GetInstructorServicesQueryParams,
  GetInstructorServicesReturnType
>

export const makeGetInstructorServicesEndpoint = () => 'services/instructorServices'

export const getInstructorServicesQuery: GetInstructorServicesQuery = ({query}) => ({
  queryKey: ['getInstructorServicesQuery', query],
  cacheTime: 1000 * 300,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<GetInstructorServicesQueryParams, GetInstructorServicesReturnType>(makeGetInstructorServicesEndpoint(), {
      params: config,
    }).then((res) => res.data),
})
