import {Flex, Grid} from 'exsportia-components'
import {FC, useCallback, useMemo} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'

import {DownloadAppModal} from '../../modals/download-app-modal/download-app-modal'
import {useAuthState} from '../../services/auth/hooks'
import {useAppLoading} from '../../services/global/hooks'
import {DropdownMenu} from '../../ui/components/dropdown-menu/dropdown-menu'
import {TabButton, TabButtonProps} from '../../ui/components/tab-button/tab-button'
import {LocaleOptions, useLocale} from '../../utils/i18n'
import {useModalActions} from '../../utils/modals'
import {profileMenuOptions} from './profile-menu'

const defaultLOptions: LocaleOptions = {
  caseAction: 'capitalize',
}

const tabs = ['profile', 'bookings', 'subscriptions']

export const MobileNavMenu: FC = () => {
  const {l, language} = useLocale()
  const {pathname} = useLocation()
  const {openModal} = useModalActions()
  const {isLoading} = useAppLoading()
  const {signOut} = useAuthState()
  const navigate = useNavigate()
  const {venueId} = useParams()
  const openDownloadAppModal = useCallback(
    (name: string) =>
      openModal({
        title: name,
        component: <DownloadAppModal />,
        footerConfig: {
          disabled: true,
        },
      }),
    [],
  )
  const menuSettings: TabButtonProps[] = useMemo(() => {
    const pathArr = pathname.split('/').filter((el) => el !== '')
    const activeTab = tabs.includes(pathArr[1]) ? pathArr[1] : 'home'
    return [
      {
        text: l('home', defaultLOptions),
        icon: 'houseAction',
        isActive: activeTab === 'home',
        onClick: () => navigate(`/${venueId}`),
      },
      {
        text: l('bookings', defaultLOptions),
        isActive: activeTab === 'bookings',
        icon: 'bookings',
        onClick: () => navigate(`/${venueId}/bookings`),
      },
      {
        text: l('subscriptions', defaultLOptions),
        icon: 'crownAction',
        isActive: activeTab === 'subscriptions',
        onClick: () => openDownloadAppModal(l('subscriptions', defaultLOptions)),
      },
    ]
  }, [l, pathname, venueId])
  const menuOptions = profileMenuOptions({
    l,
    signOut,
    language,
    isLoading,
    openProfile: () => openDownloadAppModal(l('profile')),
  })
  return (
    <Flex
      justifyContent='space-between'
      width='100%'
    >
      <Grid gridTemplateColumns='repeat(4, 1fr)' width='100%'>
        {menuSettings.map((settings, index) => (
          <TabButton
            key={`mobile-tab-${index}`}
            isMobile={true}
            icon={settings.icon}
            text={settings.text}
            onClick={settings.onClick}
            isActive={settings.isActive}
          />
        ))}
        <Flex width='100%' justifyContent='center'>
          <DropdownMenu
            name='profile'
            options={menuOptions}
            height='100%'
          >
            <TabButton
              isMobile={true}
              icon='user'
              text={l('profile')}
            />
          </DropdownMenu>
        </Flex>
      </Grid>
    </Flex>
  )
}
