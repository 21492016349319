import {Booking} from '../../api/booking/types'
import {createBasicSelectors, makeSelect} from '../../utils/redux'
import {initialState} from './constants'
import {BookingState} from './types'

const select = makeSelect<BookingState>('booking')

export const basicSelectors = createBasicSelectors<BookingState>(initialState, select)

export const someCoolSelector = select<(Booking | null)[], string>((state) => {
  const booking = state.get('booking')
  return [booking, booking]
})
