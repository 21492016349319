import {Box} from 'exsportia-components'
import {FC} from 'react'

import {Participant} from '../../../api/booking/types'
import {SessionSelectSession} from '../../../api/class-session/types'
import {Participant as ParticipantComponent} from '../../../pages/set-participants/components/participant'
import {NO_USER_PHOTO} from '../../../utils/remote-content'

type Props = {
  participants: (Participant)[] | SessionSelectSession['participants'],
  displayEquipmentCount?: boolean
}

export const ParticipantsList: FC<Props> = ({participants, displayEquipmentCount = false}) => {
  return (
    <Box
      pb={'24px'}
      overflow='auto'
      maxHeight={'60dvh'}
    >
      {participants.map((participant, index) => (
        <ParticipantComponent
          name={participant.name || ''}
          key={`participants-list-element-${index}`}
          image={participant.avatar || NO_USER_PHOTO}
          equipments={'equipments' in participant ? participant.equipments : []}
          displayEquipmentCount={displayEquipmentCount}
        />
      ))}
    </Box>
  )
}
