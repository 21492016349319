import {Box, Flex, Icon, Text} from 'exsportia-components'
import {MenuItemComplexProps} from 'exsportia-components/dist/components/organisms/menu-item'
import {FC} from 'react'

import {langNames} from '../../constants/common'
import {LanguagesEnum, TranslatorScope} from '../../locale/types'
import {DropdownMenu} from '../../ui/components/dropdown-menu/dropdown-menu'
import {setLocale, useLocale} from '../../utils/i18n'

export const languageMenuOptions: MenuItemComplexProps[] = [
  {
    name: 'en',
    text: langNames.en,
    onClick: () => setLocale(LanguagesEnum.EN),
  },
  {
    name: 'heb',
    text: langNames.heb,
    onClick: () => setLocale(LanguagesEnum.HEB),
  },
  {
    name: 'ua',
    text: langNames.ua,
    onClick: () => setLocale(LanguagesEnum.UA),
  },
]

export type SelectLanguagePropsType = {
  display?: string[] | string
}

export const SelectLanguage: FC<SelectLanguagePropsType> = ({display = 'flex'}) => {
  const {l, language} = useLocale()
  return (
    <Flex display={display}>
      <DropdownMenu
        name='language-menu'
        options={languageMenuOptions}
      >
        <Flex
          display={'flex'}
          cursor={'pointer'}
          padding={4}
          flexDirection={'row'}
        >
          <Icon icon={'language'} />
          <Box
            display={'block'}
            ml={'8px'}
          >
            <Text>{l(language as TranslatorScope)}</Text>
          </Box>
        </Flex>
      </DropdownMenu>
    </Flex>
  )
}
