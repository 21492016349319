export function isNotEmpty(
  value: unknown,
): value is Map<any, unknown> | Record<string, unknown> | Set<unknown> | unknown[] | string {
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length !== 0
  }

  if (value instanceof Map || value instanceof Set) {
    return value.size !== 0
  }

  if (value && typeof value === 'object') {
    return Object.keys(value).length !== 0
  }

  return false
}

export function isNil(value: unknown): value is null | undefined {
  return value == null
}

export function isNilOrEmpty(value: unknown): value is null | undefined {
  return isNil(value) || !isNotEmpty(value)
}

export function isNotNil(
  value: unknown,
): value is Map<any, unknown> | Record<string, unknown> | Set<unknown> | unknown[] | string {
  return !isNil(value)
}

export function isNotNilAndNotEmpty(
  value: unknown,
): value is Map<any, unknown> | Record<string, unknown> | Set<unknown> | unknown[] | string {
  return isNotNil(value) && isNotEmpty(value)
}

export const isFunction = (value: unknown): boolean => {
  return typeof value === 'function'
}

export type Option = {
  value: string
}

type Func<X, Y> = (option: Y) => X

export const optionsToMapObject = <X, Y extends Option>(options: Y[], func: Func<X, Y>) => {
  return options.reduce((acc: Record<string, X>, option: Y) => {
    acc[option.value] = func(option)
    return acc
  }, {})
}

export type labelOption = {
  label: string
}

export const optionsToLabelMapObject = <X, Y extends labelOption>(options: Y[], func: Func<X, Y>) => {
  return options.reduce((acc: Record<string, X>, option: Y) => {
    acc[option.label] = func(option)
    return acc
  }, {})
}

export const omit = <X, Y extends string | keyof X>(obj: X, omitKeys: Y[]): Omit<X, Y> => {
  const objectCopy = {...obj}
  omitKeys.forEach((el) => {
    delete objectCopy[el as keyof X]
  })

  return objectCopy
}

export const pick = <X, Y extends keyof X>(obj: X, pickKeys: Y[]): Pick<X, Y> => {
  const objectToReturn: Partial<X> = {}
  pickKeys.forEach((key) => {
    objectToReturn[key] = obj[key]
  })

  return objectToReturn as X
}
