import {createAction} from 'redux-act'

import {TranslatorScope} from '../../locale/types'
import {LocaleOptions} from '../../utils/i18n'
import {createBasicActions} from '../../utils/redux'
import {initialState} from './constants'
import {BookingState} from './types'

export const basicActions = createBasicActions<BookingState>(initialState)

// maybe we need to store this in class service
export type CheckClassPasscodePayloadType = {
  classId: string
  code: string
  callback?: () => void
  rejectCallback?: () => void
}

export type CheckServicePasscodePayloadType = {
  sportId: string
  password: string
  callback?: () => void
  rejectCallback?: () => void
}

export type CancelBookingPayloadType = {
  isPaid: boolean
  bookingId: string
  callback?: () => void
  rejectCallback?: () => void
  l: (scope: TranslatorScope, options?: LocaleOptions) => string
}

export const cancelBooking = createAction<CancelBookingPayloadType>('cancelBooking')
export const checkClassPasscode = createAction<CheckClassPasscodePayloadType>('checkClassPasscode')
export const checkServicePasscode = createAction<CheckServicePasscodePayloadType>('checkClassPasscode')
