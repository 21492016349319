import {Flex, Icon, Text, Theme, Title} from 'exsportia-components'
import {FC} from 'react'

import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {useLocale} from '../../utils/i18n'

export const AddPaymentMethodFail: FC = () => {
  const {l} = useLocale()
  return (
    <GridLayout
      header={
        <Flex p='8px' justifyContent='center'>
          <Title settings={{textAlign: 'center'}} text={l('addingPaymentMethodFailed', {caseAction: 'capitalize'})} />
        </Flex>
      }
    >
      <Flex alignItems='center' flexDirection='column'>
        <Icon
          settings={{
            width: '120px',
            height: '120px',
            iconWidth: '120px',
            iconHeight: '120px',
            color: Theme.colors.error,
          }}
          icon={'blocked'}
        />
        <Text settings={{textAlign: 'center', croppedLines: '0'}} text={l('addingPaymentMethodFailedDetails', {caseAction: 'capitalize'})} />
      </Flex>
    </GridLayout>
  )
}
