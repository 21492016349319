import {API} from '../../networking/http'
import {MutationMethod} from '../helpers/types'


export type CalculatedBookingPriceObject = {
  authorize_uri?: string
}

export type UpdateEntityForPayEndpointParams = {
  entity?: string | null
  entityId?: string | null
}

export type UpdateEntityForPayParams = undefined
export type UpdateEntityForPayMutationParams = {
  status?: string | null
  entity?: string | null
  entityId?: string | null
  subStatus?: string | null,
  expires_at?: number | null,
}
export type UpdateEntityForPayMutationReturnType = CalculatedBookingPriceObject
export type UpdateEntityForPayMutationType = MutationMethod<
  UpdateEntityForPayParams,
  UpdateEntityForPayMutationParams,
  UpdateEntityForPayMutationReturnType
>

export const getUpdateEntityForPayEndpoint = ({entity, entityId}: UpdateEntityForPayEndpointParams ) =>
  `payments/entityForPay/${entity}/${entityId}`

export const UpdateEntityForPayMutation: UpdateEntityForPayMutationType = () => ({
  mutationFn: ({entityId, entity, ...data}) =>
    API.put<UpdateEntityForPayMutationParams, UpdateEntityForPayMutationReturnType>(
      getUpdateEntityForPayEndpoint({entity, entityId}),
      data,
    ).then((res) => res.data),
})
