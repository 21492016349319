import {TextLoader} from 'exsportia-components'
import {TextLoaderProps} from 'exsportia-components/dist/components/atoms/text-loader'
import React, {FC} from 'react'

import {defaultSettings} from './settings'

export type GradientLoaderProps = TextLoaderProps

export const GradientLoader: FC<GradientLoaderProps> = ({style, height = '24px'}) => (
  <TextLoader
    {...defaultSettings}
    height={height}
    style={style}
  />
)
