import {Box, Flex, showMessage} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {DateTime} from 'luxon'
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'

import {WeekAvailableDays} from '../../api/booking/types'
import {ClassQueries} from '../../api/class'
import {SessionSelectSession} from '../../api/class-session/types'
import {MemberQueries} from '../../api/member'
import {VenueQueries} from '../../api/venue'
import {InlineCalendar} from '../../components/inline-calendar/inline-calendar'
import {Slides} from '../../components/inline-calendar/types'
import {PrivateCodeModal} from '../../components/private-code-modal/private-code-modal'
import {SessionCard} from '../../components/session-card/session-card'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {TranslatorScope} from '../../locale/types'
import {BookingService} from '../../services/booking'
import {checkClassPasscode} from '../../services/booking/actions'
import {CreateClassBookingFormType} from '../../services/booking/forms/create-class-booking-form'
import {useBookingLoader} from '../../services/booking/hooks'
import {UserService} from '../../services/user'
import {VenueService} from '../../services/venue'
import {DividerWithContent} from '../../ui/components/divider-with-content/divider-with-content'
import {ScrollableWrapper} from '../../ui/components/scrollable-wrapper/scrollable-wrapper'
import {auth} from '../../utils/firebase'
import {useLocale, useLuxon} from '../../utils/i18n'
import {useModalActions} from '../../utils/modals'
import {NO_USER_PHOTO} from '../../utils/remote-content'

const Header: FC = () => {
  const {l} = useLocale()
  const {venueId} = useParams<{venueId: string}>()
  const {data} = useQuery(VenueQueries.getVenueQuery({params: {id: venueId}}))
  return <SimpleGridLayoutHeader title={data?.entityNames.classes || l('classes')} />
}

type WeekCalendarType = {
  today: DateTime
  selectedDate: DateTime
  setSelectedDate: (date: DateTime) => void
  startOfSelectedWeek: DateTime
  weekDaysData?: WeekAvailableDays
  setStartOfSelectedWeek: (slides: Slides) => void
}

const weekDaysNumbers = [0, 1, 2, 3, 4, 5, 6]
const defaultWeekDays = [false, false, false, false, false, false, false]

const messagesByReason: (key?: SessionSelectSession['permissionData']['reason']) => TranslatorScope | undefined = (
  key,
) => {
  if (!key) {
    return
  }
  return {
    NOT_AVAILABLE: 'entity_is_not_available',
    FOR_MEMBERS_ONLY: 'for_members_only',
    CLASS_IS_NOT_FOUND: 'something_went_wrong',
    FOR_SUBSCRIBERS_ONLY: 'only_with_subscription',
    ONLY_WITHOUT_SUBSCRIPION: 'only_without_subscription',
    SLOT_IS_BOOKED_BY_THIS_MEMBER: 'is_already_booked',
    FOR_NON_MEMBERS_ONLY: 'for_non_members_only',
  }[key] as TranslatorScope
}

export const WeekCalendar: FC<WeekCalendarType> = ({
  today,
  weekDaysData,
  selectedDate,
  setSelectedDate,
  startOfSelectedWeek,
  setStartOfSelectedWeek,
}) => {
  const luxon = useLuxon()
  const {values} = useFormikContext<CreateClassBookingFormType>()
  const {data} = useQuery(ClassQueries.getClassQuery({params: {id: values.classId}}))
  const weekDaysDataToUse = weekDaysData || defaultWeekDays
  const weekDaysSettings = weekDaysNumbers.map((number: number) => {
    const currentDay = startOfSelectedWeek.plus({days: number})
    return {
      date: currentDay,
      disabled: !weekDaysDataToUse[number],
      isToday: currentDay.toFormat('D') === today.toFormat('D'),
      isSelected: currentDay.toFormat('D') === selectedDate.toFormat('D'),
      // text: String(currentDay.toFormat({ weekday: 'narrow' }, { locale: language })),
    }
  })
  const filterDate = useCallback(
    (date: string) => {
      const startOfCurrentDayLuxon = luxon.fromFormat(date, 'dd/MM/yyyy', {zone: 'utc'}).startOf('day')

      if (startOfCurrentDayLuxon.diff(today).milliseconds < 0) {
        return false
      }
      const isWeekDayDisabled = weekDaysSettings.find((el) => el.date.toFormat('dd/MM/yyyy') === date)?.disabled
      if (isWeekDayDisabled) {
        return false
      }
      if (!data?.howFarCanBookUnlimited) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const maxDayTimeStamp = today.plus({day: data?.howFarCanBookAmount}).toMillis()
        return (maxDayTimeStamp - startOfCurrentDayLuxon.toMillis()) >= 0
      }
      return true;
    },
    [startOfSelectedWeek.toFormat('dd/MM/yyyy'), weekDaysDataToUse, selectedDate, data],
  )

  return useMemo(
    () => (
      <InlineCalendar
        onSlideChange={setStartOfSelectedWeek}
        filterDate={filterDate}
        selectedDate={selectedDate.toFormat('dd/MM/yyyy')}
        onDayClick={(date) => setSelectedDate(luxon.fromFormat(date, 'dd/MM/yyyy', {zone: 'utc'}))}
      />
    ),
    [selectedDate, filterDate],
  )
}

type Params = {
  venueId: string
}

export const SelectClassSessionPage: FC = () => {
  const dispatch = useDispatch()
  const luxon = useLuxon()
  const {l} = useLocale()
  const {setIsLoading} = useBookingLoader()
  const {openModal, closeModal} = useModalActions()
  const navigate = useNavigate()
  const {venueId} = useParams<Params>()
  const {data: venue} = useQuery(VenueService.queries.getVenueQuery({params: {id: venueId}}))
  const {setValues: setBookingState, values: bookingState} = useFormikContext<CreateClassBookingFormType>()
  const params = useParams<{venueId: string}>()
  const {data: user} = useQuery(UserService.queries.GetUserQuery({cacheTime: 0, params: {id: auth.currentUser?.uid}}))
  const {data: venueData} = useQuery(VenueQueries.getVenueQuery({params: {id: params.venueId}}))
  const {data: member, isLoading: isMemberLoading} = useQuery(
    MemberQueries.getMemberByVenueQuery({
      query: {
        venueId: venueData?.uid,
        userId: user?.uid,
      },
    }),
  )
  const now = luxon.now().toUTC().startOf('day')
  const nowWithVenueTz = luxon.fromFormat(
    luxon
      .now()
      .setZone(venue?.timeZone)
      .toFormat('dd/MM/yyyy HH:mm'),
    'dd/MM/yyyy HH:mm',
    {zone: 'utc'},
  )
  const [selectedDate, setSelectedDate] = useState(now)
  const [startOfSelectedWeek, setStartOfSelectedWeek] = useState(selectedDate.startOf('week'))
  const handleSetStartOfSelectedWeek = useCallback(
    (slides: Slides) => {
      setStartOfSelectedWeek(slides[0].day)
    },
    [startOfSelectedWeek],
  )
  const handleSelectDate = useCallback((date: DateTime) => {
    setSelectedDate(date)
  }, [])
  const {data: weekDaysData} = useQuery(
    BookingService.queries.getAvailableDaysByWeekQuery({
      query: {
        venueId: !bookingState.classId ? venueId! : undefined,
        startOfWeek: luxon
          .fromFormat(startOfSelectedWeek.toFormat('DD', {locale: 'en'}), 'DD', {zone: 'utc', locale: 'en'})
          .toMillis(),
        classId: bookingState.classId,
      },
    }),
  )
  const {isLoading, data} = useQuery(
    BookingService.queries.getVenueClassSessionsByDateQuery({
      query: {
        venueId: venueId!,
        date: selectedDate.toMillis(),
        classId: bookingState.classId!,
        memberId: member?.uid,
      },
    })
  )

  useEffect(() => {
    if (data?.closestSessionTimeStamp && typeof data.closestSessionTimeStamp === 'number') {
      setSelectedDate(luxon.fromMillis(data.closestSessionTimeStamp).toUTC().startOf('day'))
    }
  }, [data])

  return (
    <GridLayout
      withoutPadding
      header={<Header />}
    >
      <ScrollableWrapper>
        <Box py={'16px'}>
          <Box px={'16px'}>
            <WeekCalendar
              today={now}
              selectedDate={selectedDate}
              setSelectedDate={handleSelectDate}
              setStartOfSelectedWeek={handleSetStartOfSelectedWeek}
              weekDaysData={weekDaysData}
              startOfSelectedWeek={startOfSelectedWeek}
            />
          </Box>
          <Box py={'8px'}>
            <DividerWithContent content={selectedDate.toFormat('ccc, MMM d, yyyy')} />
          </Box>
          <Box px={'16px'}>
            {isLoading ? (
              <SessionCard isLoading={true} />
            ) : (
              (Array.isArray(data) ? data : []).map((session, index) => {
                const {
                  price,
                  private: isSessionPrivate,
                  duration,
                  timestamp,
                  className,
                  instructors,
                  participants = [],
                  availableSlots,
                  permissionData: {validated, reason},
                  isSessionAlreadyBooked,
                } = session
                const isPast = nowWithVenueTz.toMillis() > timestamp && !session.permissionData.validated
                const handleSelectSession = () => {
                  setBookingState({
                    ...bookingState,
                    maxParticipants: availableSlots,
                    instructors: session.instructors,
                    sessionId: session.uid,
                    areaId: session.areaId,
                    classId: session.classId,
                    timestamp: session.timestamp,
                    duration: session.duration,
                    date: luxon.fromMillis(session.timestamp).toFormat('dd/MM/yyyy'),
                  })
                  navigate('../set-participants')
                }
                const handleCheckPassCode = (code: string) => {
                  setIsLoading(true)
                  dispatch(
                    checkClassPasscode({
                      code,
                      classId: bookingState.classId!,
                      callback: () => {
                        closeModal()
                        handleSelectSession()
                        showMessage(l('success'), 'success')
                        setIsLoading(false)
                      },
                      rejectCallback: () => {
                        setIsLoading(false)
                        showMessage(l('error'), 'error')
                      },
                    }),
                  )
                }
                const handleSessionClick = isSessionPrivate
                  ? () => {
                      openModal({
                        title: l('privateSession'),
                        footerConfig: {
                          disabled: true,
                        },
                        component: <PrivateCodeModal handleCheckCode={handleCheckPassCode} />,
                      })
                    }
                  : handleSelectSession
                return (
                  <Flex
                    mt='8px'
                    key={`session-${index}`}
                  >
                    <SessionCard
                      time={luxon.fromMillis(timestamp).toUTC().toFormat('HH:mm')}
                      price={price}
                      onClick={handleSessionClick}
                      currency={venue?.currency}
                      disabled={availableSlots === 0 || !validated || isPast}
                      className={className}
                      isLoading={isMemberLoading || isLoading}
                      disabledText={isPast ? 'past' : messagesByReason(reason)}
                      availableSpots={availableSlots}
                      instructorName={instructors[0]?.name}
                      instructorImage={instructors[0]?.profileImage?.secure_url ?? NO_USER_PHOTO}
                      isSessionAlreadyBooked={isSessionAlreadyBooked}
                      duration={l('minutes_short', {
                        value: duration,
                      })}
                      participants={
                        (availableSlots > 0 && bookingState.class?.participantsNamesWhenFull) ||
                        (availableSlots === 0 && bookingState.class?.participantsNames)
                          ? participants
                          : null
                      }
                    />
                  </Flex>
                )
              })
            )}
          </Box>
        </Box>
      </ScrollableWrapper>
    </GridLayout>
  )
}
