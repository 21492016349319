import {Flex} from 'exsportia-components'
import {DateTime} from 'luxon'
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'

import {noop} from '../../helpers/logic'
import {Chevron} from '../../ui/components/chevron/chevron'
import {useLuxon} from '../../utils/i18n'
import {DayItem} from './components/day-item'
import {DayStatesEnum, Slides} from './types'

type Props = {
  onSlideChange?: (slides: Slides) => void
  selectedDate?: string
  onDayClick: (date: string) => void
  filterDate?: (date: string) => boolean
}
export const InlineCalendar: FC<Props> = ({
  onSlideChange = noop,
  selectedDate = DateTime.now().toUTC().toFormat('dd/MM/yyyy'),
  filterDate = () => true,
  onDayClick,
}) => {
  const luxon = useLuxon()
  const [refDate, setRefDate] = useState(selectedDate)
  const [isPrevWeekDisabled, setIsPrevWeekDisabled] = useState(false)
  const todayTimestamp = luxon.now().startOf('day').toMillis()
  const week = useMemo(() => {
    const dateToUse = luxon.fromFormat(refDate, 'dd/MM/yyyy')
    const startOfWeek = dateToUse.startOf('week')
    const result: Slides = []
    for (let i = 0; i < 7; i++) {
      const day = startOfWeek.plus({days: i})
      const stringifiedDay = day.toFormat('dd/MM/yyyy')
      let status = DayStatesEnum.DISABLED
      if (filterDate(stringifiedDay)) {
        status = DayStatesEnum.DEFAULT
      }
      if (stringifiedDay === selectedDate) {
        status = DayStatesEnum.SELECTED
      }
      result.push({day, status})
    }
    return result
  }, [luxon, filterDate, refDate, selectedDate])
  useEffect(() => {
    setIsPrevWeekDisabled(!!week.find((el) => el.day.toMillis() === todayTimestamp))
    onSlideChange(week)
  }, [week])
  const goToPrevWeek = useCallback(
    () => setRefDate(luxon.fromFormat(refDate, 'dd/MM/yyyy').minus({days: 7}).toFormat('dd/MM/yyyy')),
    [luxon, refDate],
  )

  const goToNextWeek = useCallback(
    () => setRefDate(luxon.fromFormat(refDate, 'dd/MM/yyyy').plus({days: 7}).toFormat('dd/MM/yyyy')),
    [luxon, refDate],
  )
  useEffect(() => {
    setRefDate(selectedDate)
  }, [selectedDate])
  return (
    <Flex
      bg={'bg'}
      justifyContent={'space-between'}
    >
      <Flex
        pr={'8px'}
        alignItems={'center'}
        onClick={isPrevWeekDisabled ? noop : goToPrevWeek}
      >
        <Chevron
          disabled={isPrevWeekDisabled}
          direction={'left'}
          styleType={'simple'}
        />
      </Flex>
      {week.map((dayObj, index) => {
        const isActive = dayObj.status !== DayStatesEnum.DISABLED
        return (
          <DayItem
            {...dayObj}
            key={`inlineCalendarItem-${index}`}
            onClick={isActive ? onDayClick : noop}
          />
        )
      })}
      <Flex
        pl={'8px'}
        alignItems={'center'}
        onClick={goToNextWeek}
      >
        <Chevron
          direction={'right'}
          styleType={'simple'}
        />
      </Flex>
    </Flex>
  )
}
