import {StyleCommonType} from 'exsportia-components/dist/types'

import {TranslatorScope} from '../../../locale/types'

export type PaymentStatusSettingsProps = {
  textToken: TranslatorScope
  color: StyleCommonType
}

export const defaultSettings: PaymentStatusSettingsProps = {textToken: 'paid', color: 'success'}

export const unpaidSettings: PaymentStatusSettingsProps = {textToken: 'unpaid', color: 'notify'}

export const cancelledSettings: PaymentStatusSettingsProps = {textToken: 'cancelled', color: 'error'}

export const typeStylesMap = {
  paid: defaultSettings,
  unpaid: unpaidSettings,
  cancelled: cancelledSettings,
}

export const additionalStylesMap = {}
