import './index.css'

// eslint-disable-next-line no-restricted-imports
import {ErrorBoundary} from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'

import {App} from './App'
import {FullPageFallback} from './components/error-boundary/error-boundary-fallback'
import {reportWebVitals} from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <ErrorBoundary fallback={<FullPageFallback />}>
    <App />
  </ErrorBoundary>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
