// import {showMessage} from 'exsportia-components'
import {showMessage} from 'exsportia-components'
import {useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'

import {BookingQueries} from '../../../api/booking'
import {PaymentsMutations} from '../../../api/payments'
import {useLocale} from '../../../utils/i18n'
import {usePaymentProvider} from './payment-provider-form.provider'

type entity = string | null
type OpenPaymentFormArgsType = {totalPrice?: number, bookingId?: string, entity: entity, startProcessCallback?: () => void}
export type openPaymentFormType = (params: OpenPaymentFormArgsType) => void


type HandleDraftEntityStatusChangeType = {
  entity: entity
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  entityId: string | null
}
export const useHandleDraftEntityStatusChange = ({
  entity,
  entityId,
  isLoading,
  setIsLoading,
}: HandleDraftEntityStatusChangeType) => {
  const {l} = useLocale()
  const [] = useState()
  const navigate = useNavigate()
  const {clearUrls} = usePaymentProvider()
  const [startRefetch, setStartRefetch] = useState(false)
  const {venueId} = useParams<{venueId: string}>()
  const {mutate: updateEntityForPay} = useMutation(PaymentsMutations.UpdateEntityForPayMutation({}))
  const mustRefetch = useMemo(() => isLoading && startRefetch, [isLoading, startRefetch])
  const {data: draftEntity, error, remove, isLoading: draftEntityLoading} = useQuery(BookingQueries.getDraftEntityQuery({
    retry: false,
    enabled: !!entityId,
    query: {
      entity,
      entityId,
    },
    staleTime: 1000 * 60 * 60,
    params: {entityId},
    refetchOnWindowFocus: false,
    refetchInterval: mustRefetch ? 1000 * 2 : false,
  }))

  useEffect(() => {
    if (isLoading && draftEntity?.status === 'PAYMENT_SUCCESS') {
      clearUrls && clearUrls()
      setIsLoading(false)
      setStartRefetch(false)
      if (entity !== 'booking') {
        navigate(`/clear-view/${venueId}/payment-success?entityId=${draftEntity.entityId}&entity=${entity}`)
      }
    }
    if (draftEntity?.status === 'PAYMENT_FAIL' && isLoading) {
      setIsLoading(false)
      setStartRefetch(false)
      clearUrls && clearUrls()
      showMessage(l('paymentFailed'), 'error')
      updateEntityForPay({
        status: null,
        entityId: draftEntity.entityId,
        entity,
      })
    }
  }, [draftEntity, isLoading, clearUrls, navigate, venueId])
  const setDraftPendingStatus = () => {
    remove()
    updateEntityForPay(
      {
        entity,
        subStatus: null,
        expires_at: null,
        status: 'PAYMENT_PENDING',
        entityId: draftEntity?.entityId,
      },
      {
        onSuccess: () => {
          setStartRefetch(true)
        },
      }
    )
  }

  return {
    error,
    draftEntity,
    setStartRefetch,
    draftEntityLoading,
    setDraftPendingStatus,
  }
}
