export const OPN_DEV_PUBLIC_KEY = 'pkey_test_5yrb5qfa85sm3y0ijvn'
export const OPN_PROD_PUBLIC_KEY = 'pkey_5yyzuur1p4he247n8y3'

export type envDomainType = {
  PUBLIC_KEY: string
}

export type APIDomainsMapType = {
  development: envDomainType
  staging: envDomainType
  preprod: envDomainType
  production: envDomainType
}

export const TP_API_URL_MAP: APIDomainsMapType = {
  development: {
    PUBLIC_KEY: OPN_DEV_PUBLIC_KEY
  },
  staging: {
    PUBLIC_KEY: OPN_DEV_PUBLIC_KEY
  },
  preprod: {
    PUBLIC_KEY: OPN_DEV_PUBLIC_KEY
  },
  production: {
    PUBLIC_KEY: OPN_PROD_PUBLIC_KEY
  }
}

const env = process.env.REACT_APP_ENV ?? 'development'

export const OPN_PUBLIC_KEY = TP_API_URL_MAP[env].PUBLIC_KEY
