import {Box, Flex, Icon, IconWithText, Image, Tag, Text, Theme, Title} from 'exsportia-components'
import React, {FC, useCallback, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {TranslatorScope} from '../../locale/types'
import {useBookingLoader} from '../../services/booking/hooks'
import {useHandleDraftEntityStatusChange} from '../../services/payments/hooks/handle-draft-entity-status-change.hook'
import {PaymentProviderProvider, usePaymentProvider} from '../../services/payments/hooks/payment-provider-form.provider'
import {VenueService} from '../../services/venue'
import {Button} from '../../ui/components/button/button'
import {GradientLoader} from '../../ui/components/gradient-loader/gradient-loader'
import {useLocale} from '../../utils/i18n'
import {DEFAULT_VENUE_IMAGE} from '../../utils/remote-content'

export const PaymentPageComponent: FC = () => {
  const {l} = useLocale()
  const {isLoading: globalIsLoading, setIsLoading: setGlobalIsLoading} = useBookingLoader()
  const navigate = useNavigate()
  const params = useParams<{venueId: string}>()
  const [searchParams] = useSearchParams()
  const {openPaymentForm} = usePaymentProvider()
  const {data: venue, isLoading: isVenueLoading} = useQuery(
    VenueService.queries.getVenueQuery({params: {id: params.venueId}}),
  )
  const queryData: {entity: string | null; entityId: string | null} = useMemo(() => {
    const entityId = searchParams.get('entityId')
    const entity = searchParams.get('entity')
    return {
      entity,
      entityId,
    }
  }, [searchParams])
  const {error, draftEntity, draftEntityLoading, setDraftPendingStatus} = useHandleDraftEntityStatusChange({
    entity: queryData.entity,
    isLoading: globalIsLoading,
    entityId: queryData.entityId,
    setIsLoading: setGlobalIsLoading,
  })
  const isLoading = globalIsLoading || draftEntityLoading || isVenueLoading
  const handleTriggerPayment = useCallback(() => {
    setDraftPendingStatus()
    openPaymentForm && openPaymentForm({
      entity: queryData.entity,
      bookingId: draftEntity?.entityId,
      totalPrice: draftEntity?.totalPrice,
      startProcessCallback: () => setGlobalIsLoading(true),
    })
  }, [openPaymentForm, draftEntity, queryData, setDraftPendingStatus, setGlobalIsLoading])

  const submitButtonMarkup = useMemo(() => {
    const text = l('pay')
    if (draftEntity?.isCancelled === true || draftEntity?.isPaid || error?.response?.status === 404) {
      return (
        <Button
          disabled={isLoading}
          onClick={() => navigate(`/${params.venueId}`)}
          isLoading={isLoading || isVenueLoading}
        >
          {l('backToVenue')}
        </Button>
      )
    }
    return (
      <Button
        disabled={isLoading}
        onClick={handleTriggerPayment}
        isLoading={isLoading || isVenueLoading}
      >
        {text}
      </Button>
    )
  }, [handleTriggerPayment, isLoading, draftEntity, error, isVenueLoading])
  const valuesMarkup = useMemo(() => {
    if (error?.response?.status === 404) {
      return (
        <Flex
          alignItems='center'
          gap={Theme?.spaces?.multiplier2x}
          flexDirection='column'
        >
          <Icon
            settings={{
              width: '120px',
              height: '120px',
              iconWidth: '80px',
              iconHeight: '80px',
              bg: Theme?.colors?.bgSecondary,
            }}
            styleType='withBg'
            icon={'smileSad'}
          />
          <Text
            settings={{textAlign: 'center'}}
            text={'Entity for payment not found'}
          />
        </Flex>
      )
    }

    return (
      <Flex
        justifyContent='space-between'
      >
        <Text
          settings={{fontWeight: 'bold'}}
          text={l('totalPrice')}
        />
        {draftEntityLoading || isVenueLoading ? (
          <Flex width='40%'>
            <GradientLoader />
          </Flex>
        ) : (
          <Text
            settings={{fontWeight: 'bold'}}
            text={l(`${venue?.currency}_symbolic` as TranslatorScope, {
              value: Number(draftEntity?.totalPrice).toFixed(2),
            })}
          />
        )}
      </Flex>
    )
  }, [draftEntity, isLoading, error, isVenueLoading])

  return (
    <Flex justifyContent='center' pt={Theme.spaces.multiplier3x}>
      <Box
        overflow='hidden'
        bg={Theme?.colors?.bg}
        maxWidth='600px'
        width='100%'
        borderRadius='24px'
        className='grid-layout_box_content grid-layout_box_content_padding'
      >
        <IconWithText
          title={venue?.name || l('venueName')}
          icon={
            <Image
              width={'64px'}
              height={'64px'}
              borderRadius='50%'
              src={venue?.logo.secure_url ?? DEFAULT_VENUE_IMAGE}
            />
          }
          styleType='profileBig' />
        <Flex my='24px' gap='12px' flexDirection='column'>
          <Flex gap={Theme.spaces.multiplier}>
            <Title
              styleType='h3'
              text={l('labels_pricingAndPayments')}
            />
            {
              draftEntity?.isCancelled
              ? <Tag settings={{bg: Theme?.colors?.error, iconWithText: {text: {color: Theme?.colors?.textContrast}}}} text={l('cancelled')} />
              : draftEntity?.isPaid
                && <Tag settings={{bg: Theme?.colors?.success, iconWithText: {text: {color: Theme?.colors?.textContrast}}}} text={l('paid')} />
            }
          </Flex>
          {
            draftEntity?.lines.map((el, index) => (
              <Flex
                justifyContent='space-between'
                key={`pricing-details-${index}`}
              >
                <Text text={el.text} settings={{maxWidth: 'calc(100% - 100px)'}}/>
                <Text
                  text={`${el.subtract ? '-' : ''}${l(`${venue?.currency}_symbolic` as TranslatorScope, {value: Number(el.price).toFixed(2)})}`}
                />
              </Flex>
            ))}
          {valuesMarkup}
        </Flex>
        {submitButtonMarkup}
      </Box>
    </Flex>
  )
}

export const PaymentPage: FC = (props) => (
  <PaymentProviderProvider>
    <PaymentPageComponent {...props} />
  </PaymentProviderProvider>
)
