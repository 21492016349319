import 'firebase/compat/auth'

import firebaseRaw from 'firebase/compat/app'

import {GetUserParams} from '../api/user/get-user'
import {logger} from '../monitoring/logger'
import {store} from '../redux/store'
import {AuthService} from '../services/auth'
import {getUpdatedLocalizations} from '../services/global/actions'
import {UserService} from '../services/user'
import {firebaseOptions} from './environment'
import {removeAuthToken, setAuthToken} from './local-storage'
import {queryClient} from './react-query'

const {APP_ID, API_KEY, PROJECT_ID, AUTH_DOMAIN, DATABASE_URL, STORAGE_BUCKET, MEASUREMENT_ID, MESSAGING_SENDER_ID} =
  firebaseOptions

const firebaseConfig = {
  appId: APP_ID,
  apiKey: API_KEY,
  projectId: PROJECT_ID,
  authDomain: AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  storageBucket: STORAGE_BUCKET,
  measurementId: MEASUREMENT_ID,
  messagingSenderId: MESSAGING_SENDER_ID,
}

firebaseRaw.initializeApp(firebaseConfig)

export const auth = firebaseRaw.auth()

auth.onAuthStateChanged(async (user) => {
  store.dispatch(getUpdatedLocalizations())
  if (!user) {
    store.dispatch(AuthService.actions.isLoggedIn(false))
    queryClient.resetQueries({queryKey: ['GetUserQuery']})
    removeAuthToken()
    return
  }
  const tokenData = await user.getIdTokenResult(true)
  setAuthToken(tokenData)
  store.dispatch(AuthService.actions.isLoggedIn(true))
  const params: GetUserParams = {id: user.uid}
  const userFromBack = await queryClient.fetchQuery(UserService.queries.GetUserQuery({params, query: undefined}))
  logger.log(userFromBack)
})

auth.onIdTokenChanged(async (user) => {
  if (user) {
    const tokenData = await user.getIdTokenResult()
    setAuthToken(tokenData)
  } else {
    removeAuthToken()
  }
})

export const makeStorageFileLink = (fileName: string) => {
  return `https://firebasestorage.googleapis.com/v0/b/${firebaseOptions.STORAGE_BUCKET}/o/wba-localization%2F${fileName}?alt=media`
}

export const firebase = firebaseRaw
