import {Flex} from 'exsportia-components'
import React, {FC, useEffect, useMemo, useState} from 'react'
import Script from 'react-load-script'
import {useParams, useSearchParams} from 'react-router-dom'

import {ExsportiaCompanyDetails} from '../../components/exsportia-company-details/exsportia-company-details'
import {APIDomain} from '../../networking/constants'
import {PaymentService} from '../../services/payments'
import {queryClient} from '../../utils/react-query'
import {TP_API_URL} from '../../utils/total-processing'
import {mastercard, visa} from './icons'
// //////////////////////////////////////////////////////////////////////////////////////////////////

const totalProcessingAfterPayment = '/payments/totalProcessing/afterPayment'
const totalProcessingPaymentAfterPayment = '/payments/totalProcessing/payment/afterPayment'

type Props = {
  clearView?: boolean
}

export const AddPaymentMethod: FC<Props> = ({clearView}) => {
  const params = useParams<{venueId: string; userId: string}>()
  const [searchParams] = useSearchParams()
  const [checkoutId, setCheckoutId] = useState<string | null>(null)

  useEffect(() => {
    const checkoutIdFromSearchParams = searchParams.get('checkoutId')
    if (checkoutIdFromSearchParams) {
      setCheckoutId(checkoutIdFromSearchParams)
    }
    queryClient
      .fetchQuery(
        PaymentService.queries.getCheckoutIdQuery({
          cacheTime: 0,
          params: {userId: params.userId, venueId: params.venueId},
        }),
      )
      .then((data) => {
        setCheckoutId(data || null)
      })
  }, [])

  const handleError = () => null
  const formMarkup = useMemo(() => {
    if (!checkoutId) {
      return null
    }
    return (
      <>
        <Script
          onLoad={() => null}
          onError={handleError}
          onCreate={() => null}
          url={`${TP_API_URL}/v1/paymentWidgets.js?checkoutId=${checkoutId}/registration`}
        />
      </>
    )
  }, [checkoutId])
  const queryParams = useMemo(() => {
    const data = `userId=${params.userId}&venueId=${params.venueId}&isWba='true`
    return data
  }, [params])

  const actionURL = useMemo(() => encodeURI(`${APIDomain}${totalProcessingAfterPayment}?${queryParams}`), [queryParams])

  return (
    <Flex
      width='100vw'
      p='0 16px 16px'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      height={clearView ? '100dvh' : 'unset'}
    >
      <Flex
        mb='24px'
        width='100%'
        maxWidth='480px'
        justifyContent='flex-end'
      >
        {mastercard({h: '40px', w: '80px'})}
        {visa({h: '40px', w: '80px'})}
      </Flex>
      <form
        action={actionURL}
        className='paymentWidgets'
        data-brands='VISA MASTER AMEX'
      />
      {formMarkup}
      <ExsportiaCompanyDetails />
    </Flex>
  )
}

export const CreateTPPayment: FC<Props> = ({clearView}) => {
  const params = useParams<{venueId: string; userId: string}>()
  const [searchParams] = useSearchParams()
  const [checkoutId, setCheckoutId] = useState<string | null>(null)

  const bookingId = useMemo(() => Number(searchParams.get('bookingId')), [searchParams])

  useEffect(() => {
    const checkoutIdFromSearchParams = searchParams.get('checkoutId')
    const totalPrice = Number(searchParams.get('totalPrice'))
    if (checkoutIdFromSearchParams) {
      setCheckoutId(checkoutIdFromSearchParams)
    }
    queryClient
      .fetchQuery(
        PaymentService.queries.getPaymentCheckoutIdQuery({
          cacheTime: 0,
          params: {userId: params.userId, venueId: params.venueId, totalPrice},
        }),
      )
      .then((data) => {
        setCheckoutId(data || null)
      })
  }, [])

  const handleError = () => null
  const formMarkup = useMemo(() => {
    if (!checkoutId) {
      return null
    }
    return (
      <>
        <Script
          onLoad={() => null}
          onError={handleError}
          onCreate={() => null}
          url={`${TP_API_URL}/v1/paymentWidgets.js?checkoutId=${checkoutId}`}
        />
      </>
    )
  }, [checkoutId])
  const queryParams = useMemo(() => {
    const data = `userId=${params.userId}&venueId=${params.venueId}&isWba='true'&bookingId=${bookingId}`
    return data
  }, [params, bookingId])

  const actionURL = useMemo(() => encodeURI(`${APIDomain}${totalProcessingPaymentAfterPayment}?${queryParams}`), [queryParams])

  return (
    <Flex
      width='100vw'
      p='0 16px 16px'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      height={clearView ? '100dvh' : 'unset'}
    >
      <Flex
        mb='24px'
        width='100%'
        maxWidth='480px'
        justifyContent='flex-end'
      >
        {mastercard({h: '40px', w: '80px'})}
        {visa({h: '40px', w: '80px'})}
      </Flex>
      <form
        action={actionURL}
        className='paymentWidgets'
        data-brands='VISA MASTER AMEX'
      />
      {formMarkup}
      <ExsportiaCompanyDetails />
    </Flex>
  )
}
