import {Flex, Icon, Title} from 'exsportia-components'
import {FC, useCallback} from 'react'
import {useNavigate} from 'react-router-dom'

import {Button} from '../../ui/components/button/button'
import {useLocale} from '../../utils/i18n'
import {isFunction} from '../../utils/logic'

export type EmptyListPropsType = {
  text?: string
  buttonText?: string
  onClick?: (e?: Event) => void
  withoutBack?: boolean
}

export const EmptyList: FC<EmptyListPropsType> = ({text, onClick, buttonText, withoutBack = false}) => {
  const {l} = useLocale()
  const navigate = useNavigate()
  const onClickToUse = useCallback(() => (isFunction(onClick) ? onClick!() : navigate(-1)), [navigate, onClick])
  const textToUse = text || l('empty_list')
  const buttonTextToUse = buttonText || l('back')
  return (
    <Flex
      flexDirection='column'
      alignItems='center'
    >
      <Icon
        icon='search'
        settings={{
          iconWidth: '200px',
          iconHeight: '200px',
          width: '200px',
          height: '200px',
          color: '#BFC1C7',
        }}
      />
      <Title
        styleType='h2'
        text={textToUse}
      />
      {!withoutBack && (
        <Flex
          width='100%'
          mt='24px'
        >
          <Button onClick={onClickToUse}>{buttonTextToUse}</Button>
        </Flex>
      )}
    </Flex>
  )
}
