import {StickyStatesEnum} from './types'

export const LTRStickyStyles = {
  [StickyStatesEnum.DEFAULT]: {
    container: {paddingLeft: '12px', paddingRight: '12px'},
    thumb: {borderRadius: '12px'},
  },
  [StickyStatesEnum.LEFT]: {
    container: {
      paddingRight: '12px',
      paddingLeft: 'unset', // TODO: make unset variant
    },
    thumb: {
      backgroundColor: 'primary-secondary',
      borderTopLeftRadius: 'unset',
      borderBottomLeftRadius: 'unset',
      borderRadius: '12px',
    },
  },
  [StickyStatesEnum.RIGHT]: {
    container: {paddingLeft: '12px', paddingRight: 'unset'},
    thumb: {
      backgroundColor: 'primary-secondary',
      borderRadius: '12px',
      borderTopRightRadius: 'unset',
      borderBottomRightRadius: 'unset',
    },
  },
  [StickyStatesEnum.BOTH]: {
    container: {paddingLeft: 'unset', paddingRight: 'unset'},
    thumb: {backgroundColor: 'primary-secondary', borderRadius: 'unset'},
  },
}

export const RTLStickyStyles = {
  [StickyStatesEnum.DEFAULT]: {
    container: {paddingLeft: '12px', paddingRight: '12px'},
    thumb: {borderRadius: '12px'},
  },
  [StickyStatesEnum.LEFT]: {
    container: {
      paddingRight: 'unset',
      paddingLeft: '12px', // TODO: make unset variant
    },
    thumb: {
      backgroundColor: 'primary-secondary',
      borderTopRightRadius: 'unset',
      borderBottomRightRadius: 'unset',
      borderRadius: '12px',
    },
  },
  [StickyStatesEnum.RIGHT]: {
    container: {paddingRight: '12px', paddingLeft: 'unset'},
    thumb: {
      backgroundColor: 'primary-secondary',
      borderRadius: '12px',
      borderTopLeftRadius: 'unset',
      borderBottomLeftRadius: 'unset',
    },
  },
  [StickyStatesEnum.BOTH]: {
    container: {paddingRight: 'unset', paddingLeft: 'unset'},
    thumb: {backgroundColor: 'primary-secondary', borderRadius: 'unset'},
  },
}
