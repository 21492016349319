import {Text} from 'exsportia-components'
import React, {FC, useMemo} from 'react'

import {isNilOrEmpty} from '../../utils/logic'

export type BookingCodeProps = {
  code: string
}

const settings = {fontWeight: 'bold'}
export const BookingCode: FC<BookingCodeProps> = ({code}) => {
  const codeToUse = useMemo(() => (isNilOrEmpty(code) ? null : code.toUpperCase().split('').join(' ')), [code])

  return (
    <Text
      settings={settings}
      text={codeToUse}
    />
  )
}
