import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {CloseModalConfirmationModal} from '../../components/close-modal-confirmation-modal/close-modal-confirmation-modal'
import {AuthModal} from '../../modals/auth-flow/auth-modal'
import {useLocale} from '../../utils/i18n'
import {useModalActions} from '../../utils/modals'
import {AuthService} from './index'

export const useAuthState: (venueId?: string) => {
  isLoggedIn: boolean
  signUp: () => void
  signOut: () => void
  signIn: (email?: string) => void
  removeAccount: () => void
} = (venueId) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {l} = useLocale()
  const isLoggedIn = useSelector(AuthService.selectors.isLoggedIn())
  const {openModal, closeAllModals, closeModal, updateModalConfig} = useModalActions()
  const setModalTitle = useCallback((title: string) => {
    updateModalConfig('auth-modal', {title})
  }, [updateModalConfig])
  const handleSignOut = useCallback(() => {
    dispatch(AuthService.actions.signOutRequestAction())
  }, [dispatch])
  const handleRemoveAccount = useCallback(() => {
    dispatch(AuthService.actions.removeAccountRequestAction({callback: () => navigate('/remove-account-success')}))
  }, [dispatch, navigate])
  const handleCloseConfirmation = () => {
    openModal({
      title: l('confirmation'),
      component: <CloseModalConfirmationModal />,
      footerConfig: {
        submitButton: {
          onClick: closeAllModals,
        },
        cancelButton: {
          onClick: closeModal,
        },
      },
    })
  }
  const handleSignUp = useCallback(
    (email = '') => {
      openModal({
        id: 'auth-modal',
        title: l('sign_up_free', {caseAction: 'capitalize'}),
        component: (
          <AuthModal
            email={email}
            venueId={venueId}
            setModalTitle={setModalTitle}
          />
        ),
        settings: {modalBody: {maxWidth: '634px'}},
        footerConfig: {disabled: true},
        headerConfig: {type: 'compact'},
        customClose: handleCloseConfirmation,
      })
    },
    [l, venueId, openModal],
  )
  const handleSignIn = useCallback(
    (email = '') => {
      openModal({
        id: 'auth-modal',
        title: l('signin', {caseAction: 'capitalize'}),
        component: (
          <AuthModal
            email={email}
            venueId={venueId}
            setModalTitle={setModalTitle}
          />
        ),
        settings: {modalBody: {maxWidth: '634px'}},
        footerConfig: {disabled: true},
        headerConfig: {type: 'compact'},
        customClose: handleCloseConfirmation,
      })
    },
    [l, venueId, openModal],
  )
  return {
    isLoggedIn,
    signUp: handleSignUp,
    signIn: handleSignIn,
    signOut: handleSignOut,
    removeAccount: handleRemoveAccount,
  }
}
