import {Flex, Image, Text} from 'exsportia-components'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {NavMenu} from '../components/nav-menu/nav-menu'
import {useAuthState} from '../services/auth/hooks'
import {useAppLoading} from '../services/global/hooks'
import {VenueService} from '../services/venue'
import {useLocale} from '../utils/i18n'
import {getItemFromLocalStorage} from '../utils/local-storage'
import {DEFAULT_VENUE_IMAGE} from '../utils/remote-content'
import {DownloadAppHeader} from './components/download-app-header'
import {ProfileMenu} from './components/profile-menu'
import {SelectLanguage} from './components/select-language-menu'
import {SignInSignUpButtons} from './components/signInSignUpButtons'

const DownloadAppHeaderToUse: FC = () => {
  const isClosed = getItemFromLocalStorage('isDownloadHeaderClosed') === 'true'
  if (isClosed) {
    return null
  }
  return <DownloadAppHeader />
}

export const Header: FC = () => {
  const {venueId} = useParams()
  const {isLoggedIn, signIn, signUp} = useAuthState(venueId)
  const [registerTriggered, setRegisteredTriggered] = useState(false)
  const {isLoading} = useAppLoading()
  const {l} = useLocale()
  const[searchParams] = useSearchParams()
  const navigate = useNavigate()
  const params = useParams<{venueId: string}>()
  const {pathname} = useLocation()
  const pathArr = pathname.split('/').filter(el => el !== '')
  const {data: venueData} = useQuery(VenueService.queries.getVenueQuery({params: {id: params.venueId}}))
  const handleSignIn = useCallback(() => signIn(), [signIn])
  const handleSignUp = useCallback(() => signUp(), [signUp])
  const handleNavigateToBookingType = useCallback(() => {
    if (!isLoading) {
      navigate(`/${params.venueId}`)
    }
  }, [isLoading])
  useEffect(() => {
    if (searchParams.has('register') && !registerTriggered) {
      setRegisteredTriggered(true)
      navigate('./')
      handleSignUp()
    }
  }, [])
  return (
    <>
      <DownloadAppHeaderToUse />
      <Flex
        display={(pathArr.length < 2 || !isLoggedIn) ? 'flex' : ['none', 'flex']}
        bg={'white'}
        justifyContent={'center'}
        borderRadius={['24px', 0]}
        m={['16px 16px 0 16px', 0]}
      >
        <Flex
          width={['100%', '90%']}
          flexDirection={'row'}
          py={'16px'}
          px={'8px'}
          height='max-content'
          maxHeight='64px'
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Flex
            width={['unset', '400px']}
            justifyContent='start'
          >
            <Flex
              cursor={'pointer'}
              onClick={handleNavigateToBookingType}
              alignItems={'center'}
              height={'100%'}
              width='100%'
            >
              <Flex justifyContent={'center'}>
                <Image
                  width={'48px'}
                  height={'48px'}
                  borderRadius={['16px', '12px']}
                  aspectRatio={1}
                  mr={'16px'}
                  size={'contain'}
                  src={venueData?.logo.secure_url ?? DEFAULT_VENUE_IMAGE}
                />
              </Flex>
              <Text
                styleType='verticalCropped'
                settings={{
                  width: '100%',
                  fontSize: ['18px', '20px', '24px'],
                  lineHeight: ['24px', '32px'],
                  fontWeight: 600,
                  croppedLines: 2,
                }}
              >
                {venueData?.name || l('venueName')}
              </Text>
            </Flex>
          </Flex>
          {isLoggedIn && <NavMenu notForMobile={true} />}
          <Flex
            width={['unset', '400px']}
            justifyContent='end'
          >
            <Flex
              gap={'8px'}
              alignItems={'center'}
            >
              {!isLoggedIn && <SelectLanguage display={'flex'} />}
              {isLoggedIn ? (
                <ProfileMenu />
              ) : (
                <SignInSignUpButtons
                  display={['none', 'flex']}
                  handleSignIn={handleSignIn}
                  handleSignUp={handleSignUp}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
