import {Box, Title} from 'exsportia-components'
import React, {FC, PropsWithChildren, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'

import {GetVenueClassesReturnType} from '../../api/class/get-venue-classes'
import {VenueQueries} from '../../api/venue'
import {EmptyList} from '../../components/empty-list/empty-list'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {TranslatorScope} from '../../locale/types'
import {ClassService} from '../../services/class'
import {useServiceOptions} from '../../services/service-options/hooks'
import {EntityCard} from '../../ui/components/entity-card/entity-card'
import {NavigationList} from '../../ui/components/navigation-list/navigation-list'
import {ScrollableWrapper} from '../../ui/components/scrollable-wrapper/scrollable-wrapper'
import {TiteledSection} from '../../ui/components/titeled-section/titeled-section'
import {useLocale} from '../../utils/i18n'
import {isNilOrEmpty, isNotNilAndNotEmpty} from '../../utils/logic'

export enum CurrenciesEnum {
  'ILS' = 'ILS',
  'UAH' = 'UAH',
  'EUR' = 'EUR',
  'USD' = 'USD',
}

type ClassOption = {
  text: string
  icon: string // TODO: icon type
  subText?: string[]
  styleType: 'class'
  onClick: () => void
}

type SelectClassProps = {
  instructorId?: string | null
  setIsClassListEmpty?: (value: boolean) => void
}

type ClassMapByType = {
  [key in GetVenueClassesReturnType[number]['sportType']]: ClassOption[]
}

const Header: FC = () => {
  const {l} = useLocale()
  const {venueId} = useParams<{venueId: string}>()
  const {data} = useQuery(VenueQueries.getVenueQuery({params: {id: venueId}}))
  return <SimpleGridLayoutHeader title={data?.entityNames.classes || l('classes')} />
}

const WrapperComponent: FC<PropsWithChildren<{isInstructorFlow?: boolean; venueId: string}>> = ({
  children,
  isInstructorFlow,
  venueId,
}) => {
  const {data: venueData} = useQuery(VenueQueries.getVenueQuery({params: {id: venueId}}))
  const {l} = useLocale()
  const title = useMemo(() => venueData?.entityNames.classes || l('classes'), [venueData?.entityNames.classes])
  if (isInstructorFlow) {
    return (
      <>
        <Title
          text={title}
          styleType='h2'
          settings={{p: '16px 16px 8px 16px'}}
        />
        {children}
      </>
    )
  }
  return (
    <GridLayout
      withoutPadding
      header={<Header />}
    >
      {children}
    </GridLayout>
  )
}

export const SelectClassPage: FC<SelectClassProps> = ({instructorId, setIsClassListEmpty}) => {
  const {l} = useLocale()
  const navigate = useNavigate()
  const isInstructorFlow = isNotNilAndNotEmpty(instructorId)
  const {venueId} = useParams<{venueId: string}>()
  if (!venueId) {
    return null
  }
  const {data, isLoading: isClassesLoading} = useQuery(
    ClassService.queries.getVenueClassesQuery({
      query: {venueId, instructorId},
    }),
  )
  useEffect(() => {
    if (!isClassesLoading && isNilOrEmpty(data)) {
      setIsClassListEmpty?.(true)
    }
  }, [data, isClassesLoading, setIsClassListEmpty])
  const {getServiceConfigByServiceType, isLoading: isServicesLoading} = useServiceOptions(
    data?.map((el) => el.sportType),
  )

  const isLoading = useMemo(() => isServicesLoading || isClassesLoading, [isServicesLoading, isClassesLoading])

  const classListSettings = useMemo(
    () =>
      data
        ? data.reduce<Partial<ClassMapByType>>(
            (acc, classData) => ({
              ...acc,
              [classData.sportType]: [
                ...(acc[classData.sportType] || []),
                {
                  text: classData.name,
                  icon: classData.icon,
                  subText: [l(`${classData.currency}_short_single` as TranslatorScope, {value: classData.price})],
                  styleType: 'class',
                  onClick: () => navigate(`${isInstructorFlow ? '../' : ''}../class/${classData.uid}/select-session/`),
                },
              ],
            }),
            {},
          )
        : {},
    [data, l], // eslint-disable-line
  )

  if (isNilOrEmpty(data) && isInstructorFlow && !isLoading) {
    return null
  }

  if (isNilOrEmpty(data) && !isLoading) {
    return (
      <GridLayout header={<Header />}>
        <EmptyList />
      </GridLayout>
    )
  }

  return (
    <WrapperComponent
      isInstructorFlow={isInstructorFlow}
      venueId={venueId}
    >
      <ScrollableWrapper isScrollable={!isInstructorFlow}>
        <Box>
          {isLoading && (
            <NavigationList
              Component={EntityCard}
              isLoading={isLoading}
              elementsSettings={[]}
            />
          )}
          {Object.entries<ClassOption[]>(classListSettings as ClassMapByType).map(([key, val], index) => (
            <TiteledSection
              withPL
              key={index}
              title={l(getServiceConfigByServiceType(key).label)}
            >
              <NavigationList
                Component={EntityCard}
                isLoading={isLoading}
                elementsSettings={val}
              />
            </TiteledSection>
          ))}
        </Box>
      </ScrollableWrapper>
    </WrapperComponent>
  )
}
