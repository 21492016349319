import {Box, Flex, Image, showMessage, Title} from 'exsportia-components'
import {useFormikContext} from 'formik'
import React, {FC, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'

import {ServiceItem} from '../../api/booking/types'
import {VenueQueries} from '../../api/venue'
import {EmptyList} from '../../components/empty-list/empty-list'
import {InstructorCard} from '../../components/instructor-card/instructor-card'
import {PrivateCodeModal} from '../../components/private-code-modal/private-code-modal'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {TranslatorScope} from '../../locale/types'
import {BookingService} from '../../services/booking'
import {checkServicePasscode} from '../../services/booking/actions'
import {CreateServiceBookingFormType} from '../../services/booking/forms/create-service-booking-form'
import {useBookingLoader} from '../../services/booking/hooks'
import {useServiceOptions} from '../../services/service-options/hooks'
import {EntityCard} from '../../ui/components/entity-card/entity-card'
import {NavigationList} from '../../ui/components/navigation-list/navigation-list'
import {ScrollableWrapper} from '../../ui/components/scrollable-wrapper/scrollable-wrapper'
import {TiteledSection} from '../../ui/components/titeled-section/titeled-section'
import {auth} from '../../utils/firebase'
import {useLocale} from '../../utils/i18n'
import {isNilOrEmpty, isNotNilAndNotEmpty} from '../../utils/logic'
import {useModalActions} from '../../utils/modals'
import {SelectClassPage} from '../select-class/select-class-page'

type ServiceOption = {
  text: string
  icon: string // TODO: icon type
  subText?: string[]
  styleType: 'justTheSpace' | 'service'
  onClick: () => void
}

type ServiceMapByType = {
  [key in ServiceItem['serviceType']]: ServiceOption[]
}

const validationTokensMap: Record<NonNullable<ServiceItem['permissionData']['reason']>, TranslatorScope> = {
  FOR_MEMBERS_ONLY: 'for_members_only',
  FOR_SUBSCRIBERS_ONLY: 'only_with_subscription',
  ONLY_WITHOUT_SUBSCRIPION: 'only_without_subscription',
  FOR_NON_MEMBERS_ONLY: 'for_non_members_only',
  NOT_AVAILABLE: 'entity_is_not_available',
}

export const SelectServiceOrClassForInstructorPage: FC = () => {
  const dispatch = useDispatch()
  const {l} = useLocale()
  const navigate = useNavigate()
  const {openModal, closeModal} = useModalActions()
  const {setIsLoading} = useBookingLoader()
  const {venueId} = useParams<{venueId: string}>()
  const {values, setValues} = useFormikContext<CreateServiceBookingFormType>()
  const [isClassListEmpty, setIsClassListEmpty] = useState<boolean>(false)
  const {data, isLoading, refetch} = useQuery(
    BookingService.queries.getInstructorServicesQuery({
      query: {venueId: values.venueId, instructorId: values.teacherId},
    }),
  )
  const {getServiceConfigByServiceType} = useServiceOptions(data?.map((el) => el.serviceType))
  const {data: venueData} = useQuery(VenueQueries.getVenueQuery({params: {id: venueId}}))

  useEffect(() => {
    refetch()
  }, [auth.currentUser?.uid])

  const serviceListSettings = useMemo(
    () =>
      data
        ? data.reduce<Partial<ServiceMapByType>>(
            (acc, service) => ({
              ...acc,
              [service.serviceType]: [
                ...(acc[service.serviceType] || []),
                {
                  text: service.name,
                  icon: service.icon,
                  disabled: !service.permissionData.validated,
                  subText: service.permissionData.validated
                    ? [l(`${service.currency}_short_single` as TranslatorScope, {value: service.price})]
                    : [
                        l(validationTokensMap[service.permissionData.reason!] as TranslatorScope, {
                          caseAction: 'capitalize',
                        }),
                      ],
                  styleType: values.isJustTheSpace ? 'justTheSpace' : 'service',
                  onClick: () => {
                    const newValues: CreateServiceBookingFormType =
                      service.uid === values.serviceId
                        ? {
                            ...values,
                            service,
                            serviceId: service.uid,
                            maxParticipants: service.maxPlayers,
                            minParticipants: service.minPlayers,
                            entity: 'teacher',
                          }
                        : {
                            ...values,
                            service,
                            area: null,
                            serviceId: service.uid,
                            maxParticipants: service.maxPlayers,
                            minParticipants: service.minPlayers,
                            entity: 'teacher',
                          }
                    const handleSelectService = () => {
                      setValues(newValues, true)
                      if (isNotNilAndNotEmpty(service.selectedAreas)) {
                        return navigate('../select-playspace')
                      }
                      navigate('../select-date-time')
                    }
                    if (!service.private) {
                      handleSelectService()
                      return
                    }
                    const handleCheckPassCode = (code: string) => {
                      setIsLoading(true)
                      dispatch(
                        checkServicePasscode({
                          password: code,
                          sportId: service.uid,
                          callback: () => {
                            closeModal()
                            handleSelectService()
                            showMessage(l('success'), 'success')
                            setIsLoading(false)
                          },
                          rejectCallback: () => {
                            setIsLoading(false)
                            showMessage(l('error'), 'error')
                          },
                        }),
                      )
                    }
                    openModal({
                      title: l('privateSession'),
                      footerConfig: {
                        disabled: true,
                      },
                      component: <PrivateCodeModal handleCheckCode={handleCheckPassCode} />,
                    })
                  },
                },
              ],
            }),
            {},
          )
        : {},
    [data, l, values], // eslint-disable-line
  )

  const header = useMemo(() => <SimpleGridLayoutHeader title={''} />, [l])

  const title = useMemo(
    () => isNilOrEmpty(data) && !isLoading ? null : (
      <Title
        text={venueData?.entityNames.sports || l('services')}
        settings={{p: '28px 16px 0 16px'}}
        styleType='h2'
      />
    ),
    [venueData?.entityNames.sports, l, data, isLoading],
  )

  if (isNilOrEmpty(data) && !isLoading && isClassListEmpty) {
    return (
      <GridLayout header={header}>
        <EmptyList />
      </GridLayout>
    )
  }

  console.log(values)

  return (
    <GridLayout
      withoutPadding
      header={header}
    >
      <ScrollableWrapper>
        <Flex
          p='16px 16px 0 16px'
          width='100%'
        >
          <Flex border='default-border' borderRadius='16px' p='16px' width='100%'>
            <InstructorCard
              name={values.teacher?.name}
              subText={l('trainerAtVenueName', {venueName: venueData?.name, caseAction: 'capitalize'})}
              image={
                <Image
                  width='48px'
                  height='48px'
                  size='contain'
                  src={values.teacher?.profileImage.secure_url || ''}
                />
              }
            />
          </Flex>
        </Flex>
        {title}
        <Box>
          {isLoading && (
            <NavigationList
              Component={EntityCard}
              isLoading={isLoading}
              elementsSettings={[]}
            />
          )}
          {Object.entries<ServiceOption[]>(
            serviceListSettings as {[key in ServiceItem['serviceType']]: ServiceOption[]},
          ).map(([key, val], index) => (
            <TiteledSection
              withPL
              key={`titeled-section-${index}`}
              title={l(getServiceConfigByServiceType(key).label)}
            >
              <NavigationList
                Component={EntityCard}
                isLoading={isLoading}
                elementsSettings={val}
              />
            </TiteledSection>
          ))}
        </Box>
        <SelectClassPage instructorId={values.teacherId} setIsClassListEmpty={setIsClassListEmpty} />
      </ScrollableWrapper>
    </GridLayout>
  )
}
