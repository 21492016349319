import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'
import {AppearanceTemplateType} from './types'

export type GetAppearanceTemplateByIdParams = {
  id?: string
}
export type GetAppearanceTemplateByIdQueryParams = undefined

export type GetAppearanceTemplateByIdQuery = QueryMethod<
  GetAppearanceTemplateByIdParams,
  GetAppearanceTemplateByIdQueryParams,
  // @ts-ignore
  AppearanceTemplateType,
>

const defaultCacheTime: number = 1000 * 300 * 1000

export const makeGetAppearanceTemplateByIdEndpoint = ({id}: GetAppearanceTemplateByIdParams) => `displaySettings/layout-with-permissions/${id}`

export const getAppearanceTemplateByIdQuery: GetAppearanceTemplateByIdQuery = ({query, params, cacheTime = defaultCacheTime}) => ({
  cacheTime,
  refetchOnWindowFocus: false,
  queryKey: [`getAppearanceTemplateByIdQuery-${params?.id || ''}`, query],
  queryFn: ({queryKey: [_, config]}) => {
    if (params?.id) {
      return API.get<GetAppearanceTemplateByIdQueryParams, AppearanceTemplateType>(makeGetAppearanceTemplateByIdEndpoint(params), {
        params: config,
      }).then((res) => res.data)
    }
    return new Promise((res) => res([]))
  }
})
