import i18nJs, {Scope} from 'i18n-js'
import {DateTime, Settings} from 'luxon'
import {useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import voca from 'voca'

import * as enCommon from '../locale/common-en.json'
import * as en from '../locale/en.json'
import {INITIAL_LANGUAGE, LanguagesEnum, TranslatorScope} from '../locale/types'
import {logger} from '../monitoring/logger'
import {store} from '../redux/store'
import {GlobalService} from '../services/global'
import {getItemFromLocalStorage, setItemToLocalStorage} from './local-storage'
import {isNotNilAndNotEmpty} from './logic'

const {capitalize, lowerCase, titleCase, upperCase} = voca

export const declension = (
  quantity: number,
  single: TranslatorScope,
  singleGenitive: TranslatorScope,
  plural: TranslatorScope,
): Scope => {
  const lastNumber = Number(quantity) % 10
  const lastNumbers = Number(quantity) % 50
  if (lastNumbers > 10 && lastNumbers < 15) {
    return plural as Scope
  }
  if (lastNumber > 1 && lastNumber < 5) {
    return singleGenitive as Scope
  }
  if (lastNumber === 1) {
    return single as Scope
  }
  return plural as Scope
}

i18nJs.fallbacks = true
i18nJs.translations = {en: {en, enCommon}}

i18nJs.missingTranslation = function (scope: Scope) {
  logger.warn(`Missing translation detected: ${scope}`)
  return `[${scope}]`
}

export type CaseActions = 'capitalize' | 'lowerCase' | 'titleCase' | 'upperCase'

type CaseActionsMap = {
  [key in CaseActions]: (text: string) => string
}

export type LocaleOptions =
  | (Parameters<typeof i18nJs.t>[1] & {
      caseAction?: CaseActions
      declension?: [TranslatorScope, TranslatorScope]
    })
  | undefined

const caseActionMap: CaseActionsMap = {
  titleCase,
  upperCase,
  lowerCase,
  capitalize,
}

/**
 *  <pre>
 *    options supports {{}} pattern replacement:
 *    l('test {{test}}: {{variable}} text', { test: 1, variable: 'wow' }) -> 'test 1: wow text'
 *  </pre>
 *
 *  <pre>
 *    options supports case actions (capitalize, lowerCase, titleCase, upperCase):
 *    l('test string', { caseAction: 'upperCase' }) -> 'TEST STRING'
 *  </pre>
 *
 *  <pre>
 *    options supports declension:
 *    l(
 *      '{{value}} участник',
 *      { value: 1, declension: ['{{value}} участника', '{{value}} участников'] }
 *    ) -> '1 участник'
 *    l(
 *      '{{value}} участник',
 *      { value: 31, declension: ['{{value}} участника', '{{value}} участников'] }
 *    ) -> '31 участник'
 *    l(
 *      '{{value}} участник',
 *      { value: 30, declension: ['{{value}} участника', '{{value}} участников'] }
 *    ) -> '30 участников'
 *    l(
 *      '{{value}} участник',
 *      { value: 32, declension: ['{{value}} участника', '{{value}} участников'] }
 *    ) -> '32 участника'
 *  </pre>
 *
 */

export const l = (scope: TranslatorScope, options?: LocaleOptions): string => {
  const text = options?.declension
    ? i18nJs.t(declension(options.value, scope, ...options.declension), options)
    : i18nJs.t(scope as Scope, options)
  if (options && 'caseAction' in options) {
    const {caseAction} = options
    const caseActionFn = caseAction && caseActionMap[caseAction]
    if (caseActionFn) {
      return caseActionFn(text)
    }
  }

  if (!text) {
    return ''
  }

  return text
}

export const getTranslationsForLocale = (locale: 'en' | 'heb' | 'token' | 'ua') => {
  let cloudLocalization = {}
  if (locale !== LanguagesEnum.TOKEN) {
    const localization = getItemFromLocalStorage(`wba-localization-${locale}`)
    if (isNotNilAndNotEmpty(localization)) {
      cloudLocalization = JSON.parse(localization)
    }
  }
  switch (locale) {
    case LanguagesEnum.EN:
      return {
        ...require('../locale/common-en.json'),
        ...require('../locale/en.json'),
        ...require('../locale/en-new.json'),
        ...cloudLocalization,
      }
    case LanguagesEnum.UA:
      return {
        ...require('../locale/common-ua.json'),
        ...require('../locale/ua-new.json'),
        ...require('../locale/ua.json'),
        ...cloudLocalization,
      }
    case LanguagesEnum.HEB:
      return {
        ...require('../locale/common-heb.json'),
        ...require('../locale/heb-new.json'),
        ...require('../locale/heb.json'),
        ...cloudLocalization,
      }
    case LanguagesEnum.TOKEN:
      return {}
    default:
      return {
        ...require('../locale/common-en.json'),
        ...require('../locale/en.json'),
        ...require('../locale/en-new.json'),
        ...cloudLocalization,
      }
  }
}

export const langToValidLuxonLangMap: {[key in LanguagesEnum]: string} = {
  [LanguagesEnum.UA]: 'uk',
  [LanguagesEnum.EN]: 'en',
  [LanguagesEnum.HEB]: 'he',
  [LanguagesEnum.TOKEN]: 'en',
}

export const useLuxon = () => {
  const language = useSelector(GlobalService.selectors.language())
  return useMemo(() => DateTime, [language])
}

export const useLocale = () => {
  const language = useSelector(GlobalService.selectors.language())
  const getLocalizedString = useCallback(
    (scope: TranslatorScope, options?: LocaleOptions) => l(scope, options),
    [language],
  )
  return useMemo(() => ({
    language,
    l: getLocalizedString
  }),
    [language, getLocalizedString])
}

export const setLocale = (locale: LanguagesEnum) => {
  store.dispatch(GlobalService.actions.language(locale))
  setItemToLocalStorage('localeName', locale)
  i18nJs.translations = {[locale]: getTranslationsForLocale(locale)}
  i18nJs.locale = locale
  Settings.defaultLocale = langToValidLuxonLangMap[locale]
  document
    .getElementsByTagName('html')[0]
    .setAttribute('style', `direction:${locale === LanguagesEnum.HEB ? 'rtl' : 'ltr'}`)
}

Settings.defaultLocale = langToValidLuxonLangMap[INITIAL_LANGUAGE]
i18nJs.translations = {[INITIAL_LANGUAGE]: getTranslationsForLocale(INITIAL_LANGUAGE)}
i18nJs.locale = INITIAL_LANGUAGE
