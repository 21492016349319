import {useEffect} from 'react'
import {createBrowserRouter, useNavigate, useParams, useRouteError} from 'react-router-dom'

import {Layout} from '../layout'
import {AccountRemove} from '../pages/account-remove/account-remove'
import {AccountRemoveSuccess} from '../pages/account-remove/account-remove-success'
import {AddPaymentMethodFail} from '../pages/add-tp-payment-method/add-payment-method-fail'
import {AddPaymentMethodSuccess} from '../pages/add-tp-payment-method/add-payment-method-success'
import {AddPaymentMethod, CreateTPPayment} from '../pages/add-tp-payment-method/add-tp-payment-method'
import {BookingProfilePage} from '../pages/booking-profile/booking-profile-page'
import {BookingSummary} from '../pages/booking-summary/booking-summary'
import {BookingsListPage} from '../pages/bookings-list/bookings-list-page'
import {PaymentFail} from '../pages/payment/payment-fail'
import {PaymentPage} from '../pages/payment/payment-page'
import {PaymentSuccess} from '../pages/payment/payment-success'
import {ResetPasswordPage} from '../pages/reset-password/reset-password'
import {SelectBookingTypePage} from '../pages/select-booking-type/select-booking-type-page'
import {SelectClassPage} from '../pages/select-class/select-class-page'
import {SelectClassSessionPage} from '../pages/select-class-session/select-class-session'
import {SelectInstructorPage} from '../pages/select-instructor/select-instructor-page'
import {SelectInstructorOutOfFormPage} from '../pages/select-instructor-out-of-form/select-instructor-out-of-form'
import {SelectPlayspacePage} from '../pages/select-playspace/select-playspace-page'
import {SelectServicePage} from '../pages/select-service/select-service-page'
import {SelectBookingDateTimePage} from '../pages/select-service-booking-date-time/select-booking-date-time-page'
import {SelectServiceOrClassForInstructorPage} from '../pages/select-service-or-class-for-instructor/select-service-or-class-for-instructor'
import {SetParticipantsPage} from '../pages/set-participants/set-participants-page'
import {UserPaymentTypeModal} from '../pages/set-payment-method/set-payment-method'
import {VenueIdInputPage} from '../pages/venue-id-input/venue-id-input-page'
import {CreateClassBookingForm} from '../services/booking/forms/create-class-booking-form'
import {CreateInstructorBookingForm} from '../services/booking/forms/create-instructor-booking-form'
import {CreateServiceBookingForm} from '../services/booking/forms/create-service-booking-form'
import {CreateSpaceBookingForm} from '../services/booking/forms/create-space-booking-form'
import {useLocale} from '../utils/i18n'

const BubbleError = () => {
  const {l} = useLocale()
  const error = useRouteError()
  const navigate = useNavigate()
  const {venueId} = useParams()
  console.error(error)
  useEffect(() => {
    if (!venueId) {
      navigate(0)
    }
    navigate(`/${venueId}`)
  }, [error])
  return <div>{l('something_went_wrong')}</div>
}

const createBookingStepsRoutes = [
  {path: 'select-playspace', element: <SelectPlayspacePage />, errorElement: <BubbleError />},
  {path: 'select-date-time', element: <SelectBookingDateTimePage />, errorElement: <BubbleError />},
  {path: 'set-participants', element: <SetParticipantsPage />, errorElement: <BubbleError />},
  {path: 'select-instructor', element: <SelectInstructorPage />, errorElement: <BubbleError />},
  {path: 'set-payment-method', element: <UserPaymentTypeModal type={'service'} />, errorElement: <BubbleError />},
  {path: 'summary', element: <BookingSummary type={'service'} />, errorElement: <BubbleError />},
]

const paymentStatusRoutes = [
  {
    path: 'payment-success',
    element: <PaymentSuccess />,
  },
  {
    path: 'payment-fail',
    element: <PaymentFail />,
  },
]

export const router = createBrowserRouter([
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: '/remove-account-success',
    element: <AccountRemoveSuccess />,
  },
  {
    path: '/:venueId',
    element: <Layout />,
    children: [
      {
        path: 'add-payment-method-success/:paymentMethodId',
        element: <AddPaymentMethodSuccess />,
      },
      {
        path: 'add-payment-method-fail',
        element: <AddPaymentMethodFail />,
      },
      {
        path: 'bookings',
        element: <BookingsListPage />,
      },
      {path: 'bookings/:id', element: <BookingProfilePage />},
      {
        path: 'select-service',
        element: <SelectServicePage />,
      },
      {
        path: 'select-space-service',
        element: <SelectServicePage isJustTheSpace />,
      },
      {
        path: 'select-class',
        element: <SelectClassPage />,
      },
      {
        path: 'select-instructor',
        element: <SelectInstructorOutOfFormPage />,
      },
      {
        path: 'service/:serviceId',
        element: <CreateServiceBookingForm />,
        children: [...createBookingStepsRoutes],
      },
      {
        path: 'space/:serviceId',
        element: <CreateSpaceBookingForm />,
        children: [
          ...createBookingStepsRoutes,
        ],
      },
      {
        path: 'instructor/:instructorId',
        element: <CreateInstructorBookingForm />,
        children: [
          {path: 'select-service', element: <SelectServiceOrClassForInstructorPage />},
          {path: 'select-playspace', element: <SelectPlayspacePage />},
          {path: 'select-date-time', element: <SelectBookingDateTimePage />},
          {path: 'set-participants', element: <SetParticipantsPage />},
          {path: 'set-payment-method', element: <UserPaymentTypeModal type={'service'} />},
          {path: 'summary', element: <BookingSummary type={'service'} />},
        ],
      },
      {
        path: 'instructor-service/:serviceId',
        element: <CreateInstructorBookingForm />,
        children: [
          {path: 'select-instructor', element: <SelectInstructorPage />},
          {path: 'select-playspace', element: <SelectPlayspacePage />},
          {path: 'select-date-time', element: <SelectBookingDateTimePage />},
          {path: 'set-participants', element: <SetParticipantsPage />},
          {path: 'set-payment-method', element: <UserPaymentTypeModal type={'service'} />},
          {path: 'summary', element: <BookingSummary type={'service'} />},
        ],
      },
      {
        path: 'class/:classId',
        element: <CreateClassBookingForm />,
        children: [
          {path: 'select-session', element: <SelectClassSessionPage />},
          {path: 'set-participants', element: <SetParticipantsPage />},
          {path: 'set-payment-method', element: <UserPaymentTypeModal type={'class'} />},
          {path: 'summary', element: <BookingSummary type={'class'} />},
        ],
      },
      {path: '', element: <SelectBookingTypePage />},
      {path: 'user/:userId/add-payment-method', element: <AddPaymentMethod />},
      {
        path: 'remove-account',
        element: <AccountRemove />,
      },
    ],
  },
  {
    path: '/clear-view',
    children: [
      {
        path: ':venueId',
        children: [
          {path: 'user/:userId/add-payment-method', element: <AddPaymentMethod clearView />},
          {path: 'user/:userId/payment', element: <CreateTPPayment clearView />},
          {path: 'payment', element: <PaymentPage />},
          ...paymentStatusRoutes,
        ],
      },
      ...paymentStatusRoutes,
    ],
  },
  {
    path: 'add-payment-method-success',
    element: <AddPaymentMethodSuccess />,
  },
  {
    path: 'add-payment-method-fail',
    element: <AddPaymentMethodFail />,
  },
  ...paymentStatusRoutes,
  {path: '*', element: <VenueIdInputPage />},
])
