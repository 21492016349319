import {Loader as CommonLoader} from 'exsportia-components'
import React, {FC} from 'react'

export type LoaderProps = {
  type?: 'contrast' | 'default'
}

const settingsToUse = (type: LoaderProps['type']) => {
  if (type === 'contrast') {
    return {
      color: '#FFFFFF',
    }
  }
  return {
    color: '#007ce0',
  }
}

export const Loader: FC<LoaderProps> = ({type = 'default'}) => {
  return (
    <CommonLoader
      styleType='small'
      settings={settingsToUse(type)}
    />
  )
}
