import {Text} from 'exsportia-components'
import React, {FC, useMemo} from 'react'

import {useLocale} from '../../../utils/i18n'
import {typeStylesMap} from './settings'

export type PaymentStatusProps = {
  status: 'cancelled' | 'paid' | 'unpaid'
}

export const PaymentStatus: FC<PaymentStatusProps> = ({status}) => {
  const {l} = useLocale()
  const settingsToUse = useMemo(() => typeStylesMap[status], [status])
  const textSettings = useMemo(() => ({color: settingsToUse.color}), [settingsToUse])

  return (
    <Text
      text={l(settingsToUse.textToken)}
      settings={textSettings}
    />
  )
}
