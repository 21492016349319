import {Box, InputText as RawInput, Label, Span} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {ChangeEvent, FC, PropsWithChildren, useCallback, useState} from 'react'

type Props = {
  id: string
  disabled?: boolean
  placeholder?: string
  label?: string
  required?: boolean
  type?: string
}

export const Input: FC<PropsWithChildren<Props>> = ({
  disabled = false,
  id,
  placeholder,
  required = false,
  label,
  type,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const {values, errors, setFieldValue} = useFormikContext<any>()
  const paswordTypeSettings =
    type === 'password'
      ? {
          icon: isPasswordVisible ? 'hide' : 'view',
          onIconClick: () => setIsPasswordVisible(!isPasswordVisible),
        }
      : {}
  const typeToUse = type === 'password' ? (isPasswordVisible ? 'text' : 'password') : type
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(id, e.target.value)
    },
    [id, setFieldValue],
  )
  return (
    <>
      {label && (
        <Label settings={{color: Number(errors[id]?.length) > 0 ? 'error' : 'text-secondary'}}>
          {label}
          {required && <Span color={'error'}>*</Span>}
        </Label>
      )}
      <RawInput
        // @ts-ignore
        onChange={handleChange}
        name={id}
        id={id}
        disabled={disabled}
        value={values[id]}
        placeholder={placeholder}
        error={errors[id]}
        type={typeToUse}
        settings={{
          // fontSize: '16px'
        }}
        {...paswordTypeSettings}
      />
      <Box
        mt='5px'
        mb='10px'
        fontSize='12px'
        color='error'
      >
        {errors[id]}
      </Box>
    </>
  )
}
