
import {Box, Flex, P} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {DateTime} from 'luxon'
import React, {FC, useCallback, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'

import {BookingQueries} from '../../api/booking'
import {CalculateBookingPriceQueryParams, CalculatedBookingPriceObject, LimitationCheckResultType, LimitationCheckResultTypeKey} from '../../api/booking/calculate-booking-price'
import {getDuration} from '../../api/booking/helpers'
import {Participant} from '../../api/booking/types'
import {VenueQueries} from '../../api/venue'
import {InstructorCard} from '../../components/instructor-card/instructor-card'
import {PlayspaceCard} from '../../components/playspace-card/playspace-card'
import {PricingDetails} from '../../components/pricing-details/pricing-details'
import {SubscriptionCard} from '../../components/subscription-card/subscription-card'
import {ParticipantsList} from '../../components/timeslot-grid/components/participants-list'
import {FULL_CANCEL_POLICY_OPTIONS_MAP} from '../../constants/cancel-policy'
import {USAGE_TYPE_SUBSCRIPTION_OPTIONS_LABEL_MAP} from '../../constants/usage-type'
import {getParticipantsEquipment} from '../../helpers/data'
import {CreateServiceBookingFormType} from '../../services/booking/forms/create-service-booking-form'
import {useServiceOptions} from '../../services/service-options/hooks'
import {theme} from '../../theme/theme'
import {Chip} from '../../ui/components/chip/chip'
import {CheckBox} from '../../ui/components/input/checkbox'
import {TextArea} from '../../ui/components/input/text-area'
import {LabeledValueRow} from '../../ui/components/key-value-row/labeled-value-row'
import {LabeledSection} from '../../ui/components/labeled-section/labeled-section'
import {TiteledSection} from '../../ui/components/titeled-section/titeled-section'
import {auth} from '../../utils/firebase'
import {LocaleOptions, useLocale, useLuxon} from '../../utils/i18n'
import {isNilOrEmpty, isNotNilAndNotEmpty} from '../../utils/logic'
import {useModalActions} from '../../utils/modals'
import {NO_USER_PHOTO} from '../../utils/remote-content'

const defaultLOptions: LocaleOptions = {caseAction: 'capitalize'}

/* eslint-disable max-lines-per-function */
export const ServiceSummarySection: FC<{
  setIsLoading: (arg: boolean) => void
  setWholePrice?: (arg?: number) => void
// eslint-disable-next-line max-lines-per-function
}> = ({setIsLoading, setWholePrice}) => {
  const {l, language} = useLocale()
  const luxon = useLuxon()
  const params = useParams<{venueId: string}>()
  const {openModal} = useModalActions()
  const {data: venueData} = useQuery(VenueQueries.getVenueQuery({
    params: {id: params.venueId},
    refetchOnWindowFocus: false,
  }))
  const {values} = useFormikContext<CreateServiceBookingFormType>()
  const queryData: CalculateBookingPriceQueryParams = useMemo(() => ({
    options: [
      {
        date: values.date,
        end: values.time[1]!,
        start: values.time[0]!,
        venueId: venueData?.uid,
        areaId: values.area?.uid,
        sportId: values.serviceId!,
        userId: auth.currentUser?.uid,
        coverEquipment: values.coverEquipment,
        useForParticipants: values.useForParticipants,
        timeSlot: values.area?.timeSlot || values.teacher?.timeSlot,
        bookingType: values.entity === 'teacher' ? 'TEACHER' : 'SPORT',
        teacherId: !values.isJustTheSpace ? values.teacher?.uid : undefined,
        duration: getDuration({
          start: values.time[0],
          end: values.time[1],
          isNoDuration: values.service?.pricePeriod === 'NO_DURATION',
        }),
        participants: values.participants.map((el) => {
          const elToStave = {...el}
          delete elToStave.membership
          delete elToStave.membershipActivity
          return elToStave
        }),
      },
    ],
  }), [venueData, values, auth.currentUser?.uid])
  const {data, isFetching, refetch} = useQuery(BookingQueries.calculateBookingPriceQuery({
    enabled: !!values.participants,
    query: queryData,
    refetchOnWindowFocus: false,
  }))
  useEffect(() => {
    refetch()
  }, [queryData])
  const {getServiceConfigByServiceType} = useServiceOptions([values.service?.serviceType])
  const wholeDiscount = useMemo(() => (data ? data.reduce((acc, el) => acc + Number(el.discount), 0) : 0), [data])
  useEffect(() => {
    setIsLoading(isFetching)
  }, [isFetching, setIsLoading])
  useEffect(() => {
    setWholePrice && setWholePrice(data?.[0].price)
  }, [isFetching, data, setWholePrice])
  const {bookingDateTime, bookingDuration} = useMemo<{
    bookingDateTime: DateTime
    bookingDuration: string
  }>(() => {
    if (!values.time[0] || !values.time[1]) {
      // VALIDATE FORM AND NAVIGATE
      return {bookingDateTime: luxon.now(), bookingDuration: '-'}
    }
    return {
      bookingDateTime: luxon.fromMillis(values.time[0]),
      bookingDuration:
        values.service?.pricePeriod === 'NO_DURATION'
          ? l('noDuration')
          : `${
              luxon.fromMillis(values.time[1]).diff(luxon.fromMillis(values.time[0]), 'minutes').toObject().minutes
            } ${l('min')}`,
    }
  }, [l, luxon, values.time])
  const {label, icon} = useMemo(
    () => getServiceConfigByServiceType(values.service?.serviceType),
    [values.service, getServiceConfigByServiceType],
  )

  const cancelPolicyToUse = values.participants[0]?.membership?.cancelPolicy || values.service?.cancelPolicy
  const cancelPolicyKey = cancelPolicyToUse && FULL_CANCEL_POLICY_OPTIONS_MAP[cancelPolicyToUse].description
  const cancelPolicyDescription = cancelPolicyKey ? l(cancelPolicyKey) : cancelPolicyToUse

  const usageData: any = useMemo(
    () =>
      data
        ? data[0].participants.reduce(
            (acc, participant) => {
              const used: LimitationCheckResultType | null = participant.limitationCheckResult?.used || null
              if (!used) {
                return acc
              }
              const firstPropName: LimitationCheckResultTypeKey | null = Object.keys(used)[0] as LimitationCheckResultTypeKey
              return {
                used: acc.used + (used[firstPropName] || 0),
                amountByType: USAGE_TYPE_SUBSCRIPTION_OPTIONS_LABEL_MAP[firstPropName],
              }
            },
            {
              used: 0,
              amountByType: USAGE_TYPE_SUBSCRIPTION_OPTIONS_LABEL_MAP.entries,
            },
          )
        : null,
    [data],
  )

  const bookingPriceData = useMemo(() => {
    if (!data) {
      return []
    }
    return [
      ...data.map((el: CalculatedBookingPriceObject) => {
        const timeArr = []
        if (el.duration.hours) {
          timeArr.push(l('hours_short', {value: el.duration.hours}))
        }
        if (el.duration.minutes) {
          timeArr.push(l('minutes_short', {value: el.duration.minutes}))
        }
        const priceAndDiscount = el.participants.reduce(
          (acc, participant) => ({
            price: acc.price + participant.totalPrice + participant.discount,
            discount: acc.discount + participant.discount,
          }),
          {
            price: 0,
            discount: 0,
          },
        )
        return {
          discount: priceAndDiscount.discount,
          count: el.numberOfPeople,
          price: priceAndDiscount.price,
          text: l('service_price_element', {time: timeArr.join(' '), service: l(label)}),
        }
      }),
      ...getParticipantsEquipment(data.reduce<Participant[]>((acc, el) => [...acc, ...el.participants], [])),
    ]
  }, [data, l]) // eslint-disable-line

  const playspaceCard = useMemo(() => {
    if (isNilOrEmpty(values.area)) {
      return null
    }
    let subTextElements: any[] = []
    if (isNotNilAndNotEmpty(values.area.areaSize)) {
      subTextElements = [values.area.areaSize]
    }
    if (isNotNilAndNotEmpty(values.area.surfaceType)) {
      subTextElements = [...subTextElements, l(values.area.surfaceType!, {caseAction: 'capitalize'})]
    }
    return (
      <PlayspaceCard
        styleType='square'
        image={
          <Box
            backgroundSize='cover'
            height='48px'
            width='72px'
            background={`url(${values.area.image}) no-repeat center`}
          />
        }
        name={values.area.name}
        subTextElements={subTextElements}
      />
    )
  }, [values, language])

  const openParticipantsModal = useCallback(() => {
    openModal({
      title: l('participants'),
      footerConfig: {
        disabled: true,
      },
      component: (
        <ParticipantsList
          participants={values.participants}
          displayEquipmentCount={true}
        />
      ),
    })
  }, [values.participants, openModal])

  return (
    <Box>
      <Chip
        text={values.service?.name}
        icon={icon}
        styleType={!values.isJustTheSpace ? 'service' : 'justTheSpace'}
      />
      <TiteledSection title={l('booking_information', defaultLOptions)}>
        <LabeledValueRow label={l('dateAndTime')}>
          {bookingDateTime.toUTC().toFormat('HH:mm, MMM dd, yyyy')}
        </LabeledValueRow>
        <LabeledValueRow label={l('duration')}>{bookingDuration}</LabeledValueRow>
        <LabeledValueRow label={l('participants')}>
          <Flex
            cursor='pointer'
            onClick={openParticipantsModal}
          >
            {`${values.participants.length} ${l('participants')}`}
          </Flex>
        </LabeledValueRow>
      </TiteledSection>
      <TiteledSection title={l('about', defaultLOptions)}>
        {values.area && <LabeledSection label={l('playSpace', defaultLOptions)}>{playspaceCard}</LabeledSection>}
        {values.teacher && (
          <LabeledSection label={l('instructors')}>
            <InstructorCard
              name={values.teacher.name}
              image={
                <Box
                  backgroundSize='cover'
                  height='48px'
                  width='48px'
                  background={`url(${values.teacher.profileImage.secure_url || NO_USER_PHOTO}) no-repeat center`}
                />
              }
            />
          </LabeledSection>
        )}
      </TiteledSection>
      {values.participants[0]?.membership && (
        <TiteledSection title={l('subscription')}>
          <SubscriptionCard
            usage={usageData}
            styleType='summary'
            isLoading={isFetching}
            name={values.participants[0].membership.name}
            imageUrl={values.participants[0].membership.logo?.secure_url || ''}
          />
        </TiteledSection>
      )}
      <TiteledSection title={l('extra_details', defaultLOptions)}>
        <Box>
          <Flex
            py={theme.space.multiplier0_5x}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <P fontSize={'tag'}>{l('specialRequest')}</P>
            <Box>
              <CheckBox id={'hasSpecialRequests'} />
            </Box>
          </Flex>
          {values.hasSpecialRequests && (
            <Box pt={'8px'}>
              <TextArea id={'specialRequest'} />
            </Box>
          )}
        </Box>
        {cancelPolicyDescription && (
          <Box pt={'8px'}>
            <LabeledSection label={l('cancelPolicy')}>{cancelPolicyDescription}</LabeledSection>
          </Box>
        )}
      </TiteledSection>
      <PricingDetails
        isLoading={isFetching}
        discount={wholeDiscount}
        values={bookingPriceData}
        currency={venueData?.currency ?? ''}
      />
    </Box>
  )
}
