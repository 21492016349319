// @ts-ignore
import * as icons from 'exsportia-icons'
import IconProps from 'exsportia-icons/dist/src/icons/types'
import {FC, JSX, memo} from 'react'

import {theme} from '../../../theme/theme'

type Props = IconProps & {
  icon: string
  color?: string
}

export const ExsportiaIcon: FC<Props> = memo(({icon, color = 'bg-secondary', ...rest}) => {
  const Icon = icons[icon]
  const colorToUse = theme.colors[color as keyof typeof theme.colors]
  return (
    <Icon
      {...rest}
      color={colorToUse}
    />
  )
})

export const exsportiaIcons: Record<string, JSX.Element> = icons
