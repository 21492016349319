import {Formik, FormikConfig} from 'formik'
import {FC, useCallback, useMemo, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {Outlet, useNavigate, useParams} from 'react-router-dom'

import {BookingQueries} from '../../../api/booking'
import {classBookingAdapter, payByTokenClassBookingAdapter, PayByTokenClassBookingDraft} from '../../../api/booking/helpers'
import {Equipment} from '../../../api/booking/types'
import {Class} from '../../../api/class/types'
import {Session, SessionSelectSession} from '../../../api/class-session/types'
import {getEntityDataByIdFromAppearanceSettings} from '../../../api/helpers/data'
import {ServiceTypesEnum} from '../../../api/service/types'
import {useModalActions} from '../../../utils/modals'
import {defaultUserValues, IntegratedSignUpFormType} from '../../auth/forms/integrated-sign-up-form'
import {useHandleDraftEntityStatusChange} from '../../payments/hooks/handle-draft-entity-status-change.hook'
import {usePaymentProvider} from '../../payments/hooks/payment-provider-form.provider'
import {useBookingLoader} from '../hooks'
import {BookingService} from '../index'
import {RedirectionComponent} from './components/redirection-component'
import {useOpenBookingValidationModal} from './hooks/open-booking-validation-modal.hook'
import {BookingStateParticipant} from './types'

export type CreateClassBookingFormType = IntegratedSignUpFormType & {
  venueId?: string
  entity: 'class'
  date: string | null
  class: Class | null
  bookingType: 'class'
  areaId: string | null
  specialRequest: string
  classId: string | null
  maxParticipants: number
  minParticipants: number
  session: Session | null
  timestamp: number | null
  sessionId: string | null
  coverEquipment?: boolean
  hasSpecialRequests: boolean
  participantsNames?: boolean
  classEquipments: Equipment[]
  sportType?: ServiceTypesEnum
  paymentMethodId: string | null
  userId: string | null | undefined
  closestSessionDate: string | null
  duration?: number | string | null
  participantsNamesWhenFull?: boolean
  participants: BookingStateParticipant[]
  instructors: SessionSelectSession['instructors'] | null
  paymentType?: 'oneTimePayment' | 'payNow' | 'payOnLocation'
  useForParticipants?: boolean
}

type Params = {
  venueId: string
  classId: string
}

export const CreateClassBookingForm: FC = () => {
  // const luxon = useLuxon()
  const {venueId, classId} = useParams<Params>()
  const [entityId, setEntityId] = useState<string | null>(null)
  const navigate = useNavigate()
  const {closeAllModals} = useModalActions()
  const {isLoading, setIsLoading} = useBookingLoader()
  const {openBookingValidationModal} = useOpenBookingValidationModal()
  const {openPaymentForm} = usePaymentProvider()
  const {mutate: createDraftBooking} = useMutation(BookingService.mutations.createClassBookingDraftMutation({}))
  const {data: priceDataObjects} = useQuery(BookingQueries.calculateBookingPriceQuery({enabled: false}))

  if (!venueId || !classId) {
    return null
  }

  const classData = getEntityDataByIdFromAppearanceSettings(venueId, classId) as Class | null

  const {mutate: createClassBooking} = useMutation(BookingService.mutations.createClassBookingMutation({}))
  const {mutate: createPayByTokenClassBooking} = useMutation(
    BookingService.mutations.createClassBookingPayByTokenMutation({}),
  )
  const {setStartRefetch} = useHandleDraftEntityStatusChange({
    entity: 'booking',
    entityId,
    isLoading,
    setIsLoading,
  })

  const initialValues = useMemo<CreateClassBookingFormType>(
    () => ({
      venueId,
      classId,
      date: null,
      areaId: null,
      session: null,
      entity: 'class',
      timestamp: null,
      sessionId: null,
      class: classData,
      participants: [],
      instructors: null,
      minParticipants: 1,
      specialRequest: '',
      classEquipments: [],
      bookingType: 'class',
      paymentMethodId: null,
      closestSessionDate: classData?.closestSessionTimestamp || null,
      maxParticipants: Infinity,
      hasSpecialRequests: false,
      ...defaultUserValues,
    }),
    [venueId, classData, classId],
  )

  const handleSubmit = useCallback<FormikConfig<CreateClassBookingFormType>['onSubmit']>(
    (values) => {
      if (!priceDataObjects) {
        return
      }
      const isPayNow = !!(values.paymentMethodId && values.paymentType !== 'payOnLocation')
      const createBookingFunc = isPayNow ? createPayByTokenClassBooking : createClassBooking
      const dataForAdapter = {
        bookingState: values,
        priceDataObjects,
      }
      let adaptedData = null
      let dataForDraft: PayByTokenClassBookingDraft | null = null
      if (values.paymentType !== 'payOnLocation') {
        adaptedData = payByTokenClassBookingAdapter(dataForAdapter)
        dataForDraft = adaptedData
      } else {
        adaptedData = classBookingAdapter(dataForAdapter)
      }
      if (values.paymentType === 'oneTimePayment') {
        if (dataForDraft === null) {
          return
        }
        createDraftBooking(
          adaptedData,
          {
            onSuccess: () => {
              setStartRefetch(true)
              setEntityId(dataForDraft?.bookingId || null)
              openPaymentForm && openPaymentForm({
                totalPrice:
                dataForDraft?.data.totalPrice,
                bookingId: dataForDraft?.bookingId,
                entity: 'booking',
                startProcessCallback: () => setIsLoading(true),
              })
            }
          }
        )
      } else {
        setEntityId(null)
        setIsLoading(true)
        createBookingFunc(adaptedData, {
          onSuccess: (data) => {
            closeAllModals()
            setIsLoading(false)
            navigate(`/${venueId}/bookings/${data.bookingId || data[0].uid}`)
          },
          onError: (error) => {
            console.log(error)
            setIsLoading(false)
            if (error.response?.status === 409) {
              return openBookingValidationModal({type: 'class'})
            }
          },
        })
      }
    },
    [venueId, priceDataObjects, createClassBooking, createPayByTokenClassBooking, setIsLoading, navigate, closeAllModals],
  )

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <RedirectionComponent>
        <Outlet />
      </RedirectionComponent>
    </Formik>
  )
}
