import {ButtonSettingsProps} from 'exsportia-components/dist/components/atoms/button'

import {theme} from '../../../theme/theme'

export type ChevronSettingsProps = ButtonSettingsProps

export const defaultSettings: ChevronSettingsProps = {
  width: '40px',
  height: '40px',
  boxShadow: 'unset',
  border: 'default-border',
}

export const entityCardTypeSettings: ChevronSettingsProps = {
  bg: 'unset',
  border: 'unset',
  icon: {color: 'icon-secondary'},
}

export const simpleSettings: ChevronSettingsProps = {
  icon: {color: theme.colors['text-default'], opacity: 0.5},
  hover: {icon: {color: theme.colors['text-default'], opacity: 1}},
  bg: 'unset',
  border: 'unset',
  width: '16px',
  height: '40px',
  boxShadow: 'unset',
}

export const typeStylesMap = {
  default: defaultSettings,
  entityCard: entityCardTypeSettings,
  simple: simpleSettings,
}

export const additionalStylesMap = {}
