import {IconWithTextSettingsType} from 'exsportia-components/dist/components/molecules/icon-with-text'

export const defaultSettings: IconWithTextSettingsType = {
  width: '100%',
  height: 'max-content',
  icon: {
    overflow: 'hidden',
    borderRadius: '12px',
    width: 'multiplier8x',
    height: 'multiplier8x',
    iconWidth: 'multiplier8x',
    iconHeight: 'multiplier8x',
  },
  subText: {
    fontSize: 'label',
    display: 'block',
    styleType: 'cropped',
    color: 'text-secondary',
    lineHeight: 'card-subtext-line-height',
  },
}

export const typeStylesMap = {default: defaultSettings}

export const additionalStylesMap = {}
