import {Divider, Flex, Title} from 'exsportia-components'
import {FC, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'

import {BookingQueries} from '../../api/booking'
import {Booking} from '../../api/booking/types'
import {BookingCardForList} from '../../components/bookings-card-for-list/bookings-card-for-list'
import {EmptyList} from '../../components/empty-list/empty-list'
import {NavMenu} from '../../components/nav-menu/nav-menu'
import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {useAuthState} from '../../services/auth/hooks'
import {NavigationList} from '../../ui/components/navigation-list/navigation-list'
import {TabButtonProps} from '../../ui/components/tab-button/tab-button'
import {TiteledSection} from '../../ui/components/titeled-section/titeled-section'
import {auth} from '../../utils/firebase'
import {useLocale, useLuxon} from '../../utils/i18n'
import {isNotEmpty, isNotNilAndNotEmpty} from '../../utils/logic'

type tabType = 'past' | 'upcoming' | 'waiting'

const Header: FC<{tabSettings: TabButtonProps[]}> = ({tabSettings}) => {
  const {l} = useLocale()
  return (
    <>
      <Flex
        display={['flex', 'none']}
        p='16px 16px 0 16px'
      >
        <Title text={l('bookings')} />
      </Flex>
      <NavMenu tabsSettings={tabSettings} />
      <Divider settings={{m: 0}} />
    </>
  )
}

const BookingsList: FC<{activeTab: tabType}> = ({activeTab}) => {
  const {venueId} = useParams()
  const luxon = useLuxon()
  const userId = auth.currentUser?.uid
  const {data, isLoading} = useQuery(
    BookingQueries.getUserBookingsQuery({
      params: {
        userId,
      },
      query: {
        venueId,
        bookingType: activeTab,
      },
    }),
  )
  const bookingsForDisplay = useMemo(() => {
    const bookingsByDate = (data?.bookings || []).reduce<{[key: string]: Booking[]}>((acc, booking) => {
      const timestamp = booking.timestamp
      const dayString = luxon.fromMillis(Number(timestamp)).toUTC().toFormat('DD')
      return {
        ...acc,
        [dayString]: [...(isNotNilAndNotEmpty(acc[dayString]) ? acc[dayString] : []), booking],
      }
    }, {})
    return Object.keys(bookingsByDate).map((dayString, sectionIndex) => (
      <TiteledSection title={dayString} key={`section-${sectionIndex}`}>
          {bookingsByDate[dayString].map((booking, index) => (
          <BookingCardForList
            key={`booking-card-${index}`}
            booking={booking}
            isWaiting={activeTab === 'waiting'}
          />
        ))}
      </TiteledSection>
    ))
  }, [data, activeTab])
  if (data?.bookings === null || isLoading) {
    return (
      <NavigationList
        isLoading={true}
        Component={BookingCardForList}
        withoutChevron={true}
        elementsSettings={[]}
      />
    )
  } else if (!isNotEmpty(data?.bookings)) {
    return <EmptyList withoutBack={true} />
  }
  return <>{bookingsForDisplay}</>
}

export const BookingsListPage: FC = () => {
  const {l} = useLocale()
  const {venueId} = useParams()
  const navigate = useNavigate()
  const {isLoggedIn} = useAuthState()
  const [activeTab, setActiveTab] = useState<tabType>('upcoming')
  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/${venueId}`)
    }
  }, [isLoggedIn])
  const tabSettings: TabButtonProps[] = useMemo(
    () => [
      {
        text: l('upcoming'),
        isActive: activeTab === 'upcoming',
        onClick: () => setActiveTab('upcoming'),
      },
      {
        text: l('past'),
        isActive: activeTab === 'past',
        onClick: () => setActiveTab('past'),
      },
      {
        text: l('waitingList'),
        isActive: activeTab === 'waiting',
        onClick: () => setActiveTab('waiting'),
      },
    ],
    [activeTab],
  )
  return (
    <GridLayout header={<Header tabSettings={tabSettings} />}>
      <BookingsList activeTab={activeTab} />
    </GridLayout>
  )
}
