import {DateTime as luxon} from 'luxon'

import {GetBookingQueryReturnType} from '../api/booking/get-booking'
import {BookingUsageDataType, Participant} from '../api/booking/types'
import {GetVenueQueryReturnType} from '../api/venue/get-venue'
import {PricingElementType} from '../components/pricing-details/pricing-details'
import {FULL_CANCEL_POLICY_OPTIONS_MAP} from '../constants/cancel-policy'
import {USAGE_TYPE_SUBSCRIPTION_OPTIONS_MAP} from '../constants/usage-type'
import {TranslatorScope} from '../locale/types'
import {CurrenciesEnum} from '../pages/select-service/select-service-page'
import {LocaleOptions} from '../utils/i18n'
import {isNotNilAndNotEmpty} from '../utils/logic'
import {currDateWithTimeZone} from './other'

const getHoursBeforeForPayOnArrival = (count: number, type: 'DAYS' | 'HOURS') => {
  if (type === 'HOURS') {
    return count
  }
  return count * 24
}

export const checkIfWithRequestCancel = ({
  booking,
  venue,
}: {
  booking?: GetBookingQueryReturnType
  venue?: GetVenueQueryReturnType
}): {
  amountForRefund: number
  bookingWithSubscription: boolean
  contactToVenue: boolean
  fee: number
  isPastBooking: boolean
  occupyUsage: boolean
  cancelPolicy: string
  currency: CurrenciesEnum
  isPaymentOnArrival: boolean
} | null => {
  if (!venue || !booking) {
    return null
  }
  const {
    isPaid,
    totalPrice,
    paymentType,
    participants,
    cancelPolicy,
    date_timestamp,
    // paymentMethodId,
    allowUserToCancelBooking,
    cancelForPayOnLocationType,
    cancelForPayOnLocationDesc,
    cancelForPayOnLocationCount,
  } = booking
  const isPaymentOnArrival = !paymentType
  const currentVenueDate = currDateWithTimeZone(venue.timeZone)
  const hoursAmountBeforeStart = isPaymentOnArrival
    ? getHoursBeforeForPayOnArrival(cancelForPayOnLocationCount || 0, cancelForPayOnLocationType || 'HOURS')
    : FULL_CANCEL_POLICY_OPTIONS_MAP[cancelPolicy].hoursBeforeCancel
  const endDateForCancel = luxon.fromMillis(Number(date_timestamp)).toUTC().minus({hours: hoursAmountBeforeStart})
  const fitInCancelPolicy = endDateForCancel.valueOf() > currentVenueDate.valueOf()
  const isPastBooking = currentVenueDate.valueOf() > luxon.fromMillis(Number(date_timestamp)).valueOf()
  const bookingWithSubscription = isNotNilAndNotEmpty(participants[0]?.membershipId)

  let fee = 0
  let occupyUsage = true
  let amountForRefund = 0
  let contactToVenue = false
  if (isPaymentOnArrival && !fitInCancelPolicy) {
    contactToVenue = true
  }

  if (allowUserToCancelBooking === false) {
    contactToVenue = true
  } else if (isPaid) {
    if (totalPrice == 0) {
      if (bookingWithSubscription) {
        occupyUsage = !fitInCancelPolicy
      } else {
        // just cancel
        amountForRefund = 0
      }
      amountForRefund = 0
      fee = 0
    } else if (paymentType === 'EXSPORTIA_PAY') {
      // if (isNilOrEmpty(paymentMethodId)) {
      //   contactToVenue = true
      // } else
      if (bookingWithSubscription) {
        occupyUsage = !fitInCancelPolicy
        amountForRefund = fitInCancelPolicy ? totalPrice : 0
        fee = fitInCancelPolicy ? 0 : totalPrice
      } else {
        amountForRefund = fitInCancelPolicy ? totalPrice : 0
        fee = fitInCancelPolicy ? 0 : totalPrice
      }
    } else {
      if (bookingWithSubscription) {
        contactToVenue = true
      } else {
        if (fitInCancelPolicy) {
          contactToVenue = true
        } else {
          amountForRefund = 0
          fee = totalPrice
        }
      }
    }
  } else {
    if (bookingWithSubscription) {
      // cancel and credit usage
      occupyUsage = false
      amountForRefund = 0
      fee = 0
    } else {
      // just cancel
      amountForRefund = 0
      fee = 0
    }
  }

  return {
    fee,
    occupyUsage,
    isPastBooking,
    contactToVenue,
    amountForRefund,
    isPaymentOnArrival,
    bookingWithSubscription,
    currency: venue.currency,
    cancelPolicy: isPaymentOnArrival && cancelForPayOnLocationDesc ? cancelForPayOnLocationDesc : cancelPolicy,
  }
}

export const getParticipantsEquipment = (participants: Participant[], calculateFullPrice = true) => {
  const equipments: PricingElementType[] = participants.reduce<PricingElementType[]>((acc, cur) => {
    let accToSave = [...acc]
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!cur.equipments?.length) {
      return accToSave
    }
    cur.equipments.forEach((eq) => {
      const existingIndex = accToSave.findIndex((existedEq) => existedEq.text === eq.value)
      if (existingIndex !== -1) {
        accToSave[existingIndex] = {
          ...accToSave[existingIndex],
          count: accToSave[existingIndex].count + eq.quantity,
        }
      } else {
        accToSave = [
          ...accToSave,
          {
            price: eq.price,
            text: eq.value,
            count: eq.quantity,
          },
        ]
      }
    })
    return accToSave.filter((el) => el.count !== 0)
  }, [])
  const equipmentsToReturn = calculateFullPrice
    ? equipments.map((eq) => ({
        ...eq,
        price: eq.price * eq.count,
      }))
    : equipments
  return equipmentsToReturn
}

export const getUsageString = (
  l: (scope: TranslatorScope, options?: LocaleOptions) => string,
  usage?: BookingUsageDataType | null,
  isRefund?: boolean,
) => {
  if (!usage) {
    return null
  }
  const label: any = USAGE_TYPE_SUBSCRIPTION_OPTIONS_MAP[usage.amountByType]
  const usageText = `${usage.used} ${l(label)}`
  return `${usageText} ${l(isRefund ? 'refunded' : 'used', {caseAction: 'titleCase'})}`
}
