// eslint-disable-next-line no-restricted-imports
import {Button} from 'exsportia-components'
import React, {FC, useMemo} from 'react'

// import {LanguagesEnum} from '../../../locale/types'
import {useLocale} from '../../../utils/i18n'
import {chevronIconMap} from './constants'
import {typeStylesMap} from './settings'

export type ChevronProps = {
  disabled?: boolean
  onClick?: (e: Event) => void
  direction?: 'down' | 'left' | 'right' | 'up'
  styleType?: keyof typeof typeStylesMap
}

export const Chevron: FC<ChevronProps> = ({onClick, disabled, direction = 'right', styleType = 'default'}) => {
  const {language} = useLocale()
  const chevronIcon = useMemo(() => {
    const directionToUse = direction
    // if (language === LanguagesEnum.HEB) {
    //   switch (directionToUse) {
    //     case 'left':
    //       directionToUse = 'right'
    //       break
    //     case 'right':
    //       directionToUse = 'left'
    //       break
    //     default:
    //       break
    //   }
    // }
    return chevronIconMap[directionToUse]
  }, [direction, language])
  const settings = useMemo(() => typeStylesMap[styleType], [styleType])

  return (
    <Button
      onClick={onClick}
      icon={chevronIcon}
      styleType='control.hidden'
      disabled={disabled}
      settings={settings}
    />
  )
}
