import {Flex} from 'exsportia-components'
import React, {FC, useMemo} from 'react'

import {isFunction} from '../../../utils/logic'
import {Chevron} from '../chevron/chevron'
import {GradientLoader} from '../gradient-loader/gradient-loader'
import {defaultSettings} from './settings'

export type NavigationListProps<ComponentProps extends {value: string}> = {
  Component: FC<ComponentProps>
  isLoading?: boolean
  withoutChevron?: boolean
  elementsSettings: ComponentProps[]
}

type ElementSettings = {
  [key: string]: any
}

export const NavigationList: FC<NavigationListProps<any>> = ({
  isLoading = false,
  Component,
  elementsSettings = [],
  withoutChevron = false,
}) => {
  const skeletonData = Array(4).fill({})
  const valueToDisplay = useMemo(
    () =>
      (isLoading ? skeletonData : elementsSettings).map((elementSettings, index) => {
        const hasOnClick = !isLoading && isFunction(elementSettings.onClick) && !elementSettings.disabled
        const componentSettings: ElementSettings = {}
        for (const key in elementSettings) {
          if (key !== 'onClick') {
            componentSettings[key] = elementSettings[key]
          }
        }
        const onClick = hasOnClick ? () => elementSettings.onClick(elementSettings.value) : null
        return (
          <Flex
            p={'8px 16px'}
            width='100%'
            onClick={onClick}
            alignItems='center'
            justifyContent='space-between'
            key={`navigationElement${index}`}
            cursor={hasOnClick ? 'pointer' : 'unset'}
            backgroundHover='rgba(0, 16, 61, 0.06)'
          >
            {isLoading ? <GradientLoader {...defaultSettings.textLoader} /> : <Component {...componentSettings} />}
            {!withoutChevron && !elementSettings.disabled && (
              <Chevron
                direction={'right'}
                styleType='simple'
              />
            )}
          </Flex>
        )
      }),
    [isLoading, elementsSettings],
  )

  return (
    <Flex
      width={defaultSettings.width}
      height={defaultSettings.height}
      flexDirection={defaultSettings.flexDirection}
    >
      {valueToDisplay}
    </Flex>
  )
}
