import {BookingStateParticipant} from '../../services/booking/forms/types'
import {NO_USER_PHOTO} from '../../utils/remote-content'

export const createBookingStateParticipant = ({
  name,
  avatar,
  membership,
  coverEquipment,
  memberParticipantIndex,
}: {
  name: string
  avatar?: string
  membership?: BookingStateParticipant['membership']
  membershipActivity?: BookingStateParticipant['membershipActivity']
  memberParticipantIndex?: number
  coverEquipment?: boolean
}): BookingStateParticipant => {
  return {
    defaultSubApplied: false,
    membership: membership || null,
    equipments: [],
    membershipBookingId: membership?.membershipBookingId,
    membershipId: membership?.uid,
    membershipActivity: null,
    edited: false,
    name,
    avatar: avatar ?? NO_USER_PHOTO,
    memberParticipantIndex,
    coverEquipment,
  }
}
