import {API} from '../../networking/http'
import {BookingStateParticipant} from '../../services/booking/forms/types'
import {MutationMethod} from '../helpers/types'
import {Subscription} from './types'

type ServiceBasedOption = {
  start?: number
  end?: number
  areaId?: string
  sportId?: string | null
  teacherId?: string
}

type ClassBasedOption = {
  slotId?: string
  classId?: string | null
}

export type Option = ClassBasedOption & ServiceBasedOption & {
  venueId?: string
  userId?: string
  date?: string
  participants?: BookingStateParticipant[]
  useForParticipants?: boolean
  duration?: number | string
  timeSlot?: number
  bookingType?: string
  consideredDate?: string
  sportType?: string
  isConsideredOption?: boolean
}

export type SubscriptionStatus = string

export type ValidateUserSubscriptionsParams = undefined
export type ValidateUserSubscriptionsMutationParams = {
  options: Option[]
}
export type ValidateUserSubscriptionsMutationReturnType = Subscription[]
export type ValidateUserSubscriptionsMutation = MutationMethod<
  ValidateUserSubscriptionsParams,
  ValidateUserSubscriptionsMutationParams,
  ValidateUserSubscriptionsMutationReturnType
>

export const makeValidateUserSubscriptionsEndpoint = () => 'bookings/validate/byUser'

export const validateUserSubscriptionsMutation: ValidateUserSubscriptionsMutation = () => ({
  mutationFn: (user) =>
    API.post<ValidateUserSubscriptionsMutationParams, ValidateUserSubscriptionsMutationReturnType>(
      makeValidateUserSubscriptionsEndpoint(),
      user,
    ).then((res) => res.data),
})
