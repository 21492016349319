import {Menu, NestedMenuItem} from 'exsportia-components'
import {MenuItemComplexProps} from 'exsportia-components/dist/components/organisms/menu-item'
import {FC, ReactElement} from 'react'

export type DropdownMenuPropsType = {
  name: string
  options: MenuItemComplexProps[]
  children: ReactElement<any, any> | string
  height?: string
}

export const DropdownMenu: FC<DropdownMenuPropsType> = ({children, options, name, height = '40px'}) => {
  return (
    <NestedMenuItem
      Component={Menu}
      options={options}
      text={children}
      name={name}
      closeOnClick={true}
      closeAllOnClickSubItem={true}
      withoutChevron={true}
      settings={{height, width: 'max-content'}}
    />
  )
}
