// import {useParams} from 'react-router-dom'
import {Flex, Icon, Text, Title} from 'exsportia-components'
import {FC} from 'react'

import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {useLocale} from '../../utils/i18n'

export const AddPaymentMethodSuccess: FC = () => {
  // const {paymentMethodId} = useParams<{paymentMethodId: string}>()
  const {l} = useLocale()

  return (
    <GridLayout
      header={
        <Flex
          p='8px'
          justifyContent='center'
        >
          <Title
            settings={{textAlign: 'center'}}
            text={`${l('nowAdded')} ${l('paymentMethod', {caseAction: 'lowerCase'})}`}
          />
        </Flex>
      }
    >
      <Flex
        alignItems='center'
        flexDirection='column'
      >
        <Icon
          settings={{
            color: '#0DC268',
            width: '120px',
            height: '120px',
            iconWidth: '120px',
            iconHeight: '120px',
          }}
          icon={'doneIllustration'}
        />
        <Text
          settings={{textAlign: 'center', m: '0 0 16px 0'}}
          text={l('paymentMethodHasBeenAdded', {caseAction: 'capitalize'})}
        />
        <Text
          settings={{textAlign: 'center'}}
          text={l('you_can_close_tab', {caseAction: 'capitalize'})}
        />
      </Flex>
    </GridLayout>
  )
}
