export const sentryDSN = 'https://27b0513f4172401d9d305c477e565671@o953043.ingest.sentry.io/4505345455030272'

export const envsUrlsMap = {
  development: {
    VMS: 'https://vms.dev.exsportia.com',
    WBA: 'https://wba.dev.exsportia.com',
    BOOKING: 'https://booking.dev.exsportia.com',
  },
  staging: {
    VMS: 'https://vms.staging.exsportia.com',
    WBA: 'https://wba.staging.exsportia.com',
    BOOKING: 'https://booking.staging.exsportia.com',
  },
  preprod: {
    VMS: 'https://vms.preprod.exsportia.com',
    WBA: 'https://wba.preprod.exsportia.com',
    BOOKING: 'https://booking.preprod.exsportia.com',
  },
  production: {
    VMS: 'https://vms.exsportia.com',
    WBA: 'https://wba.exsportia.com',
    BOOKING: 'https://booking.exsportia.com',
  },
}

export type envFirebaseOptionType = {
  APP_ID: string
  API_KEY: string
  PROJECT_ID: string
  AUTH_DOMAIN: string
  DATABASE_URL: string
  STORAGE_BUCKET: string
  MEASUREMENT_ID?: string
  MESSAGING_SENDER_ID: string
}

export type firebaseOptionsMapType = {
  development: envFirebaseOptionType
  staging: envFirebaseOptionType
  production: envFirebaseOptionType
  preprod: envFirebaseOptionType
}

const developmentFirebaseOptions: envFirebaseOptionType = {
  PROJECT_ID: 'exsportia-dev',
  MESSAGING_SENDER_ID: '484903386861',
  STORAGE_BUCKET: 'exsportia-dev.appspot.com',
  AUTH_DOMAIN: 'exsportia-dev.firebaseapp.com',
  API_KEY: 'AIzaSyAZ8_FE8k84w489s87rsU-4fa8dDtwGCRE',
  APP_ID: '1:484903386861:web:8236374858bbace3f356e4',
  DATABASE_URL: 'https://exsportia-dev.firebaseio.com',
}

const stagingFirebaseOptions: envFirebaseOptionType = {
  PROJECT_ID: 'exsportia-staging',
  MESSAGING_SENDER_ID: '604982450803',
  STORAGE_BUCKET: 'exsportia-staging.appspot.com',
  AUTH_DOMAIN: 'exsportia-staging.firebaseapp.com',
  API_KEY: 'AIzaSyA5iHIk4rHQQi8SwMRZGbybluHJlNxupX4',
  APP_ID: '1:604982450803:web:0c89db675b784922d9a806',
  DATABASE_URL: 'https://exsportia-staging.firebaseio.com',
}

const productionFirebaseOptions: envFirebaseOptionType = {
  PROJECT_ID: 'exsportia-prod',
  MEASUREMENT_ID: 'G-SKYY1HSWES',
  MESSAGING_SENDER_ID: '496038283449',
  STORAGE_BUCKET: 'exsportia-prod.appspot.com',
  AUTH_DOMAIN: 'exsportia-prod.firebaseapp.com',
  API_KEY: 'AIzaSyAYHyCUTCnbdfYugUTLILxkoglI6Jb-7qo',
  APP_ID: '1:496038283449:web:26b42acb90106d28537368',
  DATABASE_URL: 'https://exsportia-prod.firebaseio.com',
}

export const firebaseOptionsMap: firebaseOptionsMapType = {
  preprod: stagingFirebaseOptions,
  staging: developmentFirebaseOptions,
  production: productionFirebaseOptions,
  development: developmentFirebaseOptions,
}

// export const firebaseOptions = {
//   BACKEND: 'https://us-central1-exsportia-dev.cloudfunctions.net/develop',
//   VMS_URL: 'https://vms.dev.exsportia.com',
//   EXSPORTIA_TERMINAL: 'exsportia',
//   API_KEY: 'AIzaSyAZ8_FE8k84w489s87rsU-4fa8dDtwGCRE',
//   AUTH_DOMAIN: 'exsportia-dev.firebaseapp.com',
//   DATABASE_URL: 'https://exsportia-dev.firebaseio.com',
//   PROJECT_ID: 'exsportia-dev',
//   STORAGE_BUCKET: 'exsportia-dev.appspot.com',
//   MESSAGING_SENDER_ID: '484903386861',
//   MEASUREMENT_ID: undefined,
//   APP_ID: '1:484903386861:web:8236374858bbace3f356e4',
//   CLIENT_ID: '484903386861-vp2q7pmv6dk8p676icnfsh8min85tcuc.apps.googleusercontent.com',
//   INFOPLIST_ID: 'com.googleusercontent.apps.484903386861-8cl1d2totj3o6ujpbolrcramupf0saqa'
// }

const env: 'development' | 'preprod' | 'production' | 'staging' =
  process.env.REACT_APP_ENV !== undefined ? process.env.REACT_APP_ENV : 'development'

export const isDevelopment = env === 'development'

export const firebaseOptions = firebaseOptionsMap[env]
export const VMS_URL = envsUrlsMap[env].VMS
export const WBA_URL = envsUrlsMap[env].WBA
export const BOOKING_URL = envsUrlsMap[env].BOOKING
