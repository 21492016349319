import {TextLoaderProps} from 'exsportia-components/dist/components/atoms/text-loader'

import {theme} from '../../../theme/theme'

export type GradientLoaderSettingsProps = TextLoaderProps

export const defaultSettings: GradientLoaderSettingsProps = {
  width: '100%',
  settings: {bg: theme.colors['gradient-loader-bg']},
}
