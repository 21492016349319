import {API} from '../../networking/http'
import {QueryMethod} from '../helpers/types'

export type UserSignInCheckParams = undefined
export type UserSignInCheckQueryParams = {
  email: string
  venueId?: string
}
export type UserSignInCheckQueryReturnType = {
  status: boolean
}
export type UserSignInCheckQuery = QueryMethod<
  UserSignInCheckParams,
  UserSignInCheckQueryParams,
  UserSignInCheckQueryReturnType
>

export const makeUserSignInCheckEndpoint = () => 'user/check/signIn'

export const userSignInCheckQuery: UserSignInCheckQuery = ({query}) => ({
  queryKey: ['UserSignInCheckQuery', query],
  cacheTime: 1000 * 60 * 60,
  queryFn: ({queryKey: [_, config]}) =>
    API.get<UserSignInCheckQueryParams, UserSignInCheckQueryReturnType>(makeUserSignInCheckEndpoint(), {
      params: config,
    }).then((res) => res.data),
})
