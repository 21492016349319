// Guide https://styled-system.com/theme-specification

const multiplier = 8

export const theme = {
  colors: {
    primary: '#ED3163',
    'icon-secondary': '#BFC1C7',
    'primary-secondary': 'rgba(237, 49, 99, 0.14)',
    'text-default': 'rgba(44, 45, 46, 1)',
    'text-contrast': '#FFFFFF',
    'text-secondary': '#919399',
    bg: '#FFFFFF',
    'bg-secondary': '#00103D',
    'sport-bg': 'rgba(237, 49, 99, 0.1)',
    black: '#000e1a',
    white: '#fff',
    blue: '#007ce0',
    navy: '#004175',
    dod: '#338811',
    success: '#0DC268',
    notify: '#FF9E00',
    error: '#ED0A34',
    border: '#65656588',
    'text-primary': '#2C2D2E',
    green_halftone: 'rgba(13, 194, 104, 0.1)',
    red_halftone: 'rgba(237, 10, 52, 0.1)',
    yellow_halftone: 'rgba(255, 158, 0, 0.1)',
    'gradient-loader-bg': '#BDBDBD',
    'class-bg': 'rgba(255, 158, 0, 0.1)',
    'just-the-space-bg': 'rgba(13, 194, 104, 0.1)',
    'just-the-space': 'rgba(13, 194, 104, 1)',
    class: 'rgba(255, 158, 0, 1)',
    sport: 'rgba(237, 49, 99, 1)',
    'toggle-off-bg': '#00103D0F',
  },
  space: {
    multiplier: `${multiplier}px`,
    multiplier5x: `${multiplier * 5}px`,
    multiplier2x: `${multiplier * 2}px`,
    multiplier3x: `${multiplier * 3}px`,
    multiplier6x: `${multiplier * 6}px`,
    multiplier8x: `${multiplier * 6}px`,
    multiplier0_5x: `${multiplier * 0.5}px`,
    multiplier1_5x: `${multiplier * 1.5}px`,
  },
  spaces: {
    multiplier: `${multiplier}px`,
    multiplier5x: `${multiplier * 5}px`,
    multiplier2x: `${multiplier * 2}px`,
    multiplier3x: `${multiplier * 3}px`,
    multiplier6x: `${multiplier * 6}px`,
    multiplier8x: `${multiplier * 6}px`,
    multiplier0_5x: `${multiplier * 0.5}px`,
    multiplier1_5x: `${multiplier * 1.5}px`,
  },
  fontSizes: {
    h1: '24px',
    h2: '20px',
    h3: '18px',
    tag: '16px',
    label: '13px',
  },
  lineHeights: {'card-title-line-height': '24px', 'card-subtext-line-height': '20px'},
  fontWeights: {bold: '600'},
  borders: {'default-border': '1px solid rgba(0, 16, 61, 0.12)'},
  radii: {multiplier2x: `${multiplier * 2}px`},
  sizes: {multiplier2x: `${multiplier * 2}px`},
}
/* Object.keys(theme).map(key => {
  Theme[key] = theme[key as keyof typeof theme]
})

Object.keys(Theme).map(key => {
  if (!theme[key as keyof typeof theme]) {
    delete Theme[key]
  }
})*/
