import {Box, Flex, Text} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {FC} from 'react'
import {useQuery} from 'react-query'

import {getLocationQuery} from '../../../api/user/get-user-location'
import {SignUpFormType} from '../../../services/auth/forms/sign-up-form'
import {useAppLoading} from '../../../services/global/hooks'
import {Button} from '../../../ui/components/button/button'
import {PhoneInput} from '../../../ui/components/phone-input/phone-input'
import {useLocale} from '../../../utils/i18n'

type PhoneSignUpStepProps = {
  handleGoToNextStep: () => void
  backAction?: (e: Event) => void
}

export const PhoneSignUpStep: FC<PhoneSignUpStepProps> = ({handleGoToNextStep, backAction}) => {
  const {l} = useLocale()
  const {isLoading} = useAppLoading()
  const {validateForm} = useFormikContext<SignUpFormType>()
  const {data: location} = useQuery(getLocationQuery({}))
  const handleNext = () => {
    (async () => {
      const validateResult = await validateForm()
      if (!validateResult.phone) {
        handleGoToNextStep()
      }
    })()
  }

  return (
    <Box pb={'24px'}>
      <Flex mb='16px'>
        <Text text={l('enter_your_phone_number', {caseAction: 'capitalize'})} />
      </Flex>
      <PhoneInput
        label={l('phoneNumber')}
        id='phone'
        countryCode={location?.country_code.toLowerCase()}
      />
      <Flex
        gap='16px'
        mt={'16px'}
      >
        {backAction && (
          <Button
            disabled={isLoading}
            type='secondary'
            onClick={backAction}
          >
            {l('back')}
          </Button>
        )}
        <Button onClick={handleNext}>{l('next')}</Button>
      </Flex>
    </Box>
  )
}
