import {Box, Flex, Text} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {FC} from 'react'

import {useAppLoading} from '../../services/global/hooks'
import {Button} from '../../ui/components/button/button'
import {Input} from '../../ui/components/input/input'
import {useLocale} from '../../utils/i18n'

type SignInStepProps = {
  backAction?: (e: Event) => void
}

export const SignInStep: FC<SignInStepProps> = ({backAction}) => {
  const {l} = useLocale()
  const {isLoading} = useAppLoading()
  const {handleSubmit} = useFormikContext()

  return (
    <Box pb={'24px'}>
      <Flex mb='16px'>
        <Text text={l('enter_your_password', {caseAction: 'capitalize'})} />
      </Flex>
      <Input
        id={'password'}
        placeholder={'password'}
        type='password'
      />
      <Flex
        gap='16px'
        mt={'16px'}
      >
        {backAction && (
          <Button
            disabled={isLoading}
            onClick={backAction}
            type='secondary'
          >
            {l('back')}
          </Button>
        )}
        <Button
          isLoading={isLoading}
          // @ts-ignore
          onClick={handleSubmit}
        >
          {l('signin')}
        </Button>
      </Flex>
    </Box>
  )
}
