import {IconWithTextSettingsType} from 'exsportia-components/dist/components/molecules/icon-with-text'
import {IconType, StyleCommonType, StyleSizeSpaceType} from 'exsportia-components/dist/types'

import {theme} from '../../../theme/theme'

export type EntityCardSettingsProps = {
  p?: StyleSizeSpaceType
  chevronIcon?: IconType
  display?: StyleCommonType
  width?: StyleSizeSpaceType
  height?: StyleSizeSpaceType
  alignItems?: StyleCommonType
  chevronColor?: StyleCommonType
  textLoaderHeight?: number | string
  iconLoaderHeight?: number | string
  subTextLoaderHeight?: number | string
  iconWithText?: IconWithTextSettingsType
}

export const defaultSettings: EntityCardSettingsProps = {
  width: '100%',
  textLoaderHeight: '20px',
  alignItems: 'center',
  height: 'max-content',
  display: 'inline-flex',
  subTextLoaderHeight: '18px',
  iconLoaderHeight: '48px',
  p: '0px',
  iconWithText: {
    text: {fontSize: '18px'},
    icon: {bg: theme.colors['sport-bg'], color: theme.colors.sport},
  },
}

export const classSettings: EntityCardSettingsProps = {
  ...defaultSettings,
  iconWithText: {icon: {bg: theme.colors['class-bg'], color: theme.colors.class}},
}

export const justTheSpaceSettings: EntityCardSettingsProps = {
  ...defaultSettings,
  iconWithText: {icon: {bg: theme.colors['just-the-space-bg'], color: theme.colors['just-the-space']}},
}

export const typeStylesMap = {
  class: classSettings,
  service: defaultSettings,
  justTheSpace: justTheSpaceSettings,
}

export const additionalStylesMap = {}
