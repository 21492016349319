import {useMemo} from 'react'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'

import {VenueQueries} from '../../api/venue'
import {detectPlatform} from '../../helpers/other'

export const TEMP_BANNED_APP = 'Exsportia'

const queryFunc = VenueQueries.getVenueDisplaySettingsQuery

export const useVenueDisplaySettings = (conf?: Parameters<typeof queryFunc>) => {
  const {venueId} = useParams<{venueId: string}>()
  return useQuery(
    queryFunc({
      ...conf,
      params: {venueId}
    })
  )
}

export const useAppDownloadData = () => {
  const {data, isLoading} = useVenueDisplaySettings()
  return useMemo(() => {
    if (!data) {
      return {isLoading: true}
    }
    return {
      downloadLink: data.appLinks[detectPlatform()],
      appIcon: data.appLinks.app_icon,
      appName: data.appLinks.app_name,
      isLoading
    }
  }, [data, isLoading])
}
