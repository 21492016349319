import {Box, Flex, Text} from 'exsportia-components'
import {useFormikContext} from 'formik'
import {FC, useCallback} from 'react'

import {ForgotPasswordForm} from '../../services/auth/forms/forgot-password-form'
import {Button} from '../../ui/components/button/button'
import {Input} from '../../ui/components/input/input'
import {useLocale} from '../../utils/i18n'
import {useModalActions} from '../../utils/modals'

export const CheckEmailStep: FC = () => {
  const {l} = useLocale()
  const {openModal} = useModalActions()
  const {handleSubmit} = useFormikContext()
  const handleOpenForgotPasswordModal = useCallback(
    () =>
      openModal({
        component: <ForgotPasswordForm/>,
        title: l('forgotPassword'),
        footerConfig: {
          disabled: true
        }
      }),
    [openModal, l],
  )
  return (
    <Box pb={'24px'}>
      <Flex mb='16px'>
        <Text text={l('enter_your_email', {caseAction: 'capitalize'})} />
      </Flex>
      <Input
        id={'email'}
        placeholder={l('email')}
      />
      <Button
        type='flat'
        onClick={handleOpenForgotPasswordModal}
      >
        {l('forgotPassword')}
      </Button>
      <Box mt={'16px'}>
        <Button
          // @ts-ignore
          onClick={handleSubmit}
        >
          {l('continue')}
        </Button>
      </Box>
    </Box>
  )
}
