import {showMessage} from 'exsportia-components'

import {API} from '../../networking/http'
import {MutationMethod} from '../helpers/types'
import {CommonServiceBookingDraftType, PayByTokenServiceBookingDraft} from './helpers'
import {Booking} from './types'

export type CreateServiceBookingParams = undefined
export type CreateServiceBookingMutationParams = CommonServiceBookingDraftType | PayByTokenServiceBookingDraft
export type CreateServiceDraftBookingMutationParams = PayByTokenServiceBookingDraft
export type CreateServiceBookingMutationReturnType = Booking
export type CreateServiceBookingMutation = MutationMethod<
  CreateServiceBookingParams,
  CreateServiceBookingMutationParams,
  CreateServiceBookingMutationReturnType
>

export const makeCreateServiceBookingEndpoint = (id: string) => `bookings/sport/${id}`
export const createBookingPayByTokenEndpoint = 'bookings/byToken'
export const createBookingDraftEndpoint = 'bookings/draft'

export const createServiceBookingMutation: CreateServiceBookingMutation = () => ({
  mutationFn: (bookingDraft) =>
    API.post<CreateServiceBookingMutationParams, CreateServiceBookingMutationReturnType>(
      makeCreateServiceBookingEndpoint(bookingDraft.uid),
      {
        data: bookingDraft,
      },
    ).then((res) => res.data),
})

export const createServiceBookingPayByTokenMutation: CreateServiceBookingMutation = () => ({
  mutationFn: (bookingDraft) =>
    API.post<CreateServiceBookingMutationParams, CreateServiceBookingMutationReturnType>(
      createBookingPayByTokenEndpoint,
      bookingDraft,
    )
      .then((res) => res.data)
      .catch((error) => {
        showMessage(`Error payment booking: ${error?.response?.data?.message ?? 'Payment Failed'}`, 'error')
        throw error
      }),
})

export const createServiceBookingDraftMutation: CreateServiceBookingMutation = () => ({
  mutationFn: (bookingDraft) =>
    API.post<CreateServiceDraftBookingMutationParams, CreateServiceBookingMutationReturnType>(
      createBookingDraftEndpoint,
      bookingDraft,
    )
      .then((res) => res.data)
      .catch((error) => {
        showMessage(`Error payment booking: ${error?.response?.data?.message ?? 'Payment Failed'}`, 'error')
        throw error
      }),
})

