import '../styles.scss'

import {FC, JSX, PropsWithChildren, useMemo} from 'react'

type Props = {
  withoutPadding?: boolean
  header: JSX.Element | string
}
export const GridLayout: FC<PropsWithChildren<Props>> = ({children, header, withoutPadding = false}) => {
  const boxes = useMemo(() => {
    let result = [children]
    if (children && Array.isArray(children)) {
      result = children as React.ReactNode[]
    }
    return result
  }, [children])
  if (!children) {
    return null
  }
  return (
    <div className={'grid-layout_center'}>
      <div className={`grid-layout_wrapper grid-layout_wrapper_${boxes.length}`}>
        <div className={'grid-layout_header_wrapper'}>
          <div className={'grid-layout_header_content'}>{header}</div>
        </div>
        {boxes.map((child, i) => (
          <div
            key={`chilld${i}`}
            className={`grid-layout_box ${i > 0 ? 'grid-layout_box-secondary' : ''}`}
          >
            <div
              className={`grid-layout_box_content ${i > 0 ? 'grid-layout_box_content-secondary' : ''} ${
                !withoutPadding && 'grid-layout_box_content_padding'
              }`}
            >
              {child}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
