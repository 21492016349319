import {BookingMutations, BookingQueries} from '../../api/booking'
import {basicActions, cancelBooking, checkClassPasscode, checkServicePasscode} from './actions'
import {basicSelectors, someCoolSelector} from './selectors'

export const BookingService = {
  actions: {
    ...basicActions,
    checkClassPasscode,
    checkServicePasscode,
    cancelBooking,
  },
  selectors: {...basicSelectors, someCoolSelector},
  queries: BookingQueries,
  mutations: BookingMutations,
} as const
