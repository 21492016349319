import {ButtonSettingsProps} from 'exsportia-components/dist/components/atoms/button'

const defaultButtonSettings: ButtonSettingsProps = {
  height: '64px',
  text: {
    color: 'rgba(44, 45, 46, 1)',
  },
}

const activeButtonSettings: ButtonSettingsProps = {
  ...defaultButtonSettings,
  text: {
    color: 'rgba(237, 49, 99, 1)',
  },
}

const defaultMobileButtonSettings: ButtonSettingsProps = {
  width: '100%',
  height: '56px',
  flexDirection: 'column',
  p: '0 4px',
  m: '0 4px',
  icon: {
    color: 'rgba(191, 193, 199, 1)',
  },
  text: {
    fontSize: '13px',
    m: '4px 0 0 0',
    color: 'rgba(191, 193, 199, 1)',
  },
}

const activeMobileButtonSettings: ButtonSettingsProps = {
  ...defaultMobileButtonSettings,
  icon: {
    color: 'rgba(44, 45, 46, 1)',
  },
  text: {
    ...defaultMobileButtonSettings.text,
    color: 'rgba(44, 45, 46, 1)',
  },
}

export const settings = {
  default: {
    active: activeButtonSettings,
    default: defaultButtonSettings,
  },
  mobile: {
    active: activeMobileButtonSettings,
    default: defaultMobileButtonSettings,
  },
}
