// eslint-disable-next-line no-restricted-imports
import {BrowserTracing, captureException, captureMessage, init, Replay, Scope} from '@sentry/react'

import {APIDomain} from '../networking/constants'
import {isDevelopment, sentryDSN} from './environment'

init({
  dsn: sentryDSN,
  integrations: [
    new BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', APIDomain],
    }),
    new Replay(),
  ],
  enabled: !isDevelopment,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
export const logErrorWithSentry = (error: Error, message: string, extra = {} as any) => {
  console.error(message)
  if (isDevelopment) {
    return
  }
  const scope = new Scope()
  scope.setExtra('Message', message)
  Object.keys(extra).map((key) => scope.setExtra(key, extra[key]))
  captureException(error, scope)
}

export const logWarningWithSentry = (message: string) => {
  console.warn(message)
  if (isDevelopment) {
    return
  }
  captureMessage(message, 'warning')
}

export const sentry = {error: logErrorWithSentry, warn: logWarningWithSentry}
