import queryString from 'query-string'
import {FC} from 'react'
import {useLocation} from 'react-router-dom'

import {GridLayout} from '../../layout/grid-layout/grid-layout'
import {SimpleGridLayoutHeader} from '../../layout/grid-layout/headers/simple-grid-layout-header'
import {ResetPasswordForm} from '../../services/auth/forms/reset-password-form'
import {useLocale} from '../../utils/i18n'

const Header = () => {
  const {l} = useLocale()
  return <SimpleGridLayoutHeader noBack={true} title={l('resetPassword')} />
}

export const ResetPasswordPage: FC = () => {
  const {search} = useLocation()
  const {oobCode} = queryString.parse(search)
  return (
    <GridLayout header={<Header />}>
      <>
        <ResetPasswordForm oobCode={oobCode} />
      </>
    </GridLayout>
  )
}
