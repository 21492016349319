import {Box, Flex, Icon, Span} from 'exsportia-components'
import {IconSettingsProps} from 'exsportia-components/dist/components/atoms/icon'
import React, {memo, useMemo} from 'react'

type Props = {
  images: string[]
  onStackClick: () => void
  maxImagesToStack?: number
  imageSize?: number
}
const getManyImagesOffset = (
  max: number,
  index: number,
  offset: number,
) => (
  `${offset * (max - index)}px`
)
const getFewImagesOffset = (
  offset: number,
  index: number,
  length: number
) => (
  `${offset * (length - index - 1)}px`
)
const getImagesOffset = (
  index: number,
  length: number,
  max: number,
  offset: number,
) => (
  length > max
    ? getManyImagesOffset(max, index, offset)
    : getFewImagesOffset(offset, index, length)
)

export const ImagesStack = memo<Props>(
  ({
     images,
     onStackClick,
     imageSize = 32,
     maxImagesToStack = 3,
   }) => {
    const iconSettings: IconSettingsProps = useMemo(() => ({
        overflow: 'hidden',
        iconHeight: `${imageSize}px`,
        iconWidth: `${imageSize}px`,
        width: `${imageSize}px`,
        height: `${imageSize}px`
      }),
      [imageSize]
    )

    const iconBoxSettings = useMemo(() => ({
        width: `${imageSize}px`,
        height: `${imageSize}px`,
        backgroundSize: 'cover',
      }),
      [images]
    )

    const length = useMemo(() => images.length, [images])

    return (
      <Box
        position={'relative'}
        height={'100%'}
        cursor='pointer'
        onClick={(e: Event) => {
          e.stopPropagation()
          onStackClick()
        }}
      >
        <Flex
          height={'100%'}
          width='max-content'
          alignItems={'center'}
        >
          {
            Boolean(images.length)
            && (
              <>
                {
                  images
                    .slice(0, maxImagesToStack)
                    .map((image, index) => {
                      const offset = getImagesOffset(
                        index,
                        length,
                        maxImagesToStack,
                        imageSize / 3 * 2
                      )
                      return (
                        <Box
                          position={'absolute'}
                          right={offset}
                        >
                          <Icon
                            key={`participant-${index}`}
                            settings={iconSettings}
                            icon={
                              <Box
                                {...iconBoxSettings}
                                background={`url(${image}) center`}
                              />
                            }
                          />
                        </Box>
                      )
                    })
                }
                {
                  length > maxImagesToStack
                  && (
                    <Flex
                      position={'absolute'}
                      width={`${imageSize}px`}
                      height={`${imageSize}px`}
                      alignItems={'center'}
                      justifyContent={'center'}
                      bg={'icon-secondary'}
                      borderRadius={`${imageSize / 2}px`}
                      right={0}
                    >
                      <Span
                        color={'text-contrast'}
                        fontSize={'label'}
                        lineHeight={'14px'}
                      >
                        {
                          `+${length - maxImagesToStack}`
                        }
                      </Span>
                    </Flex>
                  )
                }
              </>
            )
          }
        </Flex>
      </Box>
    )
  }
)