import {Box, Flex, Icon, Theme, Title} from 'exsportia-components'
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch} from 'react-redux'

import {BrandType} from '../../../api/user/types'
import {PAYMENT_PROVIDER_OPN, PAYMENT_PROVIDER_STRIPE, PAYMENT_PROVIDER_TOTAL_PROCESSING} from '../../../constants/payments'
import {uidMaker, UIDTypesEnum} from '../../../helpers/logic'
import {APIDomain} from '../../../networking/constants'
import {useAuthState} from '../../../services/auth/hooks'
import {useBookingLoader} from '../../../services/booking/hooks'
import {PaymentService} from '../../../services/payments'
import {createOpmTokenRequest, createStripeCheckoutRequest} from '../../../services/payments/actions'
import {UserService} from '../../../services/user'
import {setEntityForPayRequestAction} from '../../../services/user/actions'
import {Button} from '../../../ui/components/button/button'
import {GradientLoader} from '../../../ui/components/gradient-loader/gradient-loader'
import {RadioButton} from '../../../ui/components/radio-button'
import {auth} from '../../../utils/firebase'
import {useLocale} from '../../../utils/i18n'
import {OPN_PUBLIC_KEY} from '../../../utils/opn-provider'

export type UserPaymentsMethodsType = {
  venueId?: string
  isLoading?: boolean
  paymentMethod: string | null
  setPaymentMethod: (paymentMethod: string) => void
}

export type UserPaymentsMethodsModalPropsTypes = UserPaymentsMethodsType & {
  handleCancel: () => void
  handleSubmit: ({paymentMethodId}: {paymentMethodId: string}) => void
}

const paymentMethodIconsMap = {
  visa: 'visa',
  mastercard: 'masterclass',
  americanExpress: 'americanExpress',
}

export const UserPaymentsMethods: FC<UserPaymentsMethodsType> = ({
  venueId,
  isLoading = false,
  paymentMethod,
  setPaymentMethod,
}) => {
  const dispatch = useDispatch()
  const {l} = useLocale()
  const {isLoading: isBookingLoading} = useBookingLoader()
  const {isLoggedIn, signIn} = useAuthState(venueId)
  const {data} = useQuery(UserService.queries.getUserPaymentMethodsQuery({params: {venueId}}))
  const {data: terminal} = useQuery(PaymentService.queries.getTerminalQuery({params: {venueId}}))
  const {data: user, isFetching} = useQuery(UserService.queries.GetUserQuery({cacheTime: 0, params: {id: auth.currentUser?.uid}}))

  useEffect(() => {
    if (data?.paymentMethods && data.paymentMethods.length !== 0) {
      setPaymentMethod(data.paymentMethods[0].uid)
    }
  }, [data?.paymentMethods])

  const handleAddPaymentMethod = useCallback(() => {
    if (!isLoggedIn) {
      signIn()
      return null
    }
    if (!user?.uid || !venueId) {
      return null
    }
    if (terminal?.provider === PAYMENT_PROVIDER_OPN) {
      const OmiseCard = (window as any).OmiseCard
      if (OmiseCard) {
        OmiseCard.configure({
          publicKey: OPN_PUBLIC_KEY,
        })
        OmiseCard.open({
          submitLabel: 'Save Card',
          amount: 100,
          currency: 'THB',
          onCreateTokenSuccess: (token: string) => {
            dispatch(createOpmTokenRequest({token, userId: user.uid, venueId, isWba: true}))
          },
        })
      }
    } else if (terminal?.provider === PAYMENT_PROVIDER_STRIPE) {
      dispatch(createStripeCheckoutRequest({userId: user.uid, venueId, isWba: true}))
    } else if (terminal?.provider === PAYMENT_PROVIDER_TOTAL_PROCESSING) {
      window.open(`/clear-view/${venueId}/user/${user.uid}/add-payment-method`, '_blank')
    } else if (terminal?.name) {
      const entityId = uidMaker(10, UIDTypesEnum.NUMBERS)
      const draft = {
        entityId,
        totalPrice: 1,
        collection: 'paymentMethodsOnAdd',
        currency: 'USD',
        notifyUrl: `${APIDomain}payments/${entityId}/beforeSave`,
        failedUrl: `${APIDomain}payments/${entityId}/afterSave`,
        successUrl: `${APIDomain}payments/${entityId}/afterSave`,
      }
      const TZPaymentData = {
        venueId,
        sum: '1',
        isWba: 'true',
        currency: '1',
        tranmode: 'VK',
        pdesc: entityId,
        fail_url_address: `${APIDomain}payments/${entityId}/afterSave`,
        notify_url_address: `${APIDomain}payments/${entityId}/beforeSave`,
        success_url_address: `${APIDomain}payments/${entityId}/afterSave`,
      }
      dispatch(
        setEntityForPayRequestAction({
          entityId,
          venueId,
          userId: user.uid,
          collection: draft.collection,
        }),
      )
      const actionUrl = `https://direct.tranzila.com/${terminal.name}/iframenew.php`
      const generatedUrl = `${actionUrl}?${new URLSearchParams(TZPaymentData).toString()}`
      window.open(generatedUrl, '_blank')
    }
  }, [user, terminal, dispatch])

  const typeToSelectMarkup = useMemo(
    () => {
      if (isFetching || isLoading) {
        return <GradientLoader height={'36px'} />
      }
      return (
        <>
          {data?.paymentMethods && data.paymentMethods.length !== 0 ? (
            <Flex flexDirection='column' gap={0}>
              {data.paymentMethods.map((method, index) => {
                const brand = method.brand || ''
                const paymentMethodIcon = brand ? paymentMethodIconsMap[brand.toLowerCase() as BrandType] || 'creditCard' : 'creditCard'
                return (
                  <Flex
                    alignItems='center'
                    height={Theme?.spaces?.multiplier * 6}
                    key={`payment-method-${index}`}
                  >
                    <RadioButton
                      checked={method.uid === paymentMethod}
                      onChange={() => setPaymentMethod(method.uid)}
                      disabled={isBookingLoading}
                      text={
                        <Flex alignItems='center'>
                          <Icon
                            icon={paymentMethodIcon}
                            settings={{color: Theme.colors.icon, width: '48px', height: '48px', iconWidth: '32px', iconHeight: '32px'}}
                          />
                          <Box mx='8px'>{`•••• ${method.last4Digits}`}</Box>
                        </Flex>
                      }
                    />
                  </Flex>
                )
              })}
            </Flex>
          ) : null}
        </>
      )
    }, [data, paymentMethod, isLoading, isBookingLoading, isFetching],
  )

  return (
    <>
      <Box mt='16px'>
        <Box mb='8px'>
          <Title styleType='h4' text={l('mySavedCards')} />
        </Box>
        {typeToSelectMarkup}
        <Button
          icon='plus'
          type='flat_short'
          disabled={isLoading}
          onClick={handleAddPaymentMethod}
        >
          <Box ml={Theme?.spaces?.multiplier}>{l('addNewCard')}</Box>
        </Button>
      </Box>
    </>
  )
}

export const UserPaymentsMethodsModal: FC<UserPaymentsMethodsModalPropsTypes> = ({
  venueId,
  handleCancel,
  handleSubmit,
}) => {
  const {l} = useLocale()
  const {isLoading} = useBookingLoader()
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null)
  const {data} = useQuery(UserService.queries.getUserPaymentMethodsQuery({params: {venueId}}))

  const handleSelectPaymentMethod = useCallback(() => {
    if (!paymentMethod) {
      return
    }
    handleSubmit({paymentMethodId: paymentMethod})
  }, [handleSubmit, paymentMethod])

  useEffect(() => {
    if (data?.paymentMethods && data.paymentMethods.length !== 0) {
      setPaymentMethod(data.paymentMethods[0].uid)
    }
  }, [data?.paymentMethods])

  return (
    <>
      <UserPaymentsMethods paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
      <Flex justifyContent='flex-end'>
        <Flex
          p={['8px 0', '12px 0']}
          width='min-content'
        >
          <Flex mx='8px'>
            <Button
              type='flat'
              disabled={isLoading}
              onClick={handleCancel}
            >
              {l('cancel')}
            </Button>
          </Flex>
          <Flex width='min-content'>
            <Button
              isLoading={isLoading}
              onClick={handleSelectPaymentMethod}
            >
              {l('pay')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
