import {showMessage} from 'exsportia-components'

import {API} from '../../networking/http'
import {MutationMethod} from '../helpers/types'
import {CommonServiceBookingDraftType, PayByTokenServiceBookingDraft} from './helpers'
import {Booking} from './types'

export type CreateInstructorBookingParams = undefined
export type CreateInstructorBookingMutationParams = CommonServiceBookingDraftType | PayByTokenServiceBookingDraft
export type CreateInstructorBookingMutationReturnType = Booking
export type CreateInstructorBookingMutation = MutationMethod<
  CreateInstructorBookingParams,
  CreateInstructorBookingMutationParams,
  CreateInstructorBookingMutationReturnType
>

export const makeCreateInstructorBookingEndpoint = (id: string) => `bookings/sport/${id}`
export const createBookingPayByTokenEndpoint = 'bookings/byToken'

export const createInstructorBookingMutation: CreateInstructorBookingMutation = () => ({
  mutationFn: (bookingDraft) =>
    API.post<CreateInstructorBookingMutationParams, CreateInstructorBookingMutationReturnType>(
      makeCreateInstructorBookingEndpoint(bookingDraft.uid),
      {
        data: bookingDraft,
      },
    ).then((res) => res.data),
})

export const createInstructorBookingPayByTokenMutation: CreateInstructorBookingMutation = () => ({
  mutationFn: (bookingDraft) =>
    API.post<CreateInstructorBookingMutationParams, CreateInstructorBookingMutationReturnType>(
      createBookingPayByTokenEndpoint,
      bookingDraft,
    ).then((res) => res.data)
    .catch((error) => {
      showMessage(`Error payment booking: ${error?.response?.data?.message ?? 'Payment Failed'}`, 'error')
      throw error
    }),
})

