import {Tag} from 'exsportia-components'
import {IconType, TextType} from 'exsportia-components/dist/types'
import React, {FC} from 'react'

import {typeStylesMap} from './settings'

export type ChipProps = {
  text?: TextType
  icon?: IconType
  styleType?: keyof typeof typeStylesMap
}

export const Chip: FC<ChipProps> = ({text, icon, styleType = 'service'}) => {
  const settingsToUse = typeStylesMap[styleType]

  return (
    <Tag
      icon={icon}
      text={text}
      settings={settingsToUse}
    />
  )
}
